import React, { useState } from "react";
// import { makeStyles } from '@mui/material/styles';
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Grow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BreadcrumbMenu from "../../layout/BreadcrumbMenu";
import ImageSlider from "../../layout/imageslider";
import faqContent from "./faqsViewContent";
import "./faqs.css";
// const useStyles = makeStyles(() => ({
//     root: {
//       flexGrow: 1,
//       padding: '30px',
//     },
//     body: {
//         color: '#1A355D',
//         padding: '20px',
//     },
//     typeRoot: {
//         padding: '10px 0',
//         textAlign: 'left'
//     },
//     ques:{
//         padding: '0 16px',
//         color: '#d04e53',
//         textAlign: 'left'
//     },
//     accordionBody: {
//         backgroundColor: '#738db33d',
//         padding: '40px'
//     }
//   }));

const FaqView = () => {
  // const classes = useStyles();

  // useEffect(() => {
  //     window.scrollTo(0, 0);
  // });
  const [expandedAccordion, setExpandedAccordion] = useState(
    faqContent[0]?.key
  );
  const handleAccordionToggle = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };
  return (
    <div align="center" style={{ flexGrow: 1, padding: "30px" }}>
      {/* <ImageSlider /> */}
      <Grow in={true} timeout={1500}>
        <Grid container style={{ color: "#1A355D", padding: "20px" }}>
          <Grid item xs={12}>
            <BreadcrumbMenu
              currComp="FAQs"
              parentComp="Home"
              parentCompRoute="/"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ color: "rgb(53, 103, 178)" }}>
              <b>Frequently Asked Questions</b>
            </Typography>
          </Grid>
        </Grid>
      </Grow>
      <Grid id="accordionsgrid" >
        {" "}
        {faqContent.map((item, index) => (
          <Accordion
            key={item.key}
            expanded={
              item.key === index[0]
                ? handleAccordionToggle(0)
                : expandedAccordion === item.key
            }
            onChange={handleAccordionToggle(item.key)}
            style={{ marginBottom: "10px", backgroundColor: "#738db33d" }}
          >
            <Grow in={true} timeout={1500}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={index + item.key}
                id={index + item.key}
              >
                <Typography
                  id="question"
                  // variant="h6"
                  style={{
                    padding: "0 16px",
                    color: "rgb(53, 103, 178)",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  {item.question}
                </Typography>
              </AccordionSummary>
            </Grow>
            <AccordionDetails style={{ backgroundColor: "white" }}>
              <Grid container direction="column">
                {item.answer.map((ans) => (
                  <Grid item key={ans.key}>
                    {ans.isLink ? (
                      <Link to={`/${ans?.linkTo}`}>
                        <Typography
                          variant="body1"
                          style={{ textAlign: "justify" }}
                        >
                          {ans.value}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography
                        variant="body1"
                        style={{ textAlign: "justify" }}
                      >
                        <br /> {ans.value}
                      </Typography>
                    )}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </div>
  );
};

export default FaqView;
