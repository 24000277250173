// RootLayout.js
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import HeaderMenu from "./appbar";
import PayPalDonateView from "../PaypalDonate/paypaldonateview";
import ScrollUpButton from "react-scroll-up-button";
import { Grid } from "@mui/material";
import Ads from "../home/ads";
import "./rootlayout.css"; // Import the CSS file here
import Headermenu0 from "./appbar1";
import { NotificationContainer } from "react-notifications";

function RootLayout() {
  return (
    <div >
      <Headermenu0 />
      <div>
        <div className="main_div">
          {/* <HeaderMenu /> */}
          <Grid container className="main-content" style={{paddingTop:"120px"}}>
            <Outlet />
          </Grid>
          <PayPalDonateView />
          <ScrollUpButton className="scroll-up-button" />
        </div>
        <div className="ads-container">
          <Ads/>
        </div>
      </div>
      <Footer />
      <NotificationContainer/>
    </div>
  );
}
export default RootLayout;
