import React from "react";
import { Typography, Link, List, ListItem, Box } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Grid,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageSlider from "../../layout/imageslider";
import "./guidelines.css";

const HomeStudyGuide = () => {
  return (
    <div style={{ width: "100%" }}>
      {/* <ImageSlider /> */}
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "80%",
            backgroundColor: "white",
            margin: "20px",
            padding: "10px",
          }}
        >
          <Grid style={{ textAlign: "left", padding: "10px" }}>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "bold",
                textAlign: "center",
                color: "navy",
                textDecoration: "underline",
                marginBottom: "20px",
              }}
            >
              Guidelines for Foster and Adoptive Home Studies
            </Typography>

            <Typography paragraph>
              These guidelines are used by workers to complete foster and
              adoptive home studies. The guidelines indicate the issues that are
              required to be addressed with prospective foster and adoptive
              families. The minimum standards cited in this appendix appear in
              the{" "}
              <Link href="https://www.hhs.texas.gov/sites/default/files/documents/doing-business-with-hhs/provider-portal/protective-services/ccl/min-standards/chapter-749-cpa.pdf">
                Minimum Standards for Child-Placing Agencies
              </Link>{" "}
              published by HHS Child Care Licensing.
            </Typography>

            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              View individual sections below:
            </Typography>

            <Typography paragraph>
              <Typography>
                * for adoption purposes, the Home Study is also known as a{" "}
                <span style={{ fontWeight: "bold" }}>
                  Pre-adoptive Home Screening
                </span>
              </Typography>
            </Typography>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "red",
              width: "100%",
            }}
            id="div1"
          >
            <Grid
              item
              lg={10}
              md={10}
              sm={12}
              xs={12}
              style={{ width: "100%",}}
            >
              <Table
                // item
                // lg={10}
                // md={10}
                // sm={12}
                // xs={12}
                component={Paper}
                sx={{
                  marginTop: 4,
                  // width:"100%",
                  marginBottom: 4,
                  backgroundColor: "#F9F9F9",
                  border: "solid lightblue",
                  borderWidth: "1px",
                }}
              >
                <TableBody style={{width: "100%" }}>
                  {/* General Information Title */}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold" }}
                        component="div"
                      >
                        General Information
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Home Name */}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight="bold"
                      >
                        Home Name:
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Address Details */}
                  <TableRow>
                    <TableCell>Street Ln 1:</TableCell>
                    <TableCell>Street Ln 2:</TableCell>
                    <TableCell>City:</TableCell>
                    <TableCell>County:</TableCell>
                    <TableCell>State:</TableCell>
                    <TableCell>Zip:</TableCell>
                  </TableRow>

                  {/* Spacer Row */}
                  <TableRow>
                    <TableCell colSpan={6}>&nbsp;</TableCell>
                  </TableRow>

                  {/* Contact Information */}
                  <TableRow>
                    <TableCell>Phone:</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell colSpan={2}>Phone Extension:</TableCell>
                    <TableCell colSpan={2}>Email:</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>&nbsp;</TableCell>
                  </TableRow>

                  {/* Heads of Household Title */}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold" }}
                        component="div"
                      >
                        Heads Of Household
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Name Row */}
                  <TableRow>
                    <TableCell>Name:</TableCell>
                    <TableCell colSpan={5}>&nbsp;</TableCell>
                  </TableRow>

                  {/* Detailed Information */}
                  <TableRow>
                    <TableCell>Age:</TableCell>
                    <TableCell>DOB:</TableCell>
                    <TableCell>Ethnicity:</TableCell>
                    <TableCell>Sex:</TableCell>
                    <TableCell colSpan={2}>Languages Spoken:</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Education:</TableCell>
                    <TableCell>Occupation</TableCell>
                    <TableCell colSpan={2}>Marital Status:</TableCell>
                    <TableCell colSpan={2}>Date Married (If Married)</TableCell>
                  </TableRow>
                  {/* Empty Spacer Row */}
                  <TableRow>
                    <TableCell colSpan={6}>&nbsp;</TableCell>
                  </TableRow>

                  {/* Name Field */}
                  <TableRow>
                    <TableCell valign="top">Name:</TableCell>
                    <TableCell colSpan={5}>&nbsp;</TableCell>
                  </TableRow>

                  {/* Age, DOB, Ethnicity, Sex, Languages Spoken */}
                  <TableRow>
                    <TableCell valign="top">Age:</TableCell>
                    <TableCell valign="top">DOB:</TableCell>
                    <TableCell valign="top">Ethnicity:</TableCell>
                    <TableCell valign="top">Sex:</TableCell>
                    <TableCell colSpan={2}>Languages Spoken:</TableCell>
                  </TableRow>

                  {/* Education, Occupation, Marital Status, Date Married */}
                  <TableRow>
                    <TableCell valign="top">Education:</TableCell>
                    <TableCell valign="top">Occupation</TableCell>
                    <TableCell valign="top" colSpan={2}>
                      Marital Status:
                    </TableCell>
                    <TableCell colSpan={2} valign="top">
                      Date Married (If Married)
                    </TableCell>
                  </TableRow>

                  {/* Home Annual Income */}
                  <TableRow>
                    <TableCell colSpan={6} valign="top">
                      Home Annual Income:
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold" }}
                        component="div"
                      >
                        Other Household Members
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>Name:</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Age:</TableCell>
                    <TableCell>DOB:</TableCell>
                    <TableCell>Ethnicity:</TableCell>
                    <TableCell>Sex:</TableCell>
                    <TableCell>Education:</TableCell>
                    <TableCell>Occupation:</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>Role In Home:</TableCell>
                  </TableRow>
                  <TableRow bgcolor="#FFFFCC">
                    <TableCell colSpan={6}>
                      <em>
                        (Note: If There Are More Than one "Other Household
                        Members" Then Repeat The "Name" Through "Role In Home"
                        Sequence.)
                      </em>
                    </TableCell>
                  </TableRow>

                  {/* Space Row */}
                  <TableRow>
                    <TableCell colSpan={6}>&nbsp;</TableCell>
                  </TableRow>

                  {/* Initial Inquiry Date */}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold" }}
                        component="div"
                      >
                        Initial Inquiry Date:
                      </Typography>
                      ___ / ___ / ___
                    </TableCell>
                  </TableRow>

                  {/* Pre-Service Training and Consultations */}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold" }}
                        component="div"
                      >
                        Pre-Service Training and Consultations
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      Attended Pre-Service Training:
                    </TableCell>
                    <TableCell colSpan={2}>From:</TableCell>
                    <TableCell colSpan={2}>To:</TableCell>
                  </TableRow>
                  <TableRow bgcolor="#FFFFCC">
                    <TableCell colSpan={6}>
                      <em>
                        Note: This topic addresses the following foster home
                        minimum standard. <br /> 2420.2 Pre-Service training in
                        areas appropriate to the needs of children in care.
                      </em>
                    </TableCell>
                  </TableRow>

                  {/* Space Row */}
                  <TableRow>
                    <TableCell colSpan={6}>&nbsp;</TableCell>
                  </TableRow>

                  {/* Dates of Consultations Heading */}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold" }}
                        component="div"
                      >
                        Dates of Consultations:
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Dates of Consultations Details */}
                  <TableRow>
                    <TableCell colSpan={2}>Dates of Consultations:</TableCell>
                    <TableCell>Locations:</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell colSpan={2}>With Whom:</TableCell>
                  </TableRow>

                  {/* Note about Minimum Standards */}
                  <TableRow bgcolor="#FFFFCC">
                    <TableCell colSpan={6}>
                      <Typography variant="body1" component="div">
                        <em>Note:</em> The topic above addresses the following
                        minimum standards:
                        <br />
                        §745.4033.a.1-4 a. Interviews for a foster home
                        screening, a pre-adoptive home screening, or
                        post-placement adoptive report for family applicants may
                        be conducted in one visit and must include:
                        <br />
                        (1) Individual interviews with each prospective foster
                        or adoptive parent;
                        <br />
                        (2) Individual interviews with each child three years or
                        older living in the home and any other person living
                        full time with the family;
                        <br />
                        (3) A joint interview with the prospective foster or
                        adoptive parents; and
                        <br />
                        (4) A family group interview with family members living
                        in the home.
                        <br />
                        §745.4035 You must document in the record all interviews
                        and attempts to interview persons listed in §745.4033 of
                        this title.
                        <br />
                        §745.4037 You must make at least one visit to the home
                        when all members of the household are present.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="h6" component="div">
                        Additional Information Needed:
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* List of Pet Names */}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="h6" component="div">
                        List the names and species of each pet.
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Directions to the Home */}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="h6" component="div">
                        Directions to the Home:
                      </Typography>
                      <Typography variant="body1" component="div">
                        Provide directions on how to get to the foster or
                        adoptive home. Use a landmark as the starting point,
                        such as a DFPS office.
                      </Typography>
                      <Link href="#top" align="right">
                        back to top
                      </Link>
                    </TableCell>
                  </TableRow>

                  {/* I. Motivation */}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <br />

          <Grid
            container
            //   columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            justifyContent="center"
            style={{ gap: "30px" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={11}
              lg={5}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid>
                <Accordion style={{ textAlign: "left" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      I. Motivation
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography align="left">
                      State the applicants' initial reasons for wanting to
                      become foster and/or adoptive parents. Include the length
                      of time they have been considering foster care and/or
                      adoption.
                    </Typography>
                    <Typography>
                      Include the applicants' stated reasons for deciding to
                      commit to foster care and/or adoption after receiving
                      preparatory training. Look for language indicating a
                      desire to protect and nurture children, meet developmental
                      needs, and connect children to lifelong relationships.
                    </Typography>
                    <Typography>
                      Indicate whether or not the applicants' have been verified
                      to foster or approved to adopt by another child-placing
                      agency. Address your perceptions and assessment of issues
                      where appropriate, giving examples.
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Note:
                        </Typography>{" "}
                        The topics in Section I address the following minimum
                        standards:
                      </Typography>
                      <Typography>
                        §745.4061.5 You must evaluate why the prospective
                        parents want to foster or adopt at this time. <br />
                        §745.4061.21 You must evaluate background information
                        from child-placing agencies that previously verified a
                        foster home or approved an adoptive home.
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />
              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      II. Applicants' Feelings About Themselves, Their Parents,
                      and Their Childhood
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Mother's and Father's History
                        </Typography>
                        <br />
                        (Include information for both mother and father for all
                        these sections.) Description of Applicant. Describe the
                        applicant's appearance (include height and weight) and
                        personality.
                      </Typography>
                      <Typography>
                        Provide details about the applicant's educational
                        background, work history, and present employment.
                      </Typography>
                      <Typography>
                        Include the applicants' citizenship and length of time
                        they have spent at each residence over the last ten
                        years.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Parents
                        </Typography>
                        <br />
                        Include the applicant's description and feelings about
                        their parents or parent figures. (Include information
                        about absent parent, if appropriate.) Describe the
                        quality of their parent's marital relationship,
                        support/nurturance, and decision-making. Address the
                        applicant's understanding of the effects their parents
                        had on their life.
                      </Typography>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Childhood
                      </Typography>{" "}
                      <br />
                      Present the applicant's description and feelings about
                      their childhood, including the way they were disciplined
                      and their feelings about it. What would they change about
                      their parents and their childhood?
                      <Typography>
                        Address both the happiest and most traumatic memories of
                        their childhood, and their overall feelings regarding
                        their childhood.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Discuss the applicant's birth order,
                        </Typography>{" "}
                        their relationship with siblings (past and present), and
                        which sibling(s) they feels closest to. Does the
                        applicant believe all siblings were treated equally and
                        fairly? How was sex education handled in the applicant's
                        family of origin? Include the effects on the applicant's
                        current feelings and how they would handle (or has
                        handled) this with their own child(ren).
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          History of Child Abuse and Neglect.{" "}
                        </Typography>
                        Discuss the applicant's history of child abuse (physical
                        and sexual) and neglect, if any, and their resolution of
                        this experience.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          History of Drug and Alcohol Use.
                        </Typography>{" "}
                        Discuss the applicant's history of drug or alcohol use,
                        if any, and her resolution of this experience. (Address
                        these issues regarding other members of the family if
                        appropriate.)
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Abuse/Neglect and Criminal History Checks.
                        </Typography>{" "}
                        Explain any criminal history and subsequent
                        rehabilitative activities. Document the results of both
                        checks.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Physical, Mental and Emotional Status
                        </Typography>
                        . Include an assessment of the physical (health), mental
                        (psychological), and emotional status of the adoptive
                        mother and father in relation to their ability to
                        provide foster and/or adoptive care. (Report their
                        perceptions and your own.)
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Disabilities.
                        </Typography>{" "}
                        Discuss any disabilities the applicant has in relation
                        to their adjustment to the disability and any limits it
                        imposes on their ability to care for a child.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Summary Statement.
                        </Typography>{" "}
                        Discuss the applicant's feelings about themselves now;
                        include their work, education, personality, and
                        appearance. (Include a summary of the mother's caregiver
                        capabilities based on the Risk Assessment, if one was
                        completed.)
                      </Typography>
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Note:
                        </Typography>{" "}
                        The topics in Section II address the following minimum
                        standards:
                      </Typography>
                      <Typography>
                        §745.4061.18 and Appendix. L Criminal backgrounds and
                        abuse/neglect backgrounds.
                        <br />
                        §745.4061.4.A and C
                      </Typography>
                      <Typography>
                        You must include in the history: <br />
                        A. The length of time spent at each residence for the
                        past ten years; <br />
                        C. The citizenship of the prospective adoptive parents
                        and whether they are legal or illegal immigrants. This
                        is required to assess the stability of the home.
                      </Typography>
                      <Typography>
                        §745.4061.6 You must include the physical, mental, and
                        emotional status (including substance abuse history) of
                        all persons living in the home in relation to the
                        family's ability to provide a foster or adoptive home
                        and to assume parenting responsibilities. Consideration
                        must be given to the health and age of the prospective
                        adoptive parents. There must be a plan in place to
                        ensure the child will be raised in a stable and
                        consistent environment to adulthood.
                      </Typography>
                      <Typography>
                        §745.4061.7 You must evaluate individuals who are
                        disabled in relation to their adjustment to the
                        disability and any limits the disability imposes on the
                        prospective foster or adoptive parents' ability to care
                        for a child.
                      </Typography>
                      <Typography>
                        §745.4061.9 You must include any history of abuse or
                        neglect experienced by the prospective adoptive parents
                        and their resolution of the experience.
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      III. Family Interaction
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Previous relationships
                        </Typography>
                        . Include discussions of previous significant
                        relationships and marriages. Describe the history of
                        their past relationships including how they met, their
                        courtship, and their decision to marry, if applicable.
                        Include discussions of children by previous marriages or
                        relationships, parental visitation, and child support.
                        How were the relationships ended and resolved? Include
                        date and place of divorce(s), if applicable.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Current relationships.
                        </Typography>{" "}
                        Describe the applicants' interactions as husband and
                        wife. Describe the history of the relationship including
                        how they met, their courtship, and decision to marry.
                        Report the date and place of marriage. Discuss any
                        separations and/or marital counseling. Address their
                        decision-making processes (including financial), how
                        they handle disagreements, their support for and
                        nurturance of one another, their individual feelings
                        about themselves as spouses, and their sexual
                        relationship. Discuss the effects of adding a child to
                        the home. Describe the strengths and needs of their
                        marriage, including their perceptions and your own.
                        Describe other emotional support systems each applicant
                        has.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Couples with children.
                        </Typography>{" "}
                        Address the same issues as noted above with "current
                        relationships." Describe the applicants' interaction as
                        parents. Address their decision-making processes, their
                        agreements about parental discipline, their
                        disagreements and how they are resolved, their support
                        for one another as parents, and any other issues of
                        possessiveness, excessive control, and so forth.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Single parents
                        </Typography>
                        . Discuss the applicants' single-parent support system.
                        Describe the applicants' significant relationships with
                        both men and women, including sexual relationships.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Issues of infertility
                        </Typography>
                        . Discuss applicants' condition of infertility, their
                        feelings about infertility, how infertility was and/or
                        is handled, and how this has been resolved. Identify
                        whether infertility has affected their sexual
                        relationship.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Parenting.
                        </Typography>{" "}
                        Include an assessment of the physical (health), mental
                        (psychological), and emotional status of each child
                        living in the home in relation to the family's ability
                        to provide foster and/or adoptive care. Ask children how
                        they are disciplined, what the rules are in the family,
                        their opinions/perceptions of the family's decision to
                        foster/adopt, etc. (Report their perceptions, your own,
                        and your observations of the children's interaction with
                        parents.)
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Include the applicants' feelings about themselves as
                          parents.
                        </Typography>
                        How do the applicants describe each of their children?
                        Describe the realism of the applicants' expectations of
                        each of their children and the foster and/or adoptive
                        children.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Other household members.
                        </Typography>{" "}
                        Discuss other household members, including any who
                        reside in the home part time. (Include grandparents,
                        college children, exchange students, and part-time or
                        full-time help.) Also include the results of both
                        abuse/neglect and criminal history checks of each person
                        14 years of age and older in the home.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Religion
                        </Typography>
                        . Describe the family's religious background and
                        practices. Discuss the family's ability to be accepting
                        of religious practices other than their own. Also
                        discuss the family's willingness to take a child to the
                        church of his or her choice. Describe the health
                        protection plan the family will give a child if their
                        religious beliefs prohibit certain medical treatment.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Family rules and boundaries.
                        </Typography>{" "}
                        Discuss in regard to expectations, responsibilities,
                        division of labor, nudity, privacy, etc.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          How family members handle stress and express negative
                          feelings
                        </Typography>
                        . Be specific. Include examples of statements and
                        behaviors that support your assessment.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Extended family.
                        </Typography>{" "}
                        Describe the applicants' interaction with their extended
                        family and the community. Describe the applicants'
                        relationships with members of their extended family,
                        friends, neighbors, church, and community. Discuss the
                        attitudes extended family members have toward the
                        applicants becoming foster and/or adoptive parents,
                        including their degree of acceptance.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          References.
                        </Typography>{" "}
                        If information from the applicants' references is
                        positive, summarize them briefly using direct quotes. If
                        information from the applicants' references raises any
                        concern, address the concerns carefully. At least one
                        contact must be made with each adult child and each
                        minor child 12 years of age or older of the foster
                        and/or adoptive family who is no longer living in the
                        home. This contact may be made in person, by letter, or
                        by telephone.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Summary statement.
                        </Typography>{" "}
                        Address the overall quality and stability of marital and
                        family relationships in relation to the family's ability
                        to provide a foster and/or adoptive home. Address your
                        perceptions and assessment of issues where appropriate,
                        giving examples.
                      </Typography>
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        <Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Note:
                          </Typography>{" "}
                          The topics in Section III address the following
                          minimum standards:
                        </Typography>
                        <Typography>
                          §745.4033.b b. Interviews for a foster home screening
                          and a pre-adoptive home screening for family
                          applicants must also include any minor child 12 years
                          old or older or adult child of the prospective foster
                          or adoptive parents not living in the home. These
                          interviews may be conducted by telephone, in person,
                          or by letter.
                        </Typography>
                        <Typography>
                          §745.4061.3 Each prospective foster or adoptive parent
                          must provide information about what emotional support
                          system he or she has in place. You must document all
                          marriages, divorces, deaths of former spouses, and
                          significant relationships.
                        </Typography>
                        <Typography>
                          §745.4061.8 You must describe the quality of the
                          relationship in relation to the family's ability to
                          provide a foster or adoptive home. You should assess
                          the stability of a couple's relationship.
                        </Typography>
                        <Typography>
                          §745.4061.10.A-C You must evaluate prospective foster
                          adoptive parents on:
                        </Typography>
                        <Typography>
                          A. Their willingness to respect and encourage a
                          child's religious affiliation, if any;
                        </Typography>
                        <Typography>
                          B. Their willingness to provide a child opportunity
                          for religious and spiritual development, if desired;
                          and
                        </Typography>
                        <Typography>
                          C. The health protection they plan to give a child if
                          their religious beliefs prohibit certain medical
                          treatment.
                        </Typography>
                        <Typography>
                          §745.4061.14 The attitude of the prospective adoptive
                          parents' extended family regarding adoption.
                        </Typography>
                        <Typography>
                          §745.4061.18 Each person 14 years of age or older who
                          will regularly or frequently be staying or working at
                          the home while children are being provided care, must
                          obtain a criminal history and central registry
                          background check The results of those checks must be
                          documented.
                        </Typography>
                        <Typography>
                          §745.4061.19(adoption only) You must include
                          information about the couple's fertility. The couple's
                          fertility is important only in relation to unresolved
                          feelings about their infertility and their ability to
                          accept and parent a child not born to them.
                        </Typography>
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      IV. Home Environment
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          The applicants' home and neighborhood.
                        </Typography>{" "}
                        Describe the income level and age group of residents,
                        the racial makeup, the maintenance of the property, and
                        so on.
                      </Typography>
                      <Typography>
                        In the description of the applicants' home, include
                        sleeping arrangements and physical descriptions of the
                        foster and/or adoptive child(ren)'s room(s), as well as
                        the applicants' housekeeping standards.
                      </Typography>
                      <Typography>
                        Address the applicants' home and neighborhood as an
                        environment for child care, including the adequacy of
                        space for children to play both inside and outside.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Safety issues.
                        </Typography>{" "}
                        The applicants' knowledge of basic care and safety
                        issues must be discussed along with firearm safety
                        issues, water safety, and basic home health and safety
                        issues (pool and trampoline issues are addressed here).
                        If firearms are present, all necessary precautions must
                        be taken (firearms and ammunition must be kept separate
                        from each other and in locked compartments at all
                        times). Discuss the family's plan to keep medications
                        out of children's reach and how these plans meet minimum
                        standard requirements.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Financial situation.
                        </Typography>{" "}
                        Identify the applicants' employment history, income,
                        expenses, and ability to manage money.
                      </Typography>
                      <Typography>
                        For foster families only, address the family's ability
                        to manage the expenses of caring for foster children
                        prior to receipt of the first foster care reimbursement
                        payment.
                      </Typography>
                      <Typography>
                        For adoptive families only, verify income and insurance
                        coverage (medical and life) of all household members,
                        including the children to be placed. (Children being
                        placed may have private insurance coverage or be
                        eligible for Medicaid through SSI or adoption
                        assistance.)
                      </Typography>
                      <Typography>
                        Verify that the applicants have been informed of the
                        application process for the adoption assistance program
                        (subsidy), including the non-recurring adoption expenses
                        program (adoption only). Address the adoptive family's
                        ability to support a child with and without a subsidy or
                        prior to reimbursement.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Summary statement.
                        </Typography>{" "}
                        Address the family's ability to provide a safe home
                        environment. (Include a summary of the home environment
                        on the Risk Assessment, if one was completed.) Address
                        your perceptions and assessment of issues where
                        appropriate, giving examples.
                      </Typography>
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      <Typography>
                        <Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Note:
                          </Typography>{" "}
                          The topics in Section IV address the following minimum
                          standards:
                        </Typography>
                        <ul>
                          <li>
                            2510.4 of Appendix F "All medications must be kept
                            out of the reach of children or in a locked storage
                            area."
                          </li>
                          <li>
                            2510.5 of Appendix F "Medication requiring
                            refrigeration must be separated from food in a
                            designated container."
                          </li>
                          <li>
                            3200.1.a-e, Appendix F "The foster home and outdoor
                            areas must be maintained, repaired, and cleaned so
                            that they are not hazardous to the children in care.
                          </li>
                          <li>(a) Outdoor areas must be well drained.</li>
                          <li>
                            (b) Windows and doors used for ventilation must be
                            screened.
                          </li>
                          <li>
                            (c) Equipment and furniture must be safe for
                            children.
                          </li>
                          <li>
                            (d) Children must be protected from inflammable and
                            poisonous substances.
                          </li>
                          <li>
                            (e) Explosive materials, firearms, and projectiles
                            such as darts, arrows, and B-B's must be stored out
                            of reach of children."
                          </li>
                          <li>
                            3300.2, Appendix F "A sleeping room must have at
                            least 40 square feet of floor space for each
                            occupant. Single occupant bedrooms must have at
                            least 80 square feet of floor space."
                          </li>
                          <li>
                            3300.3, Appendix F "Each child must have his or her
                            own bed and mattress; two children of the same sex
                            may share a double bed."
                          </li>
                          <li>
                            3300.4, Appendix F "Each child must have storage
                            space for clothing and personal belongings."
                          </li>
                          <li>
                            3620.1 "Before verifying an agency home, the agency
                            must perform an inspection and document that the
                            home meets appropriate minimum standards.
                            Verification must include that either no firearms
                            are or will be present in the home or that all
                            appropriate precautions are taken."
                          </li>
                        </ul>
                        <Typography>
                          §745.4061.17 "Financial status and ability to support
                          a child, including employment history and insurance
                          coverage."
                        </Typography>
                        <ul>
                          <li>
                            4310.5 "Before placing a child into a home, the
                            child placing agency must discuss basic care and
                            safety issues with the adoptive parents, and ensure
                            that the home provides an environment safe for the
                            child or children to be placed. This must include
                            firearm safety, water safety, and basic home health
                            and fire safety."
                          </li>
                          <li>
                            4310.6 "Before placing a child into a home, the
                            child placing agency must give prospective adoptive
                            parents information about the TDFPS adoption
                            assistance programs, including the non recurring
                            adoption expenses program."
                          </li>
                        </ul>
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      V. Supporting the Child's Needs Regarding Birth Family
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Typography>
                        Information about the child (Only include if this is an
                        adoption by a relative)
                      </Typography>
                      <ul>
                        <li>
                          the child's name, age, general health, and any
                          specific medical problems;
                        </li>
                        <li>
                          the child's history of previous placements and a
                          description of the current placement;
                        </li>
                        <li>
                          the child's school adjustment (include at least the
                          following information: grade, academic performance,
                          conduct problems);
                        </li>
                        <li>
                          the child's feelings about the relative and the amount
                          of past and current contact with the relative; and
                        </li>
                        <li>
                          any observations of the child's interactions with the
                          relative.
                        </li>
                      </ul>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Birth family connections.
                        </Typography>{" "}
                        Discuss the applicants' sensitivity to and feelings
                        about children who may have been subjected to abuse
                        and/or neglect; and who are dealing with their
                        separation from, and the loss of, their biological
                        family.
                      </Typography>
                      <Typography>
                        Describe the applicants' sensitivity to and feelings
                        about the (prospective foster and/or adoptive) child's
                        birth family.
                      </Typography>
                      <Typography>
                        Address the applicants' sensitivity to and feelings
                        about maintaining sibling relationships.
                      </Typography>
                      <Typography>
                        Discuss the applicants' acceptance of the (prospective
                        foster or adoptive) child's feeling about his or her
                        birth family, and the applicants' ability to help the
                        child deal with these feelings.
                      </Typography>
                      <Typography>
                        Describe the applicants' ability to support the child's
                        relationship with his or her birth family, including
                        extended family. Include the degree of support for
                        contacts between the child and his or her birth family
                        and siblings. Describe the adoptive parents'
                        expectations about any ongoing relationship with the
                        birth family.
                      </Typography>
                      <Typography>
                        Address the applicants' feelings, willingness, and
                        ability to work with birth families towards
                        reunification, including methods used to support this
                        plan (foster only).
                      </Typography>
                      <Typography>
                        Discuss the applicants' ability to support a child's
                        search for his or her birth family (adoption only).
                      </Typography>
                      <Typography>
                        If this is an adoption by relatives, address the
                        relatives'
                      </Typography>
                      <ul>
                        <li>
                          plan for protecting the child from exposure to the
                          conditions from which the child was removed,
                        </li>
                        <li>
                          attitudes towards the child's parents and other
                          members of the child's family,
                        </li>
                        <li>thoughts and feelings about the parents,</li>
                        <li>frequency of contact with the child's parents,</li>
                        <li>
                          attitudes towards parental visitation and contact, and
                        </li>
                        <li>proximity to the child's parents.</li>
                      </ul>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Other significant relationships.
                        </Typography>{" "}
                        Describe the applicants' sensitivity to and feelings
                        about the child's need to stay connected to people
                        (other than birth parents) who have been important in
                        the child's life (foster parents, teacher, friends,
                        siblings).
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Personal identity.
                        </Typography>{" "}
                        Include the applicant's understanding of child identity
                        issues related to child abuse and neglect. These issues
                        could include questions about reasons for care, being a
                        good or bad person, desire to find birth parents, "where
                        did I come from" questions, "who am I" questions, gender
                        identity questions, and questions pertaining to
                        sexuality. (Do not include any general assessments
                        pertaining to ethnic and racial identity.)
                      </Typography>
                      <Typography>
                        For adoptive parents, include the applicant's plans
                        regarding the child's name.
                      </Typography>
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        <Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Note:
                          </Typography>{" "}
                          The topics in Section V address the following minimum
                          standards:
                        </Typography>
                        <Typography>
                          §745.4061.12 You must evaluate the prospective foster
                          or adoptive parents' sensitivity to and feelings about
                          children who may have been subjected to abuse,
                          neglect, separation from, and loss of their biological
                          family, if the applicants are planning to adopt a
                          child who is not a newborn. You must evaluate whether
                          the prospective foster or adoptive parents'
                          environment is appropriate to nurture such a child.
                          The environment includes the prospective adoptive
                          parents' interest and ability to help the child deal
                          with these experiences, and the available community
                          resources.
                        </Typography>
                        <Typography>
                          §745.4061.13 You must evaluate the prospective foster
                          or adoptive parents' expectations about any ongoing
                          relationship with the birth family as well as their
                          sensitivity to, and feelings about, birth families.
                        </Typography>
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      VI. Dealing with Separation and Loss
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          Address the applicants' understanding of the dynamics
                          of separation and placement, significant losses, how
                          these have been dealt with, and how they have been
                          managed and/or resolved. You may include stages of
                          grieving. Examples: the deaths of friends or
                          relatives, the death of a child, miscarriages,
                          infertility, experiences of victimization, loss of
                          job, children leaving home, health losses, and natural
                          disaster.
                        </li>
                        <li>
                          How have the applicants' own losses equipped them to
                          help an adoptive and/or foster child work through his
                          or her losses?
                        </li>
                        <li>
                          Discuss the applicants' ability to communicate with
                          and help the child deal with his or her foster
                          placement and/or adoption.
                        </li>
                        <li>
                          Address the applicants' ability to separate from a
                          foster child when the child leaves their home.
                        </li>
                        <li>
                          Discuss the applicants' ability to help children
                          grieve by accepting feelings of denial, anger, and
                          depression.
                        </li>
                        <li>
                          Address the applicants' ability to help build
                          continuity in the child's life. Address work on the
                          child's memory/life book and support work on DFPS's
                          efforts on the life book.
                        </li>
                        <li>
                          Address your perceptions and assessments of issues
                          where appropriate, giving examples.
                        </li>
                      </ul>
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        <Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Note:
                          </Typography>{" "}
                          The topics in Section VI address the following minimum
                          standards:
                        </Typography>
                        <Typography>
                          745.4061.12 You must evaluate the prospective foster
                          or adoptive parents' sensitivity to and feelings about
                          children who may have been subjected to abuse,
                          neglect, separation from, and loss of their biological
                          family, if the applicants are planning to adopt a
                          child who is not a newborn. You must evaluate whether
                          the prospective foster or adoptive parents'
                          environment is appropriate to nurture such a child.
                          The environment includes the prospective adoptive
                          parents' interest and ability to help the child deal
                          with these experiences, and the available community
                          resources.
                        </Typography>
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      VII. Dealing With Children Who Have Been Physically,
                      Sexually Abused and/or Neglected
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Typography>
                        Discuss the applicants' understanding of the dynamics of
                        child abuse and neglect.
                      </Typography>
                      <Typography>
                        Address the applicants' sensitivity to and feelings
                        about children who may have been subjected to abuse
                        and/or neglect; and who are dealing with their
                        separation from, and the loss of, their biological
                        family.
                      </Typography>
                      <Typography>
                        Include the applicants' understanding of how these
                        issues and feelings will affect them as well as the
                        children they will foster and/or adopt.
                      </Typography>
                      <Typography>
                        Include the applicants' ability to help the child with
                        their experience of abuse and neglect and the
                        availability of community resources to meet the needs of
                        the child.
                      </Typography>
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        <Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Note:{" "}
                          </Typography>
                          The topics in Section VII address the following
                          minimum standards:
                        </Typography>
                        <Typography>
                          §754.4061.4.B You must include in the history:
                        </Typography>
                        <Typography>
                          B. An assessment of the available community resources
                          to meet the needs of children; and
                        </Typography>
                        <Typography>
                          §745.4061.12 Sensitivity to and feelings about
                          children who may have been subjected to abuse,
                          neglect, separation from, and loss of their biological
                          family.
                        </Typography>
                        <Typography>
                          §745.4061.15 The prospective foster or adoptive
                          parents' expectations of foster or adoptive children.
                        </Typography>
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={11}
              lg={5}
              style={{
                display: "flex",
                flexDirection: "column",
                //  width:'90%'
              }}
            >
              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      VIII. Child Management and Discipline
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Discipline.
                        </Typography>{" "}
                        Describe the ways in which the applicants were
                        disciplined as children, and their feelings about the
                        discipline they received then.
                      </Typography>
                      <Typography>
                        Discuss the applicants' values (parenting philosophy)
                        regarding child discipline and care.
                      </Typography>
                      <Typography>
                        Describe the applicants' current methods of disciplining
                        children, and their feelings about the discipline of
                        children. If their current disciplinary practices are
                        incompatible with DFPS's discipline policies, how do
                        they plan to reconcile their practices with DFPS's
                        policies? Include the applicants' ability to support
                        DFPS's discipline policy.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Child-Care knowledge.
                        </Typography>{" "}
                        Describe the applicants' knowledge of child development.
                      </Typography>
                      <Typography>
                        Discuss the applicants' child-care experience if they
                        have no children.
                      </Typography>
                      <Typography>
                        Address the applicants' expectations of the foster
                        and/or adoptive children and of working with these
                        children. Are these expectations realistic? (Include
                        examples of behaviors and activities that can help
                        facilitate discussion include parents' expectations
                        about school visits, school performance, public displays
                        of inappropriate behaviors, etc.)
                      </Typography>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Describe the applicants' ability to:
                      </Typography>
                      <ul>
                        <li>assess and identify a child's needs,</li>
                        <li>promote a child's self-esteem,</li>
                        <li>follow through on professional advice, and</li>
                        <li>
                          prepare an older child to live independently as an
                          adult.
                        </li>
                      </ul>
                      <Typography>
                        Address the applicants' plans for child care, if both
                        are employed, and their plans for any baby-sitting needs
                        during emergency or occasional outings.
                      </Typography>
                      <Typography>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Child management
                        </Typography>
                        . Discuss the applicants' ability to manage the
                        behaviors of children who have been sexually or
                        physically abused and/or neglected. (Explore the
                        applicants' abilities to manage specific behaviors,
                        e.g., sexual acting out, aggression, abusive language,
                        etc.)
                      </Typography>
                      <Typography>
                        Discuss the applicants' ability to manage a child's
                        behavior associated with separation and loss.
                      </Typography>
                      <Typography>
                        <Typography>Summary statement.</Typography> Address your
                        perceptions and assessment of issues where appropriate,
                        giving examples.
                      </Typography>
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        <Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Note:
                          </Typography>{" "}
                          The topics in Section VIII address the following
                          minimum standards:
                        </Typography>
                        <Typography>
                          §745.4061.11 The prospective foster or adoptive
                          parents' values, feelings, and practices in regard to
                          child discipline and care.
                        </Typography>
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      IX. Working with the Agency
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          Describe the applicants' participation in pre-service
                          training.
                        </li>
                        <li>
                          Describe the applicants' ability to accept and act on
                          suggestions.
                        </li>
                        <li>
                          Describe the applicants' willingness to participate in
                          continued training (foster only).
                        </li>
                        <li>
                          Discuss the applicants' willingness to participate in
                          post-adoption services when appropriate (adoption
                          only).
                        </li>
                        <li>
                          Explore the applicants' understanding of their role as
                          caregivers in partnership with DFPS, and their ability
                          to advocate for the child's needs.
                        </li>
                        <li>
                          Discuss the applicants' willingness to support the
                          child's plan of service.
                        </li>
                        <li>
                          Address the applicants' understanding and acceptance
                          of DFPS's decision-making process.
                        </li>
                        <li>
                          Address the applicants' knowledge of who to contact if
                          they have complaints about the screening process and
                          that they have been notified of their right to appeal.
                        </li>
                        <li>
                          Include the applicants' ability to communicate with
                          DFPS workers about a foster and/or adoptive child's
                          adjustment and needs.
                        </li>
                        <li>
                          Include the applicants' ability/willingness to
                          transport the child to medical, therapeutic,
                          educational and visitation appointments (foster only).
                        </li>
                        <li>
                          Address your perceptions and assessment of issues
                          where appropriate, giving examples.
                        </li>
                      </ul>
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        <Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Note:
                          </Typography>{" "}
                          §745.4061.20 You must include telephone numbers for
                          entities where it is appropriate for the subject of
                          the study to file complaints about how the
                          pre-adoptive or foster home screening was conducted.
                        </Typography>
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      X. The Mutual Problem-Solving Plan
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Typography>
                        Describe the problem-solving plan worked out with the
                        applicants during their preparatory training.
                      </Typography>
                      <Typography>Identify the:</Typography>
                      <ul>
                        <li>need the plan addressed;</li>
                        <li>
                          process used to meet the need (examples: contracting,
                          gathering more information, discussions in training
                          meetings, and reading assignments); and
                        </li>
                        <li>results.</li>
                      </ul>
                      <Typography>
                        If you have already described the need, process, and
                        results elsewhere in the home study, make only a brief
                        statement here.
                      </Typography>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      XI. Verification of Compliance with Minimum Standards
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Typography>
                        The following statement MUST be on each home study:
                      </Typography>
                      <Typography>
                        The (name of family) is in compliance with all minimum
                        standards as outlined in Minimum Standards for
                        Child-Placing Agencies, including Appendix ___.* These
                        were discussed with the applicants, and the applicants
                        were evaluated and found to be in compliance with the
                        standards.
                      </Typography>
                      <Typography>
                        Foster Homes only: choose one or more of the following:
                      </Typography>
                      <ul>
                        <li>Appendix F - Basic Care;</li>
                        <li>Appendix G - Basic Group Care;</li>
                        <li>Appendix H - Primary Medical Needs Care;</li>
                        <li>Appendix I - Habilitative Care;</li>
                        <li>Appendix J - Therapeutic Care; and/or</li>
                        <li>Appendix K - Autistic-Like Behavior</li>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      XII. Type of Child
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Typography>
                        Discuss the type of child the parents are best qualified
                        to foster and/or adopt. Include specific special needs
                        and/or disabilities and behaviors the family is best
                        qualified to manage (such as: sexual acting out,
                        withdrawal, hyperactivity, noted emotional problems
                        needing counseling, noted mental delays or retardation,
                        minor to severe medical problems, etc.). Identify any
                        special abilities or qualifications the family has to
                        meet the needs of special-needs children. Discuss
                        behavior, background, special-needs status, or other
                        characteristics of a potential foster and/or adoptive
                        child that the family cannot accept.
                      </Typography>
                      <Typography>
                        Address the family's ability to accept a child with
                        parents who have
                      </Typography>
                      <ul>
                        <li>
                          a documented physical or mental illness
                          (schizophrenia, bipolar disorder, etc.);
                        </li>
                        <li>alcohol abuse and/or drug usage;</li>
                        <li>criminal history; or</li>
                        <li>no available background information.</li>
                      </ul>
                      <Typography>
                        Address your perceptions and assessment of issues where
                        appropriate, giving examples.
                      </Typography>
                    </Typography>
                    <Grid
                      style={{
                        backgroundColor: "#FFFFCC",
                        border: "solid lightblue",
                        borderWidth: "1px",
                        padding: "10px",
                      }}
                    >
                      <Typography>
                        <Typography>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Note:
                          </Typography>{" "}
                          The topics in Section XII address the following
                          minimum standards:
                        </Typography>
                        <Typography>
                          §745.4061.16 You must evaluate the behavior,
                          background, special needs status, or other
                          characteristics of a potential foster or adoptive
                          child that the family cannot or will not accept.
                        </Typography>
                        <Typography>
                          §745.4093.4 Evaluate all areas required for the foster
                          home screening and make recommendations regarding the
                          home's ability and approval to work with children with
                          respect to their age, gender, special needs, and the
                          number of children.
                        </Typography>
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />

              <Grid style={{ textAlign: "left" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "rgb(53, 103, 178)" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      XIII. Recommendations
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          Summarize the family's gifts and strengths (what the
                          family can give to the child) related to the following
                          categories: educational, emotional, medical/physical,
                          behavioral, relationship-building, and spiritual.
                        </li>
                        <li>
                          Summarize what the family needs from the child to feel
                          successful in the following categories: educational,
                          emotional, medical/physical, behavioral,
                          relationship-building, and spiritual.
                        </li>
                        <li>
                          Summarize the concerns of the family in the following
                          categories: educational, emotional, medical/physical,
                          behavioral, relationship-building, and spiritual.
                        </li>
                        <li>
                          Summarize the Risk Assessment if one was completed.
                          (Use statements such as: "The Risk Assessment revealed
                          no areas of concern except for.…")
                        </li>
                        <li>
                          Make specific recommendations about the family's
                          capacity to work with children. (Be specific about
                          what type of special needs the family can handle and
                          why).
                        </li>
                        <li>
                          This family is best able to parent (describe the age,
                          sex and number of children.)
                        </li>
                        <li>
                          Mr. and Mrs. state a desire to adopt a child of
                          (provide a description of the child or children).
                        </li>
                      </ul>
                      <Typography>Caseworker Signature</Typography>
                      <Typography>Date ___/___/___</Typography>
                      <Typography>
                        Level One Supervisor's Signature Date ___/___/___
                      </Typography>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <br />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default HomeStudyGuide;
