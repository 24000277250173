import React, { useState } from "react";

// Row Component
function Row({ id, onSave, onDelete }) {
  const [inputValue, setInputValue] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSave = () => {
    onSave(id, inputValue, file);
  };

  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleSave}>Enter</button>
      <button onClick={handleDelete}>Delete</button>
    </div>
  );
}

// Table Component
function Table() {
  const [rows, setRows] = useState([]);

  const addRow = () => {
    setRows([...rows, { id: rows.length, data: null }]);
  };

  const saveRowData = (id, inputValue, file) => {
    // Placeholder for an API call to save data
    // console.log(`Saving data for row ${id}:`, inputValue, file);
    // Assume the data is saved and update the state as needed
  };

  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  return (
    <div>
      {rows.map((row, index) => (
        <Row
          key={index}
          id={row.id}
          onSave={saveRowData}
          onDelete={deleteRow}
        />
      ))}
      <button onClick={addRow}>Add Row</button>
    </div>
  );
}

// Main App Component
function MyComponent() {
  // Render multiple tables by calling <Table /> multiple times if needed
  return (
    <div style={{ paddingTop: "100px" }}>
      <h1>Dynamic Table Rows Example</h1>
      <Table />
    </div>
  );
}

export default MyComponent;
