import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import requirementsContent from "./requirementsContent";
import ImageSlider from "../layout/imageslider";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./requirements.css";

const Requirements = () => {
  const Typographyheading = {
    color: "#1A355D",
  };

  const cardheading = {
    color: "black",
    textAlign: "left",
  };

  const items = {
    textAlign: "left",
    lineHeight: "2",
  };

  const title = {
    textAlign: "justify",
    // padding: "10px",
  };

  const bodyitems = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  };

  const listitems = {
    float: "left",
    paddingLeft: "10px",
    textAlign: "justify",
    lineHeight: "1.5",
    margin: "0",
    marginBottom: "10px",
  };

  const content = {
    textAlign: "justify",
    paddingLeft: "20px",
    padding: "10px",
    fontSize: "15px",
  };

  const root = {
    letterSpacing: "normal",
    WebkitHyphens: "auto",
    MozHyphens: "auto",
    msHyphens: "auto",
    hyphens: "auto",
  };

  // State to keep track of the expanded accordion
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  // Function to handle list item click
  const handleListItemClick = (accordionId) => {
    // Expand or collapse the accordion
    setExpandedAccordion(
      expandedAccordion === accordionId ? null : accordionId
    );
  };

  return (
    <div className="root" style={root} align="center">
      {/* <ImageSlider /> */}
      <Box id="maingrid0" style={{ backgroundColor: "White", padding: "10px" }}>
        <Grid
          container
          style={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
            // backgroundColor:'yellow'
          }}
        >
          <Grid item xs={11} sm={11} md={5} lg={5} style={{}}>
            <Card style={{ height: "18rem", borderRadius: "10px" }}>
              <CardHeader
                style={{ backgroundColor: "rgb(53, 103, 178)" }}
              ></CardHeader>
              <CardContent>
                <Typography
                  style={{ fontSize: 20, textAlign: "left" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b> Requirements for Foster/Adopt Families</b>
                </Typography>
                <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                  <li style={items}>
                    <a
                      style={{ color: "blue" }}
                      href="#basicreq"
                      onClick={() => handleListItemClick("basicreq")}
                    >
                      Basic Requirements
                    </a>
                  </li>
                  <li style={items}>
                    <a
                      style={{ color: "blue" }}
                      href="#addfostercarereq"
                      onClick={() => handleListItemClick("addfostercarereq")}
                    >
                      Additional Requirements
                    </a>
                  </li>
                  <li style={items}>
                    <a
                      style={{ color: "blue" }}
                      href="#resoffosterandadadoptivefamilies"
                      onClick={() =>
                        handleListItemClick("resoffosterandadadoptivefamilies")
                      }
                    >
                      Foster/Adopt Family Responsibilities
                    </a>
                  </li>
                  <li style={items}>
                    <a
                      style={{ color: "blue" }}
                      href="#fosterfamiliesadopt"
                      onClick={() => handleListItemClick("fosterfamiliesadopt")}
                    >
                      Can foster families adopt?
                    </a>
                  </li>
                  <li style={items}>
                    <a
                      style={{ color: "blue" }}
                      href="#provide"
                      onClick={() => handleListItemClick("provide")}
                    >
                      Can adoptive families provide foster care?
                    </a>
                  </li>
                </ul>
              </CardContent>{" "}
            </Card>
          </Grid>
          <Grid item xs={11} sm={11} md={5} lg={5}>
            <Card style={{ height: "18rem", borderRadius: "10px" }}>
              <CardHeader
                style={{ backgroundColor: "rgb(53, 103, 178)" }}
              ></CardHeader>
              <CardContent style={{}}>
                <Typography
                  style={{ fontSize: 20, textAlign: "left" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>In This Section</b>
                </Typography>
                <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                  <li>
                    <Link style={{ color: "blue" }} to="/stepsview">
                      Steps to Become a Foster/Adopt Parent
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "blue" }}
                      to="/placement#informationmeeting"
                    >
                      Information Meetings
                    </Link>
                  </li>
                </ul>
                <Typography
                  style={{ fontSize: 20, textAlign: "left" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>More Information</b>
                </Typography>
                <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                  <li>
                    <Link style={{ color: "blue" }} to="/state">
                      State Adoption Websites
                    </Link>
                  </li>
                  <li>
                    <Link style={{ color: "blue" }} to="/partners">
                      Private Adoption Agencies
                    </Link>
                  </li>
                  <li>
                    <Link style={{ color: "blue" }} to="/spanish2">
                      En Español
                    </Link>
                  </li>
                </ul>
              </CardContent>
              <br />
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            lineHeight: "1.6",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item sm={10} md={10} lg={10}>
            {requirementsContent.map((item, index) => (
              <Accordion
                key={index}
                id={item.id}
                expanded={expandedAccordion === item.id}
                onChange={() =>
                  setExpandedAccordion(
                    item.id === expandedAccordion ? null : item.id
                  )
                }
                style={{
                  marginBottom: "10px",
                  width: "100%",
                  backgroundColor: "#D7E9F7",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={cardheading}>
                    <b>{item.title}</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={bodyitems}>
                  {Array.isArray(item.subheading) ? (
                    // Handle the third item with an array of subheadings
                    item.subheading.map((subItem, subIndex) => (
                      <div
                        key={subIndex}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography style={title}>
                          <b>{subItem.head}</b>
                        </Typography>
                        {subItem.desc.map((descItem, descIndex) => (
                          <ul key={descIndex} style={{ margin: "0" }}>
                            <li style={listitems}>{descItem.value}</li>
                          </ul>
                        ))}
                      </div>
                    ))
                  ) : item.subheading ? (
                    // Handle the first two items with a single subheading
                    <>
                      <Typography style={title}>
                        <b>{item.subheading}</b>
                      </Typography>
                      {item.desc.map((descItem, descIndex) => (
                        <ul
                          key={descIndex}
                          style={{ margin: 0, lineHeight: "1.6" }}
                        >
                          <li style={listitems}>{descItem.value}</li>
                        </ul>
                      ))}
                    </>
                  ) : null}
                  {item.content && (
                    // Render the content, especially for the fourth item which only has content
                    <Typography style={content}>{item.content}</Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Requirements;
