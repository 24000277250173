const articlesInfo = {
    desc: `Here are a few articles for more information about foster care or becoming a foster parent.`,
    contents: [
        {
            key: `fosterParent`,
            label: `Becoming a Foster Parent`,
            value: `Becoming a foster parent is a rewarding, but challenging decision. In most cases, the 
            primary goal of foster care is to return the child to their biological parents (called reunification). 
            However, the foster parent is crucial in helping make this transition as easy as possible.`,
            linkRoute: "/learnMore1#caseworker"
        },
        {
            key: `helpChildren`,
            label: `Ways to Help Children in Foster Care`,
            value: `Helping children in foster care can include more than being a foster parent. Here are a few good 
            suggestions on how you can help families and children in foster care.`,
            linkRoute: "/learnMore1#childrenshome"
        },
        {
            key: `homeStudy`,
            label: `A Homestudy – What is It?`,
            value: `A homestudy has specific guidelines that must be met to be completed properly. Though the format 
            can vary from state to state and across different agencies, most generally have two different parts – training 
            process and information gathering.`,
            linkRoute: "/learnMore2#homestudy"
        },
        {
            key: `adoption`,
            label: `What Children are Available for Adoption?`,
            value: `You have probably given a lot of though to what children are available for adoption and which type of 
            child you feel may thrive in your home.`,
            linkRoute: "/learnMore2#childrenadoption"
        }
    ]
}

export default articlesInfo;