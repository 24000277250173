import { Grid, Typography, Tooltip, Fab } from "@mui/material";
// import CallIcon from "@material-ui/icons/Call";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
// import CallDrawer from './calldrawer';
import "./footer.css";

const Footer = () => {
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const toggleContactDrawer = (event, flag) => {
  //     if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //       return;
  //     }
  //     setIsDrawerOpen(flag);
  // }
  return (
    <div
      style={{
        backgroundColor: "linear-gradient(132deg, #22477E 30%, #3567B2 90%)",
        display: "flex",
        justifyContent: "center",

        // padding: "10px",
      }}
    >
      <Grid
        container
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          backgroundColor: "#3567B2",
        }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={6}
          id="contentgrid"
          style={{
            minHeight: "80px",
            backgroundColor: "#3567B2",
            background: "#22477E",
            boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
          }}
        >
          <Typography className="left-div" style={{ color: "white" }}>
            ©2024 Texas Foster Care and Adoption Services, A 501(c)(3)
            Non-Profit Agency.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={6} id="iconsgrids">
          <Tooltip
            title="Call Us"
            aria-label="call"
            arrow
            placement="top"
            style={{
              backgroundColor: "#22477E",
              border: "1px #1A355D solid",
              marginRight: "10px",
              height: "20px",
              width: "40px",
            }}
          >
            <Fab
              aria-label="Call Us"
              color="inherit"
              // onClick={(e) => toggleContactDrawer(e, true)}
            >
              <PhoneInTalkIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
          <a
            href="https://www.facebook.com/TexasFosterCareAndAdoptionServices"
            target="_blank"
            rel="noreferrer"
            style={{ paddingRight: "10px" }}
          >
            <Tooltip
              title="Facebook"
              aria-label="fb"
              arrow
              placement="top"
              style={{
                backgroundColor: "#22477E",
                border: "1px #1A355D solid",
                height: "20px",
                width: "40px",
              }}
            >
              <Fab aria-label="fb" color="black">
                <FacebookIcon style={{ color: "white" }} />
              </Fab>
            </Tooltip>
          </a>{" "}
          <a
            href="https://twitter.com/txfostercare"
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip title="Twitter" aria-label="twitter" arrow placement="top">
              <Fab
                aria-label="twitter"
                style={{
                  backgroundColor: "#22477E",
                  border: "1px #1A355D solid",
                  height: "20px",
                  width: "40px",
                }}
                color="black"
              >
                <TwitterIcon style={{ color: "white" }} />
              </Fab>
            </Tooltip>
          </a>{" "}
          {/* <img src={doller} alt="my-gif" style={{height: '50px', width: '50px', color: 'green'}} />       */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
