import { Grid, Typography } from "@mui/material";
import React from "react";
import ImageSlider from "../../../layout/imageslider";
import Mapcardnoarea from "../mappages/mapcardnoareas";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import MapCard from "../mappages/mapcard";
import "./region9.css";
import ResponsiveMap from "../../../fostercareOverview/texasmap";

const Region9 = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            {" "}
            <Typography
              variant="h5"
              fontWeight="bold"
              style={{ textAlign: "center", color: "navy" }}
            >
              Region 9, Midland and surrounding area :{" "}
            </Typography>
            <br />
            <Typography>
              Foster Care and Adoption Information Meetings
            </Typography>
            <br />
            <Typography style={{ color: "red", textAlign: "justify" }}>
              * If accomodations such as a sign language interpreter are needed
              in order to attend an information meeting, please call the
              Recruitment Staff listed below 3 days prior to the meeting, if
              possible. *
            </Typography>
            <br />
            <Typography style={{ textAlign: "justify" }}>
              The Texas Department of Family and Protective Services is looking
              for caring foster and adoptive families in your area. To learn
              more about becoming a foster or adoptive parent through our
              agency, we will be having the following information meetings in
              your area:
            </Typography>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>Recruitment Events and Foster/Adopt Information Meetings:</b>
              </Typography>
              <br />
              <ul style={{ lineHeight: "1.6" }}>
                <li>
                  <Link to="#table1"> January 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table2">February 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table3">March 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link
                    target="_blank"
                    to="https://partnershipsforchildren.org/heartgallery-home/"
                  >
                    The Heart Gallery of Central Texas
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="#recruitment"> Recruitment Staff</Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp"
                  >
                    {" "}
                    Faith-Based Staff
                  </Link>
                </li>
                <li>
                  <Link to="/partners">Private Adoption Agencies</Link>
                </li>
              </ul>
            </Grid>
            
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "justify",
              }}
            >
              <MapCard />
            </Grid>
          </Grid>
          {/* Table1 */}
          <Grid id="table1">
            <br />
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <ResponsiveMap />
            </Grid>
            <br/>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>January 2024</b>
            </Typography>
            <br />
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="top"
                      style={{ wordBreak: "break-word", verticalAlign: "top" }}
                    >
                      {" "}
                      <strong>Region 9 Counties</strong>
                      <br />
                      Andrews, Borden, Coke, Concho, Crane, Crockett, Dawson,
                      Ector, Gaines, Glasscock, Howard, Irion, Kimble, Loving,
                      Martin, Mason, McCulloch, Menard, Midland, Pecos, Reagan,
                      Reeves, Schleicher, Sterling, Sutton, Terrell, Tom Green,
                      Upton, Ward, Winkler Counties
                    </TableCell>
                    <TableCell
                      align="top"
                      style={{ verticalAlign: "top", wordBreak: "break-word" }}
                    >
                      <strong>Monday, January 8, 2024 @ 7pm CST</strong>
                      Meetings are virtual. To request a link to join please
                      contact
                      <br />
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Region 9 Counties</strong>
                      Andrews, Borden, Coke, Concho, Crane, Crockett, Dawson,
                      Ector, Gaines, Glasscock, Howard, Irion, Kimble, Loving,
                      Martin, Mason, McCulloch, Menard, Midland, Pecos, Reagan,
                      Reeves, Schleicher, Sterling, Sutton, Terrell, Tom Green,
                      Upton, Ward, Winkler Counties
                    </TableCell>
                    <TableCell>
                      <strong>Monday January 29, 2024 @ 7pm CST</strong>
                      <br />
                      <strong>
                        Meetings are virtual. To request a link to join please
                        contact
                      </strong>
                      <br />
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table2 */}
          <Grid id="table2" style={{ paddingTop: "30px" }}>
            <br />
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>February 2024</b>
            </Typography>
            <br />
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Region 9 Counties</strong>
                      Andrews, Borden, Coke, Concho, Crane, Crockett, Dawson,
                      Ector, Gaines, Glasscock, Howard, Irion, Kimble, Loving,
                      Martin, Mason, McCulloch, Menard, Midland, Pecos, Reagan,
                      Reeves, Schleicher, Sterling, Sutton, Terrell, Tom Green,
                      Upton, Ward, Winkler Counties
                    </TableCell>
                    <TableCell align="top">
                      <strong>Monday February 12, 2024@ 7pm CST</strong>
                      Meetings are virtual. To request a link to join please
                      contact{" "}
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Region 9 Counties</strong>
                      Andrews, Borden, Coke, Concho, Crane, Crockett, Dawson,
                      Ector, Gaines, Glasscock, Howard, Irion, Kimble, Loving,
                      Martin, Mason, McCulloch, Menard, Midland, Pecos, Reagan,
                      Reeves, Schleicher, Sterling, Sutton, Terrell, Tom Green,
                      Upton, Ward, Winkler Counties
                    </TableCell>
                    <TableCell>
                      <strong>Monday February 26, 2024@ 7pm CST</strong>
                      Meetings are virtual. To request a link to join please
                      contact{" "}
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table 3 */}
          <Grid id="table3" style={{ paddingTop: "30px" }}>
            <br />
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>March 2024</b>
            </Typography>
            <br />
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Region 9 Counties</strong>
                      Andrews, Borden, Coke, Concho, Crane, Crockett, Dawson,
                      Ector, Gaines, Glasscock, Howard, Irion, Kimble, Loving,
                      Martin, Mason, McCulloch, Menard, Midland, Pecos, Reagan,
                      Reeves, Schleicher, Sterling, Sutton, Terrell, Tom Green,
                      Upton, Ward, Winkler Counties
                    </TableCell>
                    <TableCell align="top">
                      <strong>Monday March 11, 2024 @ 7pm CST</strong>
                      Meetings are virtual. To request a link to join please
                      contact{" "}
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Region 9 Counties</strong>
                      Andrews, Borden, Coke, Concho, Crane, Crockett, Dawson,
                      Ector, Gaines, Glasscock, Howard, Irion, Kimble, Loving,
                      Martin, Mason, McCulloch, Menard, Midland, Pecos, Reagan,
                      Reeves, Schleicher, Sterling, Sutton, Terrell,
                    </TableCell>
                    <TableCell>
                      <strong>Monday March 26, 2024 @7pm CST</strong>
                      Meetings are virtual. To request a link to join please
                      contact{" "}
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Recruitment Grid */}
          <Grid
            id="recruitment"
            style={{ paddingTop: "20px", width: "100%", textAlign: "left" }}
          >
            <Typography variant="h5">
              <b>Recruitment Staff:</b>
            </Typography>
            <br />
            <Typography>
              Region 9 staff receive many inquiries from prospective
              foster/adopt families and hold information meetings for groups of
              families on a regular basis.
            </Typography>
            <Typography>
              Please email<a href=""> Amanda Monsivais</a> or call{" "}
              <b>(325) 657-8944</b>.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Region9;
