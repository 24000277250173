import React from "react";

import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ImageSlider from "../../../layout/imageslider";
import { Button, Grid, Grow } from "@mui/material";
import BreadcrumbMenu from "../../../layout/BreadcrumbMenu";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import TextField from "@mui/material/TextField";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Join = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grow in={true} timeout={1500}>
        <Grid container style={{ color: "#1A355D", padding: "10px 0" }}>
          <Grid item xs={12} style={{ padding: "0 16px 10px 16px" }}>
            <BreadcrumbMenu
              currComp="Join Us"
              parentComp="Home"
              parentCompRoute="/"
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "0 16px 10px 16px" }}>
            <Typography variant="h4">Join Us</Typography>
          </Grid>
        </Grid>
      </Grow>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grow in={true} timeout={1500}>
          <Grid
            container
            style={{
              backgroundColor: "white",
              color: "#1A355D",

              boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
              //   marginBottom: "20px",
              width: "90%",
            }}
          >
            <Grid
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <AppBar position="static" style={{ width: "100%" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Foster Caregivers" {...a11yProps(0)} />
                  <Tab label="Request Information" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Typography style={{ textAlign: "justify" }}>
                    <span style={{ fontWeight: "bold" }}>
                      We Need Your Help to Change Children's Lives.
                    </span>
                    <br /> Join Us! <br />
                    Great Training Makes Great Foster Parents
                    <br />{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Foster Caregiver essential duties and responsibilities
                      include the following:
                    </span>
                  </Typography>
                  <Typography>
                    <ul>
                      <Grid style={{ display: "flex", paddingBottom: "10px" }}>
                        <DoubleArrowIcon />
                        <li>Free monthly foster parent training</li>
                      </Grid>
                      <Grid style={{ display: "flex", paddingBottom: "10px" }}>
                        <DoubleArrowIcon />
                        <li>Free PRIDE 24-hour pre-training course</li>
                      </Grid>
                      <Grid style={{ display: "flex", paddingBottom: "10px" }}>
                        <DoubleArrowIcon />
                        <li>Documentation of children’s activities</li>
                      </Grid>
                      <Grid style={{ display: "flex", paddingBottom: "10px" }}>
                        <DoubleArrowIcon />
                        <li>
                          On-going guidance and training to meet the specific
                          needs of your foster home
                        </li>
                      </Grid>
                    </ul>
                  </Typography>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Typography>
                    For more details on becoming a foster parent or to learn
                    more about what foster care to do in the life of a child,
                    please fill out the form below
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Name"
                      id="fullWidth"
                      style={{ paddingBottom: "20px" }}
                    />
                    <TextField
                      fullWidth
                      label="Email"
                      id="fullWidth"
                      style={{ paddingBottom: "20px" }}
                    />
                    <TextField
                      fullWidth
                      label="Phone"
                      id="fullWidth"
                      style={{ paddingBottom: "20px" }}
                    />
                    <TextField
                      fullWidth
                      label="Subject"
                      id="fullWidth"
                      style={{ paddingBottom: "20px" }}
                    />
                    <TextField
                    //   id="outlined-multiline-static"
                      label="Message"
                      multiline
                      rows={4}
                      
                      style={{ paddingBottom: "20px",width:"100%" }}
                    />
                      <TextField
                    //   id="outlined-multiline-static"
                      label="Tell us about how you heard about Fostercare"
                      multiline
                      rows={4}
                      
                      style={{ paddingBottom: "20px",width:"100%" }}
                    />
                  </Box>
                  <Button style={{ paddingBottom: "20px",width:"100%",backgroundColor:'#1976d2',fontWeight:'bold',color:'white' }}>Send</Button>
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
        </Grow>
      </Grid>
    </div>
  );
};

export default Join;
