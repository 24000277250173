import React from "react";
import { Typography, Grid } from "@mui/material";
import doller from "../../assets/img/gallery/dollar-6186_256.gif";
import { Link } from "react-router-dom";
import "../CSS/common.css";
import "../PaypalDonate/paypal.css";
const PayPalDonateView = () => {
  const Styles = {
    leftgrid: {
      // height: "100px",
      borderTopLeftRadius: "50px",
      borderBottomLeftRadius: "50px",
      // marginRight: "-30px",
      zIndex: "0",
      color: "blue",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      fontSize: "1.8rem",
      border: "1px solid rgb(34, 71, 126) ",
      backgroundColor: "#E9F6FF",
    },
    donatebutton: {
      color: "#1E90FF",
      backgroundColor: "#40A2D8",
      // borderRadius: "300px",
      borderRadius: "100px",
      border: "none",

      cursor: "pointer",

      animationName: "$blinker",

      "@keyframes blinker": {
        "0%": { opacity: 1 },
        "50%": { opacity: 1 },
        "100%": { opacity: 1 },
      },
      animation: "blinker 3s cubic-bezier(0.1, -0.6, 0.2, 0) infinite",
    },

    donatebutton1: {
      border: "none",
      width: "70px",
      background: "transparent",
      cursor: "pointer",
      animationName: "$blinker",
    },
    gifgrid: {
      borderRadius: "100%",
      backgroundColor: "#E9F6FF",
      // height: "130px",
      border: "1px solid rgb(34, 71, 126) ",
      zIndex: "1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    rightgrid: {
      // backgroundColor: "blue",
      // height: "100px",
      borderTopRightRadius: "50px",
      borderBottomRightRadius: "50px",
      // marginLeft: "-40px",
      zIndex: "0",
      color: "red",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      // fontSize: "1.2rem",
      border: "1px solid rgb(34, 71, 126) ",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={11.5}
          sm={11.5}
          md={11}
          lg={10}
          xl={10}
          style={{
            // height: "100px",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "15px",
            paddingBottom: "15px",
            // backgroundColor: "yellow",
          }}
          id="hangover"
        >
          <Grid style={Styles.leftgrid} id="border-div">
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_blank"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor:'red'
              }}
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="F83RSAG9W88KE"
              />
              <button
                title="Click to donate via PayPal"
                style={Styles.donatebutton}
                id="donate-btn"
              >
                <Typography
                  id="button_text"
                  // variant="h4"
                  style={{
                    fontWeight: "bold",
                    height: "90%",
                    borderRadius: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                  }}
                  className="donatetxt"
                >
                  Donate (Paypal)
                </Typography>
              </button>
            </form>
          </Grid>
          <Grid item lg={1} style={Styles.gifgrid} className="dollar-btn-div">
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_blank"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="F83RSAG9W88KE"
              />
              <button
                title="Click to donate via PayPal"
                style={Styles.donatebutton1}
                id="donate_btn"
              >
                <img
                  className="dollar-glf"
                  src={doller}
                  alt="my-gif"
                  style={{
                    // height: "100px",
                    borderRadius: "100%",
                    top: "5px",
                    left: "5px",
                    zIndex: "2",
                  }}
                />
              </button>
            </form>
          </Grid>
          <Grid style={Styles.rightgrid} className="right-div">
            <Typography
              // variant="h5"
              id="contribution"
              style={{
                paddingLeft: "30px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                backgroundColor: "#E9F6FF",
                width: "100%",
                borderRadius: "100px",
              }}
            >
              Your Contributions Make It Possible!
            </Typography>
            {/* <Grid style={{width:'50%'}}></Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default PayPalDonateView;
