import React from "react";
// import VideocamIcon from '@mui/icons-material/Videocam';
// import FeedIcon from '@mui/icons-material/Feed';

import { Grid, Typography, Card, CardContent, CardHeader } from "@mui/material";
import ImageSlider from "../../layout/imageslider";
// import Link from "@mui/material";

const Homestudy = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          style={{
            backgroundColor: "white",
            width: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container style={{ backgroundColor: "white", width: "90%" }}>
            <Grid
              item
              sm={12}
              lg={6}
              style={{ paddingTop: "20px", textAlign: "left" }}
            >
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                What is a home study?
              </Typography>
              <Typography
                style={{
                  fontStyle: "bold",
                  textAlign: "justify",
                  lineHeight: "1.7",
                  fontWeight: "450",
                  fontSize: "1rem",
                  paddingTop: "10px",
                }}
              >
                &ensp; &ensp; The home study (for adoption purposes it is also
                known as the Pre-adoptive Home Screening) is used in assessing
                the home for children's safety and available space. All homes
                must meet standards enumerated in the{" "}
                <span>
                  <a
                    target="_blank"
                    href="https://www.hhs.texas.gov/sites/default/files/documents/doing-business-with-hhs/provider-portal/protective-services/ccl/min-standards/chapter-749-cpa.pdf"
                  >
                    Minimum Standards and Guidelines for Child-Placing Agencies.
                  </a>
                </span>{" "}
                The home study is designed to elicit information on a variety of
                issues including:
              </Typography>
            </Grid>
            <Grid item sm={12} lg={6} style={{ padding: "20px" }}>
              {/* <Box sx={{maxWidth: 600, backgroundColor: 'pink', display: 'flex', justifyContent: 'center', alignContent: 'center', margin: 'auto', border: '5px solid black'}}> */}
              <Card>
                <CardHeader
                  style={{ backgroundColor: "rgb(53, 103, 178)" }}
                ></CardHeader>
                <CardContent style={{ textAlign: "left" }}>
                  <Typography
                    style={{ fontSize: 20, textAlign: "left" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <b>Guidelines</b>
                  </Typography>
                  <Typography style={{ lineHeight: "1.7" }}>
                    These{" "}
                    <a
                      target="_blank"
                      href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/About_TARE/Foster_Care/home_study.asp"
                    >
                      guidelines
                    </a>{" "}
                    are used by workers to complete foster and adoptive home
                    studies. The guidelines indicate the issues that are
                    required to be addressed with prospective foster and
                    adoptive families.
                    <span>
                      {" "}
                      <a
                        target="_blank"
                        href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/About_TARE/Foster_Care/home_study.asp"
                      >
                        [Read now]
                      </a>
                    </span>
                  </Typography>
                </CardContent>
              </Card>
              {/* </Box> */}
            </Grid>
            <Grid style={{ width: "100%", textAlign: "justify" }}>
              <Typography>
                <ul>
                  <li style={{ paddingBottom: "5px" }}>
                    Motivation for wanting to foster or adopt;
                  </li>
                  <li style={{ paddingBottom: "5px" }}>Health status;</li>
                  <li style={{ paddingBottom: "5px" }}>
                    Marital and family relationships;
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Applicants feelings about their own childhood and parents
                    including any history of abuse and/or neglect;
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Opinions about discipline;
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Sensitivity about abused and neglected children;
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Sensitivity towards birth families;
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Sensitivity about different socioeconomic, ethnic, and
                    cultural groups in relation to their ability to maintain the
                    ethnic identity of a child from a different background;
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Feelings about maintaining sibling relationships;
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Expectations of children in foster care;
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Family's ability to work with specific kinds of behavior and
                    backgrounds; and
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Documentation on the number, age and sex for whom the home
                    is approved.
                  </li>
                </ul>
              </Typography>
              <Typography style={{ paddingLeft: "40px" }}>
                Applicants are informed by CPS staff whether or not their home
                was approved and the reasons for the decision. Families who have
                successfully completed the CPS assessment process and are
                determined able to meet the needs of the children in CPS custody
                are approved.
              </Typography>
              <Typography>
                <ul>
                  <li style={{ paddingBottom: "5px" }}>Foster care,</li>
                  <li style={{ paddingBottom: "5px" }}>
                    Foster/adoptive homes,
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    Legal risk homes, and
                  </li>
                  <li style={{ paddingBottom: "5px" }}>Adoptive homes.</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Homestudy;
