import { Box, Divider } from "@mui/material";
import ImageSlider from "../layout/imageslider";
import {
  Typography,
  Grid,
  Grow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import aboutContent from "./AboutContent";
// import ExpandMoreIcon from "@mui/icons/ExpandMore";
import { ExpandMore } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "./about.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const About = () => {
  const [expandedAccordion, setExpandedAccordion] = useState(
    aboutContent[0]?.key
  );
  const handleAccordionToggle = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  const Styles = {
    accordiondetails: {
      backgroundColor: "white",
      wordSpacing: "normal",
      letterSpacing: "normal",
      WebkitHyphens: "auto",
      MozHyphens: "auto",
      msHyphens: "auto",
      hyphens: "auto",
      "@media (max-width:599.95px)": {
        // xs to sm
        padding: "20px",
      },
      "@media (min-width:600px) and (max-width:899.95px)": {
        // sm to md
        padding: "30px",
      },
      "@media (min-width:900px) and (max-width:1199.95px)": {
        // md to lg
        padding: "35px",
      },
      "@media (min-width:1200px)": {
        // lg to xl and above
        padding: "40px",
      },
    },
    typography_acc_summary: {
      color: "rgb(53, 103, 178)",
      padding: "0 16px",
      textAlign: "left",

      fontWeight: "bold",

      "@media (max-width:599.95px)": {
        // xs to sm
        fontSize: "1.3rem",
      },
      "@media (min-width:600px) and (max-width:899.95px)": {
        // sm to md
        fontSize: "1.4rem",
      },
      "@media (min-width:900px) and (max-width:1199.95px)": {
        // md to lg
        fontSize: "1.4rem",
      },
      "@media (min-width:1200px)": {
        // lg to xl and above
        fontSize: "1.5rem",
      },
    },

    accordionTitle: {
      color: "rgb(53, 103, 178)",
      fontWeight: "bold",
      fontSize: "1.3rem",
    },
  };

  return (
    <div className="root" align="center">
      <Box>
        {/* <ImageSlider /> */}

        {/* main grid */}

        <Grid
          className="maingrid"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {/* <Typography
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "#255aad",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            About Us
          </Typography> */}
          <Grow in={true} timeout={1500}>
            <Grid className="box1">
              {/* Accordions */}

              <Grid container xs={11} sm={12} md={12} lg={12}>
                {aboutContent.map((item, index) => (
                  <Accordion
                    key={item.key}
                    expanded
                    // expanded={
                    //   item.key === index[0]
                    //     ? handleAccordionToggle(0)
                    //     : expandedAccordion === item.key
                    // }
                    onChange={handleAccordionToggle(item.key)}
                    style={{
                      marginBottom: "10px",
                      width: "100%",
                      backgroundColor: "#D7E9F7",
                    }}
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMore />}
                      aria-controls={index + item.key}
                      id={index + item.key}
                    >
                      <Typography
                        variant="h4"
                        className="title"
                        sx={Styles.typography_acc_summary}
                      >
                        {item?.title}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails
                      className="accordionBody"
                      sx={Styles.accordiondetails}
                    >
                      <Grid container direction="column">
                        {item.desc?.map((item) => (
                          <Grid item key={item?.key}>
                            <Typography
                              variant="body1"
                              className="typeRoot"
                              style={{
                                textAlign: "left",
                                textAlign: "justify",
                              }}
                            >
                              &emsp;{item?.value}
                            </Typography>
                            <br />
                          </Grid>
                        ))}
                        {item?.listHead && (
                          <Grid item>
                            <Typography
                              variant="h5"
                              className="title"
                              style={{
                                color: "rgb(53, 103, 178)",
                                textAlign: "left",
                              }}
                            >
                              {item?.listHead}
                            </Typography>
                          </Grid>
                        )}
                        {item?.list &&
                          item.list.map((listItem) => (
                            <Grid item key={listItem?.key}>
                              <ListItem
                                component="div"
                                key={listItem?.key}
                                className="listLinks"
                                style={{
                                  paddingRight: "0px",
                                  paddingLeft: "0px",
                                }}
                              >
                                <Grid style={{ display: "flex" }}>
                                  <ArrowRightIcon />
                                  <ListItemText primary={listItem?.value} />
                                </Grid>
                              </ListItem>
                            </Grid>
                          ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Grow>
        </Grid>
      </Box>
    </div>
  );
};

export default About;
