import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import adoptionOverviewContent from "./AdoptionOverviewContent";
import ForwardIcon from "@mui/icons-material/Forward";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [clickedTabHeading, setClickedTabHeading] = React.useState(
    adoptionOverviewContent[0].heading
  );

  const handleChange = (event, newValue) => {
    const newClickedTabHeading = adoptionOverviewContent[newValue].heading;
    // console.log(`Tab "${newClickedTabHeading}" clicked`);
    setClickedTabHeading(newClickedTabHeading);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid container justifyContent="center">
      {/* <Grid style={{ padding: "20px" }}> */}
        <Typography style={{padding: '20px',fontWeight:"bold",fontSize: '1.3rem', color: '#1A355D'}}>
          General Information for {clickedTabHeading}
        </Typography>
      {/* </Grid> */}
      <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {adoptionOverviewContent.map((items, index) => (
              <Tab key={index} label={items.heading} {...a11yProps(index)} />
            ))}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {adoptionOverviewContent.map((items, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              dir={theme.direction}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ul style={{ textAlign: "left" }}>
                {items.desc.map((item, subIndex) => (
                  <li
                    key={subIndex}
                    style={{
                      paddingLeft: "5px",
                      listStyle: "square ",
                      lineHeight: "1.8",
                    }}
                  >
                    {item.Value}
                  </li>
                ))}
              </ul>
            </TabPanel>
          ))}
        </SwipeableViews>
      </Box>
    </Grid>
  );
}
