import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./components/layout/rootlayout";
import Home from "./components/home/home";
import About from "./components/about/about";
import AdoptionOverview from "./components/adoptionOverview/AdoptionOverview";
import FosterCareOverview from "./components/fostercareOverview/fostercareOverview";
import AdoptionIncentivesview from "./components/adoptionIncentives/adoptionIncentivesview";
import GeneralInformation from "./components/getStarted/generalInformation";
import Requirements from "./components/getStarted/requirements";
import StepsView from "./components/getStarted/stepsView/stepsview.js";
import HelpfulResources from "./components/helpfulresources/helpfulresources";
import Homestudy from "./components/adoptionOverview/Homestudy/homestudy.js";
import Overview from "./components/adoptionOverview/Homestudy/overview.js";
import VerticalTabs1 from "./components/adoptionOverview/Homestudy/overview_verticaltab.js";
import Placement from "./components/adoptionOverview/Placements/placement.js";
import MapCard from "./components/adoptionOverview/Placements/mappages/mapcard.js";
import Region1 from "./components/adoptionOverview/Placements/11regions/region1.js";
import Positive from "./components/adoptionOverview/positive/positiveview.js";
import State from "./components/getStarted/stepsView/state.js";
import Partners from "./components/adoptionOverview/Partners/partners.js";
import Spanish1 from "./components/adoptionOverview/spanish/spanish1.js";
import Spanish2 from "./components/adoptionOverview/spanish/spanish2.js";
import BasicAccordion from "./components/adoptionOverview/spanish/spanishaccordion.js";
import Fostervideo from "./components/fostercareOverview/fostervideo.js";
import DonationView from "./components/home/donation/donationview.js";
import Volunteer from "./components/home/volunteer/volunteer.js";
import ArticlesInfoView from "./components/home/articleinfo/articlesInfoView.js";
import FaqView from "./components/home/faqs/faqsView.js";
import HelpfulLinksView from "./components/home/helpfulLinks/HelpfulLinksView.js";
// import HeaderMenu01 from './components/layout/Appbar1.js';
import Join from "./components/home/volunteer/Join/join.js";
import Learnmore1 from "./components/home/articleinfo/learnmore/learnMore1.js";
import Learnmore2 from "./components/home/articleinfo/learnmore/learnMore2.js";
import Region4_6 from "./components/adoptionOverview/Placements/11regions/region4,5,6.js";
import Region2 from "./components/adoptionOverview/Placements/11regions/region2.js";
import Region7 from "./components/adoptionOverview/Placements/11regions/region7.js";
import Region8 from "./components/adoptionOverview/Placements/11regions/region8.js";
import Region9 from "./components/adoptionOverview/Placements/11regions/region9.js";
import Region10 from "./components/adoptionOverview/Placements/11regions/region10.js";
import Region3 from "./components/adoptionOverview/Placements/11regions/region3.js";
import Region11 from "./components/adoptionOverview/Placements/11regions/region11.js";
import Mapcardnoarea from "./components/adoptionOverview/Placements/mappages/mapcardnoareas.js";
import Adobe from "./components/adoptionOverview/spanish/adobe.js";
import Waitingform from "./components/adoptionOverview/spanish/childrenwaitingfrm.js";
import HomeStudyGuide from "./components/adoptionOverview/Homestudy/guidelines.js";
import ChildDisabilityLevel from "./components/adoptionOverview/spanish/childdisabilitylevel.js";
import MostCommonDisability from "./components/adoptionOverview/spanish/mostcommondisability.js";
import Childrendisabilities from "./components/adoptionOverview/spanish/childrendisabilities.js";
import ADHD from "./components/adoptionOverview/spanish/adhd.js";
import Asthma from "./components/adoptionOverview/spanish/asthma.js";
import Autism from "./components/adoptionOverview/spanish/autism.js";
import CerebralPalsy from "./components/adoptionOverview/spanish/cerebral_palsy.js";
import CysticFibrosis from "./components/adoptionOverview/spanish/cystic_fibrosis.js";
import Diabetes from "./components/adoptionOverview/spanish/diabetes.js";
import DownSyndrome from "./components/adoptionOverview/spanish/downsyndrome.js";
import Enuresis from "./components/adoptionOverview/spanish/enuresis.js";
import Galactosemia from "./components/adoptionOverview/spanish/galactosemia.js";
import IntellectualDisability from "./components/adoptionOverview/spanish/intellectual_disability.js";
import MyastheniaGravis from "./components/adoptionOverview/spanish/myasthenia_gravis.js";
import SickleCell from "./components/adoptionOverview/spanish/sickle_cell.js";
import FetalAlcohol from "./components/adoptionOverview/spanish/fetal_alcohol.js";
import Why_not_me from "./components/adoptionOverview/spanish/why_not_me.js";
import Videotable from "./components/adoptionOverview/spanish/videotable.js";
import OurChildren from "./components/adoptionOverview/spanish/ourchildren.js";
import Content1 from "./components/adoptionOverview/Homestudy/overview_verticaltab_content_sm.js";
import Partners_accordings from "./components/adoptionOverview/Partners/partner_accordings.js";
import Pagenotfound_404 from "./components/layout/pagenotfound.js";
import Contact from "./components/layout/contactus/contact.js";
import MyComponent from "./components/layout/testfile.js";
import ScrollDialog from "./instructions.js";
import Example from "./components/layout/notification.js";
import Togglemenu from "./components/layout/togglemenu.js";
import Site_map from "./components/home/site_map.js";
// import ResponsiveImageMap from './components/fostercareOverview/texas_map.js';
import ResponsiveMap from "./components/fostercareOverview/texasmap.js";
import Ads from "./components/home/ads.js";
import RegionFetcher from "./components/layout/notification.js";
import Agencylist from "./components/fosteragencies_directory/agencylist.js";
import Countylist from "./components/fosteragencies_directory/countylists.js";
import Headermenu0 from "./components/layout/appbar1.js";
// import Contact from './components/layout/contact.js
import {
  NotificationContainer,
  // NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import NonProfitTaxServices from "./components/home/tax_services/taxService.js";
import Accountability from "./components/home/tax_services/costAccountability.js";
// import Counties from "./store/actions/county.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/about", element: <About /> },
      { path: "/AdoptionOverview", element: <AdoptionOverview /> },
      { path: "/FosterCareOverview", element: <FosterCareOverview /> },
      { path: "/adoptionIncentivesview", element: <AdoptionIncentivesview /> },
      { path: "/generalInformation", element: <GeneralInformation /> },
      { path: "/requirements", element: <Requirements /> },
      { path: "/stepsview", element: <StepsView /> },
      { path: "/helpfulresources", element: <HelpfulResources /> },
      { path: "/homestudy", element: <Homestudy /> },
      { path: "/overview", element: <Overview /> },
      { path: "/verticaltab", element: <VerticalTabs1 /> },
      { path: "/placement", element: <Placement /> },
      { path: "/mapcard", element: <MapCard /> },
      { path: "/region1", element: <Region1 /> },
      { path: "/positiveview", element: <Positive /> },
      { path: "/state", element: <State /> },
      { path: "/partners", element: <Partners /> },
      { path: "/spanish1", element: <Spanish1 /> },
      { path: "/spanish2", element: <Spanish2 /> },
      { path: "/spanishaccordion", element: <BasicAccordion /> },
      { path: "/fostervideo", element: <Fostervideo /> },
      { path: "/donationview", element: <DonationView /> },
      { path: "/volunteer", element: <Volunteer /> },
      { path: "/articlesInfoView", element: <ArticlesInfoView /> },
      { path: "/faqsView", element: <FaqView /> },
      { path: "/HelpfulLinksView", element: <HelpfulLinksView /> },
      { path: "/dummy", element: <RegionFetcher /> },
      { path: "/join", element: <Join /> },
      { path: "/learnMore1", element: <Learnmore1 /> },
      { path: "/learnMore2", element: <Learnmore2 /> },
      { path: "/region4,5,6", element: <Region4_6 /> },
      { path: "/region2", element: <Region2 /> },
      { path: "/region3", element: <Region3 /> },
      { path: "/region7", element: <Region7 /> },
      { path: "/region8", element: <Region8 /> },
      { path: "/region9", element: <Region9 /> },
      { path: "/region10", element: <Region10 /> },
      { path: "/region11", element: <Region11 /> },
      { path: "/mapcardnoareas", element: <Mapcardnoarea /> },
      { path: "/adobe", element: <Adobe /> },
      { path: "/childrenwaitingfrm", element: <Waitingform /> },
      { path: "/guidelines", element: <HomeStudyGuide /> },
      { path: "/childdisabilitylevel", element: <ChildDisabilityLevel /> },
      { path: "/mostcommondisability", element: <MostCommonDisability /> },
      { path: "/childrendisabilities", element: <Childrendisabilities /> },
      { path: "/adhd", element: <ADHD /> },
      { path: "/asthma", element: <Asthma /> },
      { path: "/autism", element: <Autism /> },
      { path: "/cerebral_palsy", element: <CerebralPalsy /> },
      { path: "/cystic_fibrosis", element: <CysticFibrosis /> },
      { path: "/diabetes", element: <Diabetes /> },
      { path: "/downsyndrome", element: <DownSyndrome /> },
      { path: "/enuresis", element: <Enuresis /> },
      { path: "/galactosemia", element: <Galactosemia /> },
      { path: "/intellectual_disability", element: <IntellectualDisability /> },
      { path: "/myasthenia_gravis", element: <MyastheniaGravis /> },
      { path: "/sickle_cell", element: <SickleCell /> },
      { path: "/fetal_alcohol", element: <FetalAlcohol /> },
      { path: "/why_not_me", element: <Why_not_me /> },
      { path: "/videotable", element: <Videotable /> },
      { path: "/ourchildren", element: <OurChildren /> },
      { path: "/overview_verticaltab_content_sm", element: <Content1 /> },
      { path: "/partners_accordings", element: <Partners_accordings /> },
      { path: "*", element: <Pagenotfound_404 /> },
      { path: "/contact", element: <Contact /> },
      { path: "/testfile", element: <MyComponent /> },
      { path: "/in", element: <ScrollDialog /> },
      { path: "/togglemenu", element: <Togglemenu /> },
      { path: "/site_map", element: <Site_map /> },
      // {path:"/texas_map",element:<ResponsiveImageMap/>},
      { path: "/texasmap", element: <ResponsiveMap /> },
      { path: "/ads", element: <Ads /> },
      { path: "/agencylist", element: <Agencylist /> },
      { path: "/countylists", element: <Countylist /> },
      { path: "/appbar1", element: <Headermenu0 /> },
      { path: "/notification", element: <Example /> },
      { path: "/taxService", element: <NonProfitTaxServices /> },
      { path: "/costAccountability", element: <Accountability /> },
      // {path:'/county',element:<Counties/>}
    ],
  },
  <NotificationContainer />,
]);
function App() {
  return (
    <div className="App">
      <RouterProvider router={router}>
        {/*         <NotificationManager /> */}
      </RouterProvider>
    </div>
  );
}
export default App;
