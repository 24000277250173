import HandshakeTwoToneIcon from "@mui/icons-material/HandshakeTwoTone";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
// import NextPlanIcon from '@mui/icons-material/NextPlan';
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import InfoIcon from "@mui/icons-material/Info";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Link } from "react-router-dom";

const HomeContent = {
  mainContent: [
    {
      key: "mainList1",
      value: `We need foster care due to the ever-increasing number of abused and neglected children needing homes.`,
    },
    {
      key: "mainList2",
      value: `In 2023, CPS received 819,338 intakes with allegations of abuse and neglect.`,
    },
    {
      key: "mainList3",
      value: `Currently, in Texas, there are 17,457 youth available in foster care for adoption.`,
    },
    {
      key: "mainList4",
      value: `When foster homes are not found, these children have to stay at Child Protective Services (CPS) offices on cots, and/or siblings are being split up and placed in separate foster homes or residential treatment centers (RTCs).`,
    },
    {
      key: "mainList5",
      value: `In Texas, 4,182 children were adopted in 2023.`,
    },
    {
      key: "mainList6",
      value: `In 2023, a total of 874 youth aged out of foster care.`,
    },
  ],
  mainReadCards: [
    {
      key: "mainRead2",
      title: "Becoming a Foster Parent",
      cardIcon: (
        <EscalatorWarningIcon
          id="hello"
          style={{ fontSize: "12em", color: "#22477E" }}
        />
      ),
      readMoreLink: "becomeFosterParent",
      cardContentList: [
        {
          key: "read2List1",
          value: `Becoming a foster parent is both rewarding and challenging. In most cases, the primary goal of foster care is 
                    for the child and biological parents to be reunified.`,
          icon: (
            <ArrowRightIcon style={{ fontSize: "4em", color: "#22477E" }} />
          ),
        },
        {
          key: "read2List2",
          value: `The following is a brief overview of what is expected of Foster Parents:`,
          icon: (
            <ArrowRightIcon style={{ fontSize: "4em", color: "#22477E" }} />
          ),
        },
      ],
      cardContentSecList: [
        {
          key: "read2SecList1",
          value: `Protecting and Nurturing Children`,
          isRedirect: false,
          // icon: <KeyboardDoubleArrowRightIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
        {
          key: "read2SecList2",
          value: `Meeting Children's Developmental Needs and Addressing Developing Delays`,
          isRedirect: false,
          // icon: <KeyboardDoubleArrowRightIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
        {
          key: "read2SecList3",
          value: `Supporting Relationships Between Children and Families`,
          isRedirect: false,
          // icon: <KeyboardDoubleArrowRightIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
        {
          key: "read2SecList4",
          value: `Connecting Children to Safe,Nurturing Relationships Intended to Last a Lifetime`,
          isRedirect: false,
          // icon: <KeyboardDoubleArrowRightIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
        {
          key: "read2SecList5",
          value: `Working as a Member of a Professional Team`,
          isRedirect: false,
          // icon: <KeyboardDoubleArrowRightIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
      ],
    },
    {
      key: "mainRead1",
      title: "Becoming a Adoption Parent",
      cardIcon: (
        <HandshakeTwoToneIcon
          id="hello"
          style={{ fontSize: "12em", color: "#22477E" }}
        />
      ),
      readMoreLink: "services",
      cardContentList: [
        {
          key: "read1List1",
          value: `Adoption is a profound legal process where individuals or couples become the legal parents of a child not biologically related to them. It's a life-changing journey filled with both joy and challenges, necessitating careful consideration, preparation, and support from adoption agencies. `,
          icon: (
            <ArrowRightIcon style={{ fontSize: "4em", color: "#22477E" }} />
          ),
        },
        {
          key: "read1List2",
          value: `Types of adoption include domestic, international, and foster care adoption, each offering unique dynamics. The process involves home studies, background checks, interviews, and legal procedures to ensure a stable and loving environment for the child. `,
          icon: (
            <ArrowRightIcon style={{ fontSize: "4em", color: "#22477E" }} />
          ),
        },
        {
          key: "read1List3",
          value: ` Adoption provides children with loving families and fulfills the dreams of aspiring parents. Despite complexities, it's a noble and compassionate way to create families and bring lasting happiness. `,
          icon: (
            <ArrowRightIcon style={{ fontSize: "4em", color: "#22477E" }} />
          ),
        },
      ],
    },
    {
      key: "mainRead3",
      title: "Get Involved",
      cardIcon: (
        <VolunteerActivismIcon
          id="hello"
          style={{ fontSize: "12em", color: "#22477E" }}
        />
      ),
      readMoreLink: "join",
      cardContentList: [
        {
          key: "read3List1",
          value: `Building Families. Changing Lives. One Child at a Time.`,
          icon: (
            <ArrowRightIcon style={{ fontSize: "4em", color: "#22477E" }} />
          ),
        },
      ],
      cardContentSecList: [
        {
          key: "read3SecList1",
          value: (
            <Link
              to="DonationView"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <LocalAtmIcon style={{ fontSize: "2em", color: "#22477E" }} />
              Donate
            </Link>
          ),
          isRedirect: true,
          redirectLink: "donation",
          // icon: <LocalAtmIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
        {
          key: "read3SecList2",
          value: (
            <Link
              to="Volunteer"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <EmojiPeopleIcon style={{ fontSize: "2em", color: "#22477E" }} />{" "}
              Volunteer
            </Link>
          ),
          isRedirect: true,
          redirectLink: "volunteer",
          // icon: <EmojiPeopleIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
        {
          key: "read3SecList3",
          value: (
            <Link
              to="/ArticlesInfoView"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <InfoIcon style={{ fontSize: "2em", color: "#22477E" }} />{" "}
              Articles And Information{" "}
            </Link>
          ),
          isRedirect: true,
          redirectLink: "articlesInfo",
          // icon: <InfoIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
        {
          key: "read3SecList4",
          value: (
            <Link
              to="/faqsView"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              {" "}
              <ContactSupportIcon
                style={{ fontSize: "2em", color: "#22477E" }}
              />{" "}
              Frequently Asked Questions
            </Link>
          ),
          isRedirect: true,
          redirectLink: "faq",
          // icon: <ContactSupportIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
        {
          key: "read3SecList5",
          value: (
            <Link
              to="/HelpfulLinksView"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              {" "}
              <AirlineStopsIcon style={{ fontSize: "2em", color: "#22477E" }} />
              Helpful Links
            </Link>
          ),
          isRedirect: true,
          redirectLink: "helpLinks",
          // icon: <AirlineStopsIcon style={{fontSize: '2em', color: '#22477E'}}/>
        },
      ],
    },
  ],
};

export default HomeContent;
