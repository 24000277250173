import { Grid, Typography } from "@mui/material";
import React from "react";
import ImageSlider from "../../../layout/imageslider";
import Mapcardnoarea from "../mappages/mapcardnoareas";
import { Link } from "react-router-dom";
import DoubleArrow from "@mui/icons-material/DoubleArrow";
import ResponsiveMap from "../../../fostercareOverview/texasmap"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import MapCard from "../mappages/mapcard";
import "./region7.css";
const Region7 = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div align="center">
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          style={{ backgroundColor: "white", width: "80%", padding: "20px" }}
        >
          <Typography variant="h5" style={{textAlign:"center",color:"navy",fontWeight:"bold"}}>
            Region 7, Austin and surrounding area{" "}
          </Typography>
          <br />
          <Typography style={{fontWeight:"bold",}}>Foster Care and Adoption Information Meetings</Typography>
          <br />
          <Typography style={{ color: "red", textAlign: "justify" }}>
            * If accommodations such as a sign language interpreter are needed
            in order to attend an information meeting, please contact{" "}
            <a href="">Christina Garza</a> at (512) 751-7946, at least 3 days
            prior to the meeting, if possible.{" "}
            <strong>
              Please note that children are not able to attend information
              meetings due to the potential sensitive subject matter discussed.
              Any attendees arriving 15 minutes late or more will not be
              admitted.*
            </strong>
          </Typography>
          <Grid style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
          <ResponsiveMap/>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <br />
              <Typography>
                <b>Recruitment Events and Foster/Adopt Information Meetings:</b>
              </Typography>
              <br />
              <ul style={{ lineHeight: "1.6" }}>
                <li>
                  <Link to="#table1"> January 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table2">February 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link
                    target="_blank"
                    to="https://partnershipsforchildren.org/heartgallery-home/"
                  >
                    The Heart Gallery of Central Texas
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="#recruitment"> Recruitment Staff</Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp"
                  >
                    {" "}
                    Faith-Based Staff
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "justify",
              }}
            >
              <MapCard style={{ border: "none" }} />
            </Grid>
          </Grid>
          <Typography style={{ textAlign: "justify", display: "flex" }}>
            <DoubleArrow /> The Texas Department of Family and Protective
            Services is looking for caring foster and adoptive families in your
            area. To learn more about becoming a foster or adoptive parent
            through our agency, we will be having the following information
            meetings in your area.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify", display: "flex" }}>
            <DoubleArrow />
            These information meetings are open to all but targeting the Region
            7’s counties of Bastrop, Bell, Blanco, Bosque, Brazos, Burleson,
            Burnet, Caldwell, Coryell, Falls, Fayette, Freestone, Grimes,
            Hamilton, Hays, Hill, Lampasas, Lee, Leon, Limestone, Llano,
            Madison, McLennan, Milam, Mills, Robertson, San Saba, Travis,
            Washington, and Williamson. If you live outside this area, please
            select your region using the map above.
          </Typography>
          {/* Table1 */}
          <Grid
            id="table1"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <br />
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>January 2024</b>
            </Typography>
            <br />
            <TableContainer
              component={Paper}
              id="table1"
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Online</strong>
                      <p>General Information Meeting</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <strong>
                          Tuesday, January 9, 2024, from 6:00-7:30pm
                        </strong>
                      </p>
                      <p>Register now for this foster/adopt meeting!</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://fostercommunity.org/info-session-january-9/"
                          style={{ wordBreak: "break-word" }}
                        >
                          https://fostercommunity.org/info-session-january-9/
                        </a>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Online</strong>
                      <p>General Information Meeting</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <strong>
                          Thursday, January 25, 2024, from 6:00-7:30pm
                        </strong>
                      </p>
                      <p>
                        <strong>
                          Register now for this foster/adopt event!
                        </strong>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://fostercommunity.org/info-session-january-25/"
                          style={{ wordBreak: "break-word" }}
                        >
                          https://fostercommunity.org/info-session-january-25/
                        </a>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table2 */}
          <Grid
            id="table2"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <br/>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>February 2024</b>
            </Typography>
            <br/>
            <TableContainer
              component={Paper}
              id="table2"
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Online</strong>
                      <p>General Information Meeting</p>
                      <p>Region 7</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <strong>
                          Tuesday, February 6, 2024, from 6:00-7:30pm
                        </strong>
                      </p>
                      <p>Register now for this foster/adopt meeting!</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://fostercommunity.org/info-session-february-6/"
                          style={{ wordBreak: "break-word" }}
                        >
                          https://fostercommunity.org/info-session-february-6/
                        </a>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Online</strong>
                      <p>General Information Meeting</p>
                      <p>Region 7</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <strong>
                          Thursday, February 22, 2024, from 6:00-7:30pm
                        </strong>
                      </p>
                      <p>
                        <strong>
                          Register now for this foster/adopt event!
                        </strong>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://fostercommunity.org/info-session-february-22/"
                          style={{ wordBreak: "break-word" }}
                        >
                          https://fostercommunity.org/info-session-february-22/
                        </a>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Recruitment Grid */}
          <Grid id="recruitment" style={{ paddingTop: "10px" }}>
            <br/>
            <Typography variant="h5">
              <b>Recruitment Staff:</b>
            </Typography>
            <br />
            <Typography variant="h6">DFPS Faith-Based Staff:</Typography>
            <br />
            <ul>
              <li>
                <a href="">Teoahnna Mayo</a>, FAD Faith-Based Specialist, (254)
                220-6819
              </li>
              <li>
                <p>
                  <a href="">Christi Yackel</a>, FAD Faith-Based Specialist,
                  (979) 203-6931
                </p>
                <p>
                  Works with the faith community to answers questions about how
                  to become foster and adoptive family.
                </p>
              </li>
            </ul>
            <Typography>
              *If you are interested in a specific child or siblings found on
              TARE, Adopt Us Kids from Texas, or the Heart Gallery, please
              contact the TARE Coordinator or <a href="">TARE@dfps.texas.gov.</a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Region7;
