import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageSlider from "../layout/imageslider";
import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Countylist from "./countylists";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./agencylist.css";
import axios from "axios";
import Reducer1 from "../../store/actions/fetch_counties_agencies";
import { useDispatch, useSelector } from "react-redux";
import { agenciesFetching } from "../../store/actions/fetch_counties_agencies";
import CircularProgress from "@mui/material/CircularProgress";

export default function Agencylist() {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const agencies = useSelector((state) => state.agencyList.agency_data);
  const submitted = useSelector((state) => state.countyList.county_Submitted);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate("/contact", { state: { data: item } });
  };

  const handleOnClick = async (CountyName, event) => {
    event.preventDefault();
    setLoading(true);
    dispatch({ type: "countySelected", selectedCounty: CountyName });
    dispatch(agenciesFetching());
  };

  return (
    <div align="center" style={{ width: "100%" }}>
      {/* <ImageSlider /> */}
      <Grid
        className="mainGrid"
        sx={{
          backgroundColor: "white",
          height: agencies&& agencies.result ?"100%":"100vh" ,
        }}
        >
        {console.log(agencies.result)}
        <br />
        <Typography
          id="Title"
          variant="h5"
          fontWeight="bold"
          style={{ textDecoration: "2px underline" }}
        >
          Agency Directory
        </Typography>
        <br />
        <Grid
          style={{
            width: "80%",
            // height: "100px",
            backgroundColor: "#DAEAF1",
            border: "1px solid black",
            borderRadius: "10px",
            padding: "10px",
            textAlign: "left",
          }}
        >
          <i>
            <Typography
              style={{
                color: "black",
                fontWeight: "bold",
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              Instructions To Contact Child Placing & Agencies
            </Typography>

            <ul style={{ top: 0 }}>
              <li>
                <Typography>
                  Please select a county to know the Child Placing & Agencies.
                  <br />
                </Typography>
              </li>
              <li>
                <Typography>
                  If you wish to contact a specific Agency, select the Agency
                  from the available list, click the Contact Us button, and fill
                  out the form.
                </Typography>
              </li>
              <li>
                <Typography>
                  If you wish to contact all the Child Placing & Agencies in a
                  County or Region{" "}
                  <a href="/contact" style={{ color: "blue" }}>
                    Click Here
                  </a>
                  .
                </Typography>
              </li>
            </ul>
          </i>
        </Grid>
        <br />
        <Countylist />
        <br />
        {submitted && !agencies.result ? (
          <Grid>
            <CircularProgress disableShrink />
          </Grid>
        ) : (
          <Grid style={{ width: "80%", marginTop: "30px" }}>
            {agencies.result && (
              <Typography
                style={{
                  // fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "1.2rem",
                }}
              >
                CHILD PLACING & AGENCIES IN &ndash;{" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "navy",
                    textDecoration: "underline",
                  }}
                >
                  {agencies.county}
                </span>
              </Typography>
            )}
            <br />

            <Grid className="openAccordion">
              {agencies.result &&
                agencies.result.map((item, index) => (
                  <Accordion
                    key={item.id}
                    expanded={expanded === `panel${item._id}`}
                    onChange={handleChange(`panel${item._id}`)}
                    style={{ marginBottom: "5px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ fontWeight: "bold", backgroundColor: "#8CC0DE" }}
                    >
                      <Typography key={index} style={{ fontWeight: "bold" }}>
                        {item.agencyName}
                      </Typography>
                      &nbsp;
                      <Typography> | </Typography>&nbsp;
                      <Typography key={index} style={{ fontWeight: "bold" }}>
                        {item.city} -
                      </Typography>
                      &nbsp;
                      <Typography key={index} style={{ fontWeight: "bold" }}>
                        {item.state}
                      </Typography>
                      {/* &nbsp;
                    <Typography key={index} style={{ fontWeight: "bold" }}>
                      {item.zipCode}
                    </Typography> */}
                      &nbsp;
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: "#DAEAF1" }}>
                      <Grid style={{ width: "80%", border: "1px solid black" }}>
                        <Table
                          size="small"
                          style={{ backgroundColor: "white" }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "bold" }}
                              >
                                AGENCY NAME
                              </TableCell>
                              <TableCell key={index}>
                                {item.agencyName}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "bold" }}
                              >
                                AGENCY TYPE
                              </TableCell>
                              <TableCell key={index}>
                                {item.childPlacingAgency
                                  ? "Child Placing Agency"
                                  : "Volunteer Opportunity"}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>
                                WEBSITE
                              </TableCell>
                              <TableCell key={index}>
                                {item.agencyWebsite ? (
                                  <a
                                    href={
                                      /^http[s]?:\/\//.test(item.agencyWebsite)
                                        ? item.agencyWebsite
                                        : `https://${item.agencyWebsite}`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "blue",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {item.agencyWebsite}
                                  </a>
                                ) : (
                                  "N/A"
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>
                                ADDRESS
                              </TableCell>
                              <TableCell key={index}>
                                {item.agencyAddress}
                                {item.City}
                                {", "}
                                {item.state}
                                {" - "}
                                {item.zipCode}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>
                                COUNTIES SERVED
                              </TableCell>
                              <TableCell
                                key={index}
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  listStyle: "none",
                                  flexWrap: "wrap",
                                }}
                              >
                                {item.countiesServed?.length > 0
                                  ? item.countiesServed.map(
                                      (counties, index) => (
                                        <li key={index}>
                                          <a
                                            href="#"
                                            onClick={(e) =>
                                              handleOnClick(counties, e)
                                            }
                                            style={{
                                              textDecoration: "none",
                                              color: "blue",
                                            }}
                                          >
                                            {counties}
                                          </a>
                                          ,
                                        </li>
                                      )
                                    )
                                  : "N/A"}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>
                                REQUIREMENTS
                              </TableCell>
                              <TableCell
                                key={index}
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  listStyle: "none",
                                  flexWrap: "wrap",
                                }}
                              >
                                {item.fosterRequirements?.length > 0
                                  ? item.fosterRequirements.map(
                                      (requirement, index) => (
                                        <li key={index}>{requirement},</li>
                                      )
                                    )
                                  : "N/A"}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>
                                CONTACT
                              </TableCell>
                              <TableCell key={index}>
                                <Button
                                  variant="contained"
                                  onClick={() => handleClick(item)}
                                >
                                  Contact Us
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Grid>

            {/* Mobile View */}
            <Grid className="hideAccordion">
              {agencies.result &&
                agencies.result.map((item, index) => (
                  <Accordion
                    key={item.id}
                    expanded={expanded === `panel${item._id}`}
                    onChange={handleChange(`panel${item._id}`)}
                    style={{ marginBottom: "5px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ fontWeight: "bold", backgroundColor: "#8CC0DE" }}
                    >
                      <Typography key={index} style={{ fontWeight: "bold" }}>
                        {item.agencyName}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        textAlign: "left",
                        backgroundColor: "#DAEAF1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        style={{
                          width: "95%",
                          border: "1px solid black",
                          padding: "5px",
                          backgroundColor: "white",
                        }}
                      >
                        <Grid style={{ gap: "20px", marginBottom: "2px" }}>
                          <Typography style={{ fontWeight: "bolder" }}>
                            AGENCY NAME :
                          </Typography>
                          <Typography
                            key={index}
                            style={{ fontSize: "0.9rem" }}
                          >
                            {item.agencyName}
                          </Typography>
                        </Grid>
                        <Divider />

                        <Grid style={{ gap: "20px", marginBottom: "2px" }}>
                          <Typography style={{ fontWeight: "bolder" }}>
                            AGENCY TYPE :
                          </Typography>
                          <Typography>
                            {item.childPlacingAgency
                              ? "Child Placing Agency"
                              : "Volunteer Opportunity"}
                          </Typography>
                        </Grid>

                        <Grid style={{ gap: "20px", marginBottom: "2px" }}>
                          <Typography style={{ fontWeight: "bold" }}>
                            WEBSITE :
                          </Typography>
                          <Typography key={index}>
                            {item.agencyWebsite ? (
                              <a
                                href={
                                  /^http[s]?:\/\//.test(item.agencyWebsite)
                                    ? item.agencyWebsite
                                    : `https://${item.agencyWebsite}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "blue",
                                  textDecoration: "none",
                                }}
                              >
                                {item.agencyWebsite}
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </Typography>
                        </Grid>
                        <Divider />
                        <Grid style={{ gap: "20px", marginBottom: "2px" }}>
                          <Typography style={{ fontWeight: "bold" }}>
                            ADDRESS :
                          </Typography>
                          <Typography
                            key={index}
                            style={{ fontSize: "0.9rem" }}
                          >
                            {item.agencyAddress}
                            {item.city}
                            {", "}
                            {item.state}
                            {" - "}
                            {item.zipCode}
                          </Typography>
                        </Grid>
                        <Divider />

                        <Grid style={{ gap: "20px", marginBottom: "2px" }}>
                          <Typography style={{ fontWeight: "bold" }}>
                            COUNTIES SERVED :
                          </Typography>
                          <Typography
                            key={index}
                            style={{
                              gap: "5px",
                              listStyle: "none",
                              width: "70%",
                              flexWrap: "wrap",
                              display: "flex",
                              fontSize: "0.8rem",
                            }}
                          >
                            {item.countiesServed?.length > 0
                              ? item.countiesServed.map((counties, index) => (
                                  <li key={index}>
                                    <a
                                      href="#"
                                      onClick={(e) =>
                                        handleOnClick(counties, e)
                                      }
                                    >
                                      {counties}
                                    </a>
                                    ,
                                  </li>
                                ))
                              : "N/A"}
                          </Typography>
                        </Grid>
                        <Divider />

                        <Grid style={{ gap: "20px", marginBottom: "2px" }}>
                          <Typography style={{ fontWeight: "bold" }}>
                            FOSTER REQUIREMENTS :
                          </Typography>
                          <Typography
                            key={index}
                            style={{
                              gap: "5px",
                              listStyle: "none",
                              display: "flex",
                              flexWrap: "wrap",
                              fontSize: "0.8rem",
                            }}
                          >
                            {item.fosterRequirements?.length > 0
                              ? item.fosterRequirements.map(
                                  (requirement, index) => (
                                    <li key={index}>{requirement},</li>
                                  )
                                )
                              : "N/A"}
                          </Typography>
                        </Grid>
                        <Divider />

                        <Grid style={{ gap: "20px", marginBottom: "2px" }}>
                          <Typography
                            style={{ fontWeight: "bold", marginBottom: "5px" }}
                          >
                            CONTACT:
                          </Typography>

                          <Button
                            variant="contained"
                            onClick={() => handleClick(item)}
                          >
                            Contact Us
                          </Button>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Grid>
            {/* <Divider /> */}
          </Grid>
        )}
        <br />
      </Grid>
    </div>
  );
}
