import { Link } from "react-router-dom";
const adoptionOverviewContent = [
    {
        key: 1,
        heading: "About Adoption",
        desc: [
            {
            key: 'desc1',
            Value:  <Link to="/stepsview" style={{color:'blue'}}>Steps to Become a Foster/Adoptive Parent</Link>
            },
            {
                key: 'desc2',
                Value: <Link to="/requirements" style={{color:'blue'}}>Requirements for Foster/Adopt Families</Link>
            },
            {
                key: 'desc3',
                Value: <Link target="_blank" to="https://www.dfps.texas.gov/Child_Protection/Adoption/adoption_assistance.asp" style={{color:'blue'}}>Adoption Assistance Program</Link>
            },
            {
                key: 'desc4',
                Value: <Link to="/spanish1" style={{color:'blue'}}>Otros Documentos En Españo</Link>
            },
            {
                key: 'desc5',
                Value: <Link to="/spanish2" style={{color:'blue'}}>El Cuidado Temporal y la Adopcio</Link>
            }
        ]
    },
    {
        key: '2',
        heading: 'Placement Information',
        desc: [
            {
                key: 'desc1',
                Value: <Link to="/placement#placementofDFPSinotherstate"  style={{color:'blue'}}>Placement of DFPS Children in other States.</Link>
                // Value: <a href="/placement#placementofDFPSinotherstate" >Placement of DFPS Children in other States.</a>
            },
            {
                key: 'desc2',
                Value: <Link target="_blank" to="https://www.dfps.texas.gov/child_protection/State_Care/icpc.asp" style={{color:'blue'}}>Interstate Compact on the Placement of Children (ICPC)</Link>
            },
            {
                key: 'desc3',
                Value: <Link to="/placement#placementschildren" style={{color:'blue'}}>Placement of DFPS Children Outside the United States</Link>
            }
        ]
    },
    {
        key: '3',
        heading: 'Foster Care and Adoption Information',
        desc: [
            {
                key: 'desc1',
                Value: <Link to="/placement#informationmeeting" style={{color:'blue'}}> Foster/Adopt Information Meetings in Your Area</Link> 
                // Value: <a href="/placement#informationmeeting" >Foster/Adopt Information Meetings in Your Area</a>
            },
            {
                key: 'desc2',
                Value:<Link to="/homestudy" style={{color:'blue'}}>Foster Care and Adoptive Home Study</Link>
            },
            {
                key: 'desc3',
                Value: <Link to="/overview" style={{color:'blue'}}>Foster Care and Adoption Program - Overview</Link>
            },
            {
                key: 'desc4',
                Value: <Link to="/placement#facts" style={{color:'blue'}} >Foster Care and Adoption Facts</Link> 
            },
            {
                key: 'desc5',
                // Value: 'Medicaid Update - Latest on the Preferred Drug List (PDL)'
                Value: <Link to="/placement#medicalserviceupdate" style={{color:'blue'}}>Medicaid Update - Latest on the Preferred Drug List (PDL)</Link>
            },
            {
                key: 'desc6',
                Value: <Link target="_blank" to="https://www.dfps.texas.gov/Child_Protection/Youth_and_Young_Adults/Education/ETV.asp" style={{color:'blue'}}>Higher Education Assistance for Current or Former Foster Youth</Link>
            },
            {
                key: 'desc7',
                Value: <Link target="_blank" to="https://www.dfps.texas.gov/Community/Volunteer/Opportunities/mentor.asp" style={{color:'blue'}}>Mentoring Foster Youth</Link>
            },
            {
                key: 'desc8',
                // Value: 'Private Adoption and Foster Care Agency Partners'
                Value: <Link to="/partners" style={{color:'blue'}}>Private Adoption and Foster Care Agency Partners</Link>
            }
        ]
    }
]

export default adoptionOverviewContent;