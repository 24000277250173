import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";
const Autism = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%" }}>
            <Typography variant="h5" style={{ color: "#002F86",textAlign:'center',fontWeight:"bold" }}>
              A closer look at Autism Spectrum Disorder
            </Typography>
            <br />
            <Typography>
              <em>
                More...{" "}
                <a target="_blank" href="http://www.autism-society.org">
                  Autism Society of America
                </a>{" "}
                |{" "}
                <a
                  target="_blank"
                  href="http://209.200.89.252/search_site/chapter_list.cfm?program_name=&amp;state=TX"
                >
                  Texas Chapters
                </a>{" "}
                -{" "}
                <a
                  target="_blank"
                  href="http://www.nimh.nih.gov/health/topics/autism-spectrum-disorders-asd/index.shtml"
                >
                  National Institute on Mental Health
                </a>
              </em>
            </Typography>

            <br />
            <Typography>
              The term "autism" was first used more than 50 years ago by a
              psychiatrist at Johns Hopkins University. Dr. Leo Kanner was
              describing a group of children who were self-absorbed and who had
              severe social, communication, and behavioral problems. No one
              knows what causes autism. Considered a pervasive developmental
              disability, autism occurs in about one in 150 children in the
              United States have autism according to the Centers for Disease
              Control and Prevention’s (CDC). Autism is three to four times more
              prevalent in boys than girls and 50 times more frequent in
              siblings. Parents with one child with autism have a 5%-10% chance
              of having another child with autism. It occurs in all racial,
              ethnic, and socioeconomic groups. Autism is defined by significant
              impairments in social interaction and communication and the
              presence of unusual behaviors and interests. One person with
              autism may have very different symptoms and behaviors than
              another. Because of these differences, doctors now think of autism
              as a "spectrum disorder” or a group of disorders with a range of
              similar features. Some children with autism go through the
              developmental stages of talking, crawling, and walking while
              others are delayed. Even those children with autism who progress
              regularly begin to show symptoms by the age of 3 and lasts
              throughout a person's life.
            </Typography>
            <br />
            <Typography>
              Autism sometimes shows up at birth with babies arching their back
              away from their caregiver and staying stiff (failing to go limp)
              when held. As infants, they are often described as passive or
              agitated. Some manifestations of autism include rocking to and
              fro, head-banging, self flagellation, hand-biting, poor sleeping
              patterns, eating problems, poor eye contact, insensitivity to
              pain, attention deficits, and hyperactivity or under-activity.
            </Typography>
            <br />
            <Typography>
              Many people with autism spectrum disorders (ASDs) also have
              unusual ways of learning, paying attention, or reacting to
              different sensations. The thinking and learning abilities of
              people with ASDs can vary – from gifted to severely challenged.
              They might repeat certain behaviors or perseverate on certain
              things. For example, people with ASDs may spend a lot of time
              repeatedly flapping their arms or rocking from side to side. They
              might repeatedly turn a light on and off or spin the wheels of a
              toy car in front of their eyes. These types of activities are
              known as self-stimulation or “stimming.”
            </Typography>
            <br />
            <Typography>
              People with ASDs might have trouble changing their daily routine.
              A change in the normal pattern of the day, like a stop on the way
              home from school, can be very upsetting or frustrating to people
              with ASDs. They may lose control or tantrum, especially if they
              are in a strange place. Some people with ASDs develop routines
              that might seem unusual or unnecessary. For example, a person may
              always want to watch a video in its entirety - from the previews
              at the beginning through the credits at the end. Not being allowed
              to do these types of routines may cause severe frustration and
              tantrums.
            </Typography>
            <br />
            <Typography>
              Another element is the child's apparent inability to relate to
              others through hugs and physical affection. Instead of accepting
              hugs and touches, children with autism often draw away and react
              from being touched. However, many children and adults with autism
              make eye contact, show affection, smile and laugh, and show a
              variety of other emotions, but in varying degrees. Yet, like other
              children, they respond to their environment in positive and
              negative ways. Some symptoms may lessen as the child ages; others
              may disappear altogether. With appropriate intervention, many
              behaviors of autistism can be positively changed.
            </Typography>
            <br />
            <Typography>
              Raising a child with autism is an enormous commitment and there
              are extraordinary people in Texas and the United States. If you
              are a Texas resident and are not approved as a foster or adoptive
              family, please fill out our Adoption and Foster Care Interest form
              in the
              <a href="/generalInformation"> Get Started</a> section.
            </Typography>
            <br />
            <Grid style={{ width: "100%", textAlign: "left" }}>
              {" "}
              <Typography>
                If you have questions or want to inquire about a specific child
                or sibling group, contact the{" "}
                <a href="">Texas Adoption Resource Exchange (TARE)</a> or call
                1-800-233-3405.
              </Typography>
              <Grid style={{ float: "right" }}>
                <Typography>
                  This content taken from:{" "}
                  <a
                    target="_blank"
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                  >
                    Adoption and Foster Care
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Autism;
