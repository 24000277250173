import React from "react";
import ImageSlider from "../../layout/imageslider";
import { Grid, Typography, Card, CardContent, CardHeader } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import FeedIcon from "@mui/icons-material/Feed";
import { Link } from "react-router-dom";

const OurChildren = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          textAlign="left"
          width="80%"
          style={{ padding: "20px", backgroundColor: "white" }}
        >
          <Typography variant="h5" fontWeight="bold" color="rgb(63, 81, 181)">
            {" "}
            Our Children
          </Typography>
          <Typography style={{ textAlign: "justify", lineHeight: "1.6" }}>
            Children are placed in foster homes because they have been removed
            from their own families due to abuse, neglect, or other family
            problems that endanger their safety.{" "}
          </Typography>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{ backgroundColor: "" }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="rgb(63, 81, 181)"
              >
                The children may:
              </Typography>
              <Typography>
                <ul style={{ lineHeight: "1.6" }}>
                  <li>Range in age from infancy through 18 years of age;</li>
                  <li>Have special medical, physical, or emotional needs;</li>
                  <li>Belong to any ethnicity or race; and</li>
                  <li>
                    Be a part of a sibling group who need to be placed together.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              align="center"
              style={{ backgroundColor: "" }}
            >
              <Card style={{ width: "80%" }}>
                <CardHeader style={{ backgroundColor: "rgb(53, 103, 178)" }} />
                <CardContent>
                  <ul style={{ textAlign: "left", lineHeight: "20px" }}>
                    <li style={{ lineHeight: "30px" }}>
                      Who are children with "Special Needs"?
                    </li>
                    <li style={{ lineHeight: "30px" }}>
                      <a href="/fostervideo">
                        View foster/adopt videos and Public Service
                        Announcements.
                      </a>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <br />
          <Typography variant="h5" fontWeight="bold" color="rgb(63, 81, 181)">
            Adoptive Home Placements
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify", lineHeight: "1.6" }}>
            Children are placed in adoptive homes if efforts to reunify them
            with their birth families are unsuccessful. Children whose parental
            rights have been legally terminated may be adopted by relatives, a
            foster family, or an adoptive family. If a child is adopted and
            meets the "Special Needs" requirements, the family may be eligible
            for paid Adoption Assistance. For more information about Adoption
            Assistance please see the{" "}
            <a
              target="_blank"
              href="https://www.dfps.texas.gov/Child_Protection/Adoption/adoption_assistance.asp"
              color="blue"
            >
              DFPS Adoption Assistance
            </a>{" "}
            page on the DFPS public website.
          </Typography>
          <br />
          <Typography variant="h5" fontWeight="bold " color="rgb(63, 81, 181)">
            Who are children with "Special Needs"?
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            The term "Special Needs" is used to define children with several
            characteristics and does not necessarily mean the child has a
            mental, emotional or physical disability.
          </Typography>
          <br />
          <Typography>
            <b>
              A child with special needs is one who meets all of the criteria in
              this section:
            </b>
          </Typography>
          <Typography>
            <ul
              style={{
                textAlign: "left",
                lineHeight: "1.6",
                textAlign: "justify",
              }}
            >
              <li>
                The child is in the care of DFPS, has not turned 18 years old,
                and meets one of the following conditions:
                <ul style={{ lineHeight: "1.6" }}>
                  <li>The child is at least 6 years old;</li>
                  <li>
                    The child is at least 2 years old and a member of a racial
                    or ethnic group that exits foster care at a slower pace than
                    other racial or ethnic groups;
                  </li>
                  <li>
                    The child is part of a sibling group that is being adopted
                    together or is being adopted to join siblings that have
                    previously been adopted;
                  </li>
                  <li>
                    The child has a verifiable physical, mental, or emotional
                    disabling condition, as established by an appropriately
                    qualified professional through a diagnosis that addresses
                    what the condition is AND that the condition is disabling;
                    or
                  </li>
                  <li>
                    The child qualifies to receive SSI benefits for disability.
                  </li>
                </ul>
              </li>
              <li>
                DFPS has determined that the child can not or should not return
                home to their parents.
              </li>
              <li>
                A reasonable effort has been made to find an adoptive placement
                for the child without paid Adoption Assistance.
              </li>
            </ul>
          </Typography>
          <Typography>
            For additional information please click on the following link: Who
            is a child with special needs?
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default OurChildren;
