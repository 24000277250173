import { Grid, Typography } from "@mui/material";
import React from "react";
import ImageSlider from "../../../layout/imageslider";
import MapCard from "../mappages/mapcard";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import "./region2.css"
const Region2 = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div align="center">
      {/* <ImageSlider /> */}

      <Grid id="maingrid"
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          

          <Grid style={{width:"100%",textAlign:"left"}}>
          <Typography variant="h5" fontWeight="bold" style={{textAlign:"center",color:"navy"}}>
            Region 2, Abilene and surrounding area
          </Typography>
          <br />
          <Typography variant="h6" fontWeight="bold" >
            {" "}
            Foster Care and Adoption Information Meetings
          </Typography>
          <br />
          <Typography style={{ color: "red", textAlign: "justify" }}>
            * If accomodations such as a sign language interpreter are needed in
            order to attend an information meeting, please call the Recruitment
            Staff listed below 3 days prior to the meeting, if possible. *
          </Typography>
          </Grid>
          <Grid container style={{ display: "flex", padding: "10px" }}>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>Recruitment Events and Foster/Adopt Information Meetings:</b>
              </Typography>
              <br />
              <ul style={{ lineHeight: "1.6" }}>
                <li>
                  <Link to="#table1"> January 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table2">February 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table3">March 2024</Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Texas_Heart_Galleries/WichitaFalls/default.asp"
                  >
                    {" "}
                    Heart Gallery of the Greater Wichita Falls Area
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "justify",
                // textAlign: "center"
              }}
            >
              <MapCard />
            </Grid>
          </Grid>
          <Grid style={{ display: "flex" }}>
            <KeyboardDoubleArrowRightIcon />
            <Typography
              style={{ textAlign: "justify", wordBreak: "break-word" }}
            >
              <a target="_blank" href="https://2ingage.org/">
                {" "}
                2INgage
              </a>{" "}
              is responsible for Foster Care and Adoption Information Meetings
              for all of Region 2 Counties. Please find Information Meeting
              details below. For additional information please visit Fostering
              Big Country and Texoma Kids at:{" "}
              <a target="_blank" href="https://fosteringbigcountrykids.com/">
                http://fosteringbigcountrykids.com/.
              </a>
            </Typography>
          </Grid>
          <br />
          <Grid style={{ display: "flex" }}>
            <KeyboardDoubleArrowRightIcon />

            <Typography
              variant="body1"
              style={{ textAlign: "justify", wordBreak: "break-word" }}
            >
              2INgage, is now in partnership with DFPS as part of
              Community-Based Care. Community-Based Care is a new way of
              providing foster care and case management services. It's a
              community-based approach to meeting the individual and unique
              needs of children, youth, and families. For more information
              regarding Community-Based Care, please visit
              <br />
              <a
                target="_blank"
                href="https://www.dfps.texas.gov/CBC/default.asp"
                style={{ textWrap: "wrap",wordBreak: "break-word", }}
              >
                https://www.dfps.texas.gov/CBC/default.asp
              </a>
            </Typography>
          </Grid>
          <br />
          <Grid style={{ display: "flex" ,width: '100%'}}>
            <KeyboardDoubleArrowRightIcon />
            <Typography>
              For information on fostering or adopting a child from out of
              state, contact the{" "}
              <a
                target="_blank"
                href="https://www.dfps.texas.gov/child_protection/State_Care/icpc.asp"
              >
                State Office ICPC
              </a>
              .
            </Typography>
          </Grid><br />
          {/* Table1 */}
          <Grid
            id="table1"
            style={{
              paddingTop: "30px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>January 2024</b>
            </Typography>
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "18%" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "82%" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      <strong>Online</strong>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <strong>
                          Monday, January 8th, 2024, 6:00 – 7:00 PM
                        </strong>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      
                        <Link
                          target="_blank"
                          to="https://us02web.zoom.us/meeting/register/tZ0pfuuppzwiE9zUZRW_W4-T-yLXqIYaaq8l#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZ0pfuuppzwiE9zUZRW_W4-T-yLXqIYaaq8l
                        </Link>
                      
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="top">
                      <strong>Wichita Falls </strong>
                      <p>
                        Wichita County
                        <br />
                      </p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <strong>Saturday Jan, 20th 10:00 AM – 11:00 AM </strong>
                      </p>
                      <p>807 Austin Street, Wichita Falls, TX 76301 </p>
                      <p>
                        Contact Travis Cutbirth at 940.557.5511 or visit{" "}
                        <strong>Eventbrite </strong>for more information.
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="top">
                      <strong>Online</strong>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <b>Monday, January 22nd, 2024, 12:00 – 1:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://us02web.zoom.us/meeting/register/tZcsceGopzItHNfNt-JCE6UwYSwqTGzXNYwS#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZcsceGopzItHNfNt-JCE6UwYSwqTGzXNYwS
                        </a>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <b>Monday, January 29th, 2024, 12:00 – 1:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://us02web.zoom.us/meeting/register/tZMvc-mvpzIjGtdZlsE1oOJ6dMW1EsRlkAwx#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZcuceGoqjIqE9VAt98UWuKi7gD8PdkCx9RU
                        </a>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table2 */}
          <Grid
            id="table2"
            style={{
              paddingTop: "30px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>February 2024</b>
            </Typography>
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "18%" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "82%" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      <strong>Abilene</strong>
                      <p>Taylor County</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <strong>
                          Thursday, February 1, 2024 5:30 pm - 6:30 pm
                        </strong>
                      </p>
                      <p>St. James United Methodist Church</p>
                      <p>3100 Barrow St. Abilene, TX 79605</p>
                      <p>
                        <strong>
                          Contact:&nbsp;
                          <a  href="">
                            BrieAnna Vine
                          </a>
                          &nbsp;at (325) 672-9398 - RSVP not required.
                        </strong>{" "}
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <b>Monday, February 5th, 2024, 12:00 – 1:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://us02web.zoom.us/meeting/register/tZMsduihqT0tEtXIKlnGhvl9VXgm5QbFWVK-#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZMsduihqT0tEtXIKlnGhvl9VXgm5QbFWVK-
                        </a>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <b>Monday, February 12th, 2024, 6:00 – 7:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://us02web.zoom.us/meeting/register/tZ0pfuuppzwiE9zUZRW_W4-T-yLXqIYaaq8l#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZ0pfuuppzwiE9zUZRW_W4-T-yLXqIYaaq8l
                        </a>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell >
                      <p>
                        <b>Monday, February 26th, 2024, 12:00 – 1:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://us02web.zoom.us/meeting/register/tZcsceGopzItHNfNt-JCE6UwYSwqTGzXNYwS#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZcsceGopzItHNfNt-JCE6UwYSwqTGzXNYwS
                        </a>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table3 */}
          <Grid
            id="table3"
            style={{
              paddingTop: "30px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>March 2024</b>
            </Typography>
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",

                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "18%" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "82%" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      <strong>Online</strong>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <strong>
                          Monday, March 4th, 2024, 12:00 – 1:00 PM
                        </strong>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://us02web.zoom.us/meeting/register/tZMsduihqT0tEtXIKlnGhvl9VXgm5QbFWVK-#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZMsduihqT0tEtXIKlnGhvl9VXgm5QbFWVK-
                        </a>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="top">
                      <p>
                        <b>Abilene</b>
                      </p>
                      <p>Taylor County</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <b>Thursday, March 7, 2024, 5:30 pm - 6:30 pm</b>
                      </p>
                      <p>
                        St. James United Methodist Church 3100 Barrow St.
                        Abilene, TX 79605
                      </p>
                      <p>
                        <strong>
                          Contact:&nbsp;
                          <a href="">BrieAnna Vine</a>
                          &nbsp;at (325) 672-9398 - RSVP not required.
                        </strong>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="top">
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <b>Monday, March 11th, 2024, 6:00 – 7:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <b>
                          <a
                            target="_blank"
                            href="https://us02web.zoom.us/meeting/register/tZ0pfuuppzwiE9zUZRW_W4-T-yLXqIYaaq8l#/registration"
                            style={{wordBreak: "break-word",}}
                          >
                            https://us02web.zoom.us/meeting/register/tZ0pfuuppzwiE9zUZRW_W4-T-yLXqIYaaq8l
                          </a>
                        </b>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="top">
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <b>Monday, March 18th, 2024, 8:00 – 9:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://us02web.zoom.us/meeting/register/tZAqceqoqT8iHd3kNxGVablurXWqQ36FI1-z#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZAqceqoqT8iHd3kNxGVablurXWqQ36FI1-z
                        </a>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="top">
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <b>Monday, March 25th, 2024, 12:00 – 1:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <b>
                          <a
                            target="_blank"
                            href="https://us02web.zoom.us/meeting/register/tZcsceGopzItHNfNt-JCE6UwYSwqTGzXNYwS#/registration"
                            style={{wordBreak: "break-word",}}
                          >
                            https://us02web.zoom.us/meeting/register/tZcsceGopzItHNfNt-JCE6UwYSwqTGzXNYwS
                          </a>
                        </b>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table4 */}
          <Grid
            id="table3"
            style={{
              paddingTop: "30px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>April 2024</b>
            </Typography>
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",

                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "18%" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "82%" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      <strong>Online</strong>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <strong>
                        Monday April 28th, 2024, 12:00 – 1:00 PM
                        </strong>
                      </p>
                      <p>
                      Register now for Foster Care 101- a foster/adopt meeting!
Register at this link:
                      </p>
                      {/* <p>Register at this link:</p> */}
                      <p>
                        <a
                          target="_blank"
                          href="https://us02web.zoom.us/meeting/register/tZwvdu6spj4rEtUjXEnuOT3sWFY4Cpp19K6Y#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZwvdu6spj4rEtUjXEnuOT3sWFY4Cpp19K6Y
                        </a>
                      </p>
                      <p>Contact Maggie Brennan 325-201-3974 or <a href="">askus@2INgage.org</a> for any questions</p>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell align="top">
                      <p>
                        <b>Abilene</b>
                      </p>
                      <p>Taylor County</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <b>Thursday, March 7, 2024, 5:30 pm - 6:30 pm</b>
                      </p>
                      <p>
                        St. James United Methodist Church 3100 Barrow St.
                        Abilene, TX 79605
                      </p>
                      <p>
                        <strong>
                          Contact:&nbsp;
                          <a href="">BrieAnna Vine</a>
                          &nbsp;at (325) 672-9398 - RSVP not required.
                        </strong>
                      </p>
                    </TableCell>
                  </TableRow> */}
                  {/* <TableRow>
                    <TableCell align="top">
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <b>Monday, March 11th, 2024, 6:00 – 7:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <b>
                          <a
                            target="_blank"
                            href="https://us02web.zoom.us/meeting/register/tZ0pfuuppzwiE9zUZRW_W4-T-yLXqIYaaq8l#/registration"
                            style={{wordBreak: "break-word",}}
                          >
                            https://us02web.zoom.us/meeting/register/tZ0pfuuppzwiE9zUZRW_W4-T-yLXqIYaaq8l
                          </a>
                        </b>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow> */}
                  {/* <TableRow>
                    <TableCell align="top">
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <b>Monday, March 18th, 2024, 8:00 – 9:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://us02web.zoom.us/meeting/register/tZAqceqoqT8iHd3kNxGVablurXWqQ36FI1-z#/registration"
                          style={{wordBreak: "break-word",}}
                        >
                          https://us02web.zoom.us/meeting/register/tZAqceqoqT8iHd3kNxGVablurXWqQ36FI1-z
                        </a>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow> */}
                  {/* <TableRow>
                    <TableCell align="top">
                      <p>
                        <b>Online</b>
                      </p>
                      <p>Region 2</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <b>Monday, March 25th, 2024, 12:00 – 1:00 PM</b>
                      </p>
                      <p>
                        Register now for Foster Care 101- a foster/adopt
                        meeting!
                      </p>
                      <p>Register at this link:</p>
                      <p>
                        <b>
                          <a
                            target="_blank"
                            href="https://us02web.zoom.us/meeting/register/tZcsceGopzItHNfNt-JCE6UwYSwqTGzXNYwS#/registration"
                            style={{wordBreak: "break-word",}}
                          >
                            https://us02web.zoom.us/meeting/register/tZcsceGopzItHNfNt-JCE6UwYSwqTGzXNYwS
                          </a>
                        </b>
                      </p>
                      <p>
                        <b>
                          Contact Maggie Brennan 325-201-3974 or
                          askus@2INgage.org for any questions.
                        </b>
                      </p>
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        </Grid>
      </Grid>
    </div>
  );
};

export default Region2;
