import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Link as RouterLink, useLocation } from "react-router-dom";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import FlagIcon from "@mui/icons-material/Flag";
import "./appbar.css";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

const HamMenuItem = styled(MenuItem)({
  height: "50px",
  "&:hover": {
    backgroundColor: "#1A355D",
  },
});

// const HamMenuIcon = styled("span")({
//   paddingRight: "10px",
// });

const Togglemenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();

  return (
    <>
      <Tooltip title="Menu" aria-label="menu" arrow>
        <IconButton color="inherit" onClick={handleMenuClick}>
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="menuitem"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          "& .MuiPaper-root": {
            color: "white",
            background: "linear-gradient(132deg, #22477E 30%, #3567B2 90%)",
            width: "230px",
          },
        }}
      >
        <HamMenuItem>
          <RouterLink to="/" className="routerlink">
            <HomeIcon fontSize="small" />
            Home
          </RouterLink>
        </HamMenuItem>
        <Divider />

        <HamMenuItem>
          <RouterLink to="/about" className="routerlink">
            <InfoIcon fontSize="small" />
            About Us
          </RouterLink>
        </HamMenuItem>
        <Divider />
        {location.pathname === "/" ? (
          ""
        ) : (
          <Grid>
            <HamMenuItem>
              <RouterLink to="/fostercareoverview" className="routerlink">
                <VolunteerActivismIcon fontSize="small" />
                Foster Care Overview
              </RouterLink>
            </HamMenuItem>
            <Divider />

            <HamMenuItem>
              <RouterLink to="/adoptionoverview" className="routerlink">
                <FamilyRestroomIcon fontSize="small" />
                Adoption Overview
              </RouterLink>
            </HamMenuItem>
            <Divider />

            <HamMenuItem>
              <RouterLink to="/agencylist" className="routerlink">
                <ManageSearchIcon fontSize="small" />
                Agency Directory
              </RouterLink>
            </HamMenuItem>
            <Divider />

            <HamMenuItem>
              <RouterLink to="/adoptionincentivesview" className="routerlink">
                <PriceCheckIcon fontSize="small" />
                Adoption Incentives
              </RouterLink>
            </HamMenuItem>
            <Divider />
          </Grid>
        )}
        <HamMenuItem>
          <RouterLink to="/generalinformation" className="routerlink">
            <FlagIcon fontSize="small" />
            General Information
          </RouterLink>
        </HamMenuItem>
        <Divider />
        <HamMenuItem>
          <RouterLink to="/requirements" className="routerlink">
            <ChecklistRtlIcon fontSize="small" />
            Requirements
          </RouterLink>
        </HamMenuItem>
        <Divider />
        <HamMenuItem>
          <RouterLink to="/stepsview" className="routerlink">
            <AccountTreeIcon fontSize="small" />
            Steps
          </RouterLink>
        </HamMenuItem>
        <Divider />
        {location.pathname === "/" ? (
          ""
        ) : (
          <Grid>
            <HamMenuItem>
              <RouterLink to="/helpfulresources" className="routerlink">
                <Diversity3Icon fontSize="small" />
                Helpful Resources
              </RouterLink>
            </HamMenuItem>
            <Divider />
          </Grid>
        )}
        <HamMenuItem>
          <RouterLink
            to="https://www.dfps.texas.gov/Child_Protection/Foster_Care/Litigation.asp"
            target="_blank"
            className="routerlink"
          >
            <NewspaperIcon fontSize="small" />
            News
          </RouterLink>
        </HamMenuItem>
        <Divider />
        <HamMenuItem>
          <RouterLink to="/contact" className="routerlink">
            <ContactPhoneIcon fontSize="small" />
            Contact Us
          </RouterLink>
        </HamMenuItem>
        {/* Additional menu items */}
        {/* ... */}
      </Menu>
    </>
  );
};

export default Togglemenu;
