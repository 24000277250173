import {Link} from 'react-router-dom';

const FostercareOverviewContent = [
    {
        key: '1',
        headings: 'About Foster Care',
        desc: [
            {
                key: 'desc1',
                value: <Link to="/stepsview" style={{color:'blue'}}>Steps to Become a Foster/Adoptive Parent</Link>
            },
            {
                key: 'desc2',
                value: <Link to="/requirements" style={{color:'blue'}}>Requirements for Foster/Adopt Families</Link>
            },
            {
                key: 'desc3',
                value: <Link target="_blank" to="https://www.dfps.texas.gov/Doing_Business/Purchased_Client_Services/Residential_Child_Care_Contracts/Rates/default.asp" style={{color:'blue'}}>Foster Care Reimbursements</Link>
            },
            {
                key: 'desc4',
                value: <Link to="/spanish1" style={{color:'blue'}}>Otros Documentos En Español</Link>
            },
            {
                key: 'desc5',
                value: <Link to="/spanish2" style={{color:'blue'}}>El Cuidado Temporal y la Adopcion</Link>
            }
        ]
    },
    {
        key: '2',
        headings: 'Foster Care and Adoption Information',
        desc: [
            {
                key: 'desc1',
                value: <Link to="/placement#informationmeeting" style={{color:'blue'}}>Foster/Adopt Information Meetings in Your Area</Link>
            },
            {
                key: 'desc2',
                value: <Link to="/homestudy" style={{color:'blue'}}>Foster Care and Adoptive Home Study</Link>
            },
            {
                key: 'desc3',
                value: <Link to="/overview" style={{color:'blue'}}>Foster Care and Adoption Program - Overview</Link>
            },
            {
                key: 'desc4',
                value: <Link to="/placement#facts" style={{color:'blue'}}>Foster Care and Adoption Facts</Link>
            },
            {
                key: 'desc5',
                value: <Link to="/placement#medicalserviceupdate "style={{color:'blue'}}>Medical Services Update</Link>
            },
            {
                key: 'desc6',
                value: <Link target="_blank" to="https://www.dfps.texas.gov/Child_Protection/Youth_and_Young_Adults/Education/ETV.asp" style={{color:'blue'}}>Higher Education Assistance for Current or Former Foster Youth</Link>
            },
            {
                key: 'desc7',
                value: <Link target="_blank" to="https://www.dfps.texas.gov/Community/Volunteer/Opportunities/mentor.asp" style={{color:'blue'}}>Mentoring Foster Youth</Link>
            },
            {
                key: 'desc8',
                value: <Link to="/partners" style={{color:'blue'}}>Private Adoption and Foster Care Agency Partners</Link>
            },
            {
                key: 'desc9',
                value: <Link target="_blank" to="https://www.dfps.texas.gov/Adoption_and_Foster_Care/About_TARE/Foster_Care/2023-Foster-Families-Letter.pdf" style={{color:'blue'}}>Foster Parent Appreciation Letter</Link>
            }
        ]
    }
]

export default FostercareOverviewContent;