import { Grid, Typography } from "@mui/material";
import React from "react";
import ImageSlider from "../../layout/imageslider";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
// import './positiveview.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(NO, NAME1, NAME2) {
  return { NO, NAME1, NAME2 };
}

const rows = [
  createData(1, "Birth parent", "Real parent"),
  createData(2, "Biological parent", "Natural parent"),
  createData(3, "Birth child", "Own child"),
  createData(4, "My child", "Adopted child"),
  createData(5, "Born to unmarried parents", "Illegitimate"),
  createData(6, "Terminate parental rights", "Give up"),
  createData(7, "Make an adoption plan", "Give away"),
  createData(8, "To parent", "To keep0"),
  createData(9, "Waiting child", "Adoptable child"),
  createData(10, "Making contact with", "Reunion"),
  createData(11, "Parent", "Adoptive parent"),
  createData(12, "Search", "Track down parents"),
  createData(13, "Child placed for adoption", "Unwanted cild"),
  createData(14, "Court termination", "Child taken away"),
  createData(15, "Child with special needs", "Handicapped child"),
  createData(16, "Was adopted", "Is adopted"),
  createData(
    17,
    "Two years behind in development",
    "Retarded(or other descriptive language)"
  ),
  createData(
    18,
    "Spinabifida cleft lip,or other specific condition",
    "Deformed"
  ),
  createData(19, "Has disavility or is physically challenged", "Handicapped"),
  createData(20, "Describe specifics--intellengce", "Normal or grade level"),
  createData(21, "Divorced", "Broken marriage"),
  createData(22, "Seperated from parents or rejected", "Desrted or abandoned"),
  createData(23, "Is taking Ritalin", "Hyperactive"),
  createData(24, "Neurological impairment", "Brain damaged"),
];

const Positive = () => {
  return (
    <div align="center">
      {/* <ImageSlider /> */}
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          style={{
            width: "80%",
            paddingTop: "30px",
            paddingBottom: "30px",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          <Grid
            style={{
              //   display: "flex",
              justifyContent: "left",
              alignItems: "center",
              //   width: "85%",
            }}
          >
            <Grid style={{width:"100%",textAlign:"left",paddingLeft:'10px'}}>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "" }}
              >
                Positive Adoption Language
              </Typography>
            </Grid>
            <br />
            <Grid align="center" style={{ padding:'10px' }}>
              <Grid style={{ display: "flex" }}>
                <DoubleArrowIcon />
                <Typography style={{ textAlign: "justify" }}>
                  The way we talk - and the words we choose - say a lot about
                  what we think and value. When we use positive adoption
                  language, we say that adoption is a way to build a family in
                  the same way we say that birth is a way to build a family.
                </Typography>
              </Grid>
              <br />
              <Grid style={{ display: "flex" }}>
                <DoubleArrowIcon />
                <Typography style={{ textAlign: "justify" }}>
                  Positive adoption language can stop the spread of
                  misconceptions. By using positive adoption language, we
                  educate others about adoption. We choose emotionally "correct"
                  words over emotion-laden words. We speak and write in positive
                  adoption language with the hopes of impacting others so that
                  this language will someday become the norm.
                </Typography>
              </Grid>
              <br />
              <Grid style={{ display: "flex" }}>
                <DoubleArrowIcon />
                <Typography style={{ textAlign: "justify" }}>
                  Choose the following, positive adoption language instead of
                  negative terms that help perpetuate the myth that adoption is
                  second best. By using positive adoption language, you'll
                  reflect the true nature of adoption, free of innuendo.
                </Typography>
              </Grid>
              <br />
            </Grid>
            <Grid container xl={8} lg={8} xs={11} sm={11} md={8}  >
              <TableContainer
                component={Paper}
                className="responsiveTableContainer"
              >
                <Table aria-label="customized table">
                  <TableHead style={{ backgroundColor: "lightblue" }}>
                    <TableRow>
                      <StyledTableCell style={{ border: "solid black" }}>
                        <b>NO</b>
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ border: "solid black" }}
                      >
                        <b>POSITIVE LANGUAGE</b>
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ border: "solid black" }}
                      >
                        <b>NEGATIVE LANGUAGE</b>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.NO}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ border: "solid black" }}
                        >
                          {row.NO}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{ border: "solid black" }}
                        >
                          {row.NAME1}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{ border: "solid black" }}
                        >
                          {row.NAME2}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Positive;
