import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Zoom, Hidden } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Carousel from "react-material-ui-carousel";
import slider1 from "../../assets/sliders/FotoJet1.jpg";
import slider2 from "../../assets/sliders/FotoJet2.jpg";
import slider3 from "../../assets/sliders/FotoJet3.jpg";
import slider4 from "../../assets/sliders/FotoJet4.jpg";
import slider5 from "../../assets/sliders/FotoJet5.jpg";
// import "./imagesliders.css";

const useStyles = makeStyles((theme) => ({
  carousel: {
    margin: "0 0 10px 0",
   },
  imgStlyes: {
    // backgroundSize: "contain",
    // backgroundRepeat: "no-repeat",
    boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
  },
  imgSubStyles: {
    height: "100%",
    width: "100%",
  },
}));

const ImageSlider1 = () => {
  const classes = useStyles();

  let slides = [
    <img src={slider1} className={classes.imgSubStyles} alt="1" />,
    <img src={slider2} className={classes.imgSubStyles} alt="2" />,
    <img src={slider3} className={classes.imgSubStyles} alt="3" />,
    <img src={slider4} className={classes.imgSubStyles} alt="4" />,
    <img src={slider5} className={classes.imgSubStyles} alt="5" />,
  ];

  return ( 
    <Hidden>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.carousel}
        style={{}}
      >
        <Carousel  sx={{width:"90%", height:"auto"}} interval={5000} duration={1200} animation="slide">
          {slides.map((item, i) => (
            <Zoom in={true} timeout={{ enter: 2500, exit: 500 }} key={i}>
              <Grid item key={i} id="imgStyles"  className={classes.imgStlyes}>
                {item || (
                  <Skeleton
                    variant="rectangular"
                    width={210}
                    height={450}
                    animation="wave"
                  />
                )}
              </Grid>
            </Zoom>
          ))}
        </Carousel>
      </Grid>
    </Hidden>
  );
};

export default ImageSlider1;
