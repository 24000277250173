import React from "react";
import ImageSlider from "../../layout/imageslider";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

function Accountability() {
  return (
    <div align="center">
      <Grid>{/* <ImageSlider /> */}</Grid>
      <Grid>
        <Grid
          className="maingrid"
          style={{
            backgroundColor: "white",
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Grid className="childgrid" style={{ width: "90%" }}>
            <br />
            <Typography variant="h5" fontWeight="bold" textAlign="center">
              COST AND ACCOUNTABILITY REPORT AND INFORMATION
            </Typography>
            <br />
            <Typography variant="h6" fontWeight="bold">
              OVERVIEW :-
            </Typography>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon />
              The purpose of a Medicaid Cost/Accountability Report is to gather
              financial and statistical information for HHSC to use in
              developing reimbursement rates. Some cost reports are also used in
              the determination of accountability under the Attendant
              Compensation Rate Enhancement program. The Cost and Accountability
              Reports for the following programs will be submitted thorugh the
              State of Texas Automated Informtaion Reporting System(STAIRS).
            </Typography>
            <ul style={{ lineHeight: "1.5", textAlign: "justify" }}>
              <li>24-Hour Residential Child Care (24RCC)</li>
              <li>
                Community Living Assistance and Support Services - Case
                Management Agency (CLASS - CMA)
              </li>
              <li>
                Community Living Assistance and Support Services - Direct
                Service Agency (CLASS-DSA)
              </li>
              <li>Day Activity and Health Services(DAHS)</li>
              <li>Deaf Blind Multiple Disabilities Waiver(DBMD)</li>
              <li>Early Chilhood Intervation(ECI)-Case Management</li>
              <li>
                Early Childhood Intervention(ECI)-Specialized Rehabilitative
                Services
              </li>
              <li>
                Home and Community based Services/Texas Home Living(HCS/TxHmL)
              </li>
              <li>
                Intermediate are Facilities for Individuals with an Intellectual
                Disaility or Related Conditions(ICF/IID)
              </li>
              <li>Nursing Facility(NF)</li>
              <li>Primary Home Care(PHC)</li>
              <li>Rehabilitation Services - Mental Health</li>
              <li>Residential Care</li>
              <li>
                Service Coordination - Individuals with an Intellectual
                Disablity or Related Condition
              </li>
              <li>Targeted Case Management - Mental Health</li>
              <li>
                STAR+PLUS services affiliated with the above service types
              </li>
            </ul>
            <Typography variant="h5" fontWeight="bold">
              Report Release, Request, and Due Date
            </Typography>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon /> The release date each year for STAIRS cost
              reports for all programs is February 1st with a due date of April
              30th or as indicated on the request letter.
            </Typography>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon /> The STAIRS Cost and Accountability Report
              request will go out on February 1st, and will be distributed via
              two methods:
            </Typography>
            <ul style={{ lineHeight: "1.5", textAlign: "justify" }}>
              <li>
                The request letter will be posted to the HHSC website in the
                Repost Request Letters section below. In addition, an email will
                be sent to all providers through the HHSC GovDelivery alerts.
              </li>
              <li>
                An email will also be sent out from STAIRS directly to all
                Entity and Financial Contacts that have been identified for each
                provider in STAIRS.
              </li>
            </ul>
            <Typography variant="h5" fontWeight="bold">
              STAIRS - State of Texas Automated Information Reporting System
            </Typography>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon /> STAIRS is an online application through which
              providers will prepare and submit their Cost and Accountability
              reports.
            </Typography>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon /> Providers who submitted a previous Cost Report
              via STAIRS will use the same logon information to access the
              current year's Cost and Accountability Report. An email will be
              sent to these providers from the STAIRS system that will include
              reminder information about accessing the system.
            </Typography>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon /> New providers without prior access to STAIRS
              will be sent an email from the STAIRS system by February 1st,
              containing STAIRS login information including their username and
              password.
            </Typography>
            <br />
            <Grid style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon />
              <Typography>
                This email will be sent to the Primary Entity Contact designated
                by HHSC Provider Finance Department(PFD) based on enrollment
                information previously submitted to HHSC or DFPS. Questions
                regarding STAIRS access for new providers should be sent to{" "}
                <Link to="">CostInformationPFD@hhs.texas.gov</Link>.
              </Typography>
            </Grid>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon />
              It is important to understand that each entity controls who is
              given access to their cost report. Access to STAIRS is granted
              through the assignment of roles that consist of:
            </Typography>
            <br />
            <ul style={{ lineHeight: "1.5" }}>
              <li>Entity Contact(primary or secondary)</li>
              <li>Financial Contact(primary or secondary)</li>
              <li>Preparer</li>
            </ul>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon />
              The Primary Entity Contact must assign a "Primary Financial
              Contact". Then, either of these Primary Contacts must assign at
              least one "Preparer" who will be responsible for completing the
              entity's cost report. Only the Primary Entity Contact and the
              Primary Financial Contact may grant acces to their cost report in
              STAIRS. Persons who feel they need access to the entity's cost
              report should consult with the Primary Contacts for that Entity.
            </Typography>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon /> When a Contact or Preparer is added, STAIRS
              will automatically send an email to that person providing them
              with access information including a username and password.
              Reference materials are available once you log onto STAIRS at the
              bottom of the main screen. Please take time to review the
              reference material document titled Helpful Information for Contact
              and Preparers. This document includes information on
              managing(adding, deleting, modifying) contact and roles as well as
              descriptions regarding the functionality of these roles.
            </Typography>
            <br />
            <Typography variant="h5" fontWeight="bold">
              Mandatory Training Requirements for Preparers in STAIRS
            </Typography>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon /> Per Title 1 of the Texas Administrative Code
              (1 TAC), Part 15, Chapter 355, Subchapter A, Rule 355.102(d) HHSC
              requires that all cost report preparers attend a mandatory
              training prior to completing a cost or accountability report. HHSC
              provides a list of preparers who have met the mandatory cost
              report training requirements. The entity must select a preparer
              from this list through the STAIRS application. If tha entity's
              desired Preparer is not on the trained preparers list this means
              the desired preparer has not met the training requirements and
              must do so before they can be added to the list an selected to
              complete a report (see Cost Report Training below for additional
              information.)
            </Typography>
            <br />
            <Grid>
              {" "}
              <Typography>
                For more information on the Cost and Accountability Report,
                please visit{" "}
                <Link
                  to="https://pfd.hhs.texas.gov/cost-and-accountability-report-information"
                  target="_blank"
                  style={{ color: "blue" }}
                >
                  Cost and Accountability Report Information | Provider Finance
                  Department (texas.gov)
                </Link>
              </Typography>
              <br />
              <Typography>
                For knowing the non-profit tax services, please visit
                <Link to="https://reneaucpa.com" target="_blank">
                  <Button
                    variant="contained"
                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                  >
                    Reneau cpa
                  </Button>
                </Link>
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Accountability;
