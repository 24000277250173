import { Grid, Typography, Link } from "@mui/material";
import React from "react";
import regionContent from "./regioncontent";
import ImageSlider from "../../../layout/imageslider";
import MapCard from "../mappages/mapcard";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./region1.css";

const Region1 = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Grid style={{width:'100%'}}>
            <Typography variant="h5" style={{textAlign:"center",color:"navy"}}>
              
              <b>Region 1, Lubbock and surrounding area</b>
            </Typography>
            <br />
            <Typography variant="h6">
              Foster Care and Adoption Information Meetings
            </Typography>
            <br />
            <Typography style={{ color: "red", textAlign: "justify" }}>
              * If accomodations such as a sign language interpreter are needed
              in order to attend an information meeting, please call the
              Recruitment Staff listed below 3 days prior to the meeting, if
              possible. *
            </Typography>
            <br />
            {/* <Typography><b>Recruitment Events and Foster/Adopt Information Meetings:</b></Typography><br /> */}
            <Grid container style={{ display: "flex" }}>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>
                    Recruitment Events and Foster/Adopt Information Meetings:
                  </b>
                </Typography>
                <br />
                <ul style={{ lineHeight: "1.6" }}>
                  {/* <li>
                    <a href="#table1"> October 2023</a>
                  </li>
                  <li>
                    <a href="#table2">November 2023</a>
                  </li> */}
                  <li>
                    <a
                      target="_blank"
                      href="https://partnershipsforchildren.org/heartgallery-home/"
                    >
                      Heart Gallery of the High Plains
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp"
                    >
                      Heart Gallery of the South Plains
                    </a>
                  </li>
                  {/* <li>
                    {" "}
                    <a href="#recruitment"> Recruitment Staff</a>
                  </li> */}
                  <li>
                    <a
                      target="_blank"
                      href="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp"
                    >
                      Faith-Based Staff
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid
                item
                lg={5}
                md={6}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "justify",
                }}
              >
                <MapCard />
              </Grid>
            </Grid>
            <br />
            <Grid style={{ display: "flex" }}>
              <KeyboardDoubleArrowRightIcon />
              <Typography
                style={{ textAlign: "justify", wordBreak: "break-word" }}
              >
                <a
                  target="_blank"
                  href="https://saintfrancisministries.org/texas_provider_forms/"
                  style={{ wordBreak: "break-word" }}
                >
                  Saint Francis Ministries
                </a>{" "}
                is responsible for Foster Care and Adoption Information Meetings
                for all of Region 1 Counties. Please find Information Meeting
                details below.
              </Typography>
            </Grid>
            <br />
            <Grid style={{ display: "flex" }}>
              <KeyboardDoubleArrowRightIcon />
              <Typography
                style={{ textAlign: "justify", wordBreak: "break-word" }}
              >
                Saint Francis Ministries is now in partnership with DFPS as part
                of Community-Based Care. Community-Based Care is a new way of
                providing foster care and case management services. Its a
                community-based approach to meeting the individual and unique
                needs of children, youth, and families.
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/CBC/default.asp"
                  style={{ wordBreak: "break-word" }}
                >https://www.dfps.texas.gov/CBC/default.asp
                  {/* https://www.dfps.texas.gov/Child_Protection/Foster_Care/Community-Based_Care/ */}
                </a>
              </Typography>
            </Grid>
            <br />
            <Grid style={{ display: "flex" }}>
              <KeyboardDoubleArrowRightIcon />
              <Typography>
                For information on fostering or adopting a child from out of
                state, contact the{" "}
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/child_protection/State_Care/icpc.asp"
                  style={{ wordBreak: "break-word" }}
                >
                  State Office ICPC
                </a>
              </Typography>
            </Grid>
            <br />
            {/* Table1 grid */}
            {/* <Grid
              id="table1"
              align="center"
              style={{ paddingTop: "10px", width: "100%" }}
            >
              <Typography variant="h5">
                <b>October 2023</b>
              </Typography>
              <TableContainer
                id="tablecontainer1"
                component={Paper}
                style={{
                  border: "1px solid black",
                  // width: "80%",
                  margin: "auto",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <TableHead style={{ backgroundColor: "lightgray" }}>
                    <TableRow>
                      <TableCell align="left" style={{ width: "18%" }}>
                        Location
                      </TableCell>
                      <TableCell align="left" style={{ width: "82%" }}>
                        Foster/Adopt Information Meetings
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="top">
                        <strong>Lubbock</strong>
                        <p>Lubbock and Surrounding Counties</p>
                      </TableCell>
                      <TableCell align="top">
                        <p>
                          <strong>
                            Thursday, October 12th, 12:00 pm - 1:00 pm
                          </strong>
                        </p>
                        <p>Virtual Meeting - Online registration needed:</p>
                        <p>
                          <a
                            target="_blank"
                            href="https://www.eventbrite.com/e/643248231777"
                            style={{ wordBreak: "break-word" }}
                          >
                            https://www.eventbrite.com/e/643248231777
                          </a>
                        </p>
                        <p>
                          For more information, please contact:{" "}
                          <a href="#" style={{ wordBreak: "break-word" }}>
                            Tereasa Mansfield
                          </a>
                          , Saint Francis Texas, at 
                          <strong>(806) 549-0532</strong>
                        </p>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="top">
                        <strong>Amarillo</strong>
                        <p>
                          Potter, Randall, and
                          <br />
                          Surrounding Counties
                        </p>
                      </TableCell>
                      <TableCell align="top">
                        <p>
                          <strong>
                            Thursday, October 19th, 12:00 pm - 1:00 pm
                          </strong>
                        </p>
                        <p>Virtual Meeting – Online registration needed:</p>
                        <p>
                          <a
                            target="_blank"
                            href="https://www.eventbrite.com/e/643267379047"
                            style={{ wordBreak: "break-word" }}
                          >
                            https://www.eventbrite.com/e/643267379047
                          </a>
                        </p>
                        <p>
                          For more information, please contact:{" "}
                          <a href="" style={{ wordBreak: "break-word" }}>
                            Tereasa Mansfield
                          </a>
                          , Saint Francis Texas, at 
                          <strong>(806) 549-0532</strong>
                        </p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid> */}
            {/* Table2 */}
            {/* <Grid
              align="center"
              id="table2"
              style={{ paddingTop: "30px", width: "100%" }}
            >
              <Typography variant="h5">
                <b>November2023</b>
              </Typography>
              <TableContainer
                id="tablecontainer2"
                component={Paper}
                style={{
                  border: "1px solid black",
                  // width: "80%",
                  margin: "auto",
                }}
              >
                <Table>
                  <TableHead style={{ backgroundColor: "lightgray" }}>
                    <TableRow>
                      <TableCell align="left" style={{ width: "18%" }}>
                        Location
                      </TableCell>
                      <TableCell align="left" style={{ width: "82%" }}>
                        Foster/Adopt Information Meetings
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="top">
                        <strong>Lubbock</strong>
                        <p>Lubbock and Surrounding Counties</p>
                      </TableCell>
                      <TableCell align="top">
                        <p>
                          <strong>
                            Monday November 13th, 6:30 pm – 7:30 pm
                          </strong>
                        </p>
                        <p>Virtual Meeting - Online registration needed:</p>
                        <p>
                          <a
                            target="_blank"
                            href="https://www.eventbrite.com/e/643242966027"
                            style={{ wordBreak: "break-word" }}
                          >
                            https://www.eventbrite.com/e/643242966027
                          </a>
                        </p>
                        <p>
                          For more information, please contact:{" "}
                          <a
                            href=""
                            // target="_blank"
                          >
                            Tereasa Mansfield
                          </a>
                          , Saint Francis Texas, at 
                          <strong>(806) 549-0532</strong>
                        </p>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="top">
                        <strong>Amarillo</strong>
                        <p>
                          Potter, Randall, and
                          <br />
                          Surrounding Counties
                        </p>
                      </TableCell>
                      <TableCell align="top">
                        <p>
                          <strong>
                            Monday November 20th, 6:30 pm – 7:30 pm
                          </strong>
                        </p>
                        <p>Virtual Meeting – Online registration needed:</p>
                        <p>
                          <a href="" style={{ wordBreak: "break-word" }}>
                            https://www.eventbrite.com/e/643261110297
                          </a>
                        </p>
                        <p>
                          For more information, please contact:{" "}
                          <a
                            href=""
                            // target="_blank"
                          >
                            Tereasa Mansfield
                          </a>
                          , Saint Francis Texas, at 
                          <strong>(806) 549-0532</strong>
                        </p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid> */}
            <Grid
              id="recruitment"
              style={{
                paddingTop: "20px",
                display: "flex",
                flexDirection: "column",
                textAlign: "justify",
                // width: "80%",
              }}
            >
              <Typography variant="h6" style={{ textAlign: "justify" }}>
                <b>Recruitment Staff</b>
              </Typography>
              <br />
              <Typography variant="body">
                Answers questions for the community on how to become foster and
                adoptive families.
              </Typography><br />
              <Typography style={{color: 'blue'}}>Note:{" "} For more information, please contact: <b>(806) 549-0532</b> </Typography>
              {/* <ul style={{lineHeight: '1.6'}}>
                <li>
                  Tereasa Mansfield, Saint Francis Ministries: (806) 549-0532
                </li>
                <li>
                For more information, please contact:  (806) 549-0532
                </li>
              </ul> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Region1;
