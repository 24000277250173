import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button, Grid, createTheme, textFieldClasses } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { agenciesFetching } from "../../store/actions/fetch_counties_agencies";
import { countiesFetching } from "../../store/actions/fetch_counties_agencies";
import CircularProgress from "@mui/material/CircularProgress";
import "react-notifications/lib/notifications.css";
import {
  // NotificationContainer,
  NotificationManager,
} from "react-notifications";
export default function Countylist() {
  const [county, setCounty] = React.useState(null);
  const dispatch = useDispatch();

  const countiesReceived = useSelector((state) => state.countyList.data.result);

  React.useEffect(() => {
    if (!countiesReceived) {
      dispatch(countiesFetching());
    }
  }, [countiesReceived, dispatch]);

  const handleChange = (event, newValue) => {
    setCounty(newValue);
  };

 
  const navigate = useNavigate();
  const handleOnSubmit0 = async (event,) => {
    event.preventDefault();
    dispatch({ type: "countySelected", selectedCounty: county });
    setCounty("");
    if (county) {
      dispatch(agenciesFetching());
    }
  };

  const handleOnSubmit1 = () =>{
    NotificationManager.error("Please Select the County");
  }
  return (
    <div style={{ width: "80%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "10px",
          // marginBottom: "20px",
        }}
      >
        <Autocomplete
          onChange={handleChange}
          disabled={!countiesReceived}
          options={countiesReceived}
          style={{ width: "100%" }}
         
          getOptionLabel={(option) => (option ? option : "")}
          value={county}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField  required {...params} label="Select County" />
          )}
        />
        <Button variant="contained" onClick={county ? handleOnSubmit0 : handleOnSubmit1}>
          Search
        </Button>
        
      </div>
    </div>
  );
}
