import React, { useState, useEffect, useRef } from "react";
import texasMap from "../fostercareOverview/images/texasmap.png";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import "./texasmap.css";
// import region11 from "../adoptionOverview/Placements/11regions/region11";
// import Region10  from "../adoptionOverview/Placements/11regions/region10";
// import Region3 from "../adoptionOverview/Placements/11regions/region3";

function ResponsiveMap() {
  const [scaleFactor, setScaleFactor] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [hoveredArea, setHoveredArea] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const imageRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    function updateScale() {
      if (imageRef.current && imageLoaded) {
        const { width } = imageRef.current.getBoundingClientRect();
        const originalWidth = imageRef.current.naturalWidth;
        const originalHeight = imageRef.current.naturalHeight;
        const scaleFactorWidth = width / originalWidth;
        const scaleFactorHeight = width / originalHeight;
        setScaleFactor(Math.min(scaleFactorWidth, scaleFactorHeight));
      }
    }
    if (imageLoaded) {
      window.addEventListener("resize", updateScale);
      updateScale();
    }
    return () => window.removeEventListener("resize", updateScale);
  }, [imageLoaded]);
  const scaleCoords = (coords) =>
    coords
      .split(",")
      .map((coord, index) => Math.round(coord * scaleFactor).toString())
      .join(",");
  const handleSelectChange = (event) => {
    const link = event.target.alt; // Using the 'alt' attribute to determine the link
    window.open(`https://${link}`, "_blank"); // Ensure links open in a new tab
  };

  const handleClick = (event) => {
    const link = event.target.alt; // Assuming this is a path like '/region11'
    const fullUrl = `${window.location.origin}${link}`;
    // console.log(fullUrl); // Log the full URL for debugging
    if (
      link === "/region4,5,6" ||
      "/region7" ||
      "/region8" ||
      "/region9" ||
      "region10" ||
      "region11"
    ) {
      navigate(link); // Open in a new tab
    } else{
      window.open(fullUrl, "_blank");
    }
  };

  const handleMouseEnter = (title) => {
    setHoveredArea(title);
  };

  const handleMouseLeave = () => {
    setHoveredArea(null);
  };

  // const handleMouseMove = (event) => {
  //   const mapBounds = imageRef.current.getBoundingClientRect();
  //   const x = event.clientX - mapBounds.left;
  //   const y = event.clientY - mapBounds.top;
  //   setTooltipPosition({ left: x + 10, top: y + 10 });
  // };

  return (
    <div style={{ position: "relative", width: "auto" }}>
      <img
        src={texasMap}
        alt="Map of Texas"
        useMap="#image-map"
        ref={imageRef}
        style={{ maxWidth: "80%", height: "auto" }}
        onLoad={() => setImageLoaded(true)}
      />

      {imageLoaded && (
        <map name="image-map">
          {/* Define areas here using the scaleCoords function to adjust the coords */}

          <area
            onClick={handleSelectChange}
            alt="www.dfps.texas.gov/CBC/community-areas/region-1.asp"
            title="region 1 Youth available for foster care - 70200"
            coords={scaleCoords(
              "266,306,278,6,482,8,486,195,450,192,450,233,482,235,486,272,413,272,408,311"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 1")}
            onMouseLeave={handleMouseLeave}
            // onMouseMove={handleMouseMove}
          />

          <area
            onClick={handleSelectChange}
            alt="www.dfps.texas.gov/CBC/community-areas/region-2.asp"
            title="region 2"
            coords={scaleCoords(
              "402,313,411,306,416,274,486,272,487,235,453,229,455,193,487,194,490,170,495,169,499,177,503,185,513,184,519,181,529,185,533,181,543,187,546,199,562,199,571,207,583,202,595,213,605,209,618,205,624,221,635,229,650,219,656,218,665,222,664,261,635,265,629,306,603,301,589,306,589,338,595,345,601,361,590,366,603,391,615,389,619,401,599,417,590,412,575,414,578,419,560,431,545,434,536,441,535,428,520,437,500,421,465,426,464,383,400,382,402,322"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 2")}
            onMouseLeave={handleMouseLeave}
          />

          <area
            onClick={handleSelectChange}
            alt="www.dfps.texas.gov/CBC/community-areas/region-3w.asp"
            title="region3a"
            coords={scaleCoords(
              "587,313,602,307,630,307,695,304,696,341,694,364,662,374,646,361,648,380,618,392,601,392,592,372,596,352,598,347,589,340"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 3a")}
            onMouseLeave={handleMouseLeave}
          />

          <area
            onClick={handleSelectChange}
            alt="www.dfps.texas.gov/CBC/community-areas/region-3e.asp"
            title="region3b"
            coords={scaleCoords(
              "698,229,700,264,709,270,714,305,698,306,696,340,694,364,706,381,719,396,735,400,768,384,763,372,750,364,738,353,765,352,762,312,769,314,778,306,775,231,756,228,745,242,731,237,725,226,714,230"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 3b")}
            onMouseLeave={handleMouseLeave}
          />

          <area
            // target="_blank"
            onClick={handleClick}
            alt="/region3"
            title="region3"
            coords={scaleCoords(
              "632,267,634,305,708,305,709,265,701,266,701,221,698,230,691,230,686,240,680,233,679,225,675,229,669,235,662,228,662,265"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 3")}
            onMouseLeave={handleMouseLeave}
          />
          <area
            onClick={handleSelectChange}
            alt="www.dfps.texas.gov/CBC/community-areas/region-4.asp"
            title="region4"
            coords={scaleCoords(
              "744,354,766,352,763,316,780,298,778,228,798,218,808,222,823,216,839,219,856,238,882,242,896,241,907,243,913,376,881,379,884,390,850,393,851,404,860,415,850,426,828,417,796,421,794,414,772,403"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 4")}
            onMouseLeave={handleMouseLeave}
          />
          <area
            onClick={handleSelectChange}
            alt="www.dfps.texas.gov/CBC/community-areas/region-5.asp"
            title="region5"
            coords={scaleCoords(
              "794,424,833,416,854,431,862,412,851,392,882,388,883,378,912,377,926,389,931,407,934,414,946,427,956,450,952,469,947,486,943,500,944,507,948,515,947,526,948,536,943,544,940,555,933,566,944,568,928,570,904,579,897,561,896,541,882,540,868,511,838,526,824,510,825,480,818,466,803,477,803,464,789,460,796,445"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 5")}
            onMouseLeave={handleMouseLeave}
          />
          <area
            // target="_blank"
            onClick={handleClick}
            alt="/region4,5,6"
            title="region6"
            coords={scaleCoords(
              "716,589,719,595,726,596,740,612,735,622,760,644,763,667,769,662,770,676,800,659,761,689,818,653,845,634,850,618,863,606,878,600,892,588,903,581,899,558,896,539,883,538,872,510,860,510,837,523,824,509,823,481,816,463,805,476,788,482,790,499,794,530,771,535,770,547,757,546,737,551,740,560"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 6")}
            onMouseLeave={handleMouseLeave}
          />
          <area
            // target="_blank"
            onClick={handleClick}
            alt="/region7"
            title="region7"
            coords={scaleCoords(
              "551,440,552,456,550,481,559,484,561,521,588,519,589,550,601,567,612,556,632,573,633,579,640,572,662,593,684,578,698,591,716,589,740,562,734,552,769,549,768,538,792,536,787,484,804,474,802,462,794,461,793,452,794,444,800,440,794,427,792,416,784,410,772,399,768,384,741,405,722,401,709,383,693,364,663,376,655,372,615,395,617,404,601,415,576,417"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 7")}
            onMouseLeave={handleMouseLeave}
          />
          <area
            onClick={handleSelectChange}
            alt="www.dfps.texas.gov/CBC/community-areas/region-8b.asp"
            title="region8"
            coords={scaleCoords(
              "357,542,541,540,539,522,588,520,587,557,602,568,613,563,630,576,633,584,644,576,659,592,685,580,699,592,716,591,741,613,735,623,761,648,763,672,755,666,754,680,738,666,735,680,752,686,735,699,721,684,717,690,701,684,683,696,672,695,671,687,659,685,658,675,643,668,637,674,627,666,610,682,576,680,573,729,532,728,528,713,470,716,443,662,432,639,424,625,408,619,407,606,392,602,389,587,381,581,368,578,354,582"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 8")}
            onMouseLeave={handleMouseLeave}
          />
          <area
            // target="_blank"
            onClick={handleClick}
            alt="/region9"
            title="region9"
            coords={scaleCoords(
              "184,458,310,571,326,577,336,579,345,578,354,579,356,562,356,541,396,538,442,540,538,541,538,521,562,519,559,483,551,483,550,454,550,437,542,434,535,439,527,435,523,439,517,434,511,436,507,427,470,428,468,387,444,384,400,381,400,348,403,310,268,307,265,391,198,388"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 9")}
            onMouseLeave={handleMouseLeave}
          />
          <area
            // target="_blank"
            onClick={handleClick}
            alt="/region10"
            title="Region10"
            coords={scaleCoords(
              "5,376,193,388,183,462,309,570,306,579,299,579,291,582,285,585,281,590,278,595,275,602,273,610,269,617,267,624,260,629,256,634,252,639,252,645,245,643,237,642,231,640,225,640,224,632,213,631,204,627,203,619,190,618,180,613,175,607,169,599,160,595,150,588,146,579,144,569,137,560,139,551,138,539,133,527,128,514,124,506,117,496,107,490,97,484,91,477,80,470,76,460,71,452,62,450,57,439,47,431,38,426,28,412,22,399,13,393,7,387"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 10")}
            onMouseLeave={handleMouseLeave}
          />

          <area
            // target="_blank"
            onClick={handleClick}
            alt="/region11"
            title="region11"
            coords={scaleCoords(
              "471,714,486,735,495,751,507,758,522,768,524,784,528,795,529,813,542,830,550,852,554,866,571,866,591,880,606,882,617,891,629,895,649,893,665,895,675,906,684,903,694,905,705,899,699,894,692,887,689,872,680,861,683,848,676,829,670,815,675,805,680,792,660,792,655,777,667,786,675,778,683,776,688,754,685,760,681,748,671,738,685,738,696,739,692,723,698,713,713,712,727,704,722,692,699,679,686,694,667,684,658,685,653,670,642,668,637,670,629,663,610,678,574,676,576,727,532,726,531,713"
            )}
            style={{ cursor: "pointer" }}
            shape="poly"
            onMouseEnter={() => handleMouseEnter("Region 11")}
            onMouseLeave={handleMouseLeave}
          />
        </map>
      )}
      {hoveredArea === "Region 1" && (
        <div className={`tooltip1 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(1048).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>
        </div>
      )}
      {hoveredArea === "Region 2" && (
        <div className={`tooltip2 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(1013).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 3a" && (
        <div className={`tooltip3a ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(3717).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 3b" && (
        <div className={`tooltip3b ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(3717).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 3" && (
        <div className={`tooltip3 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(3717).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 4" && (
        <div className={`tooltip4 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(1137).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 5" && (
        <div className={`tooltip5 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(818).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 6" && (
        <div className={`tooltip6 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(2377).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 7" && (
        <div className={`tooltip7 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(2362).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 8" && (
        <div className={`tooltip8 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(2724).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 9" && (
        <div className={`tooltip9 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(443).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 10" && (
        <div className={`tooltip10 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(262).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
      {hoveredArea === "Region 11" && (
        <div className={`tooltip11 ${hoveredArea ? "visible" : ""}`}>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {hoveredArea}
          </p>
          <p>
            <b color="red">{(1556).toLocaleString()}</b> Youth are Available for
            FosterCare
          </p>{" "}
        </div>
      )}
    </div>
  );
}

//     return (
//         <div style={{ position: 'relative', width: 'auto' }}>
//             <img src={texasMap} alt='Map of Texas' useMap="#image-map" ref={imageRef} style={{ maxWidth: '80%', height: 'auto' }} />
//             <map name="image-map">
//                 {/* Use scaleCoords function to adjust the coords for each area */}

//             </map>

//         </div>
//     );
// }
export default ResponsiveMap;
