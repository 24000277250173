import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./childrendisabilities.css";

const Childrendisabilities = () => {
  return (
    <div style={{width:"100%"}}>
      {/* <ImageSlider /> */}
      <Grid
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          id="maingrid"
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Grid style={{width:"100%"}}>
            <Typography variant="h5" style={{ color: "#002F86",textAlign:'center', }}>
              <b>A Closer Look at Children's Disabilities</b>
            </Typography>
            <Typography>
              <b>Also see:</b>
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li>
                <a href="/childdisabilitylevel"> Disability Levels</a>
              </li>
              <li>
                <a href="/mostcommondisability"> Most Common Disabilities</a>
              </li>
            </ul>
          </Grid>
          <Grid id="table1" style={{ paddingTop: "30px", width: "100%" }}>
            <TableContainer
              id="table"
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "lightgray" }}>
                    <TableCell
                      align="left"
                      style={{ width: "", border: "1px solid black" }}
                    >
                      <b>TARE ARTICLE</b>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ width: "", border: "1px solid black" }}
                    >
                      <b>LEARN MORE</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/adhd">ADHD</a>
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.nimh.nih.gov/health/topics/attention-deficit-hyperactivity-disorder-adhd/index.shtml">
                        National Institute on Mental Health
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/asthma">Asthma</a>
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.epa.gov/asthma/">Asthma</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/autism">Autism Spectrum Disorder</a>
                    </TableCell>
                    <TableCell>
                      <p>
                        <a target="_blank" href="http://www.autism-society.org/">
                          Autism Society of America
                        </a>
                      </p>
                      <p>
                        <a target="_blank" href="http://www.nimh.nih.gov/health/topics/autism-spectrum-disorders-pervasive-developmental-disorders/index.shtml">
                          National Institute on Mental Health
                        </a>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Asperger's Syndrome
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="https://www.ninds.nih.gov/disorders/all-disorders/asperger-syndrome-information-page">
                        National Institute of Neurological Disorders and Stroke
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Angelman Syndrome
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.angelman.org/angel/">
                        Angleman Syndrome Foundation
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/cerebral_palsy">Cerebral Palsy</a>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <p>
                        <a target="_blank" href="http://ucp.org/resources/resource-guide/">
                          United Cerebral Palsy Association
                        </a>
                      </p>
                      <p>
                        <a target="_blank" href="https://www.cerebralpalsyguide.com/treatment/">
                          Cerebral Palsy Treatment{" "}
                        </a>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/cystic_fibrosis">Cystic Fibrosis</a>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <a target="_blank" href="http://www.cff.org/AboutCF/">
                        Cystic Fibrosis Foundation
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/diabetes">Diabetes</a>
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.diabetes.org/">
                        American Diabetes Association
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/downsyndrome">Down syndrome</a>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <a target="_blank" href="http://www.ndss.org">
                        National Down Syndrome Society
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Epilepsy
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.epilepsyfoundation.org/about/">
                        Epilepsy Foundation of America{" "}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/enuresis">Enuresis</a>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/fetal_alcohol">Fetal Alcohol Syndrome</a>
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="https://www.nofas.org/">
                        National Organization on Fetal Alcohol Syndrome
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/galactosemia">Galactosemia</a>
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="https://www.nlm.nih.gov/medlineplus/ency/article/000366.htm">
                        Galactosemia
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Hydrocephalus
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.hydroassoc.org/">
                        Hydrocephalus Association
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/intellectual_disability">
                        Intellectual Disability
                      </a>
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.aacap.org/AACAP/AACAP/Families_and_Youth/Facts_for_Families/FFF-Guide/Children-with-an-Intellectual-Disability-023.aspx">
                        American Academy of Child and Adolescent Psychiatry
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Muscular Dystrophy
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.ninds.nih.gov/disorders/md/md.htm">
                        National Institute of Neurological Disorders and Stroke
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a  href="/myasthenia_gravis">Myasthenia Gravis</a>
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.myasthenia.org/amg_whatismg.cfm">
                        Myasthenia Gravis Foundation of America
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Neurofibromatosis
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.ctf.org/">
                        Children's Tumor Foundation
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Osteogenesis Imperfecta (OI)
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.oif.org/site/PageServer?pagename=AOI_Facts">
                        Osteogenesis Imperfecta (OI) Foundation
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Pervasive Development Disorder
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="https://www.ninds.nih.gov/disorders/all-disorders/pervasive-developmental-disorders-information-page">
                        National Institute of Neurological Disorders and Stroke
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <a href="/sickle_cell">Sickle Cell</a>
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://www.nhlbi.nih.gov/health/dci/Diseases/Sca/SCA_WhatIs.html">
                        National Institutes of Health
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Spina Bifida
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="http://spinabifidaassociation.org/what-is-sb/">
                        Spina Bifida Association
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Smith-Lemli Optiz Syndrome{" "}
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="https://ghr.nlm.nih.gov/condition/smith-lemli-opitz-syndrome">
                        Genetics Home Reference
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      Williams Syndrome
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href="https://williams-syndrome.org/what-is-williams-syndrome">
                        Williams Syndrome Association
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Childrendisabilities;
