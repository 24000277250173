
const agencyData = {
    agency_data: [],
    selectedCounty: "",
    error: "",
  };

  
function FetchAgencyData(state = agencyData, action) {
  if (action.type === "fetchAgencies") {
    return {
      ...agencyData,
      agency_data: action.payload,
      selectedCounty: action.county,
      error: "agencydata__received",
    };
  }
  return agencyData;
}

export default FetchAgencyData;
