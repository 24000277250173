import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";
const SickleCell = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>A closer look at Sickle Cell</b>
            </Typography>
            <br />
            <Typography>
              <em>
                For More Info Visit:{" "}
                <a
                  target="_blank"
                  href="http://www.nhlbi.nih.gov/health/dci/Diseases/Sca/SCA_WhatIs.html"
                >
                  National Institutes of Health
                </a>
              </em>
            </Typography>
            <br />
            <Typography>
              Sickle cell is a condition in which people have a disorder of
              their red blood cells. Red blood cells carry oxygen throughout the
              body via a protein called hemoglobin. Normal red blood cells
              contain hemoglobin shaped like doughnuts. The cells are flexible
              and slide easily through tiny blood vessels.
            </Typography>
            <br />
            <Typography>
              Some people, however, inherit red blood cells containing
              hemoglobin shaped like a sickle or a crescent moon. These
              sickle-shaped cells tend to snag on blood vessels, which clogs the
              flow of blood. Without the flow of oxygen-rich blood, the affected
              part of the body reacts by sending out signals for pain. In some
              cases, there is a danger that important organs of the body could
              be seriously damaged by the lack of oxygen. This is called sickle
              cell disease. Sickle cell trait is a less threatening condition in
              which a person has inherited the presence of sickle cells and is a
              carrier who can pass the condition on to their offspring.
            </Typography>
            <br />
            <Typography>
              Sickle cell anemia and sickle cell trait are not contagious. They
              are passed genetically from parents to their children.
            </Typography>
            <br />
            <Typography>
              Sickle cell trait does not turn into sickle cell anemia.
            </Typography>
            <br />
            <Typography>
              Some scientists believe that sickle cell genes started in four
              regions of the world due to mutations. People with sickle cell do
              not seem to catch malaria. Thus, they were able to survive an
              otherwise deadly disease and produce offspring. In return,
              however, they became susceptible to the sickle cell condition.
            </Typography>
            <br />
            <Typography>
              In the United States, African Americans are most likely to have
              sickle cell disease. About one of every 375 African American
              children has the disease. The disease also is found in some people
              from Mediterranean countries and India, as well as Latinos from
              the Caribbean and Central and South America.
            </Typography>
            <br />
            <Typography>
              People with sickle cell disease, often called sickle cell anemia,
              may suffer a wide variety of symptoms, most commonly anemia that
              makes them tire easily. In addition, they may have pain and
              swelling of the arms, legs, feet, and hands, as well as fast
              breathing and fast heartbeat. In the worst cases, the disease
              creates risk for serious infections of the blood stream,
              meningitis, and pneumonia. Organs, such as the lungs, spleen, and
              brain, can be affected when blood vessels are blocked by the
              sickle-shaped cells.
            </Typography>
            <br />
            <Typography>
              People with sickle cell trait usually have minimal health problems
              and can lead normal lives. For a few people, painful episodes may
              occur if they fly in unpressurized airplanes at high altitudes or
              drive in the mountains where oxygen is thinner. There is some
              evidence that athletes who train vigorously and military trainees
              in intense training may collapse and even die during extreme
              exertion due to effects of sickle cell trait. The incidents seem
              to involve intense exercise in hot conditions or at high altitudes
              that deprive the body of ample oxygen.
            </Typography>
            <br />
            <Typography>
              The most important health aspect of sickle cell trait is the need
              for counseling and education regarding the chances of passing the
              condition on to the carrier's children. Sickle cell trait occurs
              when one parent or the other genetically passes along to a child
              something called hemoglobin S. Children who inherit this
              particular type of red blood cell have sickle cell trait. When
              both parents genetically pass along the hemoglobin S. in red blood
              cells, the child stands a 25 percent chance of having sickle cell
              disease.
            </Typography>
            <br />
            <Typography>
              The parent of a child with sickle cell trait needs to know that
              when the child grows up and considers having children, genetic
              counseling and screening are important. This allows for informed
              decisions about the chances of passing sickle cell trait or sickle
              cell disease to the children. Screening for the trait, however,
              raises the issue of whether genetic information is used to
              discriminate against individuals who test positive for the trait.
              While there is no conclusive evidence of discrimination in such
              things as insurance coverage and health care, the ethical question
              remains until public policy is firmly established.
            </Typography>
            <br />
            <Typography>
              The family physician needs to know if there is a family history of
              sickle cell. If so, the doctor can recommend the best medical care
              for children who inherit the condition.
            </Typography>
            <br />
            <Typography>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the <a href="/generalInformation">Get Started</a>{" "}
              section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href="">Texas Adoption Resource Exchange (TARE)</a> or call
              1-800-233-3405.
            </Typography>
            <br />
            <Grid style={{ float: "right" }}>
              <Typography>
                This content taken from:{" "}
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                >
                  Adoption and Foster Care
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SickleCell;
