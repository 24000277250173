import React from 'react';

const LegendSwatchRow = ({ color, label, alt, href }) => (
  <tr style={{border:"2px solid black"}}>
    <td id='color_div' style={{ width: '20px', height: '20px', background: color, borderRadius: '0px',border:"1px solid black" }}></td>
    <td style={{border:"1px solid black"}}>{alt}</td>
    <td><a href={href} target="_blank" rel="noopener noreferrer">{label}</a></td>
  </tr>
);

const Vendor = () => (
  <table className="legend-table" style={{ borderSpacing: '10px 3px', borderCollapse: 'separate',border:"2px solid black",width:"25%" }}>
    <tbody style={{border:"2px solid black"}}>
      <LegendSwatchRow color="rgba(234,177,169,255)" alt="Region1" label="Saint Francis" href="https://saintfrancisministries.org/texas-2/" />
      <LegendSwatchRow color="rgba(172,198,156,255)" alt="Region2" label="2Ingage" href="https://2ingage.org/" />
      <LegendSwatchRow color="rgba(245,213,154,255)" alt="Region3W" label="OCOK" href="https://ourcommunity-ourkids.org/" />
      <LegendSwatchRow color="rgba(158,159,202,255)" alt="Region3E" label="Empower" href="https://empowerweb.org/" />
      <LegendSwatchRow color="rgba(105,205,199,255)" alt="Region4" label="4Kids4Families" href="https://www.arrow.org/4kids4families/" />
      <LegendSwatchRow color="rgba(91,151,223,255)" alt="Region5" label="Family Care Network" href="https://www.familycarenetwork.com/" />
      <LegendSwatchRow color="rgba(247,98,75,255)" alt="Region8" label="Belong" href="https://sjrcbelong.org/" />
    </tbody>
  </table>
);
export default Vendor;












