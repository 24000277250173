import React, { useEffect } from "react";
import ImageSlider from "../../layout/imageslider";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
// import VideocamIcon from '@mui/icons-material/Videocam';
import { Grid, Typography, Card, CardContent, CardHeader } from "@mui/material";
// import FeedIcon from '@mui/icons-material/Feed';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import "./state.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(NO, NAME, URL) {
  return { NO, NAME, URL };
}

const rows = [
  createData(
    1,
    "Alabama",
    <Link target="_blank" to="https://www.adoptuskids.org/states/al/index.aspx">
      Alabama Dept. of Human Resources{" "}
    </Link>
  ),
  createData(
    2,
    "Alaska",
    <Link
      target="_blank"
      to="https://dfcs.alaska.gov/ocs/Pages/adoptions/default.aspx"
    >
      Alaska Office of Children's Services{" "}
    </Link>
  ),
  createData(
    3,
    "Arizona",
    <Link target="_blank" to="https://dcs.az.gov/adoption">
      {" "}
      Arizona Division of Children, Youth, and Families
    </Link>
  ),
  createData(
    4,
    "Arkansas",
    <Link
      target="_blank"
      to="https://humanservices.arkansas.gov/divisions-shared-services/children-family-services/dcfs/fosterarkansas/"
    >
      {" "}
      Arkansas Adoption Resource Exchange
    </Link>
  ),
  createData(
    5,
    "California",
    <Link target="_blank" to="https://www.cakidsconnection.org/">
      {" "}
      California Kids Connection
    </Link>
  ),
  createData(
    6,
    "Cherokee Nation",
    <Link target="_blank" to="https://www.cherokeekids.org/">
      {" "}
      Cherokee Kids{" "}
    </Link>
  ),
  createData(
    7,
    "Colorado",
    <Link target="_blank" to="http://www.changealifeforever.org/">
      {" "}
      Change a Life Forever
    </Link>
  ),
  createData(
    8,
    "Connecticut",
    <Link target="_blank" to="https://portal.ct.gov/DCF/CTFosterAdopt/Home">
      Department of Children and Families{" "}
    </Link>
  ),
  createData(
    9,
    "Delaware",
    <Link
      target="_blank"
      to="https://kids.delaware.gov/family-services/adoption/"
    >
      {" "}
      Department of Services for Children, Youth, and Their Families
    </Link>
  ),
  createData(
    10,
    "District of Columbia",
    <Link target="_blank" to="https://cfsa.dc.gov/page/about-cfsa">
      {" "}
      Child and Family Services Agency
    </Link>
  ),
  createData(
    11,
    "Florida",
    <Link target="_blank" to="">
      {" "}
      Florida Department of Children and Families
    </Link>
  ),
  createData(
    12,
    "Georgia",
    <Link target="_blank" to="https://dfcs.georgia.gov/services/adoption">
      Division of Children and Family Services{" "}
    </Link>
  ),
  createData(
    13,
    "Hawaii",
    <Link
      target="_blank"
      to="https://humanservices.hawaii.gov/ssd/home/child-welfare-services/foster-and-adoptive-care/"
    >
      {" "}
      State of Hawaii - Dept. of Human Services
    </Link>
  ),
  createData(
    14,
    "Idaho",
    <Link
      target="_blank"
      to="https://healthandwelfare.idaho.gov/services-programs/children-families/child-and-family-services-and-foster-care"
    >
      {" "}
      Idaho Dept. of Health and Welfare
    </Link>
  ),
  createData(
    15,
    "Illinois",
    <Link target="_blank" to="https://dcfs.illinois.gov/loving-homes.html">
      {" "}
      Illinois Department of Children and Family Services
    </Link>
  ),
  createData(
    16,
    "Indiana",
    <Link target="_blank" to="https://www.in.gov/dcs/adoption//">
      Indiana Dept. of Child Services{" "}
    </Link>
  ),
  createData(
    17,
    "Iowa",
    <Link
      target="_blank"
      to="https://hhs.iowa.gov/programs/CPS/foster-care-and-adoption"
    >
      {" "}
      Iowa Department of Human Services
    </Link>
  ),
  createData(
    18,
    "Kansas",
    <Link target="_blank" to="https://www.adoptkskids.org/">
      {" "}
      Adopt Kansas Kids
    </Link>
  ),

  createData(
    19,
    "Kentucky",
    <Link target="_blank" to="https://prdweb.chfs.ky.gov/kyfaces">
      {" "}
      Kentucky FACES
    </Link>
  ),
  createData(
    20,
    "Louisiana",
    <Link
      target="_blank"
      to="https://www.dcfs.louisiana.gov/page/375#undefined"
    >
      {" "}
      Louisiana Dept. of Children and Family Services
    </Link>
  ),
  createData(
    21,
    "Maine",
    <Link
      target="_blank"
      to="https://www.maine.gov/dhhs/ocfs/cw/adoption/howtoadopt.htm"
    >
      Maine Child and Family Services{" "}
    </Link>
  ),
  createData(
    22,
    "Maryland",
    <Link target="_blank" to="">
      {" "}
      Maryland Department of Human Services
    </Link>
  ),
  createData(
    23,
    "Massachusetts",
    <Link target="_blank" to="https://www.mareinc.org/">
      {" "}
      Massachusetts Adoption Resource Exchange{" "}
    </Link>
  ),
  createData(
    24,
    "Massachusetts",
    <Link target="_blank" to="https://www.mare.org/">
      {" "}
      Michigan Adoption Resource Exchange
    </Link>
  ),
  createData(
    25,
    "Minnesota",
    <Link target="_blank" to="https://www.fosteradoptmn.org/">
      Minnesota Adoption Resource Network{" "}
    </Link>
  ),
  createData(
    26,
    "Mississippi",
    <Link
      target="_blank"
      to="https://www.mdcps.ms.gov/mississippi-heart-gallery/"
    >
      Mississippi Department of Child Protection Services{" "}
    </Link>
  ),
  createData(
    27,
    "Missouri",
    <Link target="_blank" to="https://dss.mo.gov/cd/adoption/">
      {" "}
      Missouri Dept. of Social Services
    </Link>
  ),
  createData(
    28,
    "Montana",
    <Link
      target="_blank"
      to="https://www.dphhs.mt.gov/cfsd/adoption/adoptioninmontana."
    >
      Montana Dept. of Public Health and Human Services{" "}
    </Link>
  ),
  createData(
    29,
    "Nebraska",
    <Link target="_blank" to="https://dhhs.ne.gov/Pages/Adoption.aspx">
      Nebraska Dept. of Health and Human Services{" "}
    </Link>
  ),
  createData(
    30,
    "Nevada",
    <Link target="_blank" to="https://dcfs.nv.gov/Programs/CWS/Adoption/">
      {" "}
      Nevada Division of Child and Family Services
    </Link>
  ),
  createData(
    31,
    "New Hampshire",
    <Link
      target="_blank"
      to="https://www.dhhs.nh.gov/programs-services/child-protection-juvenile-justice"
    >
      {" "}
      New Hampshire Dept. of Health and Human Services{" "}
    </Link>
  ),
  createData(
    32,
    "New Jersey",
    <Link target="_blank" to="https://www.nj.gov/njfosteradopt/">
      {" "}
      New Jersey Dept. of Children and Families
    </Link>
  ),
  createData(
    33,
    "New Mexico",
    <Link target="_blank" to="https://heartgallerynm.org/">
      Heart Gallery of New Mexico, New Mexico Children, Youth, and Families{" "}
    </Link>
  ),
  createData(
    34,
    "New York",
    <Link target="_blank" to="https://www.ocfs.state.ny.us/adopt/">
      New York State Adoption Service{" "}
    </Link>
  ),
  createData(
    35,
    "North Carolina",
    <Link
      target="_blank"
      to="https://www.ncdhhs.gov/divisions/social-services/child-welfare-services/adoption-and-foster-care"
    >
      {" "}
      NC Kids Adoption and Foster Care Network{" "}
    </Link>
  ),
  createData(
    36,
    "North Dakota",
    <Link
      target="_blank"
      to="https://www.hhs.nd.gov/services/childfamily/adoption/"
    >
      {" "}
      North Dakota Department of Human Services
    </Link>
  ),
  createData(
    37,
    "Ohio",
    <Link target="_blank" to="https://jfs.ohio.gov/oapl/index.stm">
      {" "}
      Adopt Ohio{" "}
    </Link>
  ),
  createData(
    38,
    "Oklahoma",
    <Link target="_blank" to="http://www.adoptokkids.org/Pages/default.aspx">
      AdoptOKKids{" "}
    </Link>
  ),
  createData(
    39,
    "Oregon",
    <Link
      target="_blank"
      to="https://www.oregon.gov/odhs/adoption/pages/default.aspx?utm_source=ODHS&utm_medium=egov_redirect&utm_campaign=https%3A%2F%2Fwww.oregon.gov%2Fdhs%2Fchildren%2Fadoption%2Fpages%2Findex.aspx"
    >
      Oregon Department of Human Services{" "}
    </Link>
  ),
  createData(
    40,
    "Pennsylvania",
    <Link
      target="_blank"
      to="https://www.dhs.pa.gov/AdoptPAkids/Pages/default.aspx"
    >
      {" "}
      Pennsylvania Statewide Adoption and Permanency Network
    </Link>
  ),
  createData(
    41,
    "Rhode Island",
    <Link target="_blank" to="https://adoptionri.org/">
      {" "}
      Adoption Rhode Island
    </Link>
  ),
  createData(
    42,
    "South Carolina",
    <Link target="_blank" to="https://dss.sc.gov/adoption/">
      {" "}
      South Carolina Dept. of Social Services{" "}
    </Link>
  ),
  createData(
    43,
    "South Dakota",
    <Link
      target="_blank"
      to="https://dss.sd.gov/childprotection/adoption/childrenwaiting.aspx"
    >
      Dept. of Social Services{" "}
    </Link>
  ),
  createData(
    44,
    "Tennessee",
    <Link
      target="_blank"
      to="https://www.tn.gov/dcs/program-areas/fca/adoption.html"
    >
      {" "}
      Dept. of Children's Services
    </Link>
  ),
  createData(
    45,
    "Texas",
    <Link
      target="_blank"
      to="https://www.dfps.texas.gov/Application/TARE/Home.aspx/Default"
    >
      {" "}
      Texas Adoption Resource Exchange
    </Link>
  ),
  createData(
    46,
    "Utah",
    <Link target="_blank" to="https://www.utahadopt.org/">
      {" "}
      Utah's Adoption Connection{" "}
    </Link>
  ),
  createData(
    47,
    "Vermont",
    <Link target="_blank" to="https://dcf.vermont.gov/fsd/adopt">
      Vermont Project Family{" "}
    </Link>
  ),
  createData(
    48,
    "Virginia",
    <Link target="_blank" to="https://www.dss.virginia.gov/family/ap/index.cgi">
      Virginia's Dept. of Social Services{" "}
    </Link>
  ),
  createData(
    50,
    "Washington",
    <Link target="_blank" to="https://www.dcyf.wa.gov/services/adoption">
      Washington State Dept. Of Social and Health Services
    </Link>
  ),
  createData(
    51,
    "West Virginia",
    <Link target="_blank" to="https://www.wvdhhr.org/oss/adoption/">
      {" "}
      Adopt a West Virginia Child
    </Link>
  ),
  createData(
    52,
    "Wisconsin",
    <Link target="_blank" to="https://dcf.wisconsin.gov/adoption/become">
      Dept. of Family Services{" "}
    </Link>
  ),
  createData(
    53,
    "Wyoming",
    <Link target="_blank" to="https://dfs.wyo.gov/">
      Dept. of Family Services{" "}
    </Link>
  ),
];

const State = () => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div>
      {/* <ImageSlider /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid id="maingrid" style={{ backgroundColor: "white", }}>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid 
              style={{
                textAlign: "justify",
                backgroundColor: "white",
                width: "90%",
              }}
            >
              <br />
              <Typography variant="h5" style={{ fontWeight: "bold", textAlign:"left"}}>
                State Adoption Websites
              </Typography>
              <br />
              <Grid style={{ display: "flex" }}>
                <DoubleArrowIcon />
                <Typography style={{ textAlign: "justify" }}>
                  If you are interested in a Texas child waiting for adoption,
                  but you live in another state and have not completed a home
                  study, we encourage you to get approved to adopt within your
                  state first.
                </Typography>
              </Grid>
              <br />
              <Grid style={{ display: "flex" }}>
                <DoubleArrowIcon />
                <Typography>
                  <span style={{ fontWeight: "bold" }}>Note:</span> Whenever
                  possible, links point directly to a page that explains the
                  adoption process in your state. Texas works with interested
                  families from other states, however we cannot approve or
                  develop home studies on families outside Texas. We encourage
                  you to get approved to adopt within your state first. Please
                  contact us if you have any questions.
                </Typography>
              </Grid>
              <br />
              <Grid id="responsivegrid">
                <Typography>
                  <b>Military Families:</b> National organization dedicated to
                  identifying and resolving issues of concern to military
                  families.
                </Typography>
                <ul style={{ lineHeight: "1.6" }}>
                  <li>
                    <a href="">Military Families and Adoption</a>
                  </li>
                  <li>
                    <a href="">National Military Family Association (NMFA)</a>
                  </li>
                </ul>
                <Typography>
                  <b>Adoption Costs:</b> The cost of adoption is minimal with a
                  public state agency. The cost varies with the private adoption
                  agencies in your state. However, children may qualify for{" "}
                  <a
                    target="_blank"
                    href="https://www.dfps.texas.gov/Child_Protection/Adoption/adoption_assistance_resources.asp"
                  >
                    {" "}
                    adoption assistance resources
                  </a>{" "}
                  and the federal government offers income tax credits to
                  families who adopt children.
                </Typography>
                <br />
                <Typography>
                  <b>Children with Special Needs:</b> Most public state
                  agencies, including the Texas Department of Family and
                  Protective Services (DFPS), will only train and complete a
                  home study for families who are willing to foster or adopt
                  children who
                </Typography>
                <ul style={{ lineHeight: "1.6" }}>
                  <li>Are waiting for adoption and</li>
                  <li>Who may have special needs.</li>
                </ul>
                <Typography>
                  <b>Children with Special Needs</b>
                </Typography>
                <ul style={{ lineHeight: "1.6" }}>
                  <li>May be school-aged through 18 years;</li>
                  <li>
                    May have special medical, physical, or emotional needs;
                  </li>
                  <li>May belong to any ethnicity or race; and</li>
                  <li>
                    May be a part of a group of brothers and sisters who need to
                    be placed together.
                  </li>
                </ul>
                <Typography>
                  {" "}
                  Many of the children waiting for adoption have one or more
                  special needs.
                </Typography>
                <Typography>
                  <b>Canada:</b> Families from Canada can visit:
                </Typography>
                <ul style={{ lineHeight: "1.6" }}>
                  <li>
                    <a href="">Alberta's Children Services</a>
                  </li>
                  <li>
                    <a href="">Canada's Waiting Children</a>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              backgroundColor: "white",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Grid
              container
              id="tablegrid"
              item
              xl={10}
              lg={10}
              md={12}
              sm={12}
              xs={12}
              style={{ width: "100%" }}
            >
              <TableContainer component={Paper} style={{ width: "100%" }}>
                <Table aria-label="customized table">
                  <TableHead style={{ backgroundColor: "lightblue" }}>
                    <TableRow>
                      <StyledTableCell style={{ border: "0.5px solid black" }}>
                        <b>NO</b>
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ border: "0.5px solid black" }}
                      >
                        <b>POSITIVE LANGUAGE</b>
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ border: "0.5px solid black" }}
                      >
                        <b>NEGATIVE LANGUAGE</b>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.NO}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ border: "0.5px solid black" }}
                        >
                          {row.NO}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{ border: "0.5px solid black" }}
                        >
                          {row.NAME}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{ border: "0.5px solid black" }}
                        >
                          {row.URL}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              id="miltarygrid"
              item
              lg={10}
              md={11}
              sm={11}
              xs={11}
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Card style={{ border: "1px solid gray" }}>
                <CardHeader
                  style={{
                    backgroundColor: "rgb(53, 103, 178)",
                    color: "white",
                  }}
                  title="Military Families"
                ></CardHeader>
                <CardContent style={{ textAlign: "justify" }}>
                  {/* <Typography
                                        style={{ fontSize: 20, textAlign: 'left' }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        <b>Military Families</b>
                                    </Typography> */}
                  <Typography style={{ textAlign: "justify" }}>
                    &ensp; National organization dedicated to identifying and
                    resolving issues of concern to military families.
                  </Typography>
                  <ul
                    style={{
                      textAlign: "left",
                      lineHeight: "20px",
                      paddingBottom: "5px",
                    }}
                  >
                    <li style={{ paddingBottom: "5px" }}>
                      <Link
                        target="_blank"
                        to="https://www.childwelfare.gov/topics/systemwide/diverse-populations/military/mil-adoption/"
                      >
                        Military Families and Adoption
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to="http://www.nmfa.org/">
                        National Military Family Association (NMFA)
                      </Link>
                    </li>
                  </ul>
                  <br />
                  <Typography style={{ fontWeight: "bold" }}>
                    Adoption Costs
                  </Typography>
                  <Typography
                    style={{
                      textAlign: "justify",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                    }}
                  >
                    &ensp; The cost of adoption is minimal with a public state
                    agency. The cost varies with the private adoption agencies
                    in your state. However, children may qualify for{" "}
                    <a target="_blank" href="https://www.dfps.texas.gov/Child_Protection/Adoption/adoption_assistance_resources.asp">
                      adoption assistance resources
                    </a>{" "}
                    and the federal government offers income tax credits to
                    families who adopt children.
                  </Typography>
                  <br />
                  <Typography style={{ fontWeight: "bold" }}>
                    Children with Special Needs
                  </Typography>
                  <Typography
                    style={{ paddingBottom: "5px", paddingTop: "5px" }}
                  >
                    &ensp; Most public state agencies, including the Texas
                    Department of Family and Protective Services (DFPS), will
                    only train and complete a home study for families who are
                    willing to foster or adopt children who:
                    <ul>
                      <li style={{ paddingBottom: "5px" }}>
                        Are waiting for adoption and
                      </li>
                      <li>Who may have special needs.</li>
                    </ul>
                  </Typography>
                  <Typography>
                    <b>Children with special needs:</b>
                  </Typography>
                  <ul>
                    <li style={{ paddingBottom: "5px" }}>
                      May be school-aged through 18 years;
                    </li>
                    <li style={{ paddingBottom: "5px" }}>
                      May have special medical, physical, or emotional needs;
                    </li>
                    <li style={{ paddingBottom: "5px" }}>
                      May belong to any ethnicity or race; and
                    </li>
                    <li>
                      May be a part of a group of brothers and sisters who need
                      to be placed together.
                    </li>
                  </ul>
                  <Typography>
                    &ensp; Many of the children waiting for adoption have one or
                    more special needs.
                  </Typography>
                  <br />
                  <Typography style={{ fontWeight: "bold" }}>Canada</Typography>
                  <br />
                  <Typography>Families from Canada can visit:</Typography>
                  <ul>
                    <li style={{ paddingBottom: "5px" }}>
                      <Link
                        target="_blank"
                        to="https://www.alberta.ca/childrens-services"
                      >
                        Alberta's Children Services
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to="http://canwaitkids.mantis.biz/">
                        Canada's Waiting Children
                      </Link>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default State;
