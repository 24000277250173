import React from "react";
import ImageSlider from "../../layout/imageslider";
import { Card, Grid, Typography } from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";
import txregions from "../images/txregions.gif";
import MapCard from "./mappages/mapcard";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import lubbock from "../../fostercareOverview/videos/lubbockpsa.wmv";

const Placement = () => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "justify",
        }}
      >
        <Grid
          id="placementofDFPSinotherstate"
          style={{
            width: "80%",
            textAlign: "justify",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
            //
          }}
        >
          <Typography
            variant="h6"
            align="center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#3f51b5",
              color: "white",
              width: "100%",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              height: "60px",
            }}
          >
            Placement of DFPS Children in other States
          </Typography>

          <Grid style={{ width: "90%" }}>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon /> Children in the care of DFPS may be considered
              for adoption by families who reside out-of-state. As with any
              family, the out-of-state family must have a current approved
              adoption home study. The study needs to be approved by an agency
              licensed to place children for adoption.
            </Typography>
            <br />
            <Typography style={{ display: "flex" }}>
              <DoubleArrowIcon />
              The two child placing agencies (DFPS and the out-of state agency)
              work together to assess the child's needs and the family's ability
              to meet those needs. If the placement is approved, the out-of
              state agency is responsible to provide placement support services
              and supervision until consummation of the adoption.
            </Typography>
            <br />
            <Typography style={{ display: "flex" }}>
              <DoubleArrowIcon />
              The out-of-state agency provides DFPS with progress reports and in
              most instances is also eligible for reimbursement for their
              services. They are only eligible if they have a contract with the
              department (DFPS) prior to placement.
            </Typography>
            <br />
            <Grid style={{ display: "flex" }}>
              <DoubleArrowIcon />
              <Typography>
                Prior to placement, the placement must be approved by the
                <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/child_protection/State_Care/icpc.asp"
                >
                  {" "}
                  Interstate Compact Office
                </a>{" "}
                in each state.
              </Typography>
            </Grid>
            <br />
            <Typography style={{ display: "flex" }}>
              <DoubleArrowIcon />
              Basically the same process happens for placement of non-DFPS
              children out-of-state as far as the home study requirements. The
              family must have an approved adoption home study completed by an
              agency in their state and the placement must be approved by the
              Interstate Compact.
            </Typography>
            <br />
          </Grid>
        </Grid>

        <Grid
          style={{
            marginTop: "20px",
            width: "80%",
            textAlign: "left",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
          }}
          id="facts"
        >
          <Typography
            variant="h6"
            style={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#3f51b5",
              color: "white",
              width: "100%",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              height: "60px",
            }}
          >
            Texas Investigation, Foster Care and Adoption Facts
          </Typography>

          <Grid style={{ width: "90%" }}>
            <br />
            <Typography style={{ display: "flex" }}>
              <DoubleArrowIcon />
              <Typography>
                You may view complete statistical information in the
                <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/About_DFPS/Annual_Report/default.asp"
                >
                  {" "}
                  DFPS Data Book.
                </a>
              </Typography>
            </Typography>
            <br />
            <Typography style={{ display: "flex" }}>
              <DoubleArrowIcon />
              <br />
              <span style={{ fontWeight: "bold" }}>
                Texas Adoption Resource Exchange
              </span>
            </Typography>
            <br />
            <Typography style={{ paddingLeft: "20px" }}>
              701 West 51st Street
              <br />
              PO Box 149030
              <br />
              Austin, Texas 78714-9030
              <br />
              1-800-233-3405
            </Typography>
            <br />
            <Typography style={{ paddingLeft: "20px" }}>
              <Link style={{ color: "blue" }} to="">
                Email TARE
              </Link>
            </Typography>
            <br />
          </Grid>
        </Grid>

        <Grid
          style={{
            marginTop: "20px",
            width: "80%",
            textAlign: "left",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
          }}
          id="medicalserviceupdate"
        >
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#3f51b5",
              color: "white",
              width: "100%",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              height: "60px",
            }}
          >
            Medical Services Update
          </Typography>

          <Grid style={{ width: "90%" }}>
            <br />
            <Grid style={{ display: "flex" }}>
              <DoubleArrowIcon />
              <Typography style={{ textAlign: "justify" }}>
                Effective April 1, 2008 the Health and Human Services Commission
                (HHSC) implemented STAR Health, which is a health care program
                designed to better serve and coordinate medical services for
                children in Foster Care. Superior HealthPlan Network is
                contracted to provide medical care and services to children in
                the Texas Foster Care system. For a child enrolled in the STAR
                Health program, DFPS staff, caregivers and medical consenters
                can obtain information by calling STAR Health Member Services at
                1-866-912-6283 or using the STAR Health website at{" "}
                <Link
                  target="_blank"
                  style={{ color: "blue" }}
                  to="https://www.fostercaretx.com/"
                >
                  www.fostercaretx.com.
                </Link>
                .
              </Typography>
            </Grid>
            <br />
            <Typography style={{ display: "flex" }}>
              <DoubleArrowIcon />
              <Typography>
                More information on STAR Health may be found in
                <Link
                  target="_blank"
                  to="https://www.dfps.texas.gov/Child_Protection/Medical_Services/default.asp"
                  style={{
                    // color: "blue",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  A Guide to Medical Services at CPS.
                </Link>
              </Typography>
            </Typography>
            <br />
            <Grid style={{ display: "flex", gap: "5px" }}>
              <DoubleArrowIcon />
              <Typography>
                We will post regular updates about any Medicaid related news on
                this site. In addition, we recommend checking the{" "}
                <Link
                  style={{ color: "blue" }}
                  target="_blank"
                  to="https://www.hhs.texas.gov/services/health/medicaid-chip"
                >
                  {" "}
                  HHSC Medicaid web page
                </Link>{" "}
                to keep current on Medicaid developments.
              </Typography>
            </Grid>
            <br />
          </Grid>
        </Grid>

        <Grid
          style={{
            marginTop: "20px",
            width: "80%",
            textAlign: "center",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
          }}
          id="informationmeeting"
        >
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#3f51b5",
              color: "white",
              width: "100%",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              height: "60px",
            }}
          >
            Foster Care & Adoption Information Meetings
          </Typography>

          <Grid style={{ width: "90%" }}>
            <br />
            <Grid container
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "left",
                justifyContent: "center",
                
              }}
            >
              <Grid item lg={7}style={{}}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  How Can I Find a Meeting Near Me?
                </Typography>
                <br />
                <Typography>
                  To find details about upcoming meetings near you,
                  <span style={{ fontWeight: "bold" }}>
                    select your area on the Texas map
                  </span>
                  (see map on the left), or select your Texas county from the
                  pull-down menu.
                </Typography>
                <br />
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  What is the Cost to Attend?
                </Typography>
                <br />
                <Typography>
                  There is
                  <span style={{ fontWeight: "bold" }}>
                    no charge to attend
                  </span>
                  a foster care/adoption information meeting in your area.
                </Typography>
                <br />
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Do I need to make a reservation?
                </Typography>
                <br />
                <Typography>
                  <span style={{ fontWeight: "bold" }}>
                    No reservation is needed.
                  </span>
                  The foster care and adoption information meetings are open to
                  the public.
                </Typography>
                <br />
              </Grid>
              <Grid item lg={4} style={{display:'flex',justifyContent:'center'}}>
                <MapCard />
              </Grid>
            </Grid>
            <hr />
            <br />
            <Grid style={{ textAlign: "justify" }}>
              {" "}
              <Typography>
                <span style={{ fontWeight: "bold" }}>
                  Not a Texas Resident?
                </span>
                Visit your{" "}
                <Link style={{ color: "blue" }} to="/state">
                  State Adoption Website
                </Link>{" "}
                for information on how to start the process to become a foster
                or adoptive family. Texas can only place children with families
                from other states if they have a current
                <Link style={{ color: "blue" }} to="/homestudy">
                  {" "}
                  home study.
                </Link>{" "}
                You may also visit the national website at{" "}
                <Link
                  style={{ color: "blue" }}
                  target="_blank"
                  to="https://www.adoptuskids.org/"
                >
                  www.AdoptUSKids.org.
                </Link>
              </Typography>
              <br />
              <Typography>
                <span style={{ fontWeight: "bold" }}>Note:</span> U.S. families
                approved to adopt within their state and who have a current
                <Link style={{ color: "blue" }} to="/homestudy">
                  {" "}
                  home study
                </Link>{" "}
                may fill out the interest form directly on the child's
                photo/profile page within
                <Link style={{ color: "blue" }} to="/childrenwaitingfrm">
                  {" "}
                  Child Search.
                </Link>
              </Typography>
            </Grid>

            <br />
          </Grid>
        </Grid>

        <Grid
          style={{
            marginTop: "20px",
            width: "80%",
            textAlign: "left",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
          }}
          id="placementschildren"
        >
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#3f51b5",
              color: "white",
              width: "100%",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              // height: "60px",
              textAlign: "center",
            }}
          >
            Placement of DFPS Children Outside of the United States
          </Typography>
          <Grid
            style={{ width: "90%", paddingTop: "20px", textAlign: "justify" }}
          >
            <Typography>
              The Texas Department of Family and Protective Services (DFPS)
              policy on placing children for adoption outside the country is in{" "}
              <Link
                style={{ color: "blue" }}
                target="_blank"
                to="https://www.dfps.texas.gov/handbooks/CPS/Files/CPS_pg_6900.asp#CPS_6947"
              >
                Section 6947
              </Link>{" "}
              of the Child Protective Services Handbook.
            </Typography>
            <br />
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              6947 Making Placements In Other Countries
            </Typography>
            <br />
            <Typography>
              A child should not be placed in an adoptive placement outside of
              the United States unless there is a prior relationship between the
              child and family. Making placements out of the country has
              implications for a child’s eligibility for state and federal
              support services.
            </Typography>
            <br />
            <Typography>
              When considering a family who is either living out of the country
              or who will be moving out of the country during the supervision
              period, the caseworker or supervisor must determine the extent of
              the prior relationship with the prospective adoptive family.
            </Typography>
            <br />
            <Typography>
              If the caseworker or supervisor determines that the child has a
              significant relationship with the prospective adoptive family and
              it is in the best interest of the child to continue that
              relationship with the adoptive family, then the caseworker:
            </Typography>
            <br />
            <Typography>
              <ul style={{ lineHeight: "1.8" }}>
                <li>
                  Informs the prospective adoptive parents of the limitations
                  for medical assistance and other support services to the child
                  and determines how the child’s medical needs will be met when
                  the child moves;
                </li>
                <li>
                  Requires the family to arrange for supervision of the adoption
                  (such as with a military social caseworker if the family lives
                  on a base);
                </li>
                <li>
                  Obtains information regarding the country where the family
                  will be living and services available to the child and family
                  to meet the child’s short and long term needs; and
                </li>
                <li>
                  Requests written approval from the deputy director for
                  Protective Services for Families and Children. If this
                  approval is obtained, the caseworker:
                </li>
                <li>
                  Obtains written approval from the court with jurisdiction over
                  the child; and
                </li>
                <li>
                  Informs Interstate Compact staff of the child’s move out of
                  the country.
                </li>
              </ul>
            </Typography>
            <br />
          </Grid>
        </Grid>
        <Grid
          style={{
            marginTop: "20px",
            width: "80%",
            textAlign: "left",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
          }}
          id="adoptionpartners"
        >
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#3f51b5",
              color: "white",
              width: "100%",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              height: "60px",
            }}
          >
            Adoption Partners
          </Typography>
          <Grid
            style={{ width: "90%", paddingTop: "20px", textAlign: "justify" }}
          >
            <Typography>
              <a style={{ color: "blue" }}  href="/partners">
                Private Adoption Agencies
              </a>
            </Typography>
            <br />
            <Typography>
              Private adoption agencies work in partnership with the Texas
              Department of Family and Protective Services (DFPS) to find
              adoptive homes for children. Find adoption agencies in your area.
            </Typography>
            <br />
            <Typography>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://www.dfps.texas.gov/Community/Faith-Based/default.asp"
              >
                DFPS Faith-Based Program
              </a>
            </Typography>
            <br />
            <Typography>
              Sometimes, it takes a congregation to raise a child. The
              Department of Family and Protective Services is asking your faith
              community to support foster children and help children seeking
              adoption to find their "forever family."
            </Typography>
            <br />
            <Typography>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://gtcp.org/"
              >
                Greater Texas Community Partners (GTCP)
              </a>
            </Typography>
            <br />
            <Typography>
              Each year over 100,000 abused and neglected children in Texas need
              your help. Together with GTCP, you can help.
            </Typography>
            <br />
            <Typography fontWeight="bold">
              Public Service Announcement
            </Typography>
            <br />
            <Typography>
              Watch{" "}
              <a style={{ color: "blue" }} href={lubbock}>
                Open Your Eyes
              </a>
              , a short Windows Media video encouraging fostering and adotion.
            </Typography>
          </Grid>
          <br />
        </Grid>
      </Grid>
    </div>
  );
};
export default Placement;
