import React, { useEffect, Fragment } from "react";
import {
  Typography,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import ImageSlider from "../../layout/imageslider";
import articlesInfo from "./articlesInfoContent";
import BreadcrumbMenu from "../../layout/BreadcrumbMenu";
import "./articlesinfo.css"
import { useNavigate } from "react-router-dom";
const ArticlesInfoView = () => {
  const navigate = useNavigate(); // Use useNavigate

  const handleLearnMoreLink = (redirectRoute) => {
    navigate(redirectRoute); // Use navigate for redirection
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div align="center" style={{ flexGrow: 1,}}>
      {/* <ImageSlider /> */}
      <Grid id="maingrid" style={{padding:'20px'}}>
        <Grow in={true} timeout={1500}>
          <Grid container style={{ color: "#1A355D", padding: "10px 0" }}>
            <Grid item xs={12}>
              <BreadcrumbMenu
                currComp="Articles And Information"
                parentComp="Home"
                parentCompRoute="/"
              />
            </Grid>
            <Grid item xs={12} style={{ }}>
              <Typography variant="h5">Articles and Information</Typography>
            </Grid>
          </Grid>
        </Grow>
        <Grow in={true} timeout={1500}>
          <Typography
            variant="body1"
            style={{ color: "#1A355D", padding: "10px 0" }}
          >
            {articlesInfo.desc}
          </Typography>
        </Grow>
        <Grow in={true} timeout={1500}>
          <Grid
            container
            style={{
              backgroundColor: "white",
              color: "#1A355D",
              padding: "10px",
              boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
              marginBottom: "20px",
            }}
          >
            {articlesInfo.contents.map((item) => (
              <Fragment key={item.key}>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "0 16px",
                    color: "#d04e53",
                    textAlign: "left",
                  }}
                >
                  <Typography variant="h6">{item.label}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <List component="div" aria-label="svc content">
                    <ListItem component="div">
                      <ListItemText primary={item.value} style={{textAlign:'justify',}} />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} style={{ padding: "10px 0" }}>
                  <Button
                    size="small"
                    color="inherit"
                    onClick={() => handleLearnMoreLink(item.linkRoute)}
                    style={{
                      color: "white",
                      cursor: "pointer",
                      backgroundColor: "#22477E",
                    }}
                  >
                    Learn More
                  </Button>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grow>
      </Grid>
    </div>
  );
};

export default ArticlesInfoView;
