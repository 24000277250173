import { Typography, Grid, Card } from "@mui/material";
import react from "react";
import { Link } from "react-router-dom";
import ImageSlider from "../../layout/imageslider";
import Pic1 from "../../fostercareOverview/images/pic1.jpg";
import Pic2 from "../../fostercareOverview/images/pic2.jpg";
import Pic3 from "../../fostercareOverview/images/pic3.jpg";

import video1 from "../../fostercareOverview/videos/teaPartySp.wmv";
import video2 from "../../fostercareOverview/videos/ribbonSp.wmv";
import video3 from "../../fostercareOverview/videos/soccerSp.wmv";
import Spnpsa from "../../fostercareOverview/videos/spnpsa.ram";
import Video4 from "../../fostercareOverview/videos/EnBuenasManos.wmv";
import Spanish2 from "./spanish2";
import "./spanish1,2.css"

const Spanish1 = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{
          display: "flex",
          // flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "80%",
            // display: "flex",
            // justifyContent: "center",

            padding: "10px",

            fontSize: "15px",
          }}
        >
          <Grid style={{ width: "100%",  }}>
            <Grid>
              <Grid>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    color: "#3f51b5",
                  }}
                >
                  Vea anuncios de interés público en video
                </Typography>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  gap: "50px",
                  padding: "30px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Card
                    style={{
                      padding: "10px",
                      border: "2px solid black",
                      width: "200px",
                    }}
                  >
                    <img src={Pic1} />
                    <p>
                      <a href={video1} style={{ color: "blue" }}>
                        Tomar té con mami
                      </a>
                    </p>
                  </Card>
                </Grid>
                <Grid item>
                  <Card
                    style={{
                      padding: "10px",
                      border: "2px solid black",
                      width: "200px",
                    }}
                  >
                    <img src={Pic2} />
                    <p>
                      <a href={video2} style={{ color: "blue" }}>
                        Cinta Azul
                      </a>
                    </p>
                  </Card>
                </Grid>
                <Grid item>
                  <Card
                    style={{
                      padding: "10px",
                      border: "2px solid black",
                      width: "200px",
                    }}
                  >
                    <img src={Pic3} />
                    <p>
                      <a href={video3} style={{ color: "blue" }}>
                        Jugar al fútbol con papá
                      </a>
                    </p>
                  </Card>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <Grid item>
                  <Typography textAlign="left">
                    Vea un video de 12 minutos sobre la adopción.
                    <br /> El título de el video es{" "}
                    <a href={Video4} style={{ color: "blue" }}>
                      "En Buenas Manos"
                    </a>
                    .
                    <br /> Por favor espere 20 segundos para empezar
                    <br />
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    textAlign: "left",
                    border: " 2px solid #3f51b5",
                  }}
                >
                  <ul style={{ lineHeight: "1.6" }}>
                    <li>
                      - algunos documentos estan en PDF{" "}
                      <Link to="/adobe" style={{ color: "blue" }}>
                        Adobe Acróbat -
                      </Link>
                    </li>
                    <li>
                      Vea a los{" "}
                      <Link to="/childrenwaitingfrm" style={{ color: "blue" }}>
                        Ninos Esperando la Adopción Aqui
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Grid>
              <br />
            </Grid>

            <Grid id="section1" style={{ textAlign: "left" }}>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "#3f51b5" }}
              >
                Documentos en Español
              </Typography>
              <Typography>
                <ul style={{ lineHeight: "1.6" }}>
                  <li>
                    <Link to="/spanish2" style={{ color: "blue" }}>
                      El Cuidado Temporal y la Adopción en Texas
                    </Link>
                  </li>
                  <li>
                    <a href={Spnpsa} style={{ color: "blue" }}>
                      "En Buenas Manos" annuncio de servicio público
                    </a>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.dfps.texas.gov/Espanol/default.asp"
                      style={{ color: "blue" }}
                    >
                      Otros Documentos del DFPS en Español
                    </Link>
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid style={{ textAlign: "left" }} id="section2">
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "#3f51b5" }}
              >
                Otros sitios de la Red que Usted puede visitar
              </Typography>
              <ul style={{ textAlign: "justify", lineHeight: "1.6" }}>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.childwelfare.gov/pubpdfs/empiezo.pdf"
                    style={{ color: "blue" }}
                  >
                    Adopción: Dónde empiezo?
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://cwig-prod-prod-drupal-s3fs-us-east-1.s3.amazonaws.com/public/documents/opciones.pdf"
                    style={{ color: "blue" }}
                  >
                    Opciones Para la Adopción: Una Hoja Informativa Para
                    Familias
                  </Link>
                </li>
                <li>
                  Un Vistazo a Las Opciones de La Adopción: Una Guía de
                  Orientación Para Las Familias
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://adoptuskids.org/para-familias"
                    style={{ color: "blue" }}
                  >
                    https://adoptuskids.org/para-familias
                  </Link>
                </li>
              </ul>
              <Typography textAlign="justify">
                El sitio web adoptuskids.org/para-familias es la versión en
                español de AdoptUSKids.org. Este sitio web es solamente una
                parte de una <br />
                colaboración desarrollada para reclutar familias para niños
                estadunidenses en espera a la adopción.
              </Typography>
            </Grid>
            <br />
            <Grid style={{ textAlign: "justify" }}>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "#3f51b5" }}
                id="section3"
              >
                Liñeas de Emergencia
              </Typography>
              <br />
              <Typography>
                Usted puede llamar estas liñeas de emergencia para reportar el
                maltrato o descuido de ninos o adultos o para obtener mas
                informacion:
              </Typography>
              <br />
              <Typography>
                <span style={{ fontWeight: "bold" }}>Texas Abuse Hotline</span>
                <br /> 1-800-252-5400 <br /> Línea directa de 24 horas para
                denunciar sospechas de maltrato o descuido de niños. Es un
                servicio del Departamento de Servicios de Regulación y <br />
                Protección de Texas, el departamento de bienestar del menor del
                Estado.Si Usted vive en otro estado puede usar el telefono (512)
                834-3784
              </Typography>
              <br />
              <Typography>
                <span style={{ fontWeight: "bold" }}>
                  Foster Care and Adoption Inquiry Line:
                </span>
                1-800-233-3405
              </Typography>
              <br />
              <Typography>
                Línea directa para informacion de la adopcion y de familias
                temporales. Este programa es un servicio del Departamento de
                Servicios de Regulación y <br /> Protección de Texas. <br />8
                a.m. to 5 p.m. Central Time, Lunes a Viernes
              </Typography>
              <br />
              <Typography>
                <Link
                  target="_blank"
                  to="https://www.hhs.texas.gov/services/safety/child-care"
                  style={{ color: "blue" }}
                >
                  Child Care Information
                </Link>
                <br />
                <br />
                1-800-862-5252
                <br /> Para la información sobre cuidado de los niño en Texas. 8
                a.m. to 5 p.m. Central Time, Lunes a Viernes
              </Typography>
              <br />
              <Typography>
                <Link
                  target="_blank"
                  to="https://www.dfps.texas.gov/Youth-Helpline/default.asp"
                  style={{ color: "blue" }}
                >
                  Texas Youth Helpline
                </Link>
                <br />
                <br /> 1-800-989-6884 <br />
                Para proveer consejo al joven fugado de casa y para su familia y
                tambien para los conflictos de la familia, la delincuencia,
                truancy, maltrato y <br /> negligencia. 24 horas al dia 7 dias a
                la semana
              </Typography>
              <br />
              <Typography>
                <span style={{ fontWeight: "bold" }}>
                  Abuse in MHMR Facilities
                </span>
                <br /> 1-800-647-7418
                <br /> Para denunciar sospechas de maltrato o descuido en MHMR
                facilidades. 24 horas al dia 7 dias a la semana
              </Typography>
              <br />
              <Typography>
                Traducción es cortesía de{" "}
                <Link
                  target="_blank"
                  to="http://babel.altavista.com/"
                  style={{ color: "blue" }}
                >
                  Babel Fish
                </Link>{" "}
                y{" "}
                <Link
                  target="_blank"
                  to="https://www.systransoft.com/"
                  style={{ color: "blue" }}
                >
                  SYSTRAN
                </Link>{" "}
                como servicio gratis y no es endosada por DFPS. La exactitud de
                la traducción no está garantizada.
                <br /> Lea por favor los FAQ's de la traducción para estar
                enterado de las limitaciones y de otra información sobre él uso.
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Spanish1;
