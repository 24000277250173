import React from "react";
import ImageSlider from "../../layout/imageslider";
import { Grid, Typography, Card, CardContent, CardHeader } from "@mui/material";
import VerticalTabs1 from "./overview_verticaltab";
import "./verticaltab.css";

const Overview = () => {
  return (
    <div id="maindiv" >
      {/* <ImageSlider /> */}
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            backgroundColor: "white",
            width: "80%",
          }}        >
          <Grid style={{ paddingTop: "20px", textAlign: "left" }}>
            <Typography
              variant="h5"
              style={{
                backgroundColor: "#3f51b5",
                textAlign: "center",
                color: "white",
                padding: "5px",
              }}
            >
              Overview
            </Typography>
            <br/>
            <Typography
              style={{
                fontStyle: "bold",
                textAlign: "justify",
                lineHeight: "1.7",
                fontWeight: "450",
                fontSize: "1rem",
                padding: "10px",
              }}
            >
              &ensp; &ensp; The protection and nurturance of children is a
              paramount concern of The Texas Department of Family and Protective
              Services (TDFPS). As such, the recruitment, development, and
              maintenance of foster and adoptive homes for children in TDFPS
              managing conservatorship is an ongoing process. This is a brief
              overview of the TDFPS substitute placement programs commonly
              referred to as foster care and adoptive care.
            </Typography>
            <br />
          </Grid>
          {/* <Grid item sm={12} lg={6} style={{ padding: "20px" }}>
                        <Box sx={{maxWidth: 600, backgroundColor: 'pink', display: 'flex', justifyContent: 'center', alignContent: 'center', margin: 'auto', border: '5px solid black'}}>
                        <Card >
                            <CardHeader style={{ backgroundColor: 'rgb(53, 103, 178)' }}></CardHeader>
                            <CardContent style={{ textAlign: 'left' }}>
                                <Typography
                                    style={{ fontSize: 20, textAlign: 'left' }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    <b>On This Page</b>
                                </Typography>
                                <Typography style={{ lineHeight: '1.7' }}>
                                    <ul>
                                        <li><a href="" style={{textDecoration:'none',color:'blue'}}>Recruitment</a></li>
                                        <li><a href="" style={{textDecoration:'none',color:'blue'}}>Certification Process</a></li>
                                        <li><a href="" style={{textDecoration:'none',color:'blue'}}>Certifications</a></li>
                                        <li><a href="" style={{textDecoration:'none',color:'blue'}}>Foster Care Reimbursements</a></li>
                                        <li><a href="" style={{textDecoration:'none',color:'blue'}}>Training and Support</a></li>
                                        <li><a href="" style={{textDecoration:'none',color:'blue'}}>Adoption worker’s support responsibilities</a></li>
                                    </ul>
                                </Typography>
                            </CardContent>
                        </Card>
                        </Box>

                    </Grid> */}

          <Grid style={{ width: "100%" }}>
            <VerticalTabs1 />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;
