const counties = () => {
  return {
    result: [
      "Anderson",
      "Andrews",
      "Angelina",
      "Aransas",
      "Archer",
      "Armstrong",
      "Atascosa",
      "Austin",
      "Bailey",
      "Bandera",
      "Bastrop",
      "Baylor",
      "Bee",
      "Bell",
      "Bexar",
      "Blanco",
      "Borden",
      "Bosque",
      "Bowie",
      "Brazoria",
      "Brazos",
      "Brewster",
      "Briscoe",
      "Brooks",
      "Brown",
      "Burleson",
      "Burnet",
      "Caldwell",
      "Calhoun",
      "Callahan",
      "Cameron",
      "Camp",
      "Carson",
      "Cass",
      "Castro",
      "Chambers",
      "Cherokee",
      "Childress",
      "Clay",
      "Cochran",
      "Coke",
      "Coleman",
      "Collin",
      "Collingsworth",
      "Colorado",
      "Comal",
      "Comanche",
      "Concho",
      "Cooke",
      "Coryell",
      "Cottle",
      "Crane",
      "Crockett",
      "Crosby",
      "Culberson",
      "Dallam",
      "Dallas",
      "Dawson",
      "Deaf Smith",
      "Delta",
      "Denton",
      "Dewitt",
      "Dickens",
      "Dimmit",
      "Donley",
      "Duval",
      "Eastland",
      "Ector",
      "Edwards",
      "El Paso",
      "Ellis",
      "Erath",
      "Falls",
      "Fannin",
      "Fayette",
      "Fisher",
      "Foard",
      "Fort Bend",
      "Franklin",
      "Freestone",
      "Frio",
      "Gaines",
      "Galveston",
      "Garza",
      "Gillespie",
      "Glasscock",
      "Goliad",
      "Gonzales",
      "Gray",
      "Grayson",
      "Gregg",
      "Grimes",
      "Guadalupe",
      "Hale",
      "Hall",
      "Hamilton",
      "Hansford",
      "Hardeman",
      "Hardin",
      "Harris",
      "Harrison",
      "Hartley",
      "Haskell",
      "Hays",
      "Hemphill",
      "Henderson",
      "Hidalgo",
      "Hill",
      "Hockley",
      "Hood",
      "Hopkins",
      "Houston",
      "Howard",
      "Hudspeth",
      "Hunt",
      "Hutchinson",
      "Irion",
      "Jack",
      "Jackson",
      "Jasper",
      "Jeff Davis",
      "Jefferson",
      "Jim Hogg",
      "Jim Wells",
      "Johnson",
      "Jones",
      "Karnes",
      "Kaufman",
      "Kendall",
      "Kenedy",
      "Kent",
      "Kerr",
      "Kimble",
      "King",
      "Kinney",
      "Kleberg",
      "Knox",
      "LaSalle",
      "Lamar",
      "Lampasas",
      "Lavaca",
      "Lee",
      "Leon",
      "Liberty",
      "Limestone",
      "Lipscomb",
      "Live Oak",
      "Llano",
      "Loving",
      "Lubbock",
      "Lynn",
      "Madison",
      "Marion",
      "Martin",
      "Mason",
      "Matagorda",
      "Maverick",
      "McMullen",
      "Mcculloch",
      "Mclennan",
      "Medina",
      "Menard",
      "Midland",
      "Milam",
      "Mills",
      "Mitchell",
      "Montague",
      "Montgomery",
      "Moore",
      "Morris",
      "Nacogdoches",
      "Navarro",
      "Newton",
      "Nolan",
      "Nueces",
      "Ochiltree",
      "Oldham",
      "Orange",
      "Palo Pinto",
      "Panola",
      "Parker",
      "Parmer",
      "Pecos",
      "Polk",
      "Potter",
      "Presidio",
      "Rains",
      "Randall",
      "Reagan",
      "Real",
      "Red River",
      "Reeves",
      "Refugio",
      "Roberts",
      "Robertson",
      "Rockwall",
      "Runnels",
      "Rusk",
      "Sabine",
      "Sage",
      "San Augustine",
      "San Jacinto",
      "San Patricio",
      "San Saba",
      "Schleicher",
      "Scurry",
      "Shackelford",
      "Shelby",
      "Sherman",
      "Smith",
      "Somervell",
      "Starr",
      "Stephens",
      "Sterling",
      "Stonewall",
      "Sutton",
      "Swisher",
      "Tarrant",
      "Taylor",
      "Terrell",
      "Terry",
      "Throckmorton",
      "Titus",
      "Tom Green",
      "Travis",
      "Trinity",
      "Tyler",
      "Upshur",
      "Upton",
      "Uvalde",
      "Val Verde",
      "Van Zandt",
      "Victoria",
      "Walker",
      "Waller",
      "Ward",
      "Washington",
      "Webb",
      "Wharton",
      "Wheeler",
      "Wichita",
      "Wilbarger",
      "Willacy",
      "Williamson",
      "Wilson",
      "Winkler",
      "Wise",
      "Wood",
      "Woods",
      "Yoakum",
      "Young",
      "Zapata",
      "Zavala",
    ],
    status: true,
  };
};

export default counties;
