import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ImageSlider from "../layout/imageslider";
import { Grid, Typography } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(No, Description, Action, Url) {
  return { No, Description, Action, Url };
}

const rows = [
  createData(
    1,
    "Post Adoption Services",
    "More Info...",
    "https://www.dfps.texas.gov/Child_Protection/Adoption/adoption_support.asp"
  ),
  createData(
    2,
    "Council on Adoptable Children of Texas",
    "More Info...",
    "https://www.nacac.org/help/adoption-assistance/adoption-assistance-us/state-programs/texas-state-adoption-assistance-program/"
  ),
  createData(
    3,
    "Texas Foster Family Association (TFFA)",
    "More Info...",
    "http://www.tffa.org"
  ),
  createData(
    4,
    "Texas Council of Child Welfare Boards",
    "More Info...",
    "http://www.tccwb.org"
  ),
  createData(
    5,
    "Texas Family Code",
    "More Info...",
    "http://www.statutes.legis.state.tx.us/?link=FA"
  ),
  createData(
    6,
    "Texas Administrative Code (Adoption Assistance)",
    "More Info...",
    "http://texreg.sos.state.tx.us/public/readtac$ext.ViewTAC?tac_view=5&amp;ti=40&amp;pt=19&amp;ch=700&amp;sch=H"
  ),
  createData(
    7,
    "Childrens Health Insurance Program (CHIP)",
    "More Info...",
    "https://hhs.texas.gov/services/health/medicaid-chip/programs/chip"
  ),
  createData(
    8,
    "Texas Health Steps, Services and Providers",
    "More Info...",
    "http://www.dshs.texas.gov/thsteps/default.shtm"
  ),
  createData(
    9,
    "Medicaid Update",
    "More Info...",
    "https://hhs.texas.gov/services/health/medicaid-chip"
  ),
];

function createData1(No, Description, Action, Url) {
  return { No, Description, Action, Url };
}

const table2 = [
  createData1(
    1,
    "Child Welfare Information Gateway - Adoption",
    "More Info...",
    "http://www.childwelfare.gov/adoption/index.cfm"
  ),
  createData1(
    2,
    "Casey Family Programs",
    "More Info...",
    "http://casey.org/index.htm"
  ),
  createData1(
    3,
    "The Collaboration to AdoptUSKids",
    "More Info...",
    "http://www.adoptuskids.org"
  ),
  createData1(
    4,
    "North American Council on Adoptable Children",
    "More Info...",
    "http://www.nacac.org"
  ),
  createData1(
    5,
    "National Foster Parent Association (NFPA)",
    "More Info...",
    "http://nfpaonline.org/"
  ),
  createData1(
    6,
    "National Adoption Center (NAC)",
    "More Info...",
    "http://www.adopt.org"
  ),
  createData1(
    7,
    "Child Welfare League of America (CWLA)",
    "More Info...",
    "http://www.cwla.org/"
  ),
  createData1(
    8,
    "Laws Related to Adoption",
    "More Info...",
    "https://www.childwelfare.gov/topics/adoption/laws/"
  ),
  createData1(
    9,
    "Fostering Families Today-Resource for Families and Professionals",
    "More Info...",
    "http://www.fosteringfamiliestoday.com"
  ),
  createData(
    10,
    "Northwest Adoption Exchange",
    "More Info...",
    "https://www.nwae.org/"
  ),
];

const HelpfulResources = () => {
  return (
    <div align="center" style={{width:"100%"}}>
      {/* <ImageSlider /> */}
      <Grid style={{ padding: "20px" }}>
        <Typography variant="h4" style={{ color: "rgb(26, 53, 93)" }}>
          <b>Helpful Resources</b>
        </Typography>
      </Grid>
      <Grid
        align="center"
        style={{backgroundColor:'white',  width: "80%", paddingBottom: "30px" }}
      >
        <Grid style={{ width: "90%" }}>
          <Typography
            variant="h6"
            style={{ padding: "20px", color: "rgb(26, 53, 93)" }}
          >
            <b>Helpful Texas Web Sites</b>
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="customized table">
              <TableHead style={{ backgroundColor: "lightblue" }}>
                <TableRow>
                  <StyledTableCell style={{ color: "rgb(26, 53, 93)" }}>
                    <b>No</b>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "rgb(26, 53, 93)" }}
                  >
                    <b>Description</b>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "rgb(26, 53, 93)" }}
                  >
                    <b>Action</b>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.No}>
                    <StyledTableCell component="th" scope="row">
                      {row.No}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.Description}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">{row.Action}</StyledTableCell> */}
                    <StyledTableCell align="center">
                      <a
                        href={row.Url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        {row.Action}
                      </a>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid style={{ width: "90%" }}>
          <Typography
            variant="h6"
            style={{ padding: "20px", color: "rgb(26, 53, 93)" }}
          >
            <b>Helpful National Web Sites</b>
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="customized table">
              <TableHead style={{ backgroundColor: "lightblue" }}>
                <TableRow>
                  <StyledTableCell style={{ color: "rgb(26, 53, 93)" }}>
                    <b>No</b>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "rgb(26, 53, 93)" }}
                  >
                    <b>Description</b>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "rgb(26, 53, 93)" }}
                  >
                    <b>Action</b>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table2.map((row2) => (
                  <StyledTableRow key={row2.No}>
                    <StyledTableCell component="th" scope="row">
                      {row2.No}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row2.Description}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">{row2.Action}</StyledTableCell> */}
                    <StyledTableCell align="center">
                      <a
                        href={row2.Url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        {row2.Action}
                      </a>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default HelpfulResources;
