import { Grid, Typography } from "@mui/material";
import ImageSlider from "../../layout/imageslider";
// import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
// import { Link } from "react-router-dom";
import manos from "../../../assets/newimages/manos (1).jpg";
import girl from "../../../assets/newimages/girl (1).gif";
import BasicAccordion from "./spanishaccordion";
import pdf from "../../../assets/newimages/spnpsa.ram";
import Spnpsa from "../../fostercareOverview/videos/spnpsa.ram";
import "./spanish1,2.css"
const Spanish2 = () => {
  return (
    <div align="center">
      {/* <ImageSlider /> */}
      <Grid id="maingrid"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid 
          style={{
            // width: "80%",
            padding: "20px",
            backgroundColor: "white",
            textAlign: "left",
         
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: "#3f51b5",
              textDecoration: "underline",
            }}
          >
            El Cuidado Temporal y la Adopción :-
          </Typography>
          {/* <Grid>
                        
                        <ul style={{textAlign: 'left', fontSize: '15px',lineHeight: '2'}}>
                            <li ><a  style= {{color:"blue"}} href="#grid1">¿Por qué se coloca a un niño en un hogar temporal o adoptivo?</a></li>
                            <li><a  style= {{color:"blue"}} href="#grid2">¿Cómo puede ser mi familia una familia temporal o adoptiva?</a></li>
                            <li><a  style= {{color:"blue"}} href="#grid3">Responsabilidades</a></li>
                            <li><a  style= {{color:"blue"}} href="#grid4">Requisitos básicos para la familia temporal o adoptiva</a></li>
                            <li><a  style= {{color:"blue"}} href="#grid5">Reembolso por el cuidado tempora</a></li>
                            <li><a  style= {{color:"blue"}} href="#grid6">Subsidios para la adopción</a></li>
                            <li><a  style= {{color:"blue"}} href="#grid7">¿Puede adoptar una familia temporal?</a></li>
                            <li><a  style= {{color:"blue"}} href="#grid8">Contactos</a> </li>
                            <li><a  style= {{color:"blue"}} href="">Otros documentos en Español</a></li>
                        </ul>
                    <Typography variant="body"><a  style= {{color:"blue"}}rrowLeftIcon /><Link to=""> Otros documentos en Español</Link></Typography>
                    </Grid> */}
          <Grid style={{ paddingTop: "10px" }}>
            <Typography
              variant="h6"
              style={{
                color: "#3f51b5",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Los padres temporales y adoptivos de Tejas hablan
            </Typography>
            {/* <table cellSpacing="2" cellPadding="5">
              <tbody>
                <tr>
                  <td>
                    <strong>
                    </strong>
                  </td>
                  <td>
                    <p>
                     
                    </p>
                    
                    
                  </td>
                </tr>
              </tbody>
            </table> */}
            <Grid container style={{display:"flex",justifyContent:'center',alignItems:"center",gap:'20px'}}>
              <Grid item>
                <img
                  src={manos}
                  alt="Photo de una madre adoptiva y su hija"
                  width="158"
                  height="117"
                  border="0"
                />
              </Grid>
              <Grid item>
                <a style={{ color: "blue" }} href={pdf}></a>
                <a style={{ color: "blue" }} href={Spnpsa}>
                  "En Buenas Manos" annuncio de servicio público
                </a>
                <a
                  style={{ color: "blue" }}
                  href="http://www.real.com/realoneplayer.html?pp=home&amp;src=011403realhome_2"
                >
                  RealOne Player.
                </a>
                <p>Se necesita &nbsp; </p>
                <p>
                  {" "}
                  Busque las palabras{" "}
                  <strong>"Download the Free RealOne Player"</strong>
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid id="grid1" style={{ lineHeight: "1.6" }}>
            <Typography
              variant="h5"
              style={{
                color: "#3f51b5",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontWeight: "bold",
                textAlign: "justify",
              }}
            >
              ¿Por qué se coloca a un niño en un hogar temporal o adoptivo?
            </Typography>
            <Typography style={{ textAlign: "justify" }}>
              &ensp; El niño se coloca en un hogar adoptivo si no se logra
              devolverlo a su familia biológica. El niño cuyos padres perdieron
              legalmente sus derechos paternales puede ser adoptado por
              familiares, familias temporales o familias adoptivas.
            </Typography>
            <br />
            <Typography style={{ textAlign: "justify" }} s>
              &ensp; El niño se coloca en un hogar temporal porque ha sido
              separado de su propia familia debido a maltrato, descuido o a
              otros problemas familiares que ponen en peligro su seguridad. La
              edad del niño puede variar de bebé a los 18 años. Este niño puede
              tener necesidades especiales médicas, físicas o emocionales. Puede
              ser de cualquier origen étnico o raza y formar parte de un grupo
              de hermanos que necesitan ser colocados juntos.
            </Typography>
          </Grid>
          <Grid id="grid2">
            <Typography
              variant="h5"
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                color: "#3f51b5",
                fontWeight: "bold",
              }}
            >
              ¿Cómo puede ser mi familia una familia temporal o adoptiva?
            </Typography>
            <Grid
              style={{
                display: "flex",
                lineHeight: "1.6",
                // textAlign: "justify",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Grid>
                <Typography>
                  <b>Primer paso:</b> Reuniones informativas
                </Typography>
                <Grid style={{ textAlign: "justify" }}>
                  <img
                    src={girl}
                    alt="arte de nino"
                    //   width="72"
                    //   height="107"

                    style={{ float: "right" }}
                  />
                  <Typography style={{ textAlign: "justify" }}>
                    Usted deberá ir a una reunión informativa en su área donde
                    podrá hablar sobre lo que significa y lo que requiere ser
                    padre temporal o adoptivo. Recibirá información básica y
                    podrá hacer preguntas. La oficina local de DFPS le dará esta
                    información si no hay este tipo de reuniones en su área.
                  </Typography>
                </Grid>
              </Grid>
              <Grid></Grid>
            </Grid>
            <Typography>
              <b>Segundo paso:</b> Preparación y selección
            </Typography>
            <Grid textAlign={{ textAlign: "justify" }}>
              {" "}
              <Typography>
                Si usted llena los requisitos básicos, se le invita a reunirse
                con el personal de DFPS para decidir qué le conviene más a su
                familia el cuidado temporal o la adopción. El personal de DFPS
                también le hará una valoración. Este proceso le permite recibir
                información sobre DFPS y los niños que llegan al sistema de
                cuidado temporal.
              </Typography>
              <Typography style={{ paddingTop: "15px" }}>
                <b>Tercer paso:</b> Entrenamiento
              </Typography>
              <Typography>
                Usted recibirá entrenamiento para aprender más sobre los niños
                disponibles por medio de DFPS y para valorar sus puntos fuertes
                en la crianza de niños. Las clases también aumentan su
                conocimiento y la confianza en sí mismo para enfrentar el reto
                de cuidar a un niño en su hogar. Usted se dará cuenta si está
                listo para comprometerse.
              </Typography>
              <Typography style={{ paddingTop: "15px" }}>
                <b>Cuarto paso:</b> Estudio del hogar
              </Typography>
              <Typography>
                Un trabajador de casos le visitará en su casa. El propósito de
                la visita es hablar sobre su pasado, los intereses y el estilo
                de vida de la familia, su experiencia en el cuidado de niños,
                los niños que usted cree se adaptarían mejor en su casa y sus
                cualidades y habilidad para satisfacer las necesidades del niño.
              </Typography>
            </Grid>
          </Grid>

          <Grid style={{ paddingTop: "30px" }}>
            <BasicAccordion />
          </Grid>
          {/* </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Spanish2;
