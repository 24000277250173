import ImageSlider from "../../layout/imageslider";
import { Divider, Grid, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import fas1pdf from "../../fostercareOverview/videos/fas-1 (1).pdf";
import fas2pdf from "../../fostercareOverview/videos/fas-2.pdf";
import "./fetal_alcohol.css";
const FetalAlcohol = () => {
  return (
    <div align="center">
      {/* <ImageSlider /> */}
      <Grid
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          id="maingrid"
          container
          xs={12}
          sm={12}
          md={10}
          lg={10}
          style={{
            backgroundColor: "white",
            width: "80%",
            textAlign: "justify",
            display: "flex",
            padding: "20px",
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              width: "100%",
            }}
          >
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>A closer look at Fetal Alcohol Syndrome</b>
            </Typography>
            <br />
            <Typography>
              <em>
                More...{" "}
                <a target="_blank" href="http://www.nofas.org/faqs.aspx">
                  National Organization on Fetal Alcohol Syndrome
                </a>
              </em>
            </Typography>
            <br />
          </Grid>
          <Grid
            container
            id="table1"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            {/* <TableContainer component={Paper} style={{border: "none",}}>
                        <Table >
                            <TableBody>
                                <TableRow style={{wordBreak: 'break-word'}}>
                                    <TableCell >
                                        <ul>
                                            <li><a href="#neuro"> What are Alcohol-Related Neurodevelopmental Disorders?</a></li>
                                            <li><a href="#primary"> What are the Primary Characteristics?</a></li>
                                            <li><a href="#occur"> How often do FAS, ARND and ARBD Occur?</a></li>
                                            <li><a href="#FAS"> Identifying Fetal Alcohol Syndrome (FAS)</a></li>
                                            <li><a href="#behaviorchar"> Behavioral Characteristics</a></li>
                                            <li><a href="#diff"> Learning Difficulties Related to FAS, ARND and ARBD</a></li>
                                        </ul>
                                    </TableCell>
                                    <TableCell>
                                        <ul>
                                            <li><a href="#behavior"> Behavior</a></li>
                                            <li><a href="#skill"> Inappropriate Social Skills Related to FAS/FAE</a></li>
                                            <li><a href="#educational"> Educational Needs</a></li>
                                            <li><a href="#strategies"> Strategies for FAS Parents and Caregivers</a></li>
                                            <li><a href="#advocacy"> The Need for Advocacy</a></li>
                                            <li><a href="#sources"> Sources</a></li>
                                            <li><a href="#resources"> Resources for FAS caregivers</a></li>
                                        </ul>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ textAlign: "left" }}
            >
              <ul style={{ listStyle: "1.6" }}>
                <li>
                  <a href="#neuro">
                    {" "}
                    What are Alcohol-Related Neurodevelopmental Disorders?
                  </a>
                </li>
                <li>
                  <a href="#primary"> What are the Primary Characteristics?</a>
                </li>
                <li>
                  <a href="#occur"> How often do FAS, ARND and ARBD Occur?</a>
                </li>
                <li>
                  <a href="#FAS"> Identifying Fetal Alcohol Syndrome (FAS)</a>
                </li>
                <li>
                  <a href="#behaviorchar"> Behavioral Characteristics</a>
                </li>
                <li>
                  <a href="#diff">
                    {" "}
                    Learning Difficulties Related to FAS, ARND and ARBD
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ textAlign: "left" }}
            >
              <ul style={{ listStyle: "1.6" }}>
                <li>
                  <a href="#behavior"> Behavior</a>
                </li>
                <li>
                  <a href="#skill">
                    {" "}
                    Inappropriate Social Skills Related to FAS/FAE
                  </a>
                </li>
                <li>
                  <a href="#educational"> Educational Needs</a>
                </li>
                <li>
                  <a href="#strategies">
                    {" "}
                    Strategies for FAS Parents and Caregivers
                  </a>
                </li>
                <li>
                  <a href="#advocacy"> The Need for Advocacy</a>
                </li>
                <li>
                  <a href="#sources"> Sources</a>
                </li>
                <li>
                  <a href="#resources"> Resources for FAS caregivers</a>
                </li>
              </ul>
            </Grid>
          </Grid>{" "}
          <br />
          <Typography>
            Fetal Alcohol Syndrome (FAS) is an alcohol-related birth disability
            and is the number one cause of intellectual disability in the United
            States. It is also the only cause of birth defects that is entirely
            preventable. The condition occurs from maternal alcohol use during
            pregnancy. When a pregnant woman drinks alcohol, it passes through
            the placenta and is absorbed by the unborn baby.
          </Typography>{" "}
          <br />
          <Typography>
            Children with fetal alcohol syndrome typically have multiple
            disabilities and require special medical, educational, familial and
            community assistance. These children may require lifelong,
            expensive, intensive care and intervention to reach their potential.
            There is no known safe amount of alcohol use during pregnancy and no
            known time when drinking alcohol is safe. Alcohol can do more damage
            to the developing embryo and fetus than illegal or legal drugs.
          </Typography>
          <br />
          <Grid id="neuro">
            <Typography
              variant="h5"
              style={{ color: "#002F86", textAlign: "left" }}
            >
              What are Alcohol-Related Neurodevelopmental Disorder (ARND) and
              Alcohol-Related Birth Defects (ARBD)?
            </Typography>
            <br />
            <Typography>
              Prenatal alcohol exposure does not always result in FAS—although
              there is no known safe level of alcohol consumption during
              pregnancy. Most individuals affected by alcohol exposure before
              birth do not have the characteristic facial abnormalities and
              growth retardation identified with FAS, yet they have brain damage
              and other impairments that are just as significant.
            </Typography>
            <br />
            <Typography style={{ textAlign: "justify" }}>
              Alcohol Related Neurodevelopmental Disorder (ARND) describes the
              functional or cognitive impairments linked to prenatal alcohol
              exposure, and Alcohol-Related Birth Defects (ARBD) describes
              malformations in the skeletal and major organ systems.
            </Typography>
            <br />
          </Grid>
          <br />
          <Grid id="primary">
            <Typography
              variant="h5"
              style={{ color: "#002F86", textAlign: "left" }}
            >
              What are the Primary Characteristics of FAS, ARND and ARBD?
            </Typography>
            <br />
            <Typography>
              Individuals with FAS have a distinct pattern of facial
              abnormalities, growth deficiency and evidence of central nervous
              system dysfunction. In addition to intellectual disability,
              individuals with FAS, ARND and ARBD may have other neurological
              deficits such as poor motor skills and hand-eye coordination. They
              may also have a complex pattern of behavioral and learning
              problems, including difficulties with memory, attention and
              judgment.
            </Typography>
          </Grid>
          <br />
          <Grid id="occur">
            <Typography
              variant="h5"
              style={{ color: "#002F86", textAlign: "left" }}
            >
              How often do FAS, ARND and ARBD Occur?
            </Typography>
            <br />
            <Typography>
              As many as 12,000 infants are born each year with FAS and three
              times as many have ARND or ARBD. FAS, ARND and ARBD affect more
              newborns every year than Down syndrome, cystic fibrosis, spina
              bifida and Sudden Infant Death Syndrome combined.
            </Typography>
          </Grid>
          <br />
          <Grid id="FAS" style={{ width: "100%" }}>
            <Typography
              variant="h5"
              style={{ color: "#002F86", textAlign: "left" }}
            >
              Identifying Fetal Alcohol Syndrome (FAS)
            </Typography>
            <br />
            <Typography>
              <b>Physical Characteristics Related to FAS, ARND or ARBD</b>
            </Typography>
            <br />
            <TableContainer
              id="identifytable"
              component={Paper}
              style={{
                border: "none",
                // width: "85%",
                margin: "auto",
                border: "1px solid black",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "lightgray" }}>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <b>GROWTH DEFICIENCIES MAY INCLUDE:</b>
                    </TableCell>
                    <TableCell>
                      <b>FACIAL MALFORMATIONS MAY INCLUDE:</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderRight: "1px solid black",
                        wordBreak: "break-word",
                        verticalAlign: "top",
                      }}
                    >
                      <ul
                        style={{
                          lineHeight: "1.6",
                          verticalAlign: "top",
                          wordBreak: "break-word",
                          padding: "0px",
                          listStyle: "none",
                        }}
                      >
                        <li>Low birth weight</li>
                        <li>Small size for age in weight and length</li>
                        <li>Small head for age</li>
                        <li>Failure to thrive</li>
                      </ul>
                      <Table>
                        <TableHead style={{ backgroundColor: "lightgray" }}>
                          <TableCell>
                            <b>OTHER EFFECTS MAY INCLUDE:</b>
                          </TableCell>
                        </TableHead>
                        <TableBody style={{ wordBreak: "break-word" }}>
                          <ul
                            style={{
                              lineHeight: "1.6",
                              wordBreak: "break-word",
                              padding: "0px",
                              listStyle: "none",
                            }}
                          >
                            <li>Large or malformed ears</li>
                            <li>Underdeveloped fingernails or toenails</li>
                            <li>Short neck</li>
                            <li>Poor eye-hand coordination</li>
                            <li>Hearing problems</li>
                            <li>Joint and bone abnormalities</li>
                          </ul>
                        </TableBody>
                      </Table>
                    </TableCell>
                    <TableCell
                      style={{ verticalAlign: "top", wordBreak: "break-word" }}
                    >
                      <ul
                        style={{
                          lineHeight: "1.6",
                          wordBreak: "break-word",
                          padding: "0px",
                          listStyle: "none",
                        }}
                      >
                        <li>Short eye slits</li>
                        <li>Droopy eyelids</li>
                        <li>Widely spaced eyes</li>
                        <li>Nearsightedness</li>
                        <li>Crossed eyes</li>
                        <li>Short upturned nose</li>
                        <li>Low and/or wide bridge of the nose</li>
                        <li>Flat or smooth area between the nose and lip</li>
                        <li>Thin upper lip</li>
                        <li>Flat midface</li>
                        <li>Small underdeveloped jaw</li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </Grid>
          <br />
          <Grid id="behaviorchar" style={{ width: "100%" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              Behavioral Characteristics Related to FAS, ARND and ARBD
            </Typography>
            <br />
            <Typography>
              Children with FAS, ARND and ARBD often have behavior problems due
              to brain injury. Some have more behavioral problems than others.
              Some are so severely affected that they cannot function
              independently in the community. Behavior problems will vary with
              the individual.
            </Typography>
            <br />
            <TableContainer
              id="tablecontainer1"
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "70%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "lightgray" }}>
                    <TableCell style={{ borderRight: "1px solid black" }}>
                      <b>THEY INCLUDE:</b>
                    </TableCell>
                    <TableCell>
                      <b>OTHER EFFECTS MAY INCLUDE:</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderRight: "1px solid black",
                        wordBreak: "break-word",
                        verticalAlign: "top",
                      }}
                    >
                      <ul
                        style={{
                          lineHeight: "1.6",
                          padding: "0px",
                          listStyle: "none",
                        }}
                      >
                        <li>Hyperactivity</li>
                        <li>Stubbornness</li>
                        <li>Impulsiveness</li>
                        <li>Passiveness</li>
                        <li>Fearlessness</li>
                        <li>Irritability</li>
                        <li>Sleep difficulties</li>
                        <li>Teasing or bullying of others</li>
                      </ul>
                    </TableCell>
                    <TableCell>
                      <ul
                        style={{
                          lineHeight: "1.6",
                          wordBreak: "break-word",
                          verticalAlign: "top",
                          padding: "0px",
                          listStyle: "none",
                        }}
                      >
                        <li>Hypersensitivity to sound and touch</li>
                        <li>Difficulty with change</li>
                        <li>Organizational difficulties</li>
                        <li>Poor self-image</li>
                        <li>Overstimulation difficulties</li>
                        <li>Depression or withdrawal</li>
                        <li>Problems with truancy</li>
                        <li>Problems with sexuality</li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </Grid>
          <br />
          <Grid id="diff" style={{ width: "100%" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              Learning Difficulties Related to FAS, ARND and ARBD
            </Typography>
            <br />
            <Typography>
              Children with FAS, ARND and ARBD frequently have learning
              difficulties. These difficulties result from poor thinking and
              processing skills. Information may be known, but cannot be applied
              to different situations. Learning may occur in spurts. Easy
              learning periods may be followed by harder ones. During difficult
              periods, children may have trouble remembering and using their
              learned information. Because of inconsistent learning, teachers
              may think they are just not trying. They may label them as lazy or
              stubborn.
            </Typography>
            <br />
            <TableContainer
              id="tablecontainer2"
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "lightgray" }}>
                    <TableCell colSpan={2}>
                      <b>DIFFICULTIES MAY INCLUDE:</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderRight: "1px solid black",
                        verticalAlign: "top",
                      }}
                    >
                      <ul
                        style={{
                          lineHeight: "1.6",
                          padding: "0px",
                          listStyle: "none",
                        }}
                      >
                        <li>Developmental delays</li>
                        <li>Attention deficit</li>
                        <li>Poor organization skills</li>
                        <li>Problems with memory</li>
                        <li>Poor mathematical skills</li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <ul
                        style={{
                          lineHeight: "1.6",
                          padding: "0px",
                          listStyle: "none",
                          //   wordBreak: "break-word ",
                        }}
                      >
                        <li>Difficulty with abstract concepts</li>
                        <li>Difficulty learning from past experiences</li>
                        <li>Difficulty understanding cause and effect</li>
                        <li>Speech delays, stuttering and stammering</li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </Grid>
          <br />
          <Grid id="behavior">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              Behavior
            </Typography>
            <br />
            <Typography>
              There are many behavioral characteristics which differentiate
              individuals with FAS, ARND and ARBD from other individuals with
              intellectual disabilities. Socially, they tend to be very outgoing
              and socially engaging, yet they are frequently seen by others as
              intrusive, overly talkative, and generally unaware of social cues
              and conventions. Poor social judgment and poor socialization
              skills are common: many patients are hungry for attention, even
              negative. Due to their social immaturity, they have difficulty
              establishing friendships, especially with children of the same
              age. The potential for both social isolation and exploitation of
              individuals with FAS, ARND and ARBD in very evident. Hyperactivity
              is frequently cited as a problem for young children who
              characteristically have short attention spans. Many also have
              memory problems, thus creating further setbacks to adaptive
              functioning and academic achievement later on.
            </Typography>
          </Grid>
          <br />
          <Grid id="skill">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              Inappropriate Social Skills Related to FAS/FAE
            </Typography>
            <br />
            <Typography>
              Children with FAS/FAE often show socially inappropriate behavior
              due to impaired practical reasoning skills. They also may be
              unable to consider results of their actions. They may miss cues
              used as subtle messages like gestures and facial expressions. They
              may be socially and emotionally immature and have difficulty
              getting along with peers.
            </Typography>
            <br />
            <Typography>
              Children with FAS, ARND and ARBD can be easily influenced by
              others. Due to their trusting nature and eagerness to please,
              random attraction to strangers may occur. They may be vulnerable
              to manipulation and victimization. This can cause concern for
              caregivers. Constant supervision may be required.
            </Typography>
          </Grid>
          <br />
          <Grid id="educational">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              Educational Needs
            </Typography>
            <br />
            <Typography>
              Children with FAS, ARND and ARBD have special educational needs.
              Even very young infants can benefit from early stimulation
              programs to help with intellectual and motor development.
            </Typography>
            <br />
            <Typography>
              Preschoolers often have a range of developmental and language
              delays as well as signs of hyperactivity, irritability, and
              distractibility. Preschool programs which follow individualized
              educational plans are helpful for the child as well as for the
              parents who gain valuable respite time to regroup from the intense
              demands of these children.
            </Typography>
            <br />
            <Typography>
              Appropriate placement in special education classes beginning in
              elementary school is often necessary for children with FAS, ARND
              and ARBD. A small classroom setting with clear guidelines and a
              great deal of individual attention can maximize the intellectual
              capabilities of these learners. Although intensive remedial
              education has not been show to increase the intellectual
              capabilities of children with FAS, ARND and ARBD, it may prevent
              further deterioration.
            </Typography>
            <br />
            <Typography>
              Many patients with fetal alcohol syndrome reach an academic
              plateau in high school. Many will be unable to hold a regular job.
              Nonetheless, all of these students need to know basic life skills,
              including money management, safety skills, interpersonal relating,
              and so forth. These tasks will enrich their adult lives and allow
              them a degree of independence. The addition of such a life-skills
              component to the special education curricula for FAS students can
              be invaluable. Wherever possible and appropriate, vocational
              training should be part of the high school experience. There are
              several vocational and technical institutes beyond high school
              that offer a curricula for individuals with developmental
              disabilities.
            </Typography>
          </Grid>
          <br />
          <Grid id="strategies">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              Strategies for FAS Parents and Caregivers
            </Typography>
            <br />
            <Typography>
              Keys to working successfully with FAS, ARND and ARBD children are
              structure, consistency, variety, brevity and persistence. Because
              these children can lack internal structure, caretakers need to
              provide external structure for them. It is important to be
              consistent in response and routine so that the child feels the
              world is predictable. Because of serious problems maintaining
              attention, it is important to be brief in explanations and
              directions, but also to use a variety of ways to get and keep
              their attention. Finally, we must repeat what it is we want them
              to learn, over and over again.
            </Typography>
            <br />
            <Typography>
              <b>Many FAS children:</b>
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li>Have difficulty structuring work time.</li>
              <li>Show impaired rates of learning.</li>
              <li>Experience poor memory.</li>
              <li>Have trouble generalizing behaviors and information.</li>
              <li>Act impulsively.</li>
              <li>Exhibit reduced attention span or is distractible.</li>
              <li>
                Display fearlessness and are unresponsive to verbal cautions.
              </li>
              <li>Demonstrate poor social judgment.</li>
              <li>Cannot handle money age appropriately.</li>
              <li>Have trouble internalizing modeled behaviors.</li>
              <li>
                May have differences in sensory awareness (Hypo or Hyper).
              </li>
              <li>Language production higher than comprehension.</li>
              <li>Show poor problem solving strategies.</li>
            </ul>
            <br />
            <Typography>
              <b>Effective strategies include:</b>
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li>Fostering independence in self-help and play.</li>
              <li>Give your child choices and encourage decision-making.</li>
              <li>Focus on teaching daily living skills.</li>
              <li>Encourage the use of positive self talk.</li>
              <li>
                Have child get ready for next school day before going to bed.
              </li>
              <li>
                Establish a few simple rules. Use identical language to remind
                them of the rules. "This is your bed, this is where you are
                supposed to be."
              </li>
              <li>Establish routines so child can predict coming events.</li>
              <li>
                Give child lots of advance warning that activity will soon
                change to another one.
              </li>
              <li>
                For unpredictable behavior at bedtime/mealtime, establish a firm
                routine.
              </li>
              <li>
                Break their work down into small pieces so they do not feel
                overwhelmed.
              </li>
              <li>Be concrete when teaching a new concept. Show them.</li>
            </ul>
            <br />
            <Typography>
              <b>Discipline:</b>
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li>Set limits and follow them consistently.</li>
              <li>
                Change rewards often to keep interest in reward getting high.
              </li>
              <li>
                Review and repeat consequences of behaviors. Ask them to tell
                you consequences.
              </li>
              <li>
                Do not debate or argue over rules already established. "Just do
                it."
              </li>
              <li>
                Notice and comment when your child is doing well or behaving
                appropriately.
              </li>
              <li>Avoid threats.</li>
              <li>Redirect behavior.</li>
              <li>Intervene before behavior escalates.</li>
              <li>Avoid situations where child will be overstimulated.</li>
              <li>Have child repeat back their understanding of directions.</li>
              <li>Protect them from being exploited. They are naive.</li>
              <li>Have pre-established consequences for misbehavior.</li>
            </ul>
            <br />
            <Typography>
              The foster or adoptive parent of a child with FAS assumes a
              responsibility far beyond that normally associated with parenting.
              The constellation of physical, intellectual, and behavioral
              characteristics that typifies individuals with FAS can create a
              very demanding situation for any family. The children often
              require constant supervision. Parents require an extraordinary
              amount of energy, love, and most of all, consistency.
            </Typography>
          </Grid>
          <br />
          <Grid id="advocacy">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              The Need for Advocacy
            </Typography>
            <br />
            <Typography>
              Children and parents dealing with FAS/FAE need strong advocates.
              Advocacy must come from both the parents and the professionals
              involved. Their different spheres of influence and different roles
              must combine to ensure that the needs of both parent and child are
              being met.
            </Typography>
            <br />
            <Typography>
              Individuals with FAS/FAE have a great capacity for love and
              contribution to family and community. The challenge of caretakers
              and service providers alike is to help these children harness
              their potential and find their place in the world.
            </Typography>
          </Grid>
          <br />
          <Grid id="sources" style={{ width: "100%" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              Sources
            </Typography>
            <br />
            <Typography style={{ display: "flex", flexDirection: "column" }}>
              This article was compiled from the following sources. To see the
              full text of these articles, click on the links below,Social
              Casework: The Journal of Contemporary Social Work, 9/99. Carole T.
              Guinta{" "}
              <a
                target="_blank"
                style={{ wordBreak: "break-word" }}
                href="http://www.adopting.org/rwfas.html%20"
              >
                http://www.adopting.org/rwfas.html{" "}
              </a>
            </Typography>
            <ul>
              <li
                style={{
                  wordBreak: "break-word",
                  display: "flex",
                  flexDirection: "column",
                }}
              ></li>
            </ul>
            <Typography>
              Attached below are two excellent guides for parenting the FAS
              child. Both are in PDF format.
            </Typography>
            <ul style={{ lineHeight: "1.6", wordBreak: "break-word" }}>
              <li>
                <a target="_blank" href={fas1pdf}>
                  Living with Infants, Toddlers, and Children who have been
                  Prenatally Exposed to Alcohol
                </a>
              </li>
              <li>
                <a target="_blank" href={fas2pdf}>
                  Parenting children Affected by Fetal Alcohol syndrome – A
                  Guide for Daily Living
                </a>
              </li>
            </ul>
          </Grid>
          <br />
          <Grid
            id="resources"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              width: "100%",
            }}
          >
            <Typography variant="h5" style={{ color: "#002F86" }}>
              Resources for FAS Caregivers
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li style={{ wordBreak: "break-word" }}>
                <a target="_blank" href="http://www.nofas.org">
                  http://www.nofas.org/
                </a>
              </li>
              <li style={{ wordBreak: "break-word" }}>
                <a
                  target="_blank"
                  href="http://www.nlm.nih.gov/medlineplus/fetalalcoholsyndrome.html"
                >
                  http://www.nlm.nih.gov/medlineplus/fetalalcoholsyndrome.html
                </a>
              </li>
            </ul>
            <Divider />
            <br />
            <Typography>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the <a href="/generalInformation">Get Started</a>{" "}
              section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href="">Texas Adoption Resource Exchange (TARE)</a> or call
              1-800-233-3405.
            </Typography>
            <br />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FetalAlcohol;
