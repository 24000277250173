import { Grid } from "@mui/material";
import React from "react";
// import Image_404 from "../../assets/newimages/sleepyhead.jpg";
import image1 from "../../assets/newimages/404.png";
const Pagenotfound_404 = () => {
  return (
    <div style={{ display:"flex",alignItems:"center",justifyContent:"center", width:"100%",height:"100vh"}}>
        <img alt="image2" src={image1} style={{height:"20rem",width:"20rem"}} />
      
    </div>
  );
};
export default Pagenotfound_404;
