import React from "react";
import { Grid, Typography } from "@mui/material";
import MapCard from "../mappages/mapcard";
import { Link } from "react-router-dom";
import Mapcardnoarea from "../mappages/mapcardnoareas";
import ImageSlider from "../../../layout/imageslider";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./region4,5,6.css";

const Region4_6 = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* <ImageSlider /> */}
      
      <Grid
        style={{
          marginTop: "20px",
          width: "80%",
          textAlign: "left",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
          marginBottom: "50px",
        }}
        id="region4"
      >
        <Typography
        id="header"
          variant="h5"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#3f51b5",
            color: "white",
            width: "100%",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            height: "60px",
          }}
        >
          Region 4, Tyler and surrounding area
        </Typography>

        <Grid style={{ width: "90%" }}>
          <br />
          <Grid
          id="sub-grid"
            style={{
              display: "flex",
            }}
          >
            <Grid >
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Foster Care and Adoption Information Meetings
              </Typography>
              <br />
              <Typography style={{ color: "red" , textAlign: 'justify'}}>
                * If accommodations such as a sign language interpreter are
                needed in order to attend an information meeting, please call
                the Recruitment Staff listed below 3 days prior to the meeting,
                if possible. *
              </Typography>
              <br />
              <Typography style={{ color: "black", fontWeight: "bold" , textAlign: 'justify'}}>
                <span style={{ fontWeight: "normal" }}>
                  These information meetings are open to all the Region 4’s
                  counties of
                </span>{" "}
                Anderson, Bowie, Camp, Cass, Cherokee, Delta, Franklin, Gregg,
                Harrison, Henderson, Hopkins, Lamar, Marion, Morris, Panola,
                Rains, Red River, Rusk, Smith, Titus, Upshur, Van Zandt, and
                Wood.
                <span style={{ fontWeight: "normal" }}>
                  If you live outside this area, please select your region using
                  the map.
                </span>
              </Typography>
              <br />
              {/* <Typography >There is <span style={{ fontWeight: 'bold' }}>no charge to attend</span> a foster care/adoption information meeting in your area.</Typography>
                                <br />
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Do I need to make a reservation?</Typography>
                                <br /> */}
              {/* <Typography><span style={{ fontWeight: 'bold' }}>No reservation is needed.</span> The foster care and adoption information meetings are open to the public.</Typography> */}
              <br />
            
                <Grid>
                  <Typography style={{ fontWeight: "bold" }}>
                    Recruitment Events and Foster/Adopt Information Meetings:
                  </Typography>

                  <ul style={{ lineHeight: "1.6" }}>
                    <li>
                      <Link target="_blank" to="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Texas_Heart_Galleries/NorthEast/default.asp">
                        Heart Gallery of North East Texas
                      </Link>
                    </li>
                    
                    <li>
                      <Link target="_blank" to="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp">
                        Faith-Based Staff
                      </Link>
                    </li>
                  </ul>
                </Grid>
              
            </Grid>
            <Grid
              style={{
                // width: "380px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                
              }}
             >           
                <MapCard/>
             
            </Grid>
          </Grid>

          <br />
          <Typography style={{textAlign: 'justify'}}>
            The Texas Department of Family and Protective Services is looking
            for caring foster and adoptive families in your area. To learn more
            about becoming a foster or adoptive parent through our agency, we
            will be having the following information meetings in your area.
          </Typography>
          <br />
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Recruitment Staff
          </Typography>
          <br />
          <Typography>
            Answers questions for the community on how to become foster and
            adoptive families.
          </Typography>

          <ul style={{ lineHeight: "1.6" }}>
            <li>
              <Link to="">
                Haley Weaver{" "}
              </Link>{" "}
              (281) 210-1510
            </li>
            <li>
              <Link to="">
                {" "}
                Jonathan Sanchez
              </Link>{" "}
              (281) 210-1510
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid
        style={{
          marginTop: "20px",
          width: "80%",
          textAlign: "left",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
          marginBottom: "50px",
        }}
        id="region5"
      >
        <Typography
          variant="h5"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#3f51b5",
            color: "white",
            width: "100%",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            height: "60px",
          }}
          id="header"
        >
          Region 5, Beaumont and surrounding area
        </Typography>

        <Grid style={{ width: "90%" }}>
          <br />
          <Grid id="sub-grid" style={{ display: "flex" }}>
            <Grid >
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Foster Care and Adoption Information Meetings
              </Typography>
              <br />
              {/* <Typography style={{ color: "red" }}>
                * If accommodations such as a sign language interpreter are
                needed in order to attend an information meeting, please call
                the Recruitment Staff listed below 3 days prior to the meeting,
                if possible. *
              </Typography>
              <br /> */}
              <Typography style={{ color: "black", fontWeight: "bold" , textAlign: 'justify'}}>
                <span style={{ fontWeight: "normal" }}>
                  These information meetings are open to all the Region 5’s
                  counties of
                </span>
                Angelina, Hardin, Houston, Jasper, Jefferson, Nacogdoches,
                Newton, Orange, Polk, Sabine, San Augustine, San Jacinto,
                Shelby, Tinity, Tyler .
                <span style={{ fontWeight: "normal" }}>
                  If you live outside this area, please select your region using
                  the map.
                </span>
              </Typography>
              <br />
              {/* <Typography >There is <span style={{ fontWeight: 'bold' }}>no charge to attend</span> a foster care/adoption information meeting in your area.</Typography>
                                <br />
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Do I need to make a reservation?</Typography>
                                <br /> */}
              {/* <Typography><span style={{ fontWeight: 'bold' }}>No reservation is needed.</span> The foster care and adoption information meetings are open to the public.</Typography> */}
              <br />
              <Grid>
                <Grid>
                  <Typography style={{ fontWeight: "bold" }}>
                    Recruitment Events and Foster/Adopt Information Meetings:
                  </Typography>

                  <ul style={{ lineHeight: "1.6" }}>
                    <li>
                      <Link target="_blank" to="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Texas_Heart_Galleries/EastTexas/default.asp">
                        Heart Gallery of North East Texas
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp">
                        Faith-Based Staff
                      </Link>
                    </li>
                  </ul>
                  <Typography>
                    ***DFPS is no longer hosting information meetings at this
                    time.***
                  </Typography>
                  <br />
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Recruitment Staff
                  </Typography>
                  <br />
                  <Typography>
                    Answers questions for the community on how to become foster
                    and adoptive families.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                // width: "25%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MapCard />
            </Grid>
          </Grid>
          {/* 
          <br />
          <Typography>
            The Texas Department of Family and Protective Services is looking
            for caring foster and adoptive families in your area. To learn more
            about becoming a foster or adoptive parent through our agency, we
            will be having the following information meetings in your area.
          </Typography> */}
          <br />

          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Contact Information
          </Typography>
          <Typography>
            TARE Coordinator: Melissa LaFleur
            <br />
            Phone: 409-728-7699
            <br />
            <Link to="">
              Melissa.LaFleur@dfps.texas.gov
            </Link>
          </Typography>
          <br />
          {/* <ul>
            <li>
              <Link to="">Haley Weaver </Link> (281) 210-1510
            </li>
            <li>
              <Link to=""> Jonathan Sanchez</Link> (281) 210-1510
            </li>
          </ul> */}
        </Grid>
      </Grid>
      <Grid
        id="region6"
        style={{
          marginTop: "20px",
          width: "80%",
          textAlign: "left",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
          marginBottom: "50px",
        }}
      >
        <Typography
          variant="h5"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#3f51b5",
            color: "white",
            width: "100%",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            height: "60px",
          }}
          id="header"
        >
          Region 6, Houston and surrounding area
        </Typography>

        <Grid style={{ width: "90%" }}>
          <br />
          <Grid id="sub-grid" style={{ display: "flex" }}>
            <Grid >
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Foster Care and Adoption Information Meetings
              </Typography>
              <br />
              <Typography style={{ color: "red", textAlign: 'justify' }}>
                * The Department of Family and Protective Services is no longer
                hosting information meetings for the recruitment, training or
                verification of foster and adoptive homes in Region 6. To access
                these services, we encourage you to visit <a href="">here</a>{" "}
                for a list of our partner Child Placing Agencies that serve
                children entering foster care and their communities. *
              </Typography>
              <br />
              <Typography style={{ color: "black", fontWeight: "bold" , textAlign: 'justify'}}>
                <span style={{ fontWeight: "normal" }}>
                  These information meetings are open to all the Region 6’s
                  counties of
                </span>
                Austin, Brazoria, Chambers, Colorado, Fort Bend, Galveston,
                Harris, Liberty, Matagorda, Montagomery, Walker, Waller,
                Wharton.{" "}
                <span style={{ fontWeight: "normal" }}>
                  If you live outside this area, please select your region using
                  the map.
                </span>
              </Typography>
              {/* <br /> */}
              {/* <Typography >There is <span style={{ fontWeight: 'bold' }}>no charge to attend</span> a foster care/adoption information meeting in your area.</Typography>
                                <br />
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Do I need to make a reservation?</Typography>
                                <br /> */}
              {/* <Typography><span style={{ fontWeight: 'bold' }}>No reservation is needed.</span> The foster care and adoption information meetings are open to the public.</Typography> */}
              <br />

              <Grid>
                <Typography style={{ fontWeight: "bold" }}>
                  Recruitment Events and Foster/Adopt Information Meetings:
                </Typography>

                <ul style={{ lineHeight: "1.6" }}>
                  <li>
                    <Link to="">TARE Regional Staff</Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp">
                      Faith-Based Staff
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid
              style={{
                // width: "25%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MapCard />
            </Grid>
          </Grid>

          <br />
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            To speak with someone regarding specific child(ren) on TARE from
            Region 6, please contact:
          </Typography>
          <ul style={{ lineHeight: "1.6" }}>
            <li>
              <Link to="">
                Angela Mason
              </Link>
              , Foster/Adopt Specialist (Harris County):
              <span style={{ fontWeight: "bold" }}> (713) 394-4216</span>
            </li>
            <li>
              <Link to="">
                Oree Anglin
              </Link>
              , Foster/Adopt Specialist (Harris County):
              <span style={{ fontWeight: "bold" }}> (281) 546-0637</span>
            </li>
            <li>
              <Link to="">
                Sandra Gordon
              </Link>
              , Foster/Adopt Specialist (Outlying Counties):
              <span style={{ fontWeight: "bold" }}> (832) 315-2412</span>
            </li>
            <li>
              <Link to="">
                Luis Calderon
              </Link>
              , Foster/Adopt Administrative Assistant (Outlying Counties):
              <span style={{ fontWeight: "bold" }}> (979) 331-7012</span>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};
export default Region4_6;
