import { Grid, TableBody, Typography } from "@mui/material";
import React from "react";
import ImageSlider from "../../../layout/imageslider";
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import Mapcardnoarea from "../mappages/mapcardnoareas";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MapCard from "../mappages/mapcard";
import "./region7.css";
const Region8 = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div align="center">
      {/* <ImageSlider /> */}
      <Grid
        id="maingrid"
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid style={{ textAlign: "left" }}>
          <Typography variant="h5" style={{fontWeight:"bold",textAlign:"center",color:"navy"}}>
            Region 8, San Antonio and surrounding area
          </Typography>
          <br />
          <Grid style={{textAlign:'justify'}}>
            <Typography>
              Foster Care and Adoption Information Meetings
            </Typography>
            <br />
            <Typography>
              <b>IF YOU RESIDE IN BEXAR COUNTY:</b>
            </Typography>
            <Typography>
              For Information meetings in Bexar County, 8A, contact{" "}
              <b>Diana Menchaca</b> at <b>(210) 337-3117</b>or email:{" "}
              <a href=""> diana.menchaca@dfps.texas.gov.</a>{" "}
            </Typography>
          </Grid>
          {/* Table Grid */}
          <Grid style={{ paddingTop: "20px" }}>
            <TableContainer
              component={Paper}
              id="table1"
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow colspan="2">
                    <TableCell align="left" colSpan="2">
                      IF YOU RESIDE IN ONE OF THESE COUNTIES:
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <ul>
                        <li>Atascosa</li>
                        <li>Bandera</li>
                        <li>Calhoun</li>
                        <li>Comal</li>
                        <li>DeWitt</li>
                        <li>Dimmit</li>
                        <li>Edwards</li>
                        <li>Frio</li>
                        <li>Gillespie</li>
                        <li>Goliad</li>
                        <li>Gonzales</li>
                        <li>Guadalupe</li>
                        <li>Jackson</li>
                        <li>Karnes</li>
                      </ul>
                    </TableCell>
                    <TableCell>
                      <ul>
                        <li>Kendall</li>
                        <li>Kerr</li>
                        <li>Kinney</li>
                        <li>La Salle</li>
                        <li>Lavaca</li>
                        <li>Maverick</li>
                        <li>Medina</li>
                        <li>Real</li>
                        <li>Uvalde</li>
                        <li>Val Verde</li>
                        <li>Victoria</li>
                        <li>Wilson</li>
                        <li>Zavala</li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            <a target="_blank" href="https://sjrcbelong.org/">Belong</a> is responsible for
            Foster Care and Adoption Information Meetings for Region 8b. For
            additional information please contact Belong's point of contact,{" "}
            <a href="">Julia Braun</a> at <b>(210) 876-6958</b> Option #1.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            Belong is now in a partnership with DFPS as part of Community-Based
            Care. Community-Based Care is a new way of providing foster care and
            case management services. It's a community-based approach to meeting
            the individual and unique needs of children, youth, and families.
            For more information, visit the{" "}
            <a target="_blank" href="https://www.dfps.texas.gov/CBC/default.asp">
              Community-Based Care
            </a>{" "}
            section of the{" "}
            <a target="_blank" href="https://www.dfps.texas.gov/default.asp">DFPS website. </a>{" "}
          </Typography>
          <br />
          <Typography>
            For information on fostering or adopting a child from out of state,
            contact the{" "}
            <a target="_blank" href="https://www.dfps.texas.gov/child_protection/State_Care/icpc.asp">
              State Office ICPC.
            </a>
          </Typography>
          <br />
          <Typography style={{ color: "red", textAlign: "justify" }}>
            <b>
              * If accommodations such as a sign language interpreter are needed
              in order to attend an information meeting, please call the
              Recruitment Staff listed below 3 days prior to the meeting, if
              possible. Meeting will start promptly at the designated time. A
              fifteen minute grace period is given; thereafter participants will
              not be permitted to enter. *
            </b>
          </Typography>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>Recruitment Events and Foster/Adopt Information Meetings:</b>
              </Typography>
              <br />
              <ul style={{ lineHeight: "1.6" }}>
                <li>
                  <Link to="#table1"> January 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table2">February 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table3">March 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="">Heart Gallery of Bluebonnet Country</Link>
                </li>
                <li>
                  <Link target="_blank" to="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp">
                    {" "}
                    Faith-Based Staff
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "justify",
              }}
            >
              <MapCard />
            </Grid>
          </Grid>
          <Typography style={{ textAlign: "justify" }}>
            The Information Meetings posted below are for Relatives referred to
            us and families via the Interstate Compact. We are referring all
            other applicants to our community partners; other Child Placing
            Agencies. For a listing, feel free to contact me via email @{" "}
            <a href="">diana.menchaca@dfps.texas.gov</a>
          </Typography>
          <br />
          <Typography>Thank you!</Typography>
          {/* Table1 */}
          <Grid align="center" style={{ paddingTop: "30px", width: "100%" }}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>January 2024</b>
            </Typography>
            <br/>
            <TableContainer
              id="table2"
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Teams (Virtual)</strong>
                      <p>Bexar County</p>
                      <p>Residents Only</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <strong>Thursday, January 11th @ 3:30 pm</strong>
                      </p>
                      <p>
                        <strong>
                          TEAMS MEETING: Email or call to register and receive
                          meeting invitation{" "}
                        </strong>{" "}
                      </p>
                      <p>
                        <b>Contact:</b>{" "}
                        <a href=""> Region08FAD@dfps.texas.gov </a>or (210)
                        337-3117
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Teams (Virtual)</strong>
                      <p>Bexar County</p>
                      <p>Residents Only</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <strong>Monday, January 22rd @ 5:30 pm</strong>
                      </p>
                      <p>
                        <strong>
                          TEAMS MEETING: Email or call to register and receive
                          meeting invitation
                        </strong>
                      </p>
                      <p>
                        <strong>
                          Contact: <a href=""> Region08FAD@dfps.texas.gov</a> or
                          (210) 337-3117
                        </strong>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table2 */}
          <Grid style={{ paddingTop: "30px" }}>
            <Typography
              variant="h5"
              style={{ textAlign: "center", width: "100%" }}
            >
              <b>February 2024</b>
            </Typography>
            <br/>
            <TableContainer
              id="table3"
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Teams (Virtual)</strong>
                      <p>Bexar County</p>
                      <p>Residents Only</p>
                    </TableCell>
                    <TableCell align="top">
                      <p>
                        <strong>Friday, February 9th @ Noon (12:00 pm)</strong>
                      </p>
                      <p>
                        <strong>
                          TEAMS MEETING: Email or call to register and receive
                          meeting invitation
                        </strong>{" "}
                      </p>
                      <p>
                        <b>
                          Contact: <a href=""> Region08FAD@dfps.texas.gov</a> or
                          (210) 337-3117
                        </b>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Teams (Virtual)</strong>
                      <p>Bexar County</p>
                      <p>Residents Only</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <strong>Thursday, February 22rd @ 5:30 pm</strong>
                      </p>
                      <p>
                        <strong>
                          TEAMS MEETING: Email or call to register and receive
                          meeting invitation
                        </strong>
                      </p>
                      <p>
                        <strong>
                          Contact: <a href=""> Region08FAD@dfps.texas.gov</a> or
                          (210) 337-3117
                        </strong>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table3 */}
          <Grid style={{ paddingTop: "30px" }}>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <b>March 2024</b>
            </Typography>
            <br/>
            <TableContainer
              id="table3"
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Teams (Virtual)</strong>
                      <p>Bexar County</p>
                      <p>Residents Only</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <strong>Friday, March 8th @ 10:00am</strong>
                      </p>
                      <p>
                        <strong>
                          TEAMS MEETING: Email or call to register and receive
                          meeting invitation
                        </strong>
                      </p>
                      <p>
                        <strong>
                          Contact: <a href="">Region08FAD@dfps.texas.gov</a> or
                          (210) 337-3117
                        </strong>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Teams (Virtual)</strong>
                      <p>Bexar County</p>
                      <p>Residents Only</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <strong>Monday, March 18th @ 5:30 pm</strong>
                      </p>
                      <p>
                        <strong>
                          TEAMS MEETING: Email or call to register and receive
                          meeting invitation
                        </strong>
                      </p>
                      <p>
                        <strong>
                          Contact: <a href=""> Region08FAD@dfps.texas.gov</a> or
                          (210) 337-3117
                        </strong>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Region8;
