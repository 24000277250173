import { createStore ,combineReducers,applyMiddleware} from "redux";
import {logger} from 'redux-logger';
import {thunk} from 'redux-thunk';
import Fetchdata from "./reducers/counties_reducer";
import FetchAgencyData from "./reducers/agencies_reducer";

const rootReducer = combineReducers({
countyList:Fetchdata,
agencyList:FetchAgencyData,
});

// to check the instamt update of the states in the store we use "logger"

// const store = createStore(rootReducer,applyMiddleware(thunk,logger));


const store = createStore(rootReducer,applyMiddleware(thunk));
export default store;
