import ImageSlider from "../../layout/imageslider";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Grow,
  Typography,
  Button,
  Divider,
} from "@mui/material";
// import BreadcrumbMenu from '../../../layout/BreadcrumbMenu';
import { donateContent, donateSection } from "./donationviewContent";
import { Fragment } from "react";
import BreadcrumbMenu from "../../layout/BreadcrumbMenu";
// import { Link } from "react-router-dom";
// import { WidthFull } from "@mui/icons-material";


const DonationView = () => {
  return (
    <div align="center">
      {/* <ImageSlider /> */}
      <Grid style={{ flexGrow: 1,  }}>
        <Grow in={true} timeout={1500}>
          <Grid container style={{ color: "#1A355D", padding: "10px 0" }}>
            <Grid item xs={12} style={{ padding: "0 16px 10px 16px" }}>
              <BreadcrumbMenu
                currComp="Donation"
                parentComp="Home"
                parentCompRoute="/"
              />
            </Grid>
            {/* <Grid item xs={12} style={{ padding: '0 16px 10px 16px'}}>
                        <Typography variant="h4" >
                            Donation
                        </Typography>
                    </Grid> */}
          </Grid>
        </Grow>
        <Typography
          variant="h4"
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
        >
          Donation
        </Typography>
        <Grid container xs={11} sm={11} md={10} lg={10} >
          {" "}
          <Grow in={true} timeout={1500}>
            <Grid
              container
              style={{
                backgroundColor: "white",
                color: "#1A355D",
                // padding: "30px",
                boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
                marginBottom: "20px",
                borderRadius: "10px",
              }}
            >
              <Grid item style={{ padding: "10px", color: "#d04e53" }}>
                <Typography variant="h5">{donateContent.title}</Typography>
              </Grid>
              <Grid item>
                <List component="div" aria-label="svc content">
                  {donateContent.mainContent.map((content) => (
                    <ListItem
                      component="div"
                      key={content.key}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <ListItemText
                        primary={content.value}
                        style={{ textAlign: "justify" }}
                      />
                    </ListItem>
                  ))}
                  <Grid>
                    <form
                      action="https://www.paypal.com/cgi-bin/webscr"
                      method="post"
                      target="_blank"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input type="hidden" name="cmd" value="_s-xclick" />
                      <input
                        type="hidden"
                        name="hosted_button_id"
                        value="F83RSAG9W88KE"
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        title="Click to donate via PayPal"
                        //  style={Styles.donatebutton1}
                        sx={{
                          width: "100px",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        // id="donate-btn"
                      >
                        Donate
                      </Button>
                    </form>
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grow>
          <Grow in={true} timeout={1500}>
            <Grid
              container
              style={{
                backgroundColor: "white",
                color: "#1A355D",
                // padding: "30px",
                boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
                marginBottom: "20px",
                borderRadius: "10px",
              }}
            >
              {donateContent.gifts.map((item) => (
                <Fragment key={item.key}>
                  <Grid
                    item
                    style={{
                      textAlign: "center",
                      paddingTop: "10px",
                      color: "#1A355D",
                    }}
                    xs={12}
                  >
                    <Typography variant="h5">
                      {item.icon} {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <List component="div" aria-label="svc content">
                      <ListItem component="div" key={item.key}>
                        <ListItemText
                          primary={item.value}
                          style={{ textAlign: "justify" }}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  {item.hasLink && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        color: "#22477E",
                        cursor: "pointer",
                        padding: "10px 0",
                        "& button": {
                          color: "white",
                          cursor: "pointer",
                          backgroundColor: "#22477E",
                          "&:hover": {
                            backgroundColor: "#485f81",
                            color: "white",
                            animation: 0,
                          },
                        },
                      }}
                    >
                      <form
                        action="https://www.paypal.com/cgi-bin/webscr"
                        method="post"
                        target="_blank"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input
                          type="hidden"
                          name="hosted_button_id"
                          value="F83RSAG9W88KE"
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          title="Click to donate via PayPal"
                          //  style={Styles.donatebutton1}
                          style={{ width: "100px", borderRadius: "30px" }}
                          id="donate-btn"
                        >
                          Donate
                        </Button>
                      </form>
                      <Divider sx={{ paddingTop: "10px" }} />
                    </Grid>
                  )}
                </Fragment>
              ))}
            </Grid>
          </Grow>
          <Grow in={true} timeout={1500}>
            <Grid
              container
              style={{
                backgroundColor: "white",
                color: "#1A355D",
                // padding: "30px",
                boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
                marginBottom: "20px",
                borderRadius: "10px",
              }}
            >
              <Grid
                item
                style={{
                  textAlign: "center",
                  paddingTop: "10px",
                  color: "#1A355D",
                }}
                xs={12}
              >
                <Typography variant="h5">
                  {/* <FontAwesomeIcon icon={donateSection.icon}/> {donateSection.title} */}
                  {donateSection.icon}
                  {donateSection.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <List component="div" aria-label="svc content">
                  <ListItem component="div">
                    <ListItemText primary={donateSection.text1} />
                  </ListItem>

                  <ListItem component="div" style={{ textAlign: "center" }}>
                    <ListItemText primary={donateSection.text2} />
                  </ListItem>
                  <ListItem
                    component="div"
                    style={{
                      padding: "0 30px",
                      "& .MuiTypography-body1": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <ListItemText primary={donateSection.addressSA1} />
                  </ListItem>
                  <ListItem
                    component="div"
                    style={{
                      padding: "0 30px",
                      "& .MuiTypography-body1": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <ListItemText primary={donateSection.addressSA2} />
                  </ListItem>
                  <ListItem component="div">
                    <ListItemText primary={donateSection.text3} />
                  </ListItem>
                  <ListItem component="div">
                    <ListItemText primary={donateSection.text4} />
                  </ListItem>
                  <ListItem
                    component="div"
                    style={{
                      padding: "0 30px",
                      "& .MuiTypography-body1": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <ListItemText primary={donateSection.addressAus1} />
                  </ListItem>
                  <ListItem
                    component="div"
                    style={{
                      padding: "0 30px",
                      "& .MuiTypography-body1": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <ListItemText primary={donateSection.addressAus2} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grow>
        </Grid>
      </Grid>

      {/* <PayPalDonateView />
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/> */}
    </div>
  );
};

export default DonationView;
