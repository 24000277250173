import React from "react";
import { Grid, Typography, Card, CardContent, CardHeader } from "@mui/material";
import ImageSlider from "../../layout/imageslider";
import Partnerview from "./partnerstabs";
import { Link } from "react-router-dom";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import txregions from "../../../components/adoptionOverview/images/txregions.gif";
import MapCard from "../Placements/mappages/mapcard";
import "./partnerstabs.css"

const Partners = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    
      }}
    >
      {/* <ImageSlider /> */}
      <Grid id="maingrid"
        style={{
        //   width: "82%",
          backgroundColor: "white",
          textAlign: "justify",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding:'10px'
        }}
      >
        <Typography
          id="title"
          style={{
            width: "100%",
            backgroundColor: "rgb(53, 103, 178)",
            paddingTop: "5px",
            paddingBottom: "5px",
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Foster and Adoption Agencies in Texas
        </Typography>
        <br />
        <Grid style={{ }}>
          <Grid container
            id="grid1"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Grid item lg={8} style={{display:'flex',flexDirection:'column',justifyContent:"flex-start",width:"100%" }}>
              <Grid style={{ display: "flex", paddingLeft: "10px", }}>
                <DoubleArrowIcon />{" "}
                <Typography style={{ textAlign: "justify", fontSize: "1rem" }}>
                  A child placing agency (CPA) is a private foster and/or
                  adoption agency that works in partnership with the Texas
                  Department of Family and Protective Services (DFPS) to train
                  foster and adoptive parents and find homes for children. If
                  you foster or adopt a child in the conservatorship of DFPS,
                  the agencies below can provide you with services at minimal
                  costs.
                </Typography>
              </Grid>
              <br />
              <Grid style={{ display: "flex", paddingLeft: "10px" }}>
                <DoubleArrowIcon />{" "}
                <Typography style={{ textAlign: "justify", fontSize: "1rem" }}>
                  To learn about requirements for becoming a verified foster
                  parent or approved adoptive parent, read{" "}
                  <Link target="_blank" to="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Adoption_Partners/documents/CPA_Survey_Responses.pdf">
                    Texas Child Placing Agency Foster and Adoptive Parent
                    Training Requirements
                  </Link>
                  , which provides details on each child placing agency and
                  their unique requirements for licensure. If you need help
                  finding a CPA that's right for you, contact your{" "}
                  <Link target="_blank" to="https://www.hhs.texas.gov/services/safety/child-care/contact-child-care-regulation">
                    local child care regulation office
                  </Link>
                </Typography>
              </Grid>
              <br />
              <Grid style={{ display: "flex", paddingLeft: "10px" }}>
                <DoubleArrowIcon />{" "}
                <Typography style={{ textAlign: "justify", fontSize: "1rem" }}>
                  Foster and adoption agency offices are located in most regions
                  of Texas. Several of them provide services statewide.
                </Typography>
              </Grid>
              <br />
              <Typography
                style={{ fontWeight: "bold", paddingLeft: "10px" }}
                variant="h5"
              >
                Adoption Agencies
              </Typography>
              <br />
              <Grid style={{ display: "flex", paddingLeft: "10px" }}>
                <DoubleArrowIcon />
                <Typography>
                  You may review each agency's regulatory history in Child
                  Placing Agencies For Adoption.
                </Typography>
              </Grid>
              <br />
            </Grid>

            <Grid item lg={4} style={{display:'flex',justifyContent:"center"}}>
              <MapCard />
            </Grid>
          </Grid>
          <Grid style={{ textAlign: "justify", paddingLeft: "10px" }}>
            <Typography style={{ fontWeight: "bold" }} variant="h5">
              Foster Agencies
            </Typography>
            <br />
            <Grid style={{ display: "flex" }}>
              <DoubleArrowIcon />{" "}
              <Typography>
                Many of the adoption agencies listed above also provide foster
                care services. For a complete list of available foster agencies
                in your area, please visit{" "}
                <Link target="_blank" to="https://www.dfps.texas.gov/Doing_Business/Purchased_Client_Services/Residential_Child_Care_Contracts/active_contracts.asp">
                  Active Residential Child Care Contracts
                </Link>
                . You may search for a foster agency and review each agency's
                regulatory history in{" "}
                <Link target="_blank" to="https://childcare.hhs.texas.gov/Child_Care/Search_Texas_Child_Care/ppFacilitySearchFoster.asp">
                  {" "}
                  Child Placing Agencies For Foster Care.
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid
            style={{ textAlign: "left", width: "100%", paddingLeft: "10px" }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", textAlign: "left" }}
            >
              Statewide
            </Typography>
            <br />
            <Grid style={{ display: "flex", paddingLeft: "10px" }}>
              <DoubleArrowIcon />{" "}
              <Typography>
                <Link to="/placement#informationmeeting">
                  Texas Department of Family and Protective Services (DFPS)
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{width:'100%'}}>
          <Partnerview />
        </Grid>
      </Grid>
    </div>
  );
};
export default Partners;
