import { Typography, Grid } from "@mui/material";
import React from "react";
import ImageSlider from "../../layout/imageslider";
import image from "../../../assets/newimages/acrobat_5968377.png";

const Adobe = () => {
  return (
    <div>
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <ImageSlider /> */}
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            width: "80%",
            backgroundColor: "white",
          }}
        >
          <Grid
            style={{ width: "90%", paddingTop: "20px", paddingBottom: "20px" }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", color: "#3f51b5", gap: "5px",textAlign:'center' }}
            >
              Aviso Sobre{" "}
              <img style={{ width: "30px", height: "30px" }} src={image} />{" "}
              Adobe Acrobat Reader
            </Typography>
            <br />
            <Typography style={{ textAlign: "justify" }}>
              El Departamento de Servicios para la Familia y de Protección de
              Texas (DFPS) ha publicado un número de documentos en documento
              portable (.pdf) de 'Adobe Acrobat'. Estos documentos se pueden ver
              con 'Adobe Acrobat Reader'. El programa está disponible
              gratuitamente en la red de{" "}
              <a
                target="_blank"
                style={{ color: "blue" }}
                href="https://get.adobe.com/reader/"
              >
                {" "}
                ADOBE
              </a>{" "}
              y puede ejecutarse en sistemas de computadoras de Windows, de
              Macintosh, y de Unix. Para su conveniencia, hemos fijado las
              conexiones al Adobe en varias localizaciones a través de este
              sitio.
              <a
                target="_blank"
                style={{ color: "blue", wordBreak: "break-word" }}
                href="https://get.adobe.com/reader/"
              >
                Transferencia del Adobe Acrobat Reader.
              </a>
            </Typography>
            <br />
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", color: "#3f51b5" }}
            >
              Personas Visualmente Limitados
            </Typography>
            <br />
            <Typography>
              El Adobe tambien incluye en{" "}
              <a
                target="_blank"
                style={{ color: "blue" }}
                href="https://www.adobe.com/accessibility.html"
              >
                access.adobe.com
              </a>{" "}
              ayude a las personas visualmente limitados. Ustedes pueden
              convirtier el documento que esta en .pdf al texto simple del HTML
              o del ASCII en <br />
              <a
                target="_blank"
                style={{ color: "blue", wordBreak: "break-word" }}
                href="https://www.adobe.com/accessibility.html"
              >
                http://access.adobe.com/simple_form.html.
              </a>
              <br /> Todo lo que usted tiene que hacer es incluir la direccion o
              URL en el caja rectángulo del URL y seleccionar el botón: 'Get
              this Adobe PDF Document as HTML".
              <br /> El documento del pdf del Adobe será convertido
              inmmediatamente al HTML, y Usted podra leerlo con su aparato de
              leer texto simple.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Adobe;
