import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./cerebral_palsy.css";
import "./common_diseases.css"
const CerebralPalsy = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
        id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Typography variant="h5" style={{ color: "#002F86" ,width:'100%',textAlign:"center",fontWeight:"bold"}}>
            <b>A closer look at Cerebral Palsy</b>
          </Typography>
          <br />
          <Typography style={{width:'100%',textAlign:"left"}}>
            <em>
              More...{" "}
              <a
                target="_blank"
                href="http://www.ucp.org/ucp_generaldoc.cfm/1/9/37/37-37/447"
              >
                United Cerebral Palsy Association
              </a>
            </em>
          </Typography>
          <br />
          <Grid>
            <TableContainer
              id="cerebral_palsy_table"
              component={Paper}
              style={{ border: "1px solid black", margin: "auto" }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="top"
                      style={{ borderRight: "1px solid black", width: "50%" }}
                    >
                      <a href="#what">What is Cerebral Palsy (CP)?</a>
                    </TableCell>
                    <TableCell align="top" style={{ width: "50%" }}>
                      <a href="#worse">Does CP get worse?</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="top"
                      style={{ borderRight: "1px solid black" }}
                    >
                      <a href="#why">What causes CP and how is it diagnosed?</a>
                    </TableCell>
                    <TableCell align="top">
                      <a href="#how">How many people have CP?</a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="top"
                      style={{ borderRight: "1px solid black" }}
                    >
                      <a href="#types">
                        What do the different types of CP mean?
                      </a>
                    </TableCell>
                    <TableCell align="top">
                      <a href="#cured">Can CP be cured?</a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <br />
          <Grid id="what">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>What is Cerebral Palsy?</b>
            </Typography>
            <br />
            <Typography>
              Cerebral means brain, and palsy is defined as a loss of control or
              weakness in movement. Putting the two words together, Cerebral
              Palsy (CP) is a loose term which is given to a number of central
              nervous system (CNS) motor disorders which are characterized by
              impairment of voluntary muscle movement. In individuals who have
              Cerebral Palsy, the parts of the body that provide for movement,
              such as the muscles, nerves, and the spinal cord are normal.
              However, the brain, which is the command center for sending
              messages to those parts of the body that coordinate movement, is
              unable to do so in its usual manner. There are a number of
              conditions that may be associated with Cerebral Palsy.
              Intellectual disability is commonly associated with Cerebral
              Palsy.
            </Typography>
            <br />
            <Typography>
              The percentage of individuals experiencing intellectual disability
              in some form ranges from 25 to 75 percent. Some individuals with
              Cerebral Palsy may have seizure disorders. Others may experience
              problems with vision, such as Strabismus. In some instances,
              individuals with Cerebral Palsy may experience difficulty
              swallowing (dysphasia). Learning disabilities are also a
              possibility. Problems may arise in bowel and bladder control, as
              well as in hearing or speech, and in gross or fine motor control.
            </Typography>
          </Grid>
          <br />
          <Grid id="why">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>What causes Cerebral Palsy, and how is it diagnosed?</b>
            </Typography>
            <br />
            <Typography>
              In many instances, the cause of Cerebral Palsy in an individual
              may be difficult to pin down. For decades, it had been thought
              that in the majority of cases anoxia, or the lack of oxygen,
              either prior to birth or during birth was the main cause of
              Cerebral Palsy. Recent studies, however, have found that anoxia is
              the cause of Cerebral Palsy in only between 3 and 12 percent of
              the cases. Approximately 85 percent of the brain damage related to
              Cerebral Palsy occurs prior to birth. Some of the prenatal causes
              may be prematurity, lack of proper nutrition, or virus. In 75
              percent of prenatal Cerebral Palsy, the cause can not be
              determined. Of the other 15 percent of Cerebral Palsy that occurs
              post-natally, the main cause is infection, usually measles. Other
              post-natal causes are automobile accidents, falls, and child
              abuse.
            </Typography>
            <br />
            <Typography>
              Since in many cases the cause of Cerebral Palsy is difficult to
              determine, risk factors are difficult to establish with any
              measure of statistical certainty. However, there is a risk to
              children who are born prematurely and are of low birth weight when
              the mother has had a history of reproductive problems. Women who
              have had multiple miscarriages are at an increased risk of giving
              birth to a child with Cerebral Palsy. Statistically, more whites
              than African-Americans are affected, as are more males than
              females.
            </Typography>
          </Grid>
          <br />
          <Grid id="types">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>What do the different types of CP mean?</b>
            </Typography>
            <br />
            <Typography>
              There are four different types of Cerebral Palsy. Spastic Cerebral
              Palsy is the most common form, affecting approximately 70 percent
              of all individuals with Cerebral Palsy. Spasticity can be either a
              mild or severe impairment of motor functions. The affected limbs
              are usually underdeveloped, and there is a tendency for the
              individual to have a "scissor gait" or to toe walk. It is also
              common for the affected individual to have hypertonicity, an
              excessive tension of muscles.
            </Typography>
            <Typography>
              <b>There are four sub-groups of spasticity:</b>
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li>
                Hemiplegia - involvement of both limbs on one side; the arm
                usually more affected.
              </li>
              <li>
                Paraplegia - involvement of both legs; arms minimally involved,
                or not at all.
              </li>
              <li>
                Quadriplegia or Tetraplegia - involvement of all limbs, usually
                to the same degree.
              </li>
              <li>
                Diplegia - intermediate form between paraplegia and
                quadriplegia; both legs involved.
              </li>
            </ul>
            <br />
            <Typography>
              Athetoid or Dyskinetic Cerebral Palsy occurs approximately 20
              percent of the time. It is characterized by low muscle tone, slow,
              writhing movement patterns and involuntary jerking of the head or
              of the arms and legs. The movements generally increase with
              emotional tension and decrease while the individual is asleep.
            </Typography>
            <br />
            <Typography>
              Ataxic Cerebral Palsy is rare, occurring in only about 10 percent
              of all individuals affected. It is characterized by weakness,
              uncoordinated movements, and unsteadiness. A wide gait and
              difficulty with fine motor skills is also common.
            </Typography>
            <br />
            <Typography>
              Mixed forms or Cerebral Palsy are common, and is exactly what the
              name implies. There can be a combination of any of the Cerebral
              Palsy forms, however, spasticity and athetosis are the most common
              combination.
            </Typography>
          </Grid>
          <br />
          <Grid id="worse">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>Does Cerebral Palsy get worse?</b>
            </Typography>
            <br />
            <Typography>
              No. Cerebral Palsy is a nonprogressive disorder. This means that
              whatever damage was done to the brain will not get any worse. The
              problem that many parents face is that, generally, it is
              impossible to diagnose Cerebral Palsy with any certainty in
              infancy. Many cases are not diagnosed until somewhere around age
              two. It only appears that the disorder is progressive because the
              symptoms may not appear until the child's lack of motor skills, or
              other developmental delays begin to emerge.
            </Typography>
            <br />
            <Typography>
              For example, a child at age two may have difficulty walking and at
              age six difficulty in writing and reading. The difficulty in
              reading and writing is not a progression of the Cerebral Palsy,
              but has appeared as a developmental milestone should have been
              attained. In other words, the difficulty at age six will occur if
              the brain injury affected that particular developmental milestone,
              regardless of any interventions on the part of the parents.
            </Typography>
          </Grid>
          <br />
          <Grid id="how">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>How many people have Cerebral Palsy?</b>
            </Typography>
            <br />
            <Typography>
              It is estimated that some 500,000 children and adults in the
              United States manifest one or more of the symptoms of Cerebral
              Palsy. Currently, about 8,000 babies and infants are diagnosed
              with the condition each year. In addition, some 1,200 - 1,500
              preschool age children are recognized each year to have Cerebral
              Palsy.
            </Typography>
            <br />
            <Typography>
              Children with Cerebral Palsy may exhibit a wide range of symptoms,
              including involuntary muscular reactions; muscle rigidity;
              problems with sucking, chewing, and swallowing; incontinence;
              inability or difficulty in speaking; trouble concentrating, lack
              of sensing things by touch; and hearing and vision problems.
            </Typography>
          </Grid>
          <br />
          <Grid id="cured">
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>Can Cerebral Palsy be cured?</b>
            </Typography>
            <br />
            <Typography>
              There is no treatment, as such, that will cure Cerebral Palsy. The
              main goal of professionals who work with individuals affected by
              Cerebral Palsy is to foster as much independence for the
              individual as his or her impairment will allow. Individuals with
              seizure disorders may be effectively controlled with
              anticonvulsive medications. Physical therapy, speech therapy, and
              occupational therapy are frequently utilized to maximize
              participation and independence.
            </Typography>
            <br />
            <Typography>
              For individuals who do not have intellectual involvement,
              accommodations can be made to living spaces, places of employment,
              and schools so that they may be included in the everyday world of
              business, education, and recreation. For many individuals with
              Cerebral Palsy, inclusion is more a matter or management of their
              disorder, rather than treatment or a cure.
            </Typography>
            <br />
            <Typography>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the{" "}
              <a href="/generalInformation">Get Started </a>section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href=""> Texas Adoption Resource Exchange (TARE) </a>or call
              1-800-233-3405.
            </Typography>
          </Grid>
          <Grid style={{ float: "right" }}>
            <Typography>
              This content taken from:{" "}
              <a
                target="_blank"
                href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
              >
                Adoption and Foster Care
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CerebralPalsy;
