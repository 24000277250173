import { Typography, Grid, Button } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ImageSlider from "../../layout/imageslider";
import { useState } from "react";
import { Form } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";

const Waitingform = () => {
  // const [age, setAge] = useState("");
  // const grouphandleChange = (event) => {
  //   setAge(event.target.value);
  // };

  // const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [groupType, setGroupType] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [gender, setGender] = useState("");
  const [region, setRegion] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [behavioral, setBehavioral] = useState("");
  const [emotional, setEmotional] = useState("");
  const [physical, setPhysical] = useState("");
  const [medical, setMedical] = useState("");
  const [learning, setLearning] = useState("");
  const [developmental, setDevelopmental] = useState("");
  const [riskFactors, setRiskFactors] = useState("");

  const handleGroupTypeChange = (event) => {
    setGroupType(event.target.value);
  };

  const handleAgeRangeChange = (event) => {
    setAgeRange(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  const handleEthnicityChange = (event) => {
    setEthnicity(event.target.value);
  };

  const handleBehavioralChange = (event) => {
    setBehavioral(event.target.value);
  };

  const handleEmotionalChange = (event) => {
    setEmotional(event.target.value);
  };

  const handlePhysicalChange = (event) => {
    setPhysical(event.target.value);
  };

  const handleMedicalChange = (event) => {
    setMedical(event.target.value);
  };

  const handleLearningChange = (event) => {
    setLearning(event.target.value);
  };

  const handleDevelopmentalChange = (event) => {
    setDevelopmental(event.target.value);
  };
  const handleRiskFactorsChange = (event) => {
    setRiskFactors(event.target.value);
  };

  // multiple checkbox list item
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = [
    "American Indian/Alaskan Native",
    " Asian",
    "Black/African Descent",
    "Native Hawaiian/Pacific Islander",
    "White/European Descent",
  ];

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* <ImageSlider /> */}
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "80%",
          backgroundColor: "white",
        }}
      >
        <Grid style={{ width: "90%" }}>
          {" "}
          <Grid style={{ textAlign: "left", paddingTop: "20px" }}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", color: "#3f51b5" }}
            >
              Search for Children Waiting for Adoption
            </Typography>
            <br />
            <Typography style={{ textAlign: "justify" }}>
              Enter your search criteria below to view the profiles of children
              or sibling groups waiting for their forever family. The search
              results section below will list the children matching the
              criteria.
            </Typography>
            <br />
            <Typography style={{ textAlign: "justify" }}>
              Families need to create an account and complete a family profile
              in order to view the full profile information and to inquire on
              children or sibling groups. Refer to the{" "}
              <a
                target="_blank"
                style={{ color: "blue" }}
                href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/pdf/TARE_Family_User_Manual.pdf"
              >
                User Guide
              </a>{" "}
              for tips and guidelines on creating an account and completing a
              famuly profile.
            </Typography>
            <br />
            <Typography style={{ textAlign: "justify" }}>
              If you are having problems viewing this site, we recommend
              upgrading to the most recent version of your browser. If you are
              still having problems,
              <a href="#"> contact us.</a>
            </Typography>
          </Grid>{" "}
          <br />
          <Grid>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                color: "#3f51b5",
                textAlign: "left",
              }}
            >
              Child Characteristics
            </Typography>
            <br />
            {/* <Grid>
              <Form style={{backgroundColor: 'greenyellow'}}>
                <Grid style={{display: 'flex', gap: '10px'}}>
                <TextField id="filled-basic" label="First Name" variant="filled" />
                <TextField id="filled-basic" label="Tare ID" variant="filled" />
                </Grid>
              </Form>
            </Grid> */}
            <Grid>
              <Form>
                <Grid style={{ backgroundColor: "" }}>
                  <Grid
                    container
                    style={{
                      gap: "10px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item lg={5} sm={10} xs={10}>
                      <TextField
                        id="filled-basic"
                        label="First Name"
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={5} sm={10} xs={10}>
                      <TextField
                        id="filled-basic"
                        label="Tare Id"
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                  </Grid>{" "}
                  <br />
                  <Grid
                    container
                    style={{
                      gap: "10px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        style={{ paddingRight: "50px" }}
                        fullWidth
                        // sx={{ width: "300px", textAlign: "left",gap: '10px' }}
                      >
                        <InputLabel id="">Group Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="grouptype"
                          name="groupType"
                          value={groupType}
                          onChange={handleGroupTypeChange}
                          label="Group Type"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>All Groups</MenuItem>
                          <MenuItem value={20}>Siblings Only</MenuItem>
                          <MenuItem value={30}>
                            Individual Children Only
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        style={{ paddingRight: "50px" }}
                        fullWidth
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Age
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={ageRange}
                          onChange={handleAgeRangeChange}
                          label="Age"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>All Ages</MenuItem>
                          <MenuItem value={20}>6 Years Old or Less</MenuItem>
                          <MenuItem value={30}>7 to 12 Years Old</MenuItem>
                          <MenuItem value={30}>13 to 17 Years Old</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>{" "}
                  <br />
                  <Grid
                    container
                    style={{
                      gap: "10px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={gender}
                          onChange={handleGenderChange}
                          label="Gender"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Boy or Girl</MenuItem>
                          <MenuItem value={20}>Boy</MenuItem>
                          <MenuItem value={30}>Girl</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // style={{ paddingRight: "50px" }}
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Region
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={region}
                          onChange={handleRegionChange}
                          label="Region"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>
                            Abilene / N/W - Northwest
                          </MenuItem>
                          <MenuItem value={20}>
                            Arlington / ARL - Arlington
                          </MenuItem>
                          <MenuItem value={30}>
                            Austin / E/C - East Central
                          </MenuItem>
                          <MenuItem value={40}>
                            Beaumont / E/C - East Central
                          </MenuItem>
                          <MenuItem value={50}>
                            Beaumont / E/C - East Central
                          </MenuItem>
                          <MenuItem value={60}>
                            Edinburg / SOU - South Texas
                          </MenuItem>
                          <MenuItem value={70}>
                            El Paso / N/W - Northwest
                          </MenuItem>
                          <MenuItem value={80}>
                            Houston / HOU - Houston
                          </MenuItem>
                          <MenuItem value={90}>
                            Lubbock / N/W - Northwest
                          </MenuItem>
                          <MenuItem value={100}>
                            Midland / N/W - Northwest
                          </MenuItem>
                          <MenuItem value={110}>
                            Other / S/O - State Office
                          </MenuItem>
                          <MenuItem value={120}>
                            San Antonio / SOU - South Texas
                          </MenuItem>
                          <MenuItem value={130}>
                            Other / S/O - State Office
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>{" "}
                  <br />
                  <Grid
                    container
                    style={{
                      gap: "10px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // style={{ paddingRight: "50px" }}
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Ethnicity
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={ethnicity}
                          onChange={handleEthnicityChange}
                          label="Ethnicity"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Does Not Matter</MenuItem>
                          <MenuItem value={20}>Hispanic</MenuItem>
                          <MenuItem value={30}>Non-Hispanic</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Race
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleChange}
                          input={<OutlinedInput label="Race" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>{" "}
                </Grid>
                <br />
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "pink",
                  }}
                >
                  <Grid
                    style={{
                      width: "70%",
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {/* <Grid style={{display: 'flex'}}>
                      <Grid style={{width: '50%'}}>
                      <FormControl
                      variant="filled" fullWidth></FormControl>
                      <InputLabel id="demo-simple-select-standard-label">
                        Ethnicity
                      </InputLabel>
                      <Checkbox {...label} />
                      American Indian/Alaskan Native</Grid>
                     <Grid> <Checkbox {...label} />
                      Asian</Grid>
                    </Grid>{" "} */}
                    {/* <br />
                    <Grid style={{}}>
                      <Checkbox {...label} />
                      Black/African Descent
                      <Checkbox {...label} />
                      Native Hawaiian/Pacific Islander
                    </Grid>{" "} */}
                    {/* <br />
                    <Grid>
                      <Checkbox {...label} />
                      White/European Descent
                    </Grid> */}
                  </Grid>
                </Grid>{" "}
                <br />
                <hr />
                <Grid>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "bold",
                      color: "#3f51b5",
                      textAlign: "left",
                    }}
                  >
                    Special Needs
                  </Typography>
                  <br />
                  <Typography
                    style={{ textAlign: "left", textAlign: "justify" }}
                  >
                    Select the highest level of need or each category you are
                    willing to accept.
                    <br />
                    *For more information on each Special Needs category, see
                    the
                    <a
                      style={{ color: "blue" }}
                      href="/childdisabilitylevel
                    "
                    >
                      {" "}
                      Special Needs Description.
                    </a>
                  </Typography>
                  <br />
                  <Grid
                    container
                    style={{
                      gap: "10px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // style={{ paddingRight: "50px" }}
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Behavioral
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={behavioral}
                          onChange={handleBehavioralChange}
                          label="Behavioral"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Mild</MenuItem>
                          <MenuItem value={20}>Moderate</MenuItem>
                          <MenuItem value={30}>Severe</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // style={{ paddingRight: "50px" }}
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Emotional
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={emotional}
                          onChange={handleEmotionalChange}
                          label="Emotional"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Mild</MenuItem>
                          <MenuItem value={20}>Moderate</MenuItem>
                          <MenuItem value={30}>Severe</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>{" "}
                  <br />
                  <Grid
                    container
                    style={{
                      gap: "10px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // style={{ paddingRight: "50px" }}
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Physical
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={physical}
                          onChange={handlePhysicalChange}
                          label="Physical"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Mild</MenuItem>
                          <MenuItem value={20}>Moderate</MenuItem>
                          <MenuItem value={30}>Severe</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // style={{ paddingRight: "50px" }}
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Medical
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={medical}
                          onChange={handleMedicalChange}
                          label="Medical"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Mild</MenuItem>
                          <MenuItem value={20}>Moderate</MenuItem>
                          <MenuItem value={30}>Severe</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>{" "}
                  <br />
                  <Grid
                    container
                    style={{
                      gap: "10px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // style={{ paddingRight: "50px" }}
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Learning
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={learning}
                          onChange={handleLearningChange}
                          label="Learning"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Mild</MenuItem>
                          <MenuItem value={20}>Moderate</MenuItem>
                          <MenuItem value={30}>Severe</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // style={{ paddingRight: "50px" }}
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Developmental
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={developmental}
                          onChange={handleDevelopmentalChange}
                          label="Developmental"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Mild</MenuItem>
                          <MenuItem value={20}>Moderate</MenuItem>
                          <MenuItem value={30}>Severe</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>{" "}
                  <br />
                  <Grid
                    container
                    style={{
                      gap: "10px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid lg={5} sm={10} xs={10}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        // style={{ paddingRight: "50px" }}
                        // sx={{ width: "300px", textAlign: "left" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Risk Factors
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={riskFactors}
                          onChange={handleRiskFactorsChange}
                          label="Risl Factors"
                          style={{ textAlign: "left" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Mild</MenuItem>
                          <MenuItem value={20}>Moderate</MenuItem>
                          <MenuItem value={30}>Severe</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <Button variant="contained" color="success">
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Waitingform;
