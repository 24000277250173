import { Grid, Typography, Card, CardContent, CardHeader } from "@mui/material";
import ImageSlider from "../layout/imageslider";
import VideocamIcon from "@mui/icons-material/Videocam";
import FeedIcon from "@mui/icons-material/Feed";
import FullWidthTabs from "./fullwidthTab";
import { Link } from "react-router-dom";
import "./adoptionoverview.css";
const AdoptionOverview = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width:"100%",
      }}
    >
      {/* <ImageSlider /> */}
      {/* Box card view grid */}
      <Grid
        id="maingrid"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            color: "#255aad",
            textAlign: "center",
            marginBottom: "20px",
            textDecoration: "underline",
          }}
        >
          Adoption Overview
        </Typography>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <Grid
            item
            lg={6}
            xs={11}
            md={6}
            sm={12}
            style={{ marginTop: "20px" }}
          >
            <Typography
              style={{
                fontStyle: "bold",
                textAlign: "justify",
                color: "#1A355D",
                lineHeight: "1.7",
                wordSpacing: "normal",
                letterSpacing: "normal",
                WebkitHyphens: "auto",
                MozHyphens: "auto",
                msHyphens: "auto",
                hyphens: "auto",
              }}
            >
              &ensp; &ensp; Adoption is a profound legal process where
              individuals or couples become the legal parents of a child not
              biologically related to them. It's a life-changing journey filled
              with both joy and challenges, necessitating careful consideration,
              preparation, and support from adoption agencies. Types of adoption
              include domestic, international, and foster care adoption, each
              offering unique dynamics. The process involves home studies,
              background checks, interviews, and legal procedures to ensure a
              stable and loving environment for the child. Adoption provides
              children with loving families and fulfills the dreams of aspiring
              parents. Despite complexities, it's a noble and compassionate way
              to create families and bring lasting happiness.
            </Typography>
          </Grid>
          <Grid
            item
            lg={5}
            xs={11}
            md={5}
            sm={10}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Box sx={{maxWidth: 600, backgroundColor: 'pink', display: 'flex', justifyContent: 'center', alignContent: 'center', margin: 'auto', border: '5px solid black'}}> */}
            <Card>
              <CardHeader
                style={{ backgroundColor: "rgb(53, 103, 178)" }}
              ></CardHeader>
              <CardContent>
                <Typography
                  style={{ fontSize: 20, textAlign: "left" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>More Information on Adoption</b>
                </Typography>
                <ul style={{ textAlign: "left", lineHeight: "20px" }}>
                  <li style={{ lineHeight: "30px" }}>
                    <Link to="/positiveview" style={{ color: "blue" }}>
                      Positive Adoption Language
                    </Link>
                  </li>
                  <li style={{ lineHeight: "30px" }}>
                    <Link
                      target="_blank"
                      to="https://www.dfps.texas.gov/Child_Protection/Adoption/Adoption_or_PMC.asp"
                      style={{ color: "blue" }}
                    >
                      Adoption or Permanent Managing Conservatorship
                    </Link>
                  </li>
                  <li style={{ lineHeight: "30px" }}>
                    <Link to="/fostervideo" style={{ color: "blue" }}>
                      <VideocamIcon style={{ color: "rgb(53, 103, 178)" }} />
                      foster/adopt videos and Public Service Announcements.
                    </Link>
                  </li>
                  <li style={{ lineHeight: "30px" }}>
                    <Link
                      target="_blank"
                      to="https://www.dfps.texas.gov/Child_Protection/Adoption/Adoption_Registry/default.asp"
                      style={{ color: "blue" }}
                    >
                      DFPS Adoption Registry
                    </Link>
                  </li>
                  <li style={{ lineHeight: "30px" }}>
                    <Link
                      target="_blank"
                      to="https://www.dfps.texas.gov/site_map/forms.asp"
                      style={{ color: "blue" }}
                    >
                      <FeedIcon style={{ color: "rgb(53, 103, 178)" }} /> Need a
                      Form? View List
                    </Link>
                  </li>
                </ul>
              </CardContent>
            </Card>
            {/* </Box> */}
          </Grid>
        </Grid>
        <Grid style={{width:"100%"}}>
          <FullWidthTabs />
        </Grid>
      </Grid>
      {/* Tab panel grid */}
    </div>
  );
};

export default AdoptionOverview;
