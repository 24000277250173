import React from "react";
import {  Grid, Typography } from "@mui/material";
import txregions from "../../images/txregions.gif";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MapCard = () => {
  const navigate = useNavigate();
  const [selectedRegion, setSelectedRegion] = useState("");

  const handleSelectChange = (event) => {
    setSelectedRegion(event.target.value);
    if (event.target.value === "01") {
      navigate("/region1");
    }
    if (event.target.value === "02") {
      navigate("/region2");
    }
    if (event.target.value === "03") {
      navigate("/region3");
    }
    if (event.target.value === "04") {
      navigate("/region4,5,6");
    }
    if (event.target.value === "05") {
      navigate("/region4,5,6");
    }
    if (event.target.value === "06") {
      navigate("/region4,5,6");
    }
    if (event.target.value === "07") {
      navigate("/region7");
    }
    if (event.target.value === "08") {
      navigate("/region8");
    }
    if (event.target.value === "09") {
      navigate("/region9");
    }
    if (event.target.value === "10") {
      navigate("/region10");
    }
    if (event.target.value === "11") {
      navigate("/region11");
    }
  };

  return (
    <div
      style={{
        // backgroundColor: "red",
        height: "320px",
        display: "flex",
        // backgroundColor: "grey",
      }}
    >
      <Grid
        id="MapCard"
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          height: "300px",
          width: "250px",
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border:"1px solid black"
        }}
      >
        <Typography style={{ fontWeight: "bold" }}>
          SELECT YOUR TEXAS AREA
        </Typography>
        <div style={{ textAlign: "center" }}>
          <img
            src={txregions}
            width="160"
            height="151"
            alt="Map of Texas"
            useMap="#MapMap"
            style={{ verticalAlign: "middle" }}
          />
          <map name="MapMap" id="Map2">
            <area
              shape="poly"
              coords="114,63,92,74,94,84,100,88,108,96,117,99,130,88,130,72"
              href="/region7"
              alt="Region 7"
            />
            <area
              shape="poly"
              coords="61,97,60,92,86,91,91,89,97,93,101,96,106,100,116,101,120,107,121,113,112,115,99,112,95,113,94,120,79,118"
              href="/region8"
              alt="Region 8"
            />
            <area
              shape="poly"
              coords="132,81,136,86,141,87,144,91,147,97,142,103,137,107,131,109,124,107,122,102,124,96"
              href="/region4,5,6#region6"
              alt="Region 6"
            />
            <area
              shape="poly"
              coords="50,3,79,4,80,29,73,31,73,38,77,42,69,44,68,51,44,52,46,18"
              href="/region1"
              alt="Region 1"
            />
            <area
              shape="poly"
              coords="85,32,78,37,78,44,73,48,71,55,70,62,79,64,79,69,88,72,97,66,95,57,95,49,102,48,108,40,97,36"
              href="/region2"
              alt="Region 2"
            />
            <area
              shape="poly"
              coords="112,40,112,46,106,48,108,53,100,52,102,59,102,63,107,60,114,60,119,65,123,63,124,56,126,50,125,43,118,39"
              href="/region3"
              alt="Region 3"
            />
            <area
              shape="poly"
              coords="32,64,31,78,43,88,52,95,59,96,58,90,88,89,89,85,92,84,89,80,89,73,78,71,76,65,65,64,65,53,44,53,44,63"
              href="/region9"
              alt="region 9"
            />
            <area
              shape="poly"
              coords="150,62,148,41,137,36,130,37,126,59,129,67,136,69,141,70,142,64,150,62"
              href="/region4,5,6#region4"
              alt="region 4"
            />
            <area
              shape="poly"
              coords="150,94,156,87,157,74,151,65,142,66,141,72,133,70,133,77,139,84,145,88,149,93"
              href="/region4,5,6#region5"
              alt="region 5"
            />
            <area
              shape="poly"
              coords="117,149,112,134,115,122,119,117,107,113,96,113,95,120,88,121,80,119,87,131,91,141,100,146,106,149,115,148"
              href="/region11"
              alt="region 11"
            />
            <area
              shape="poly"
              coords="30,66,3,66,18,82,25,92,32,103,41,107,44,99,47,94,31,80"
              href="/region10"
              alt="10 - Northwest District"
            />
          </map>
        </div>
        <Typography>Select country</Typography>
        {/* <br> */}
        <form name="form" id="form">
          <select value={selectedRegion} onChange={handleSelectChange}>
            <option value="#" selected="">
              {" "}
              - - - - -
            </option>
            <option value="04">ANDERSON</option>
            <option value="09">ANDREWS</option>
            <option value="05">ANGELINA</option>
            <option value="11">ARANSAS</option>
            <option value="02">ARCHER</option>
            <option value="01">ARMSTRONG</option>
            <option value="08">ATASCOSA</option>
            <option value="06">AUSTIN</option>
            <option value="01">BAILEY</option>
            <option value="08">BANDERA</option>
            <option value="07">BASTROP</option>
            <option value="02">BAYLOR</option>
            <option value="11">BEE</option>
            <option value="07">BELL</option>
            <option value="08">BEXAR</option>
            <option value="07">BLANCO</option>
            <option value="09">BORDEN</option>
            <option value="07">BOSQUE</option>
            <option value="04">BOWIE</option>
            <option value="06">BRAZORIA</option>
            <option value="07">BRAZOS</option>
            <option value="10">BREWSTER</option>
            <option value="01">BRISCOE</option>
            <option value="11">BROOKS</option>
            <option value="02">BROWN</option>
            <option value="07">BURLESON</option>
            <option value="07">BURNET</option>
            <option value="07">CALDWELL</option>
            <option value="08">CALHOUN</option>
            <option value="02">CALLAHAN</option>
            <option value="11">CAMERON</option>
            <option value="04">CAMP</option>
            <option value="01">CARSON</option>
            <option value="04">CASS</option>
            <option value="01">CASTRO</option>
            <option value="06">CHAMBERS</option>
            <option value="04">CHEROKEE</option>
            <option value="01">CHILDRESS</option>
            <option value="02">CLAY</option>
            <option value="01">COCHRAN</option>
            <option value="09">COKE</option>
            <option value="02">COLEMAN</option>
            <option value="03">COLLIN</option>
            <option value="01">COLLINGSWORTH</option>
            <option value="06">COLORADO</option>
            <option value="08">COMAL</option>
            <option value="02">Commanche</option>
            <option value="09">CONCHO</option>
            <option value="03">COOKE</option>
            <option value="07">CORYELL</option>
            <option value="02">COTTLE</option>
            <option value="09">CRANE</option>
            <option value="09">CROCKETT</option>
            <option value="01">CROSBY</option>
            <option value="10">CULBERSON</option>
            <option value="01">DALLAM</option>
            <option value="03">DALLAS</option>
            <option value="09">DAWSON</option>
            <option value="01">DEAF SMITH</option>
            <option value="04">DELTA</option>
            <option value="03">DENTON</option>
            <option value="08">DEWITT</option>
            <option value="01">DICKENS</option>
            <option value="08">DIMMIT</option>
            <option value="01">DONLEY</option>
            <option value="11">DUVAL</option>
            <option value="02">EASTLAND</option>
            <option value="09">ECTOR</option>
            <option value="08">EDWARDS</option>
            <option value="03">ELLIS</option>
            <option value="10">EL PASO</option>
            <option value="03">ERATH</option>
            <option value="07">FALLS</option>
            <option value="03">FANNIN</option>
            <option value="07">FAYETTE</option>
            <option value="02">FISHER</option>
            <option value="01">FLOYD</option>
            <option value="02">FOARD</option>
            <option value="06">FORT BEND</option>
            <option value="04">FRANKLIN</option>
            <option value="07">FREESTONE</option>
            <option value="08">FRIO</option>
            <option value="09">GAINES</option>
            <option value="06">GALVESTON</option>
            <option value="01">GARZA</option>
            <option value="08">GILLESPIE</option>
            <option value="09">GLASSCOCK</option>
            <option value="08">GOLIAD</option>
            <option value="08">GONZALES</option>
            <option value="01">GRAY</option>
            <option value="03">GRAYSON</option>
            <option value="04">GREGG</option>
            <option value="07">GRIMES</option>
            <option value="08">GUADALUPE</option>
            <option value="01">HALE</option>
            <option value="01">HALL</option>
            <option value="07">HAMILTON</option>
            <option value="01">HANSFORD</option>
            <option value="02">HARDEMAN</option>
            <option value="05">HARDIN</option>
            <option value="06">HARRIS</option>
            <option value="04">HARRISON</option>
            <option value="01">HARTLEY</option>
            <option value="02">HASKELL</option>
            <option value="07">HAYS</option>
            <option value="01">HEMPHILL </option>
            <option value="04">HENDERSON</option>
            <option value="11">HIDALGO</option>
            <option value="07">HILL_</option>
            <option value="01">HOCKLEY</option>
            <option value="03">HOOD</option>
            <option value="04">HOPKINS</option>
            <option value="05">HOUSTON</option>
            <option value="09">HOWARD</option>
            <option value="10">HUDSPETH</option>
            <option value="03">HUNT</option>
            <option value="01">HUTCHINSON </option>
            <option value="09">IRION </option>
            <option value="02">JACK </option>
            <option value="08">JACKSON </option>
            <option value="05">JASPER </option>
            <option value="10">JEFF DAVIS </option>
            <option value="05">JEFFERSON </option>
            <option value="11">JIM HOGG </option>
            <option value="11">JIM WELLS </option>
            <option value="03">JOHNSON </option>
            <option value="02">JONES </option>
            <option value="08">KARNES </option>
            <option value="03">KAUFMAN </option>
            <option value="08">KENDALL </option>
            <option value="11">KENEDY </option>
            <option value="02">KENT</option>
            <option value="08">KERR </option>
            <option value="09">KIMBLE </option>
            <option value="01">KING </option>
            <option value="08">KINNEY</option>
            <option value="11">KLEBERG </option>
            <option value="02">KNOX</option>
            <option value="04">LAMAR </option>
            <option value="01">LAMB </option>
            <option value="07">LAMPASAS </option>
            <option value="08">LA SALLE </option>
            <option value="08">LAVACA </option>
            <option value="07">LEE </option>
            <option value="07">LEON </option>
            <option value="06">LIBERTY </option>
            <option value="07">LIMESTONE </option>
            <option value="01">LIPSCOMB </option>
            <option value="11">LIVE OAK </option>
            <option value="07">LLANO </option>
            <option value="09">LOVING </option>
            <option value="01">LUBBOCK </option>
            <option value="01">LYNN </option>
            <option value="07">MADISON </option>
            <option value="04">MARION </option>
            <option value="09">MARTIN </option>
            <option value="09">MASON </option>
            <option value="06">MATAGORDA </option>
            <option value="08">MAVERICK </option>
            <option value="09">MCCULLOCH</option>
            <option value="07">MCLENNAN </option>
            <option value="11">MCMULLEN </option>
            <option value="08">MEDINA </option>
            <option value="09">MENARD </option>
            <option value="09">MIDLAND </option>
            <option value="07">MILAM </option>
            <option value="07">MILLS</option>
            <option value="02">MITCHELL </option>
            <option value="02">MONTAGUE </option>
            <option value="06">MONTGOMERY </option>
            <option value="01">MOORE </option>
            <option value="04">MORRIS </option>
            <option value="01">MOTLEY </option>
            <option value="05">NACOGDOCHES </option>
            <option value="03">NAVARRO </option>
            <option value="05">NEWTON </option>
            <option value="02">NOLAN </option>
            <option value="11">NUECES </option>
            <option value="01">OCHILTREE </option>
            <option value="01">OLDHAM </option>
            <option value="05">ORANGE </option>
            <option value="03">PALO PINTO </option>
            <option value="04">PANOLA</option>
            <option value="03">PARKER</option>
            <option value="01">PARMER</option>
            <option value="09">PECOS</option>
            <option value="05">POLK</option>
            <option value="01">POTTER</option>
            <option value="10">PRESIDIO</option>
            <option value="04">RAINS</option>
            <option value="01">RANDALL</option>
            <option value="09">REAGAN</option>
            <option value="08">REAL</option>
            <option value="04">RED RIVER</option>
            <option value="09">REEVES</option>
            <option value="11">REFUGIO</option>
            <option value="01">ROBERTS</option>
            <option value="07">ROBERTSON</option>
            <option value="03">ROCKWALL</option>
            <option value="02">RUNNELS</option>
            <option value="04">RUSK</option>
            <option value="05">SABINE</option>
            <option value="05">SAN AUGUSTINE </option>
            <option value="05">SAN JACINTO </option>
            <option value="11">SAN PATRICIO </option>
            <option value="07">SAN SABA</option>
            <option value="09">SCHLEICHER</option>
            <option value="02">SCURRY</option>
            <option value="02">SHACKELFORD</option>
            <option value="05">SHELBY</option>
            <option value="01">SHERMAN</option>
            <option value="04">SMITH_</option>
            <option value="03">SOMERVELL</option>
            <option value="11">STARR</option>
            <option value="02">STEPHENS</option>
            <option value="09">STERLING</option>
            <option value="02">STONEWALL</option>
            <option value="09">SUTTON</option>
            <option value="01">SWISHER</option>
            <option value="03">TARRANT</option>
            <option value="02">TAYLOR</option>
            <option value="09">TERRELL</option>
            <option value="01">TERRY</option>
            <option value="02">THROCKMORTON</option>
            <option value="04">TITUS</option>
            <option value="09">TOM GREEN</option>
            <option value="07">TRAVIS</option>
            <option value="05">TRINITY</option>
            <option value="05">TYLER</option>
            <option value="04">UPSHUR</option>
            <option value="09">UPTON</option>
            <option value="08">UVALDE</option>
            <option value="08">VAL VERDE</option>
            <option value="04">VAN ZANDT </option>
            <option value="08">VICTORIA</option>
            <option value="06">WALKER</option>
            <option value="06">WALLER</option>
            <option value="09">WARD_</option>
            <option value="07">WASHINGTON</option>
            <option value="11">WEBB</option>
            <option value="06">WHARTON</option>
            <option value="01">WHEELER</option>
            <option value="02">WICHITA</option>
            <option value="02">WILBARGER</option>
            <option value="11">WILLACY</option>
            <option value="07">WILLIAMSON</option>
            <option value="08">WILSON</option>
            <option value="09">WINKLER</option>
            <option value="03">WISE</option>
            <option value="04">WOOD</option>
            <option value="01">YOAKUM</option>
            <option value="02">YOUNG</option>
            <option value="11">ZAPATA</option>
            <option value="08">ZAVALA</option>
          </select>
        </form>
      </Grid>
    </div>
  );
};
export default MapCard;
