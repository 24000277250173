import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";
const Diabetes = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>A closer look at Diabetes</b>
            </Typography>
            <br />
            <Typography>
              {" "}
              <em>
                More...
                <a target="_blank" href="http://www.diabetes.org/">
                  American Diabetes Association
                </a>
              </em>
            </Typography>
            <br />
            <Typography>
              Diabetes mellitus is a chronic disorder that impairs the body's
              ability to turn sugar into energy.
            </Typography>
            <br />
            <Typography>
              One form of the disease is called "juvenile diabetes" because it
              mainly occurs in young people, often starting in childhood. Other
              names for it include "diabetes mellitus type I" and
              "insulin-dependent diabetes." There is a type II diabetes that
              usually affects people over the age of 40.
            </Typography>
            <br />
            <Typography>
              The pancreas of a child with diabetes mellitus type I does not
              produce enough insulin for the absorption of glucose (sugar) by
              cells which need it for energy and by the liver which stores it.
              Without energy from glucose, the body gets energy from fat and
              this produces a toxic substance called ketones.
            </Typography>
            <br />
            <Typography>
              Diabetes is not contagious. There is no cure. Children with
              diabetes mellitus type I need daily injections of insulin, the
              hormone that allows conversion of sugar into energy.
            </Typography>
            <br />
            <Typography>
              A main symptom of uncontrolled diabetes is frequent urination.
              This excess loss of fluids results in being unusually thirsty.
              Also, due to the lack of sugar conversion to energy, the body
              becomes tired and weak. As the body burns its own fat for energy,
              some children lose weight. Other symptoms may include tingling in
              the hands and feet, vision problems, and reduced resistance to
              infections.
            </Typography>
            <br />
            <Typography>
              Although there is no cure, diabetes can be controlled through
              daily injections of insulin, together with a balanced diet of food
              and exercise. Food raises the sugar (glucose) level in the body,
              while exercise lowers it.
            </Typography>
            <br />
            <Typography>
              Control of diabetes is a balancing act of these three components:
              Insulin, food, exercise. A good balance and a child can lead a
              normal life. Without that balance, a child can suffer one of two
              opposite reactions.
            </Typography>
            <br />
            <Typography>
              One is the shock of low blood sugar, called "hypoglycemia." A
              child with low blood sugar may be confused, cranky, clumsy, dizzy,
              hungry, trembling, and perspiring.
            </Typography>
            <br />
            <Typography>
              The other reaction is high blood sugar (hyperglycemia), where
              symptoms build gradually, including extreme thirst, drowsiness,
              hot and dry skin, no appetite, blood test showing high sugar,
              labored breathing, and eventual unconsciousness. This ultimate
              symptom is known as a diabetic coma.
            </Typography>
            <br />
            <Typography>
              Regular meals, exercise, and insulin will usually keep the child's
              blood sugar under control. Children can eat the same healthy foods
              as their playmates. They should stay away from concentrated
              sweets. They should avoid exercise right before meal time. And
              they should consider eating more frequently during the day or have
              snacks at midmorning and mid-afternoon. If a child is going to
              burn up a lot of sugar through exercise, a snack before the
              activity is a good idea.
            </Typography>
            <br />
            <Typography>
              As children grow old enough, they may take over the task of
              monitoring their blood sugar level and giving themselves
              injections of insulin. The adult should oversee this
              responsibility, however, and participate occasionally to keep up
              the skill of measuring the blood sugar and giving an injection.
            </Typography>
            <br />
            <Typography>
              Research continues into the causes of diabetes and the possible
              ways to intervene in its development. These include programs to
              explore the body's immune system and how to alter its response,
              genetics, transplantation, how insulin works, and prevention and
              reversal of diabetic complications.
            </Typography>
            <br />
            <Typography>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the
              <a href="/generalInformation"> Get Started </a>
              section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href="">Texas Adoption Resource Exchange (TARE)</a> or call
              1-800-233-3405.
            </Typography>
            <br />
            <Grid style={{ float: "right" }}>
              <Typography>
                This content taken from:{" "}
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                >
                  Adoption and Foster Care
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Diabetes;
