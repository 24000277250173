import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";
const Galactosemia = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>A closer look at Galactosemia</b>
            </Typography>
            <br />
            <Typography>
              <em>
                More...
                <a
                  target="_blank"
                  href="http://depts.washington.edu/transmet/gal.html"
                >
                  Galactosemia
                </a>
              </em>
            </Typography>
            <br />
            <Typography>
              {" "}
              Galactosemia is a rare genetic disorder that can cause severe
              damage, even death, to children who consume milk or dairy
              products.
            </Typography>
            <br />
            <Typography>
              Galactosemia should not be confused with lactose intolerance,
              which is an allergic reaction some children get from dairy
              products.
            </Typography>
            <br />
            <Typography>
              Galactosemia is a condition that can cause complications such as
              an enlarged liver, kidney failure, cataracts, and brain damage.
              Diagnosis is usually made shortly after birth as a standard part
              of newborn screening. However, even though a restricted diet is
              started right away, there continues to be a high incidence of
              complications involving speech and language, fine and gross motor
              skill delays, and specific learning disabilities.
            </Typography>
            <br />
            <Typography>
              Galactosemia means too much galactose in the blood due to absence
              of an enzyme that converts galactose into glucose. If the
              galactose is converted to glucose, it is used by the body for
              energy. However, when the galactose isn't converted into glucose,
              it accumulates in the body as a poison. Galactosemia occurs in
              children when both the mother and the father carry the defective
              gene. Incidence of the disease is about one in 20,000 births.
            </Typography>
            <br />
            <Typography>
              Children with galactosemia must be prevented from consuming any
              milk, cheese, or milk products, including lactose additives in
              other foods. Parents must be careful readers of the ingredients on
              food, including processed foods in cans or dry packages.
            </Typography>
            <br />
            <Typography>
              Parents must also screen the child's food at school, restaurants,
              and at the homes of friends and family.
            </Typography>
            <br />
            <Typography>
              In addition to careful attention to the child's diet, parents
              usually must contend with the effects of galactosemia due to
              consumption of milk prior to being diagnosed and due to
              undiscovered consumption of milk or dairy products while growing
              up.
            </Typography>
            <br />
            <Typography>
              In the case of this month's feature child, Paul does not
              understand which foods he must not eat and why. Parents whose
              child has galactosemia must be vigilant in screening out any
              product containing lactose and they must be firm in enforcing the
              rule of not consuming milk or dairy products. This can be
              difficult when children don't understand why they can't have ice
              cream and the other treats that children enjoy.
            </Typography>
            <br />
            <Typography>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the <a href="/generalInformation">Get Started</a>{" "}
              section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href="">Texas Adoption Resource Exchange (TARE)</a> or call
              1-800-233-3405.
            </Typography>
            <br />
            <Grid style={{ float: "right" }}>
              <Typography>
                This content taken from:{" "}
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                >
                  Adoption and Foster Care
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Galactosemia;
