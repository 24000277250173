import * as React from "react";
import {
  Typography,
  Grid,
  Grow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import people from "../../../assets/newimages/people.gif";
import rainbow from "../../../assets/newimages/rainbow.gif";
import arrow from "../../../assets/newimages/home.gif";
import { Link } from "react-router-dom";
import lionimage from "../../../assets/newimages/lion.gif";
import heartimage from "../../../assets/newimages/logo.gif";

export default function BasicAccordion() {
  return (
    <div style={{ fontSize: "15px", textAlign: "justify" }}>
      <Accordion id="grid3" style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "lightblue" }}
        >
          <Typography
            style={{
              color: "black",
              fontSize: "1.0rem",
              fontWeight: "bold",
            }}
          >
            Responsabilidades
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography> */}
          <Grid style={{ lineHeight: "1.6" }}>
            <Grid style={{}}>
              <Typography>
                <b>Padre temporal:</b>
              </Typography>
              <Grid container justifyContent="center">
                <Grid item lg={8}>
                  <Typography>
                    <ul style={{ lineHeight: "1.6" }}>
                      <li>Cuidar y criar a los niños bajo cuidado temporal.</li>
                      <li>
                        Abogar por los niños en la escuela y en la comunidad.
                      </li>
                      <li>
                        Informar al trabajador de casos del niño sobre cómo se
                        está adaptando a la casa, la escuela y la comunidad, así
                        como también cualquier problema que surja, como, alguna
                        enfermedad grave, un accidente o alguna situación grave
                        en la que se vea envuelto el niño bajo cuidado temporal
                        o su propia familia.
                      </li>
                      <li>
                        Tratar, como miembro de un equipo junto con el
                        trabajador de casos del niño, de volver a reunir al niño
                        con su familia biológica.
                      </li>
                      <li>
                        Ser un ejemplo positivo para la familia biológica. y
                      </li>
                      <li>
                        Ayudar al niño a aprender habilidades para salir
                        adelante en la vida.
                      </li>
                    </ul>
                  </Typography>
                </Grid>
                <Grid item lg={4} align="center">
                  <img
                    src={people}
                    alt="people image"
                    // style={{ float: "right" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Typography>
              <b>Padre adoptivo:</b>
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li>
                Dar al niño un hogar permanente y comprometerse de por vida al
                niño hasta que sea un adulto.
              </li>
              <li>
                Hacerse cargo de las necesidades del niño a corto y a largo
                plazo.
              </li>
              <li>
                Hacerse cargo de las necesidades emocionales, mentales, físicas,
                sociales, educacionales y culturales, según la edad y el
                crecimiento en el desarrollo del niño.
              </li>
              <li>
                Recibir aprobación para ser familia temporal y aceptar a niños
                que todavía no pueden ser adoptados legalmente, pero cuyo plan
                de permanencia es la adopción.
              </li>
            </ul>
            <Grid style={{ display: "flex", alignItems: "center" }}>
              <Grid container justifyContent="center">
                <Grid item lg={8}>
                  <Typography>
                    <b>
                      Requisitos básicos para la familia temporal o adoptiva
                    </b>
                    Los padres que aspiran a ser padres temporales o adoptivos
                    pueden ser solteros o casados y deben:
                  </Typography>

                  <Typography>
                    <ul>
                      <li>
                        Tener por lo menos 21 años de edad, ser estables
                        económicamente y ser adultos maduros y responsables.
                      </li>
                      <li>
                        Asistir al entrenamiento gratuito para aprender sobre
                        temas de niños que han sufrido maltrato y descuido.
                      </li>
                    </ul>
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={4}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={rainbow}
                    alt="rainbow image"
                    style={{ width: "150px", float: "right" }}
                  />
                </Grid>

                <Typography>
                  <b>Nota:</b> Este entrenamiento es una oportunidad para que la
                  familia y el Departamento de Servicios para la Familia y de
                  Protección de Texas (DFPS) determinen si el cuidado temporal o
                  la adopción es lo mejor para la familia. Los padres pueden
                  dejar de asistir a las reuniones en cualquier momento. El
                  entrenamiento es gratis. Los padres temporales y adoptivos
                  generalmente reciben entrenamiento juntos.
                </Typography>
                <li>
                  llenar una solicitud (el personal le ayudará, si usted
                  quiere).
                </li>
                <li>Dar información sobre su pasado y estilo de vida.</li>
                <li>
                  Dar los nombres de familiares y de personas que no sean
                  familiares como referencia.
                </li>
                <li>Presentar constancia de matrimonio y/o divorcio.</li>
                <li>
                  Aceptar que le hagan un estudio del hogar en el cual se
                  conversa con todos los miembros de la casa. y
                </li>
                <li>
                  Permitir que el personal haga una investigación completa de
                  los antecedentes penales y de maltrato y descuido de todos los
                  adultos de la casa.
                </li>
              </Grid>
              <Grid></Grid>
            </Grid>
          </Grid>
          {/* <Grid>
          </Grid> */}
        </AccordionDetails>
      </Accordion>
      <Accordion id="grid4" style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ backgroundColor: "lightblue" }}
        >
          <Typography
            style={{
              fontSize: "1.0rem",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            Requisitos adicionales para el cuidado temporal:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent="center">
            <Grid item lg={8}>
              <Typography>
                Además de los requisitos básicos, los padres temporales:
              </Typography>

              <ul style={{ lineHeight: "1.6" }}>
                <li>Deben tener lugar adecuado para dormir.</li>
                <li>
                  No deben tener más de 6 niños en la casa contando sus propios
                  hijos o los niños que cuidan.
                </li>
                <li>
                  Deben estar de acuerdo en no recurrir a medidas físicas para
                  la disciplina.
                </li>
                <li>
                  Deben permitir que hagan inspecciones de incendios, salud y
                  seguridad a la casa.
                </li>
                <li>Deben vacunar a todas las mascotas.</li>
                <li>
                  Deben recibir y mantener vigente la certificación de
                  resucitación cardiopulmonar y de primeros auxilios.
                </li>
                <li>
                  Todos los miembros de la casa deben hacerse la prueba de
                  tuberculosis como lo requiere el Departamento de Salud local.
                </li>
                <li>
                  Deben asistir a 20 horas o más de entrenamiento cada año.
                </li>
              </ul>
            </Grid>
            <Grid
              item
              lg={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={arrow}
                alt="arrow image"
                style={{ height: "130px", float: "right" }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion id="grid5" style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ backgroundColor: "lightblue" }}
        >
          <Typography
            style={{
              fontSize: "1.0rem",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            Reembolso por el cuidado temporal
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid>
            <Typography>
              El reembolso mensual que recibe la familia temporal es una
              combinación de fondos federales, estatales o del condado. Es para
              gastos relacionados con el cuidado del niño como comida, ropa,
              recreación, transportación o vivienda. En casos excepcionales, se
              reembolsan pagos especiales a la familia temporal que cuida a
              niños con necesidades excepcionales, como en el caso de
              necesidades médicas que requieren que el niño reciba cuidado,
              comida, ropa o equipo especializado.
            </Typography>
            <Typography>
              El 22 de Agosto de 2002, el Departamento adoptó las tarifas para
              los{" "}
              <Link to="">
                {" "}
                re-embolsos residenciales de 24 horas del cuidado de niño.
              </Link>
            </Typography>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion id="grid6" style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ backgroundColor: "lightblue" }}
        >
          <Typography
            style={{
              fontSize: "1.0rem",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            Subsidios para la adopción
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Hay subsidios para la adopción disponibles para ayudar a la familia
            adoptiva a cubrir los gastos de servicios especiales necesarios para
            el niño, como, terapia, consejería, tratamiento dental correctivo
            extraordinario o atención y artículos médicos. En algunos casos como
            en la adopción de un grupo de hermanos por medio de DFPS, un
            subsidio puede ayudar con los gastos de los niños, como, alimentos,
            ropa, vivienda y cuidado de niños. La cantidad de asistencia se basa
            en las necesidades del niño y en la disponibilidad de recursos para
            satisfacer esas necesidades.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion id="grid7" style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ backgroundColor: "lightblue" }}
        >
          <Typography
            style={{
              fontSize: "1.0rem",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            ¿Puede adoptar una familia temporal? ¿Puede ofrecer cuidado temporal
            una familia adoptiva?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid>
            <Grid container justifyContent="center">
              <Grid item lg={8}>
                <Typography style={{}}>
                  Muchas familias se interesan en ser familias temporales y
                  adoptivas. Estas familias están de acuerdo con el departamento
                  en que las necesidades de los niños son lo más importante. La
                  mayoría del tiempo, esto significa ayudar a preparar a los
                  niños para volver con su familia biológica, aconsejar a los
                  padres de sangre o buscar la manera de colocar a los niños con
                  un familiar o pariente.
                </Typography>
                <b />
                <Typography>
                  Cuando lo mejor para el bienestar del niño es quitarle a los
                  padres el derecho paternal y se considera ponerlos en
                  adopción, se les da a los padres temporales la oportunidad de
                  adoptar. Si se aprueba a los padres para ser padres temporales
                  y padres adoptivos, se acelera el trámite de la colocación, se
                  reducen las transferencias del niño de casa en casa y se logra
                  mejorar las relaciones con el trámite de colocación inicial.
                  Las familias temporales son responsables de casi la mitad de
                  las adopciones de niños en el cuidado temporal de DFPS.
                </Typography>
              </Grid>
              <Grid
                item
                lg={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <img
                  src={lionimage}
                  alt="lionimage"
                  style={{ float: "right" }}
                />
              </Grid>
            </Grid>
            <Grid>
              <Typography style={{}}>
                Las familias adoptivas que están dispuestas a aceptar a niños
                que todavía no son libres legalmente para ser adoptados pero que
                tienen un plan de adopción pueden también recibir la aprobación
                como familias temporales. La aprobación doble aumenta las
                posibilidades de llevar a cabo adopciones exitosas.
              </Typography>
              <Typography style={{}}>
                En algunas áreas del estado, se ha creado un "sistema de cuates"
                en el cual las familias temporales con experiencia y que
                entienden los retos y las satisfacciones de ser padres
                temporales comparten sus experiencias con nuevas familias y
                ofrecen su apoyo.
              </Typography>
              <Typography>
                Muchas áreas también tienen asociaciones de padres temporales y
                grupos de apoyo para la adopción que ayudan con el entrenamiento
                y apoyan las actividades de las familias temporales y adoptivas.
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'black'}}/>}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion> */}
      <Accordion id="grid8" style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ backgroundColor: "lightblue" }}
        >
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
            Contactos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid>
            <Grid container justifyContent="center">
              <Grid item lg={8}>
                <Typography>
                  Para saber más sobre cómo ser un padre temporal o adoptivo por
                  medio del Departamento de Servicios para la Familia y de
                  Protección de Texas (DFPS) llame al:
                </Typography>
                <ul style={{ lineHeight: "1.6" }}>
                  <li>1-800-233-3405 en todo el estado</li>
                  <li>
                    o puede visitarnos en la red en:{" "}
                    <Link to="/why_not_me"> www.adoptchildren.org</Link>
                  </li>
                </ul>
              </Grid>
              <Grid
                item
                lg={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={heartimage}
                  alt="heartimage"
                  style={{ float: "right" }}
                />
              </Grid>
            </Grid>
            <Typography style={{}}>
              Los niños llegan al cuidado de DFPS porque han sufrido maltrato o
              descuido. Por esta razón, la familia que cuida temporalmente o
              adopta a niños por medio del departamento debe entender o estar
              dispuesta a aprender la dinámica del descuido, maltrato o abuso
              sexual.
            </Typography>
            <Typography style={{}}>
              La asistencia y los servicios de DFPS son para todos sin
              distinción de edad, raza, estado civil, color, religión, sexo,
              discapacidad, origen nacional o creencia política. Si usted cree
              que lo han discriminado, puede quejarse ante la oficina de Equal
              Opportunity and Client Compliance.
            </Typography>
            <Typography style={{}}>
              Para mas informacion correo electrónico TARE. Los niños llegan al
              cuidado de DFPS porque han sufrido maltrato o descuido. Por esta
              razón, la familia que cuida temporalmente o adopta a niños por
              medio del departamento debe entender o estar dispuesta a aprender
              la dinámica del descuido, maltrato o abuso sexual.
            </Typography>
            <Typography>
              La asistencia y los servicios de DFPS son para todos sin
              distinción de la edad, raza, estado civil, color, religión, sexo,
              discapacidad, origen nacional o creencia política. Si usted cree
              que lo han discriminado, puede quejarse ante la oficina de Equal
              Opportunity and Client
            </Typography>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
