import React, { Fragment } from "react";
import {
  Typography,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import ImageSlider from "../../layout/imageslider";
import volunteerOpp from "./volunteerContent";
import BreadcrumbMenu from "../../layout/BreadcrumbMenu";
// import Join1 from "./Join/join";
import "./volunteer.css"

const VolunteerView = () => {
  return (
    <div style={{ flexGrow: 1,}} align="center">
      {/* <ImageSlider /> */}
      <Grid style={{padding:'20px'}}>
        <Grow in={true} timeout={1500}>
          <Grid container style={{ color: "#1A355D", padding: "10px 0" }}>
            <Grid item xs={12} style={{ padding: "0 16px 10px 16px" }}>
              <BreadcrumbMenu
                currComp="Volunteer"
                parentComp="Home"
                parentCompRoute="/"
              />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 16px 10px 16px" }}>
              <Typography variant="h4">Volunteer</Typography>
            </Grid>
          </Grid>
        </Grow>
        <Grow in={true} timeout={1500}>
          <Grid
            container
            id="maingrid"
            style={{
              backgroundColor: "white",
              color: "#1A355D",
              padding: "10px 0",
              boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
              marginBottom: "20px",
              // width:"80%"
            }}
          >
            {volunteerOpp.map((item) => (
              <Fragment key={item.key}>
                <Grid
                  item
                  xs={12}
                  style={{ padding: "0 16px", color: "#d04e53" }}
                >
                  <Typography variant="h5">{item.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <List component="div" aria-label="volunteer content">
                    {item.contents.map((content) =>
                      content.label ? (
                        content.value.map((item) => (
                          <ListItem
                            component="div"
                            key={item.key}
                            style={{ padding: "0 30px", alignItems: "center" }}
                          >
                            <ListItemIcon
                              style={{ minWidth: "20px", color: "#1A355D" }}
                            >
                              <FontAwesomeIcon icon={faCaretRight} size="2x" />
                            </ListItemIcon>
                            <ListItemText primary={item.value} />
                          </ListItem>
                        ))
                      ) : (
                        <ListItem
                          component="div"
                          key={content.key}
                          style={{ textAlign: "justify" }}
                        >
                          {content.key === "content3" ? (
                            <>
                              <ListItemText primary={content.value} />
                              {/* <Typography><Link to="/join">Foster Caregivers page.</Link></Typography> */}
                            </>
                          ) : (
                            <ListItemText primary={content.value} />
                          )}
                        </ListItem>
                      )
                    )}
                  </List>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grow>
      </Grid>
    </div>
  );
};

export default VolunteerView;
