import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  // CardActions,
  // Avatar,
  IconButton,
} from "@mui/material";

import "./home.css"; 

// Import your images here
import image1 from "../../assets/ads images/governmental-and-nonprofit-accounting.png";
import image2 from "../../assets/ads images/Cost-Accounting-main.jpg";
import image3 from "../../assets/ads images/belong.jpg";
import image4 from "../../assets/ads images/ad.png";
import image5 from "../../assets/ads images/divinity.avif"
import image6 from "../../assets/ads images/youthview.jpg"
// Add or remove imports as necessary

// Define your card contents here
const cardContents = [
  {
    title: "RENEAUCPA & CO",
    titleLink: "https://reneaucpa.com/services-nptax",
    // subtitle: "Subtitle ",
    image: image1,
    imageLink: "https://reneaucpa.com/services-nptax",
    description: "NON-PROFIT SERVICES",
    descriptionLink: "https://reneaucpa.com/services-nptax",
    // avatar: "R",
  },
  {
    title: "RENEAUCPA & CO",
    titleLink: "https://reneaucpa.com/",
    // subtitle: "Subtitle ",
    image: image2,
    imageLink: "https://reneaucpa.com/",
    description: "COST AND ACCOUNTABILITY REPORT AND INFORMATION",
    descriptionLink: "https://reneaucpa.com/",
    // avatar: "T",
  },
  {
    title: "BELONG",
    titleLink: "https://sjrcbelong.org/",
    // subtitle: "Subtitle ",
    image: image3,
    imageLink: "https://sjrcbelong.org/",
    description: "A Chance For Children To Find Where They BELONG",
    descriptionLink: "https://sjrcbelong.org/",
    avatar: "A",
  },
  {
    title: "DIVINITY FAMILY SERVICES",
    titleLink: "https://www.divinityfamilyservices.com/",
    // subtitle: "Subtitle ",
    image: image5,
    imageLink: "https://www.divinityfamilyservices.com/",
    description: "Defend the weak and fatherless uphold the cause of the poor and the oppressed ",
    descriptionLink: "https://www.divinityfamilyservices.com/",
    avatar: "A",
  },
  {
    title: "YOUTH VIEW",
    titleLink: "https://youthinview.org/",
    // subtitle: "Subtitle ",
    image: image6,
    imageLink: "https://youthinview.org/",
    description: "Become a Texas Foster Care Parent",
    descriptionLink: "https://youthinview.org/",
    avatar: "A",
  },
  // Add more objects for more cards as needed
];

const Ads = () => {
  const [currentContentIndex, setCurrentContentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentContentIndex(
        (prevIndex) => (prevIndex + 1) % cardContents.length
      );
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(interval);
  }, []);

  // const ExpandMoreStyled = styled(IconButton)(({ theme, expand }) => ({
  //   transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  //   marginLeft: "auto",
  //   transition: theme.transitions.create("transform", {
  //     duration: theme.transitions.duration.shortest,
  //   }),
  // }));

  // const [expanded, setExpanded] = useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  // Function to render a single card
  const renderCard = (contentIndex) => {
    const content = cardContents[contentIndex];
    return (
      <Card elevation={3} sx={{ maxWidth: 345, backgroundColor: "white" }}>
        <IconButton
          aria-label="settings"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          {/* <MoreVertIcon /> */}
          <img
            alt="image1"
            src={image4}
            style={{ height: "20px", width: "20px" }}
          />
        </IconButton>
        <CardHeader
          // avatar={
          //     <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
          //       {content.avatar}
          //     </Avatar>
          //   }
          // action={
          //   <IconButton aria-label="settings">
          //     {/* <MoreVertIcon /> */}
          //     <img src={image4} style={{height:"20px",width:"20px"}}/>
          //   </IconButton>
          // }
          title={
            <a
              href={content.titleLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "navy",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {content.title}
            </a>
          }
          subheader={content.subtitle}
          style={{ margin: "0px", padding: "0px" }}
        />
        <a href={content.imageLink} target="_blank" rel="noopener noreferrer">
          <CardMedia
            component="img"
            height="180"
            image={content.image}
            alt="Advertisement"
          />
        </a>
        <CardContent>
          <Typography variant="body2" color="text.secondary" style={{fontWeight:"bold"}}>
            <a
              href={content.descriptionLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "navy" }}
            >
              {content.description}
            </a>
          </Typography>
        </CardContent>
        {/* <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
          <ExpandMoreStyled
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMoreStyled>
        </CardActions> */}
      </Card>
    );
  };

  return (
    <div>
      <Grid container spacing={1}>
        {Array.from({ length: cardContents.length }).map((_, i) => (
          <Grid item key={i}>
            {renderCard((currentContentIndex + i) % cardContents.length)}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Ads;
