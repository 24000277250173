import { Grid, Typography } from "@mui/material";
import React from "react";
import ImageSlider from "../../../layout/imageslider";
// import Mapcardnoarea from "../mappages/mapcardnoareas";
import MapCard from "../mappages/mapcard";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./region8.css";
import ResponsiveMap from "../../../fostercareOverview/texasmap";

const Region10 = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Grid style={{ textAlign: "left", width: "100%" }}>
            {" "}
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", color: "navy", textAlign: "center" }}
            >
              Region 10, El Paso and surrounding area{" "}
            </Typography>
            <br />
            <Typography>
              Foster Care and Adoption Information Meetings
            </Typography>
            <br />
            <Typography style={{ color: "red", textAlign: "justify" }}>
              * If accomodations such as a sign language interpreter are needed
              in order to attend an information meeting, please call the
              Recruitment Staff listed below 3 days prior to the meeting, if
              possible. *
            </Typography>
          </Grid>

          <br />
          <Grid
            container
            style={{
              display: "flex",
              padding: "10px",
              textAlign: "left",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>Recruitment Events and Foster/Adopt Information Meetings:</b>
              </Typography>
              <br />
              <ul style={{ lineHeight: "1.6" }}>
                <li style={{ textAlign: "left" }}>
                  <Link to="#table1"> January 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table2">February 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table3">March 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link
                    target="_blank"
                    to="https://www.heartgalleryelpaso.org/"
                  >
                    Heart Gallery of El Paso
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="#recruitment"> Recruitment Staff</Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp"
                  >
                    {" "}
                    Faith-Based Staff
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "justify",
              }}
            >
              <MapCard />
            </Grid>
          </Grid>
          <Typography>
            The Texas Department of Family and Protective Services is looking
            for caring foster and adoptive families in your area. To learn more
            about becoming a foster or adoptive parent through our agency, we
            will be having the following information meetings in your area:
          </Typography>
          <br />
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <ResponsiveMap/>
          </Grid>
          {/* Table1 */}
          <br />
          <Grid id="table1" style={{ paddingTop: "30px" }}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>January 2024</b>
            </Typography>
            <br />
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Region 10 Counties</strong>
                      Brewster, El Paso, Hudspeth, Jeff Davis, Presidio Counties
                    </TableCell>
                    <TableCell align="top">
                      <strong>Monday January 8, 2024 @ 6pm MST</strong>
                      Meetings are virtual. To request a link to join please
                      contact{" "}
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Region 10 Counties</strong>
                      <br />
                      Brewster, El Paso, Hudspeth, Jeff Davis, Presidio Counties
                    </TableCell>
                    <TableCell>
                      <strong>Monday January 29, 2024 @6pm MST</strong>
                      <br />
                      <strong>
                        Meetings are virtual. To request a link to join please
                        contact{" "}
                      </strong>
                      <br />
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table2 */}
          <Grid id="table2" style={{ paddingTop: "30px" }}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>February 2024</b>
            </Typography>
            <br />
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Region 10 Counties</strong>
                      <br />
                      Brewster, El Paso, Hudspeth, Jeff Davis, Presidio Counties
                    </TableCell>
                    <TableCell align="top">
                      <strong>Monday February 12, 2024 @ 6pm MST</strong>
                      <br />
                      Meetings are virtual. To request a link to join please
                      contact
                      <br />
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Region 10 Counties</strong>
                      <br />
                      Brewster, El Paso, Hudspeth, Jeff Davis, Presidio Counties
                    </TableCell>
                    <TableCell>
                      <strong>Monday February 26, 2024@ 7pm CST</strong>
                      <br />
                      Meetings are virtual. To request a link to join please
                      contact <br />
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table 3 */}
          <Grid id="table3" style={{ paddingTop: "30px" }}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>March 2024</b>
            </Typography>
            <br />
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Region 10 Counties</strong>
                      <br />
                      Brewster, El Paso, Hudspeth, Jeff Davis, Presidio Counties
                    </TableCell>
                    <TableCell align="top">
                      <strong>Monday March 11, 2024 @6pm MST</strong>
                      <br />
                      Meetings are virtual. To request a link to join please
                      contact
                      <br />
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Region 10 Counties</strong>
                      <br />
                      Brewster, El Paso, Hudspeth, Jeff Davis, Presidio Counties
                    </TableCell>
                    <TableCell>
                      <strong>Monday March 25, 2024 @6pm MST</strong>
                      <br />
                      Meetings are virtual. To request a link to join please
                      contact <br />
                      <a style={{ wordBreak: "break-word" }} href="">
                        Melissa.Ferguson@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Recruitment Grid */}
          <Grid
            id="recruitment"
            style={{ paddingTop: "20px", textAlign: "left", width: "100%" }}
          >
            <Typography variant="h5">
              <b>Recruitment Staff:</b>
            </Typography>
            <br />
            <Typography>
              El Paso staff receive many inquiries from prospective foster/adopt
              families and hold information meetings for groups of families on a
              regular basis.
            </Typography>
            <Typography>
              Please email <a href="">Bianca Avila</a> or call{" "}
              <b>(915) 521-3870</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Region10;
