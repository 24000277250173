import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";

const Enuresis = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>A closer look at Enuresis</b>
            </Typography>
            <br />
            <Typography>
              Enuresis is involuntary urination at an age when children are
              older and expected to stay dry.
            </Typography>

            <br />
            <Typography>
              Enuresis is more widespread than people think and it has been
              embarrassing children and some adults since at least the Egyptian
              times 3,000 years ago. For most families, enuresis refers to
              nighttime bedwetting.
            </Typography>
            <br />
            <Typography>
              As many as one in five children still wet the bed at least once a
              month at the age of 5 years, according to one report. Another
              source estimates that up to seven million children have nighttime
              enuresis. The condition decreases as children grow older and in
              most people it has disappeared by the teen years.
            </Typography>
            <br />
            <Typography>
              Most children outgrow their bedwetting. However, parents should
              consult their child's physician if the condition is causing
              concerns for themselves or the child after the age of 5 or 6.
              There are several ways the doctor can help, most importantly by
              ruling out other more serious health problems, such as diabetes or
              malfunction of the bladder or urinary tract. Doctors also can
              prescribe treatment plans or medications that curtail if not
              eliminate nighttime bedwetting.
            </Typography>
            <br />
            <Typography>
              Bedwetting is defined as "primary" if it has been going on since
              infancy. It is considered "secondary" if it crops up after six
              months of a child being dry. Another part of the definition tells
              whether the condition is nocturnal (nighttime bed wetting) or
              diurnal (daytime). For most children, enuresis happens at night.
              It is more prevalent in boys and it may be inherited from one or
              both parents.
            </Typography>
            <br />
            <Typography>
              The exact cause of enuresis isn't known, and many factors may
              contribute to it. Among these is a developmental delay in the
              central nervous system's influence over bladder control. Another
              possibility is that bed-wetters do not produce high levels of a
              hormone that recycles water from the urine back into the
              bloodstream. As a possible result, too much urine is formed, the
              bladder gets full, and the child doesn't get the neural message in
              time to prevent an accidental bedwetting. Another factor may be
              the deep sleep of children. They simply don't wake up enough to
              know that they should go to the bathroom.
            </Typography>
            <br />
            <Typography>
              Researchers have noticed that bedwetting and attention deficit
              hyperactivity disorder commonly go together. One report suggests
              that nearly half the children with ADHD also are enuretic. One
              possibility is that children with ADHD and a genetic tendency to
              bedwetting are less able to wake up enough to go to the bathroom
              in time. While deep sleep patterns may be involved, reports
              indicate that emotional and behavioral problems are not a cause of
              enuresis in normal child development. Enuresis that persists past
              the age of 8 to 10 years, however, may be associated with a lack
              of self-esteem or psychological problems. In this event, medical
              attention and counseling may be considered. For some, enuresis
              continues into adulthood and should be approached from a medical
              as well as psychological perspective.
            </Typography>
            <br />
            <Typography>
              Whatever the cause of enuresis, parents and caregivers have a
              special responsibility to avoid allowing the condition to scar a
              child's psyche. This could cause a lifetime deficit of
              self-confidence and self-esteem. Although difficult, patience and
              understanding are the desired response of parents to children who
              wet the bed. Enuresis has numerous effects on children, most of
              them adverse. It creates stress with the parents. Family
              relationships are clouded by the child's shame and guilt. Children
              who wet the bed often feel fear, worthlessness, anxiety, and even
              depression. They see themselves as different from others and avoid
              the possible embarrassment that might occur by staying overnight
              at another child's house. They may fear visits to relatives or
              staying in hotels where they may be found out. Overnight school
              field trips seem out of the question. All these psychological
              harms are compounded if unsympathetic parents scold, punish, or
              embarrass the child.
            </Typography>
            <br />
            <Typography>
              A positive approach by a parent or caregiver would be to seek a
              physician's examination to ensure there are no medical causes of
              the condition. After that, a physician may recommend a treatment
              plan from one or more options.
            </Typography>
            <br />
            <Typography>
              These include a battery-powered alarm system designed to wake the
              child at the first few drops of urine. The child must be motivated
              to make this work, and the device must be used for several months
              to see an improvement. The alarm is used until the child stays dry
              for at least two weeks, and some people advocate continued use to
              "over-learn" the habit of waking to go to the bathroom. This is
              done by encouraging the child to drink extra fluid to condition
              the bladder to being fuller than usual.
            </Typography>
            <br />
            <Typography>
              Physicians may prescribe several medications that suppress
              enuretic behavior. One of these has become popular in a nasal
              spray form that reduces the amount of urine created by the
              kidneys. Medications have side effects and should be used only
              under a doctor's direction and care.
            </Typography>
            <br />
            <Typography>
              Meanwhile, parents and caregivers can consider measures to help
              children. These include a daily routine of avoiding consumption of
              fluids or caffeine before bedtime, making sure the bathroom is
              accessible and well lit, getting the child up to go to the
              bathroom at a set time each night, taking the child out of diapers
              or training pants, requiring the child to take soiled sheets to
              the laundry room (not as punishment, but as a routine activity),
              and protecting the child's self-esteem.
            </Typography>
            <br />
            <Typography>
              Persons interested in additional information about enuresis may
              contact the National Enuresis Society at 1-800-NES-8080.
            </Typography>
            <br />
            <Typography>
              Enuresis is an embarrassing condition for children, especially as
              they grow older. To avoid stigmatizing children, the profiles of
              children available for adoption through the Texas Adoption
              Resource Exchange (TARE) merely mention the fact that a child has
              enuresis, without further elaboration. The same courtesy is
              extended to children with other undesired conditions, such as
              encopresis (uncontrolled bowel movements).
            </Typography>
            <br />
            <Typography>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the{" "}
              <a href="/generalInformation"> Get Started</a> section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href="">Texas Adoption Resource Exchange (TARE)</a> or call
              1-800-233-3405.
            </Typography>
            <br />
            <Grid style={{ float: "right" }}>
              <Typography>
                This content taken from:{" "}
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                >
                  Adoption and Foster Care
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Enuresis;
