const countyData = {
  data: [],
  countyName: "",
  error: "",
  county_Submitted: "",
};

function Fetchdata(state = countyData, action) {
  if (action.type === "countylist0") {
    return {
      ...state,
      data: "data loading",
      error: "wait",
    };
  }
  if (action.type === "countylist1") {
    return {
      ...state,
      data: action.payload,
      error: "data received",
    };
  }
  if (action.type === "countySelected") {
    return {
      ...state,
      countyName: action.selectedCounty,
      county_Submitted: true,
    };
  }
  if (action.type === "error") {
    return {
      ...state,
      error: action.payload,
    };
  }
  return state;
}

export default Fetchdata;
