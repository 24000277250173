import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";

export default function ScrollDialog() {
  // Styles
  const Styles = {
    content: { color: "black", lineHeight: "normal", fontSize: "1.1rem" },
    ulcontent: {
      paddingLeft: "2.3rem",
      marginTop: "0",
      paddingTop: "0",
      margin: "0",
      textAlign: "justify",
      backgroundColor: "red",
      "@media screen and (max-width:576px) and (min-width: 0px)": {
        backgroundColor: "lightgreen",
        padding: "5px",
      },
      " @media screen and (max-width:768px) and (min-width: 577px)": {
        backgroundColor: "navy",
      },
    },
    title: {
      color: "black",
      lineHeight: "normal",
      fontSize: "1.25rem",
      fontWeight: "bold",
      "@media screen and (max-width:576px) and (min-width: 0px)": {
        backgroundColor: "lightgreen",
        padding: "5px",
      },
      " @media screen and (max-width:768px) and (min-width: 577px)": {
        backgroundColor: "navy",
      },
    },
    oltitle: {
      paddingLeft: "1.5rem",
      marginLeft: "1.2rem",
      marginBottom: "3px",
      padding: "0",
      marginTop: "1em",
      "@media screen and (max-width:576px) and (min-width: 0px)": {
        backgroundColor: "lightgreen",
        padding: "5px",
      },
      " @media screen and (max-width:768px) and (min-width: 577px)": {
        backgroundColor: "navy",
      },
    },
  };

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fileDropUrl = () => {
    window.open(
      "https://eltonreneau.sharefile.com/share/filedrop/dx7df445-4896-46d0-a69b-f4553c2e47c2",
      "_blank"
    );
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Button
        style={{ paddingTop: "10rem" }}
        onClick={handleClickOpen("paper")}
      >
        scroll=paper
      </Button>
      <Button onClick={handleClickOpen("body")}>scroll=body</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"md"}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ fontSize: { xs: "15px", sm: "30px" }, fontWeight: "bold" }}
        >
          {" "}
          Instructions to Use File Drop{" "}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Grid container sx={Styles.ulcontent}>
              <Grid item>
                {/* <!-- Step 1: Accessing File Drop --> */}
                <Typography style={Styles.title}>
                  <ol start={"1"} style={{ ...Styles.oltitle, marginTop: 0 }}>
                    <li>Accessing File Drop</li>
                  </ol>
                </Typography>
                <Typography sx={Styles.ulcontent}>
                  <ul style={Styles.ulcontent}>
                    <li>
                      Click on the{" "}
                      <span style={{ fontWeight: "bold" }}>"Proceed"</span>{" "}
                      button to open the File Drop.
                    </li>
                  </ul>
                </Typography>

                {/* <!-- Step 2: Entering Details --> */}
                <Typography style={Styles.title}>
                  <ol start={"2"} style={Styles.oltitle}>
                    <li>Entering Details</li>
                  </ol>
                </Typography>
                <Typography style={Styles.content}>
                  <ul style={Styles.ulcontent}>
                    <li>
                      In the Information Form, enter your{" "}
                      <span style={{ fontWeight: "bold" }}>
                        Email, First Name, Last Name, and Company
                      </span>
                      . (This information is required to identify the sender of
                      the files.)
                    </li>
                  </ul>
                </Typography>

                {/* <!-- Step 3: Selecting the Recipient --> */}
                <Typography style={Styles.title}>
                  <ol start="3" style={Styles.oltitle}>
                    <li>Selecting the Recipient</li>
                  </ol>
                </Typography>
                <Typography style={Styles.content}>
                  <ul style={Styles.ulcontent}>
                    <li>
                      Once you access File Drop, select a{" "}
                      <span style={{ fontWeight: "bold" }}>Recipient</span> for
                      your files. Choose the appropriate contact from the
                      dropdown list.
                    </li>
                  </ul>
                </Typography>

                {/* <!-- Step 4: Uploading Files --> */}
                <Typography style={Styles.title}>
                  <ol start="4" style={Styles.oltitle}>
                    <li>Uploading Files</li>
                  </ol>
                </Typography>
                <Typography style={Styles.content}>
                  <ul style={Styles.ulcontent}>
                    <li>
                      Files can be uploaded by either{" "}
                      <span style={{ fontWeight: "bold" }}>Dragging</span> them
                      into the File Drop area or by using the{" "}
                      <span style={{ fontWeight: "bold" }}>'Browse files'</span>{" "}
                      option to select files from your computer. You can upload
                      one or more files at a time.
                    </li>
                  </ul>
                </Typography>

                {/* <!-- Step 5: Sending the Files --> */}
                <Typography style={Styles.title}>
                  <ol start="5" style={Styles.oltitle}>
                    <li>Sending the Files</li>
                  </ol>
                </Typography>
                <Typography style={Styles.content}>
                  <ul style={Styles.ulcontent}>
                    <li>
                      Once the files have been added, click on the{" "}
                      <span style={{ fontWeight: "bold" }}>'Upload'</span> or
                      <span style={{ fontWeight: "bold" }}>'Send'</span> button.
                      This will transfer the files to the designated Recipient
                      ShareFile account.
                    </li>
                  </ul>
                </Typography>

                {/* <!-- Step 6: Confirmation --> */}
                <Typography style={Styles.title}>
                  <ol start="6" style={Styles.oltitle}>
                    <li>Confirmation</li>
                  </ol>
                </Typography>
                <Typography
                  style={Styles.content}
                  sx={{ paddingBottom: "1rem" }}
                >
                  <ul style={Styles.ulcontent}>
                    <li>
                      After a successful upload, a confirmation message or an
                      <span style={{ fontWeight: "bold" }}>"Uploaded" </span>
                      label will appear. If you need to send more files, repeat
                      steps 4 and 5. Please ensure to{" "}
                      <span style={{ fontWeight: "bold" }}>close</span> the File
                      Drop window after completing the upload process.
                    </li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
          <Button onClick={fileDropUrl} variant="contained">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
