import { Grid, TableContainer, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Mapcardnoarea from "../mappages/mapcardnoareas";
import ImageSlider from "../../../layout/imageslider";
import MapCard from "../mappages/mapcard";

const Region3 = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div align="center">
      {/* <ImageSlider /> */}
      <Grid
        container
        xs={12}
        sm={10}
        md={10}
        lg={10}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <Grid
          container
          // lg ={10}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // width: "100%",

            textAlign: "left",
          }}
        >
          <br />
          <Typography variant="h5" style={{ fontWeight: "bold",color:"navy" }}>
          REGION 3, Foster Care and Adoption Information Meetings
          </Typography>
          <br/>
          <br/>
        </Grid>
        <br />
        <Grid
          item
          xs={11}
          sm={11}
          md={11}
          lg={8}
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
        >
          <TableContainer>
            <Table
              style={{
                backgroundColor: "white",
                border: "solid black",
                // width: "900px",
              }}
            >
              <TableHead style={{ backgroundColor: "lightblue" }}>
                <TableRow style={{ border: "solid black" }}>
                  <TableCell align="left" style={{ border: "solid black", wordBreak: "break-word",verticalAlign:"top", }}>
                    IF YOU RESIDE IN ONE OF THESE COUNTIES (REGION 3B):
                  </TableCell>
                  <TableCell align="left" style={{ wordBreak: "break-word",verticalAlign:"top",}}>
                    IF YOU RESIDE IN ONE OF THESE COUNTIES (REGION 3E):
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid black",
                      verticalAlign:"top",
                      marginTop: "0",
                      paddingTop: "0",
                      // width: "50%",
                    }}
                  >
                    <Typography  style={{ wordBreak: "break-word" }}>
                    Our Community, Our Kids is responsible for Foster Care and
                    Adoptoon Information Meetings for your area. Please find
                    Foster Care and Adoption 101 Information Meetings Detsils
                    below. For additional information please visit Our Community
                    Our Kids at:http://www/ourcommunity-ourkids.org/ or call
                    844-644-9240.
                      <ul style={{ wordBreak: "break-word" }}>
                        <li>Tarrant</li>
                        <li>Hood</li>
                        <li>Erath</li>
                        <li>Someervell</li>
                        <li>Parker</li>
                        <li>Palo Pinto</li>
                        <li>Johnson</li>
                      </ul>
                    </Typography>
                    Our Community Our Kids, is now in partnership with DFPS as
                    part of Community-Based Care. Community-Based Care is a new
                    way of providing foster care and case management services.
                    It's a community-Based approach to meeting the individual
                    and unique needs of children, youth, and families. For more
                    information regarding Community-Based Care, please visit
                    https://www.CommunityBasedCarw.org. <br />
                    <br /> For information on fostering or adopting a child from
                    out of state, contact the state ICPC office.
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid black",
                      verticalAlign:"top",
                      marginTop: "0",
                      paddingTop: "0",
                      // width: "50%",

                    }}
                    >
                    <Typography  style={{ wordBreak: "break-word" }}>
                    EMPOWER is responsible for Foster Care in our area. You may
                    contact Cherly Batiste, the recuirtment specialist at
                    EMPOWER at (947) 289-2228.
                      <ul style={{ wordBreak: "break-word" }}>
                        <li>Dallas</li>
                        <li>Denton</li>
                        <li>Cooke</li>
                        <li>Wise</li>
                        <li>Fannin</li>
                        <li>Grayson</li>
                        <li>Collin</li>
                        <li>Rockwall</li>
                        <li>Hunt</li>
                        <li>Kaufman</li>
                        <li>Ellis</li>
                        <li>Navarro</li>
                      </ul>
                    </Typography>
                    Please find Information Meeting details below.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <br />
        <Typography
          style={{
            textAlign: "left",
            color: "red",
            paddingTop: "20px",
            width: "90%",
          }}
        >
          * If accomodations such as a sign language interpreter are needed in
          order to attend an information meeting, please call the Recritment
          Staff listed below 3 days prior to the meeting, if possible. *
        </Typography>
        <br />
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Grid item style={{ marginTop: "60px" }}>
            <Typography style={{ fontWeight: "bold" }}>
              Recruitment Events And Foster/Adopt Information Meetings:
            </Typography>
            <ul style={{ textAlign: "left" }}>
              <li>
                <Link to="#march2024">March 2024</Link>
              </li>
              <li>
                <Link to="#feb2024">February 2024</Link>
              </li>
              <li>
                <Link to="#jan2024">January 2024</Link>
              </li>
              <li>
                <Link target="_blank" to="https://heartgallerynorthtexas.org/">Heart Gallery of North Texas</Link>
              </li>
              <li>
                <Link to="#recrutstaff">Recritment Staff</Link>
              </li>
              <li>
                <Link target="_blank" to="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp">Faith-Based Staff</Link>
              </li>
              <li>
                <Link to="#homestudies">Where to mail Family Home Study</Link>
              </li>
            </ul>
          </Grid>
          <Grid item>
            <MapCard />
            <br/>
          </Grid>
        </Grid>
        <br />
        <Grid id="jan2024"
          container
          xs={11}
          sm={11}
          md={11}
          lg={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "bold",paddingBottom:'10px' }}>
            January 2024
          </Typography>
      
          <TableContainer
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
          >
            <Table
              style={
                {
                  // backgroundColor: "white",
                  // border: "solid black",
                  // // width: "900px",
                }
              }
            >
              <TableHead
                style={{
                  backgroundColor: "lightblue",
                  border: "solid black",
                }}
              >
                <TableRow>
                  <TableCell style={{ border: "solid black" }}>
                    LOCATION
                  </TableCell>
                  <TableCell>FOSTER/ADOPT INFORMATION MEETINGS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Friday,Jan 5th, 12:00 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        href="https://us02web.zoom.us/meeting/register/tZUtf-6qrDgpH9T2Ut5QsVcPBmOzW8A3FLA_#/registration"
                      >
                        https://us02web.zoom.us/meeting/register/tZUtf-6qrDgpH9T2Ut5QsVcPBmOzW8A3FLA_
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Tuesday, Jan 9th, 12:00 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://zoom.us/meeting/register/tJEpc-GtqD8rEtbjp4rwx8AOiqaWblFVy00F#/registration"
                      >
                        https://zoom.us/meeting/register/tJEpc-GtqD8rEtbjp4rwx8AOiqaWblFVy00F
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Wednesday, January 17th, 6:30pm
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      6100 Cowell, Blvd, Irving, TX 75309
                      <br />
                      <Link
                        style={{ wordBreak: "break-word" }} target="_blank"
                        to="https://docs.google.com/forms/d/e/1FAIpQLScEj1ssT8ZHWFvSEYlM_liSAtUJdQCR381RpVLVrlLwT7-4Ig/viewform?usp=sf_link"
                      >
                        https://docs.google.com/forms/d/e/1FAIpQLScEj1ssT8ZHWFvSEYlM_liSAtUJdQCR381RpVLVrlLwT7-4Ig/viewform?usp=sf_link
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Friday, Jan 19th, 12:00 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://zoom.us/meeting/register/tJAtce6qrz0iHtKCYymLkQZRdZfecUy1lW72#/registration"
                      >
                        https://zoom.us/meeting/register/tJAtce6qrz0iHtKCYymLkQZRdZfecUy1lW72
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Tuesday, Jan 23rd, 12:00 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }} 
                        to="https://zoom.us/meeting/register/tJEucu2prDoqHNfeluaazDFoxw1ge_m4pc8R#/registration"
                      >
                        https://zoom.us/meeting/register/tJEucu2prDoqHNfeluaazDFoxw1ge_m4pc8R
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Monday, Jan 29th, 6:30 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link  target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://us02web.zoom.us/meeting/register/tZUtf-6qrDgpH9T2Ut5QsVcPBmOzW8A3FLA_#/registration"
                      >
                        https://us02web.zoom.us/meeting/register/tZUtf-6qrDgpH9T2Ut5QsVcPBmOzW8A3FLA_
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <br />

        <Grid id="feb2024" container xs={11} sm={11} md={11} lg={8}>
          <TableContainer
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <br/>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              February 2024
            </Typography>
            <br/>
            <Table
              style={{
                backgroundColor: "white",
                border: "solid black",
                // width: "900px",
              }}
            >
              <TableHead style={{ backgroundColor: "lightblue" }}>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid black",
                    }}
                  >
                    LOCATION
                  </TableCell>
                  <TableCell>FOSTER/ADOPT INFORMATION MEETINGS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Friday, Feb 2nd, 12:00 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://zoom.us/meeting/register/tJIpcuqoqzkuEtPXYMfAz1AGGJmLFPuNGltD#/registration"
                      >
                        https://zoom.us/meeting/register/tJIpcuqoqzkuEtPXYMfAz1AGGJmLFPuNGltD
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Tuesday, February 6th, 6:30 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://us02web.zoom.us/meeting/register/tZ0vf-iupz8pGNdfzBaTunKw6TAguZbyhdDi#/registration"
                      >
                        https://us02web.zoom.us/meeting/register/tZ0vf-iupz8pGNdfzBaTunKw6TAguZbyhdDi
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Monday, Feb 12th, 6:30 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      6100 Cowell, Blvd, Irving, TX 75309
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://zoom.us/meeting/register/tJEsf-ugrTMtHdLT17gCgMHYl259D3o5vE5S#/registration"
                      >
                        https://zoom.us/meeting/register/tJEsf-ugrTMtHdLT17gCgMHYl259D3o5vE5S
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Friday, February 16th, 12:00 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://us02web.zoom.us/meeting/register/tZ0vf-iupz8pGNdfzBaTunKw6TAguZbyhdDi#/registration"
                      >
                        https://us02web.zoom.us/meeting/register/tZ0vf-iupz8pGNdfzBaTunKw6TAguZbyhdDi
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Tuesday, Feb 20th, 6:30 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://zoom.us/meeting/register/tJUpf-uqrzgpHd3FXhfZ2LZy_Vo5dPKZ1y5H#/registration"
                      >
                        https://zoom.us/meeting/register/tJUpf-uqrzgpHd3FXhfZ2LZy_Vo5dPKZ1y5H
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Wednesday, February 28th, 6:30pm
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      2201 K Ave Ste A200, Plano, TX 75074
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://docs.google.com/forms/d/e/1FAIpQLSfOrY2MOVC2LZbe0v6Cl3egZszRCnVlBgJIPVNR5X8anArTAw/viewform"
                      >
                        https://us02web.zoom.us/meeting/register/tZUtf-6qrDgpH9T2Ut5QsVcPBmOzW8A3FLA_
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <br />
        <Grid id="march2024"
          container
          xs={11}
          sm={11}
          md={11}
          lg={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "bold" ,padding:'10px'}}>
            March 2024
          </Typography>
          <TableContainer
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Table
              style={{
                backgroundColor: "white",
                border: "solid black",
                // width: "900px",
              }}
            >
              <TableHead style={{ backgroundColor: "lightblue" }}>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid black",
                    }}
                  >
                    LOCATION
                  </TableCell>
                  <TableCell>FOSTER/ADOPT INFORMATION MEETINGS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Friday, March 1st, 12:00 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://zoom.us/meeting/register/tJ0lcuCtrzksGNb6B3K3E4xkIz3vnPc4B02o#/registration"
                      >
                        https://zoom.us/meeting/register/tJ0lcuCtrzksGNb6B3K3E4xkIz3vnPc4B02o
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Friday, March 2nd, 10:00 am- 11:00 am
                      <br />
                      Foster Care and Adoption 101 Information Meeting
                      <br />
                      The Bedford Center YMCA, 2801 Forest Ridge Dr Bedford, TX 76021
                      <br />
                      <b>RSVP:</b><Link target="https://www.eventbrite.com/e/foster-care-and-adoption-101-information-tickets-528656675347" to=""> www.eventbrite.com/e/528656675347</Link>
                    </span>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Monday, March 4th, 6:30 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://us02web.zoom.us/meeting/register/tZUpcO-oqjspGtKNqp_6ppyFHNYw8ivXLt5y#/registration"
                      >
                        https://us02web.zoom.us/meeting/register/tZUpcO-oqjspGtKNqp_6ppyFHNYw8ivXLt5y
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Thursday, March 7th, 6:30 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      6100 Cowell, Blvd, Irving, TX 75309
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://zoom.us/meeting/register/tJMudeGqrTgpHNJGxNDGb-dfjNQC6BUx8Yp6#/registration"
                      >
                        https://zoom.us/meeting/register/tJMudeGqrTgpHNJGxNDGb-dfjNQC6BUx8Yp6
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Friday, March 15th 12:00 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://us02web.zoom.us/meeting/register/tZUpcO-oqjspGtKNqp_6ppyFHNYw8ivXLt5y#/registration"
                      >
                        https://us02web.zoom.us/meeting/register/tZUpcO-oqjspGtKNqp_6ppyFHNYw8ivXLt5y
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Tuesday, March 19th, 6:30 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://us02web.zoom.us/meeting/register/tZUpcO-oqjspGtKNqp_6ppyFHNYw8ivXLt5y#/registration"
                      >
                        https://us02web.zoom.us/meeting/register/tZUpcO-oqjspGtKNqp_6ppyFHNYw8ivXLt5y
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    ONLINE <br />
                    General Information Meeting
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "white",
                      border: "solid black",
                    }}
                  >
                    <span style={{ fontWeightL: "bold" }}>
                      Wednesday, March 27th ,6:30 PM
                      <br />
                      Register now for this foster/adopt meeting!
                      <br />
                      2201 K Ave Ste A200, Plano, TX 75074
                      <br />
                      <Link target="_blank"
                        style={{ wordBreak: "break-word" }}
                        to="https://zoom.us/meeting/register/tJYscemqpzIqGdNrX9G_V5VQWoB54zSwmHPY#/registration"
                      >
                        https://zoom.us/meeting/register/tJYscemqpzIqGdNrX9G_V5VQWoB54zSwmHPY
                      </Link>
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <br />
        <Grid id="recrutstaff"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            alignItems: "flex-start",
            paddingLeft: "10px",
            width: "90%",
            paddingBottom: "20px",
          }}
        >
          <br/>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Recruitment Staff
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: "bold" }}>
              Region 3b Recruitment Staff:
            </span>
            <br />
            <span style={{ color: "blue" }}>Aresha Dickens</span> at Our
            Community Our Kids, OOL (817) 502-1333
          </Typography>
          <Typography style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: "bold" }}>
              Region 3b Recruitment Staff:
            </span>
            <br />
            <span style={{ color: "blue" }}>Cheryl Batiste</span> at EMPOWER
            (945) 289-2228
          </Typography>
          <br />
          {/* <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Home Studies
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: "bold" }}>
              Out of state and private adoption agency families:
            </span>
            Please mail your home study to us if you are intrested in children
            from this area. If you are a DFPS family, you do not need to do this
            since DFPS staff can obtain your study. <br />
            Our mailing address:
            <br />
            Department of Family and Protective Services
            <br />
            Recruitment Unit
            <br />
            P.O. Box 200697
            <br />
            Arlington,TX 76006
          </Typography> */}
        </Grid>
        <br />
        <Grid id="homestudies"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            alignItems: "flex-start",
            paddingLeft: "10px",
            width: "90%",
            paddingBottom: "20px",
          }}
        >
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Home Studies
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: "bold" }}>
              Out of state and private adoption agency families:
            </span>
            Please mail your home study to us if you are intrested in children
            from this area. If you are a DFPS family, you do not need to do this
            since DFPS staff can obtain your study. <br />
            Our mailing address:
            <br />
            Department of Family and Protective Services
            <br />
            Recruitment Unit
            <br />
            P.O. Box 200697
            <br />
            Arlington,TX 76006
          </Typography>

          </Grid>
          </Grid>
    
    </div>
  );
};

export default Region3;
