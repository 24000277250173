import React from "react";
import ImageSlider from "../layout/imageslider";
// import {Grid} from "@mui/icons-material"
import { Grid, Typography, Card } from "@mui/material";
import { Link } from "react-router-dom";
import Pic1 from "../../components/fostercareOverview/images/pic1.jpg";
import Pic2 from "../../components/fostercareOverview/images/pic2.jpg";
import Pic3 from "../../components/fostercareOverview/images/pic3.jpg";
import pic4 from "../../components/fostercareOverview/images/pic4.jpg";
import pic5 from "../../components/fostercareOverview/images/pic5.jpg";
import pic6 from "../../components/fostercareOverview/images/pic6.jpg";
import pic7 from "../../components/fostercareOverview/images/pic7.jpg";
import pic8 from "../../components/fostercareOverview/images/pic8.jpg";
import pic9 from "../../components/fostercareOverview/images/pic9.jpg";
import video1 from "../../components/fostercareOverview/videos/teaPartySp.wmv";
import video2 from "../../components/fostercareOverview/videos/ribbonSp.wmv";
import video3 from "../../components/fostercareOverview/videos/soccerSp.wmv";
import ado1 from "../../components/fostercareOverview/videos/adopt1.ram";
import ado2 from "../../components/fostercareOverview/videos/adopt2.ram";
import COA from "../../components/fostercareOverview/videos/COAC.ram";
import fost1 from "../../components/fostercareOverview/videos/foster1.ram";
import fost2 from "../../components/fostercareOverview/videos/foster2.ram";
import mal from "../../components/fostercareOverview/videos/malv.ram";
import oyh from "../../components/fostercareOverview/videos/oyh1.ram";
import family from "../../components/fostercareOverview/videos/weareafamily.wmv";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import "./fostervideo.css";
const Fostervideo = () => {
  return (
    <div>
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <ImageSlider /> */}
        <Grid id="maingrid">
          <Grid
            align="center"
            style={{
              // height: "50px",
              textAlign: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <Typography
              variant="h5"
              style={{ backgroundColor: "#3f51b5", padding: "10px" }}
            >
              Adoption and Foster Care Videos and Public Service Announcements
            </Typography>
          </Grid>
          <br />
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Grid style={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingBottom: "10px" }}
              >
                You don't have to be perfect to be a perfect parent.
              </Typography>
              <Grid style={{ display: "flex", textAlign: "justify" }}>
                <DoubleArrowIcon />
                <Typography>
                  A new campaign released by the Ad Council focuses on the need
                  for parents to provide loving, permanent homes for teens in
                  foster care. Learn more and view the 4 public service
                  announcements below:
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "50px",
                width: "80%",
                marginTop: "20px",
                marginBottom: "20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Card
                  style={{
                    border: "solid black",
                    borderWidth: "2px",
                    padding: "10px",
                    width: "150px",
                  }}
                >
                  <p>Consoling</p>
                  <img src={pic4} />
                  <p>Gift</p>
                </Card>
              </Grid>
              <Grid item>
                <Card
                  style={{
                    border: "solid black",
                    borderWidth: "2px",
                    padding: "10px",
                    width: "150px",
                  }}
                >
                  <p>Phone</p>
                  <img src={pic5} />
                  <p>Hamster</p>
                </Card>
              </Grid>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "left",
                textAlign: "left",
                width: "100%",
              }}
            >
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                En Español
              </Typography>
              <ul>
                <li style={{ paddingBottom: "10px" }}>
                  <Link to="">En Buenas Manos"</Link> View 12 minute recruitment
                  video in Spanish.
                </li>
                <li>
                  <Link to="">En Buenas Manos"</Link>. Vea un video de 12
                  minutos sobre la adopción.
                </li>
              </ul>
            </Grid>
            <Grid style={{ width: "100%", textAlign: "left" }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Vea estos tres anuncios de interés público en video
              </Typography>
              <Grid
                container
                style={{
                  display: "flex",
                  gap: "50px",
                  padding: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Card
                    align="center"
                    style={{
                      border: "2px solid black",
                      width: "150px",
                      height: "200px",
                      padding: "10px",
                    }}
                  >
                    <img src={Pic1} />
                    <p>
                      <a href={video1}>Tomar té con mami</a>
                    </p>
                  </Card>
                </Grid>
                <Grid item>
                  <Card
                    align="center"
                    style={{
                      padding: "10px",
                      border: "2px solid black",
                      width: "150px",
                      // backgroundColor:'red',
                      height: "200px",
                    }}
                  >
                    <img src={Pic2} />
                    <p>
                      <a href={video2}>Cinta Azul</a>
                    </p>
                  </Card>
                </Grid>
                <Grid item>
                  <Card
                    align="center"
                    style={{
                      padding: "10px",
                      border: "2px solid black",
                      width: "150px",
                      height: "200px",
                    }}
                  >
                    <img src={Pic3} />
                    <p>
                      <a href={video3}>Jugar al fútbol con papá</a>
                    </p>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "10px", fontWeight: "bold" }}
              >
                Foster/Adoptive Parents Speak
              </Typography>
              <Typography>
                <b>Note:</b> The multimedia files we use are in .ram format. You
                will need{" "}
                <Link target="_blank" to="https://www.real.com/realplayer">
                  RealOne Player
                </Link>{" "}
                to view videos. Look for "Free RealOne Player" then "Download
                the Free RealOne Player." <br />
                Please make note where the file will download to so you can find
                it quickly and execute the installation of RealOne Player.
              </Typography>

              <br />
              <Grid>
                <Grid
                  container
                  gap="30px"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item>
                    <Card
                      align="center"
                      style={{
                        height: "290px",
                        border: "2px solid black",
                        padding: "10px",
                        width: "250px",
                      }}
                    >
                      <img src={pic6} />
                      <p>Foster Parent</p>
                      <ul style={{ textAlign: "left" }}>
                        <li style={{ paddingBottom: "10px" }}>
                          <a href={fost1}>
                            The importance of training prospective foster
                            parents
                          </a>
                        </li>
                        <li>
                          <a href={fost2}>
                            What it takes to become a foster parent
                          </a>
                        </li>
                      </ul>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      align="center"
                      style={{
                        height: "290px",
                        border: "2px solid black",
                        padding: "10px",
                        width: "250px",
                      }}
                    >
                      <img src={pic7} />
                      <p>Adoptive Parent</p>
                      <ul style={{ textAlign: "left" }}>
                        <li style={{ paddingBottom: "10px" }}>
                          <a href={ado1}>
                            The importance of training prospective adoptive
                            parents
                          </a>
                        </li>
                        <li style={{ paddingBottom: "10px" }}>
                          <a href={ado2}>Making the decision to adopt</a>
                        </li>
                        <li>
                          <a href={family}>We are a Family</a>
                        </li>
                      </ul>
                    </Card>
                  </Grid>
                  <Grid item>
                    {" "}
                    <Card
                      align="center"
                      style={{
                        height: "290px",
                        width: "250px",
                        border: "2px solid black",
                        padding: "10px",
                      }}
                    >
                      <img
                        src={pic8}
                        style={{ width: "150px", height: "150px" }}
                      />
                      {/* <p>Meet this Texas Adoption Family</p> */}
                      <ul style={{ textAlign: "left" }}>
                        <li>
                          <a href={mal}>Meet this Texas Adoption Family</a>
                        </li>
                      </ul>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <Grid item>
                <Card
                  align="center"
                  style={{
                    // height: "300px",
                    border: "2px solid black",
                    padding: "10px",
                  }}
                >
                  <img src={pic9} />
                  <p>
                    <a href={oyh}>Open Your Eyes</a>
                  </p>
                  <p>Courtesy of the Lubbock Advertising Federation (LAF)</p>
                  <ul style={{ textAlign: "left" }}>
                    <li style={{ paddingBottom: "10px" }}>
                      <a href={COA}>
                        Every Kid" and "Children of Color" Public Service
                        Announcement
                      </a>
                    </li>
                    <li>
                      Courtesy of Austin Council on Adoptable Children - ACOAC
                    </li>
                  </ul>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Fostervideo;
