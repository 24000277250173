import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";
const Asthma = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" style={{ color: "#002F86",textAlign:"center",fontWeight:"bold" }}>
              A closer look at Asthma{" "}
            </Typography>
            <br />
            <Typography>
              <p>
                {" "}
                <em>
                  More...
                  <a target="_blank" href="http://www.epa.gov/asthma/">
                    Asthma
                  </a>
                </em>
              </p>
            </Typography>

            <br />
            <Typography>
              Asthma is a chronic inflammation of the lungs that causes
              breathing problems.
            </Typography>

            <br />
            <Typography>
              As many as 15 million Americans suffer from asthma and many more
              probably have it and don’t know it. Asthma is not contagious.
              While there is no cure yet, asthma can be controlled, usually
              through medications prescribed by a doctor and by avoiding the
              things that trigger an asthma attack.
            </Typography>
            <br />
            <Typography>
              Typical symptoms of asthma include wheezing, coughing, chest
              tightness, shortness of breath, and excess mucus. These conditions
              result from the underlying inflammation of the airways of the
              lungs. The airway muscles contract at the same time that the lung
              tissue swells, constricting the flow of air in and out of the
              lungs. Further, mucus is excreted, and it plugs up the tiny
              airways.
            </Typography>
            <br />
            <Typography>
              Symptoms of asthma may appear as a result of respiratory
              infections or exposure to a wide range of irritants, as well as
              exposure to certain medications, emotional stress, weather
              changes, exercise, and physical conditions. Among the irritants
              that can trigger asthma are pollen, mold, dust, cockroach, animal
              dander, urine, saliva, workplace chemicals, smoke, perfume, hair
              spray, vapors, paint, cleaners, food additives, cosmetics, air
              pollutants, gases, fumes, and aerosols.
            </Typography>
            <br />
            <Typography>
              Emotions such as fear, anger, frustration, crying, and laughing
              can cause asthma in some people. People with asthma often have to
              be careful about exercise, sometimes by taking medication before
              exertion. Menstrual period, pregnancy, and thyroid conditions also
              are known to trigger asthma problems.
            </Typography>
            <br />
            <Typography>
              Certain food additives and medicines are asthma triggers, too.
              These include sulfites in tuna, lemon juice, grape juice, dried
              apples and raisins, and wine. Medication irritants include
              aspirin, ibuprofen, indomethacin, and naproxen.
            </Typography>
            <br />
            <Typography>
              When an irritant is inhaled or swallowed, the body reacts by
              releasing extra antibodies, known as immune globulin E, to fend
              off the noxious substance by generating a substance called
              histamine. This substance swells tissue, constricts muscles, and
              produces mucus. While these three effects are part of the body’s
              defense system for keeping out foreign irritants, an overreaction
              can cause asthma symptoms.
            </Typography>
            <br />
            <Typography>
              Each asthma sufferer must devise a plan to manage the condition
              according to a physician’s advice. The idea is to prevent
              inflammation of the lungs and keep the airways from constricting.
              An individual, even a child, can learn when the lungs are starting
              to react to irritants. Avoiding the irritants is a primary means
              of preventing the airways of the lungs from reacting. Quick use of
              medication also can stave off an asthma attack.
            </Typography>
            <br />
            <Typography>
              Many asthma sufferers monitor the air flow through their lungs
              with a device that measures the peak air flow. Using such a
              self-test, they can know their relative susceptibility to an
              asthma attack and gauge the need for a medicated breathing
              therapy. Physicians prescribe a wide variety of drugs to help
              reduce lung inflammation and dilate the airways.
            </Typography>
            <br />
            <Typography>
              Parents of children with asthma must seek the continued care of a
              health care professional, usually a physician. The doctor will
              administer one or more tests to make a firm diagnosis that the
              condition really is asthma and not another bronchial problem. The
              parents and child will work to learn which irritants to avoid,
              often by keeping a journal of the foods and medicines ingested and
              the environment encountered. Usually, the doctor will prescribe
              medication, such as steroids and antibiotics, to tackle the lung
              inflammation, as well as a regimen of inhaling a drug to open the
              airways of the lungs.
            </Typography>
            <br />
            <Typography>
              Parents will help the child "get in tune" with his or her body to
              recognize when the symptoms of an asthma attack are imminent.
              Parents also will develop a positive plan of action--based on a
              physician’s recommendations--for an acute asthma attack.
            </Typography>
            <br />
            <Grid style={{ width: "100%", textAlign: "left" }}>
              <Typography>
                A source of information and support for parents is Allergy and
                Asthma Network/Mothers of Asthmatics, Inc., 3554 Chain Bridge
                Road, Suite 200, Fairfax, Virginia 22030. Phone 1-800-878-4403.
              </Typography>
              <br />
              <Typography>
                People with asthma can control their condition and lead an
                active life.
              </Typography>
              <br />
              <Typography>
                If you are a Texas resident and are not approved as a foster or
                adoptive family, please fill out our Adoption and Foster Care
                Interest form in the{" "}
                <a href="/generalInformation">Get Started</a> section.
              </Typography>
              <br />
              <Typography>
                If you have questions or want to inquire about a specific child
                or sibling group, contact the{" "}
                <a href=" ">Texas Adoption Resource Exchange (TARE)</a> or call
                1-800-233-3405.
              </Typography>
              <br />
              <Grid style={{ float: "right" }}>
                <Typography>
                  This content taken from:{" "}
                  <a
                    target="_blank"
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                  >
                    Adoption and Foster Care
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Asthma;
