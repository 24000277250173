import { Grid } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";

const Partners_accordings = () => {
  return (
    <div>
      <Grid id="partners_acoording">
        <Grid>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography> REGION 1 (Lubbock)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    REGION 1 (Lubbock)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="https://www.arrow.org/"  target="_blank" id="listslink">
                        Arrow Child and Family Ministries
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.awfc.org/"  target="_blank" id="listslink">
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.bair.org/"  target="_blank" id="listslink">
                        Bair Foundation
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.buckner.org/"  target="_blank" id="listslink">
                        Buckner Children and Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="https://childshome.org/"  target="_blank" id="listslink">
                        Children's Home of Lubboock,Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="https://childrenhp.org/"  target="_blank" id="listslink">
                        Children's Hope Residential Services,Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.depelchin.org/"  target="_blank" id="listslink">
                        Depelchin Children's Center
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.gracemanor.org/"  target="_blank" id="listslink">
                        Grace Manor
                      </Link>
                    </li>
                    <li>
                      <Link to="https://fosteradopttexas.com/"  target="_blank" id="listslink">
                        Panhandle Child Placement Services,Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to=""  target="_blank" id="listslink">
                        Texas Boys Ranch,Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to=""  target="_blank" id="listslink">
                        Texas Family Initiative
                      </Link>
                    </li>
                    <li>
                      <Link to="https://upbring.org/"  target="_blank" id="listslink">
                        Upbring
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.arrow.org/"
                        id="listslink"
                      >
                        Arrow Child and Family Ministries
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.awfc.org/"
                        id="listslink"
                      >
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.bair.org/"
                        id="listslink"
                      >
                        Bair Foundation
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.buckner.org/"
                        id="listslink"
                      >
                        Buckner Children and Family Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://childshome.org/"
                        id="listslink"
                      >
                        Children's Home of Lubboock,Inc.
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://childrenhp.org/"
                        id="listslink"
                      >
                        Children's Hope Residential Services,Inc.
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.depelchin.org/"
                        id="listslink"
                      >
                        Depelchin Children's Center
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.gracemanor.org/"
                        id="listslink"
                      >
                        Grace Manor
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://fosteradopttexas.com/"
                        id="listslink"
                      >
                        Panhandle Child Placement Services,Inc.
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.texasboysranch.org/"
                        id="listslink"
                      >
                        Texas Boys Ranch,Inc.
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://tfifamily.org/"
                        id="listslink"
                      >
                        Texas Family Initiative
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://upbring.org/"
                        id="listslink"
                      >
                        Upbring
                      </Link>
                    </li>
                  </ul>
                  <br />
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography>REGION 2 (Abilene)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 2 (Abilene)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="https://www.arrow.org/"  target="_blank" id="listslink">
                        Arrow Child and Family Ministries
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.awfc.org/"  target="_blank" id="listslink">
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link to="https://caringheartsforchildren.org/"  target="_blank" id="listslink">
                        Caring Hearts for children
                      </Link>
                    </li>
                    <li>
                      <Link to="https://christianhomes.com/"  target="_blank" id="listslink">
                        Christian Homes & Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"  target="_blank" id="listslink">
                        Gladney Center for Adoption
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.harmonyfamilyservices.org/"  target="_blank" id="listslink">
                        Harmony Family Services - child Placing
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.mch.org/"  target="_blank" id="listslink">
                        Methodist Children's Home
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.newhorizonsinc.com/"  target="_blank" id="listslink">
                        New Horizons Ranch and Center Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="https://pathway.org/"  target="_blank" id="listslink">
                        Pathways Youth & Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.pchas.org/"  target="_blank" id="listslink">
                        Prestbyterian Children's Homes & Services.
                      </Link>
                    </li>
                    <li>
                      <Link to="https://tfifamily.org/"  target="_blank" id="listslink">
                        Texas Family Initiative
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.arrow.org/"
                        id="listslink"
                      >
                        Arrow Child and Family Ministries
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.awfc.org/"
                        id="listslink"
                      >
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://caringheartsforchildren.org/"
                        id="listslink"
                      >
                        Caring Hearts for children
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://christianhomes.com/"
                        id="listslink"
                      >
                        Christian Homes & Family Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                        id="listslink"
                      >
                        Gladney Center for Adoption
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.harmonyfamilyservices.org/"
                        id="listslink"
                      >
                        Harmony Family Services - child Placing
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.mch.org/"
                        id="listslink"
                      >
                        Methodist Children's Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.newhorizonsinc.com/"
                        id="listslink"
                      >
                        New Horizons Ranch and Center Inc.
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://pathway.org/"
                        id="listslink"
                      >
                        Pathways Youth & Family Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.pchas.org/"
                        id="listslink"
                      >
                        Prestbyterian Children's Homes & Services.
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://tfifamily.org/"
                        id="listslink"
                      >
                        Texas Family Initiative
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography> REGION 3 (Arlington)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 3 (Arlington)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link
                        to="https://www.awfc.org/"
                        target="_blank"
                        id="listslink"
                      >
                        A World For Children{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.aheartwithhope.org/"
                        target="_blank"
                        id="listslink"
                      >
                        A Heart with Hope Family Services, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://achservices.org/"
                        target="_blank"
                        id="listslink"
                      >
                        ACH Child and Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Addy's Hope Adoption Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Advantage Adoptions{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Agape Manor Homes{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Amazing Grace Child and Family
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Anchor Family Services, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Angelheart
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Antelope Valley Child, Youth & Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Arrow Child and Family Ministries{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Assuring Love Child Placement Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Azleway Children's Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Benevolent House Child Placing Agency
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Benchmark Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Buckner Children and Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Caregivers Youth and Transitional Living Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Caring Hearts for Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Catholic Charities of Dallas
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Cherished Impressions Child Placing Agency
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Children and Family Institute{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Children's Hope Residential Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Circles of Care{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        CK Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Divinity Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Eckerd Youth Alternatives{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Gladney Center for Adoption{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Harbor of Hope, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Homebound Child-Placing Agency
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Hope Cottage, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        House of Shiloh Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Houston Serenity Place, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Jonathan's Place
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Kids Grace Child Placement Agency
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Kingdom Kids
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Lifeline Children and Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Lonestar Social Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Make A Way, Inc. Child Placing Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Methodist Children's Home
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Optimum Children's Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Passage of Youth Family Center{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Pathways Youth & Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Perfection Children Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Presbyterian Children's Homes & Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Ratcliff Youth and Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        REACH Child Placing Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Refuge House{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Safe Life Journey Child Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Texas Baptist Home For Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        The Bair Foundation of Texas
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        The Gladney Center for Adoption
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        The Grandberry Intervention Foundation
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Therapeutic Family Life{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Tomorrow's Children, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Upbring{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Urban Neighborhood Initiative for Families and Youth
                        (UNIFY){" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Vessels With Purpose{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Youth in View{" "}
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.awfc.org/"
                        id="listslink"
                      >
                        A World For Children{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.aheartwithhope.org/"
                        id="listslink"
                      >
                        A Heart with Hope Family Services, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://achservices.org/"
                        id="listslink"
                      >
                        ACH Child and Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.addyshope.org/ "
                        id="listslink"
                      >
                        Addy's Hope Adoption Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.ococtexas.org/ "
                        id="listslink"
                      >
                        Advantage Adoptions{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.agapemanorhome.org/ "
                        id="listslink"
                      >
                        Agape Manor Homes{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.amazinggracecfs.org/ "
                        id="listslink"
                      >
                        {" "}
                        Amazing Grace Child and Family
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to=" " id="listslink">
                        Anchor Family Services, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.angelheartkids.org/"
                        id="listslink"
                      >
                        {" "}
                        Angelheart
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://avcyfs.org/"
                        id="listslink"
                      >
                        Antelope Valley Child, Youth & Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.arrow.org/ "
                        id="listslink"
                      >
                        Arrow Child and Family Ministries{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.assuringlove.org/page/page/8789769.htm "
                        id="listslink"
                      >
                        Assuring Love Child Placement Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.azleway.org/ "
                        id="listslink"
                      >
                        Azleway Children's Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://benevolenthouse.org/"
                        id="listslink"
                      >
                        {" "}
                        Benevolent House Child Placing Agency
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://benchmarkfamilyservices.org/"
                        id="listslink"
                      >
                        Benchmark Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.buckner.org/foster-care-adoption "
                        id="listslink"
                      >
                        Buckner Children and Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.caregiversytls.org/"
                        id="listslink"
                      >
                        Caregivers Youth and Transitional Living Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://caringheartsforchildren.org/"
                        id="listslink"
                      >
                        {" "}
                        Caring Hearts for Children
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.ccdallas.org/ "
                        id="listslink"
                      >
                        {" "}
                        Catholic Charities of Dallas
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.cherishedimpressionscpa.com/ "
                        id="listslink"
                      >
                        {" "}
                        Cherished Impressions Child Placing Agency
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.cfiservices.org/ "
                        id="listslink"
                      >
                        Children and Family Institute{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://childrenhp.org/ "
                        id="listslink"
                      >
                        {" "}
                        Children's Hope Residential Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://coc.tempurl.host/ "
                        id="listslink"
                      >
                        Circles of Care{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://ckfamilyservices.org/ "
                        id="listslink"
                      >
                        CK Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.divinityfamilyservices.com/ "
                        id="listslink"
                      >
                        {" "}
                        Divinity Family Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://eckerd.org/"
                        id="listslink"
                      >
                        Eckerd Youth Alternatives{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption "
                        id="listslink"
                      >
                        Gladney Center for Adoption{" "}
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to=" " id="listslink">
                        Harbor of Hope, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" http://homeboundcpa.com/"
                        id="listslink"
                      >
                        {" "}
                        Homebound Child-Placing Agency
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.hoshiloh.com/"
                        id="listslink"
                      >
                        Hope Cottage, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.hoshiloh.com/ "
                        id="listslink"
                      >
                        {" "}
                        House of Shiloh Family Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://serenityrtc.org/"
                        id="listslink"
                      >
                        {" "}
                        Houston Serenity Place, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.jpkids.org/"
                        id="listslink"
                      >
                        {" "}
                        Jonathan's Place
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to=" " id="listslink">
                        {" "}
                        Kids Grace Child Placement Agency
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="http://ww25.kingdomkidscpa.org/?subid1=20240123-1740-4272-9815-d87df97c10b1 "
                        id="listslink"
                      >
                        {" "}
                        Kingdom Kids
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://lifelinecfs.org/ "
                        id="listslink"
                      >
                        Lifeline Children and Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.lonestarsocialservices.com/"
                        id="listslink"
                      >
                        Lonestar Social Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://makeaway.info/"
                        id="listslink"
                      >
                        Make A Way, Inc. Child Placing Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.mch.org/"
                        id="listslink"
                      >
                        {" "}
                        Methodist Children's Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://ocskids.care/ "
                        id="listslink"
                      >
                        Optimum Children's Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.passageofyouth.org/"
                        id="listslink"
                      >
                        Passage of Youth Family Center{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://pathway.org/"
                        id="listslink"
                      >
                        {" "}
                        Pathways Youth & Family Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://ourcommunity-ourkids.org/agencies/ "
                        id="listslink"
                      >
                        {" "}
                        Perfection Children Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.pchas.org/ "
                        id="listslink"
                      >
                        {" "}
                        Presbyterian Children's Homes & Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.ratcliffservices.org/ "
                        id="listslink"
                      >
                        {" "}
                        Ratcliff Youth and Family Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.reachcpatx.org/"
                        id="listslink"
                      >
                        REACH Child Placing Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://refugehouse.org/ "
                        id="listslink"
                      >
                        Refuge House{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://safelifefoster.com/ "
                        id="listslink"
                      >
                        Safe Life Journey Child Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://tbhc.org/ "
                        id="listslink"
                      >
                        {" "}
                        Texas Baptist Home For Children
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.bair.org/"
                        id="listslink"
                      >
                        {" "}
                        The Bair Foundation of Texas
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://adoptionsbygladney.com/ "
                        id="listslink"
                      >
                        {" "}
                        The Gladney Center for Adoption
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to=" https://www.tgifoundation.org/"
                        id="listslink"
                      >
                        {" "}
                        The Grandberry Intervention Foundation
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://tflife.org/ "
                        id="listslink"
                      >
                        Therapeutic Family Life{" "}
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to=" " id="listslink">
                        {" "}
                        Tomorrow's Children, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://upbring.org/ "
                        id="listslink"
                      >
                        Upbring{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://unifycpa.org/ "
                        id="listslink"
                      >
                        Urban Neighborhood Initiative for Families and Youth
                        (UNIFY){" "}
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to=" " id="listslink">
                        Vessels With Purpose{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://youthinview.org/ "
                        id="listslink"
                      >
                        Youth in View{" "}
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography>REGION 4 (Tyler)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 4 (Tyler)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Arrow Child and Family Ministries
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Azleway Children's Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        BCFS Health and Human Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Buckner Children and Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Christian Homes and Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Gladney Center for Adoption
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Grace Manor{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Living Alternatives{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Lonestar Social Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Methodist Children's Home
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Noble Children's Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        The Bair Foundation
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Upbring{" "}
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <a target="_blank" href="http://www.awfc.org">
                        A World For Children
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.arrow.org/">
                        Arrow Child and Family Ministries
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://azleway.org">
                        Azleway Children's Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://discoverbcfs.net/">
                        BCFS Health and Human Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.buckner.org">
                        Buckner Children and Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://christianhomes.com/">
                        Christian Homes and Family Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                      >
                        Gladney Center for Adoption
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.gracemanor.org/">
                        Grace Manor
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://livingalternatives.org/">
                        Living Alternatives{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://lonestarsocialservices.com/"
                      >
                        Lonestar Social Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.mch.org/">
                        Methodist Children's Home
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a target="_blank" href="https://noblecs.org/">
                        Noble Children's Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.bair.org/">
                        The Bair Foundation
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.upbring.org/">
                        Upbring
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography> REGION 5 (Beaumont)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 5 (Beaumont)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Arrow Child and Family Ministries
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Azleway Children's Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Buckner Children and Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Faith 2 Faith Foster Care and Adoption{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Gladney Center for Adoption
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Grace Manor
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Methodist Children's Home
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Noble Children's Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Therapeutic Family Life{" "}
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <a target="_blank" href="http://www.arrow.org">
                        Arrow Child and Family Ministries
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://azleway.org">
                        Azleway Children's Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.beafamily.org">
                        Buckner Children and Family Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="javascript:obfuscator('shalonda_hendrix','yahoo.com');"
                      >
                        Faith 2 Faith Foster Care and Adoption
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                      >
                        Gladney Center for Adoption
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.gracemanor.org/">
                        Grace Manor
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.mch.org/">
                        Methodist Children's Home
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://noblecs.org/">
                        Noble Children's Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.tflife.org">
                        Therapeutic Family Life
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography>REGION 6 (Houston)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 6 (Houston)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        1 Archangel Foster and Adoption Agency
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        1 Care Premier Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Accompanied by God's Love{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Agape Manor Home{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Alternatives in Motion (AIM){" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        America's Angels, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Arms Wide Adoption Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Arrow Child and Family Ministries
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Ascension Child and Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Azleway Children's Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Caring Adoptions{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Casa De Esperanza De Los Ninos{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Children of Diversity, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Circles of Care
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Circle of Living Hope{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Credence Village, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        DePelchin Children’s Center
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Family to Family Adoption Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Forever Families, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Gladney Center for Adoption{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Good Hearts Youth and Family Services, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Guardians Promise, LLC
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Hands of Healing Residential Treatment Center, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Have Haven{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Heart Of The Kids{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Homes with Hope{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Houston Achievement Place
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Houston Serenity Place, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Kidz 2 Kidz Child Placing Agency
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Kidz Thrive Child Placing Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Light Accommodation Foundation{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Lonestar Social Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Loving Houston Adoption Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Matching Hearts
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Methodist Children's Home{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Monarch Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Passion For Families{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Pathways Youth & Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Presbyterian Children's Homes & Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Rainbow of Love Adoption Agency, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Safe Haven Community Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Transitions for Tomorrow Child Placing Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Trel's Home for Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Unity Children's Home
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        We R Champions Child Placing Agency
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <a target="_blank" href="https://www.1archangel.org/">
                        1 Archangel Foster and Adoption Agency
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.1carepremierservices.com/"
                      >
                        1 Care Premier Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.awfc.org">
                        {" "}
                        A World For Children
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.accompaniedbygodslove.com/"
                      >
                        Accompanied by God's Love
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.agapemanorhome.org/">
                        Agape Manor Home
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.aimadoptions.org/">
                        Alternatives in Motion (AIM)
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.americas-angels.com/"
                      >
                        America's Angels, Inc.
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.armswideadoption.org/"
                      >
                        Arms Wide Adoption Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.arrow.org/">
                        Arrow Child and Family Ministries
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://ascensionchildandfamilyservices.com/"
                      >
                        Ascension Child and Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://azleway.org">
                        Azleway Children's Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.caringadoptions.org">
                        Caring Adoptions
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.casahope.org/">
                        Casa De Esperanza De Los Ninos
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.children-of-diversity.org/"
                      >
                        Children of Diversity, Inc.{" "}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.circlesofcareinc.org">
                        Circles of Care
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.colh.org/">
                        Circle of Living Hope
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://credencevillage.org/">
                        Credence Village, Inc.
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.depelchin.org">
                        DePelchin Children’s Center
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.fam2fam.org">
                        Family to Family Adoption Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.forfam.org/%20">
                        Forever Families, Inc.
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                      >
                        Gladney Center for Adoption
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://goodheartsyfs.org/">
                        Good Hearts Youth and Family Services, Inc.
                      </a>
                      &nbsp;&nbsp;{" "}
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.guardianspromise.com/"
                      >
                        Guardians Promise, LLC
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://handsofhealing.org/child-placement-agency/"
                      >
                        Hands of Healing Residential Treatment Center, Inc.
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.havehaven.org">
                        Have Haven
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.heartofthekids.org/">
                        Heart Of The Kids
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.homeswithhope.org/">
                        Homes with Hope
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://hapkids.org/">
                        Houston Achievement Place
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://serenityrtc.org/">
                        Houston Serenity Place, Inc.
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="javascript:obfuscator('KIDZ2KIDZ_CPA','YAHOO.COM;);"
                      >
                        Kidz 2 Kidz Child Placing Agency
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://kidzthrive.com/">
                        Kidz Thrive Child Placing Agency
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://lightaccommodation.org/">
                        Light Accommodation Foundation
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.lonestarsocialservices.com/"
                      >
                        Lonestar Social Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://lovinghoustonadoption.org/"
                      >
                        Loving Houston Adoption Agency
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://matchingheartstx.com/">
                        Matching Hearts
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.mch.org/">
                        Methodist Children's Home
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://monarchfamilyservices.com/"
                      >
                        Monarch Family Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.passionforfamilies.org/"
                      >
                        Passion For Families
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.pathway.org">
                        Pathways Youth &amp; Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.pchas.org">
                        Presbyterian Children's Homes &amp; Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.rainbowoflove.org/">
                        Rainbow of Love Adoption Agency, Inc.
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://safehavencommunityservices.org/"
                      >
                        Safe Haven Community Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="javascript:obfuscator('secunda','transitionsfortomorrow.com');"
                      >
                        Transitions for Tomorrow Child Placing Agency
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.th4c.org/">
                        Trel's Home for Children
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.unitychildrenshome.com/"
                      >
                        Unity Children's Home
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.werchampionscpa.com/">
                        We R Champions Child Placing Agency
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography>REGION 7 (Austin)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 7 (Austin)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        4 Points Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Access Hope{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        America's Heart{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Angelheart{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Arrow Child and Family Ministries
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Benchmark Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Caring Hearts for Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Circles of Care{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Circle of Living Hope{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        CK Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Depelchin Children’s Center
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Divinity Family Services, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Foster Texas{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Gladney Center for Adoption{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Helping Hand Home Foster Care and Adoption Program
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Lighthouse Family Network{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Lonestar Social Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Pathways Youth & Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Renaissance Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        STARRY{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        The Bair Foundation
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        The Giocosa Foundation
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        The Payton Foundation
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        The Safe Alliance{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        The Settlement Home for Children{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Texas Foster Care and Adoption Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Therapeutic Family Life{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Upbring
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <a target="_blank" href="https://www.4points.life/">
                        4 Points Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.awfc.org">
                        A World For Children
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.fbfutures.org/">
                        Access Hope
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.americas-heart.org/">
                        America's Heart
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://angelheartkids.org/">
                        Angelheart
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.arrow.org">
                        Arrow Child and Family Ministries
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.benchmarkfamilyservices.org/"
                      >
                        Benchmark Family Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.caringheartsforchildren.org/"
                      >
                        Caring Hearts for Children
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.circlesofcareinc.org/"
                      >
                        Circles of Care
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.colh.org/">
                        Circle of Living Hope
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.ckfamilyservices.org">
                        CK Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.depelchin.org">
                        Depelchin Children’s Center
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.divinityfamilyservices.com/"
                      >
                        Divinity Family Services, Inc.
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.fostertexas.net/">
                        Foster Texas
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                      >
                        Gladney Center for Adoption
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.helpinghandhome.org">
                        Helping Hand Home Foster Care and Adoption Program
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.lfnetwork.org/">
                        Lighthouse Family Network
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.lonestarsocialservices.com"
                      >
                        Lonestar Social Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.pathway.org">
                        Pathways Youth &amp; Family Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.rfsoftexas.com/index.html"
                      >
                        Renaissance Family Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.starry.org/Site/Services/Foster-Care-Adoption-Program/"
                      >
                        STARRY
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.bair.org">
                        The Bair Foundation
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.giocosa.org/">
                        The Giocosa Foundation
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.tpfcpa.org/about">
                        The Payton Foundation
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.safeaustin.org/">
                        The Safe Alliance
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.settlementhome.org/">
                        The Settlement Home for Children
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.texasfostercare.org/">
                        Texas Foster Care and Adoption Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.tflife.org">
                        Therapeutic Family Life
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.upbring.org/">
                        Upbring
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography> REGION 8 (San Antonio)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {" "}
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 8 (San Antonio)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        1 HOPE for Kids, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        4kids of South Texas{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Adoption Promises{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Angels' Crossing{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Baptist Child & Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Best Care 4 Kidz
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Boysville
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Benchmark Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Caring For Kids, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Compass Connection{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Circle of Living Hope{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Depelchin Children’s Center{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Divinity Family Services, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        El Paso Center for Children, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Emberhope, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Families Especial{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Familylink Treatment Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Hill Country Youth Ranch{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Kids First, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Lighthouse Foster Care & Adoption Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Methodist Children's Home
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Pathways Youth & Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Providence Place
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        San Antonio Foster Care and Adoption Services, Inc.{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        St. Jude’s Ranch for Children - SJRC Texas
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        St. Peter – St. Joseph Children’s Home
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Texas Foster Care and Adoption Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        The Children's Shelter
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        The Sanctuary Foster Care Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Therapeutic Family Life
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        TruLight127 Ministries, Inc{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Upbring
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <a target="_blank" href="https://1hopeforkids.org/">
                        1 HOPE for Kids, Inc.
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.4kidsofstx.org/">
                        4kids of South Texas
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.awfc.org">
                        A World For Children
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.adoptionpromises.com/"
                      >
                        Adoption Promises
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://angelscrossingtx.org/index.html"
                      >
                        Angels' Crossing
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://bcfs.net/services">
                        Baptist Child &amp; Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://bestcare4kidz.com/">
                        Best Care 4 Kidz
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://boysvilletexas.org/foster-care-and-adoption/"
                      >
                        Boysville
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://benchmarkfamilyservices.org/"
                      >
                        Benchmark Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://cfkadopt.org/">
                        Caring For Kids, Inc.
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://compassconnections.org/">
                        Compass Connection
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.colh.org/">
                        Circle of Living Hope
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.depelchin.org">
                        Depelchin Children’s Center
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.divinityfamilyservices.com/"
                      >
                        Divinity Family Services, Inc.{" "}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://epccinc.org/">
                        El Paso Center for Children, Inc.
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.emberhope.org/">
                        Emberhope, Inc.{" "}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://familiesespecial.org/">
                        Families Especial{" "}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://familylinkkids.com/">
                        Familylink Treatment Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://youth-ranch.org/">
                        Hill Country Youth Ranch
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://kidsfirstinc.net/">
                        Kids First, Inc.{" "}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.lighthousefcas.org/">
                        Lighthouse Foster Care &amp; Adoption Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.mch.org/">
                        Methodist Children's Home
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.pathway.org">
                        Pathways Youth &amp; Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.provplace.org/">
                        Providence Place
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.sanantoniofostercare.org/"
                      >
                        San Antonio Foster Care and Adoption Services, Inc.{" "}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.sjrctexas.org">
                        St. Jude’s Ranch for Children - SJRC Texas
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.stpjhome.org/">
                        St. Peter – St. Joseph Children’s Home
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.texasfostercare.org/">
                        Texas Foster Care and Adoption Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.childrensshelter.org"
                      >
                        The Children's Shelter
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.sanctuaryfostercare.org/"
                      >
                        The Sanctuary Foster Care Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.tflife.org">
                        Therapeutic Family Life
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.trulight127.org/">
                        TruLight127 Ministries, Inc
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.upbring.org/">
                        Upbring
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography> REGION 9 (Big Spring)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 9 (Big Spring, Midland, Odessa, San Angelo)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Arrow Child and Family Ministries
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Addy's Hope Adoption Agency{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Buckner Children and Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Children's Hope Residential Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        High Sky Children's Ranch
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Methodist Children's Home{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        New Horizons Ranch and Center
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <a target="_blank" href="https://www.arrow.org/">
                        Arrow Child and Family Ministries
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.awfc.org">
                        A World For Children
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.addyshope.org/">
                        Addy's Hope Adoption Agency
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.buckner.org">
                        Buckner Children and Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://childrenhp.org">
                        Children's Hope Residential Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://highsky.org/">
                        High Sky Children's Ranch
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.mch.org/">
                        Methodist Children's Home
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.newhorizonsinc.com">
                        New Horizons Ranch and Center
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />

        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography> REGION 10 (El Paso)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 10 (El Paso)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        A World For Children
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Children's Hope Residential Services, Inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Circle of Living Hope
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Upbring
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <a target="_blank" href="http://www.awfc.org">
                        A World For Children
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.childrenhp.org">
                        Children's Hope Residential Services, Inc.
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.colh.org/">
                        Circle of Living Hope
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.upbring.org/">
                        Upbring
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "skyblue" }}
            >
              <Typography> REGION 11 (Corpus Christi)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid id="Lists" style={{ textAlign: "justify" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", paddingLeft: "25px" }}
                  >
                    REGION 11 (Corpus Christi)
                  </Typography>
                  {/* <ul>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        A World For Children{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Agape Harbor
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Arms Wide Adoption Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Arrow Child and Family Ministries{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Buckner Children and Family Services
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Children's Hope Residential Services, inc.
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Circles of Care
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        Circle of Living Hope
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Gladney Center for Adoption{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Homes in Harmony{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Pathways Youth & Family Services{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        {" "}
                        The Burke Foundation
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank" id="listslink">
                        Upbring{" "}
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    <li>
                      <a target="_blank" href="http://www.awfc.org">
                        A World For Children
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.agapeharbor.org/">
                        Agape Harbor
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.armswideadoption.org/"
                      >
                        Arms Wide Adoption Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.arrow.org">
                        Arrow Child and Family Ministries
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.beafamily.org">
                        Buckner Children and Family Services
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.childrenhp.org">
                        Children's Hope Residential Services, inc.
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.circlesofcareinc.org/"
                      >
                        Circles of Care
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.colh.org/">
                        Circle of Living Hope
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                      >
                        Gladney Center for Adoption
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.harmonyfcaa.com/">
                        Homes in Harmony
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://www.pathway.org">
                        Pathways Youth &amp; Family Services
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://burkecenterforyouth.org/"
                      >
                        The Burke Foundation
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.upbring.org/">
                        Upbring
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
      </Grid>
    </div>
  );
};

export default Partners_accordings;
