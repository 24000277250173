import { VideoStable } from "@mui/icons-material";
import {
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Typography,
  Divider,
} from "@mui/material";
import Broucher from "../../fostercareOverview/images/New folder/brouchers.png";
import React from "react";
import Bookmarks from "../../fostercareOverview/images/New folder/bookmarks.png";
import English_60 from "../../fostercareOverview/videos/English_60.mp3";
import Spanish_60 from "../../fostercareOverview/videos/Spanish_60.mp3";
import ImageSlider from "../../layout/imageslider";
import "./videotable.css";
const Videotable = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid style={{ display: "flex", justifyContent: "center" }}>
        <Grid style={{ width: "80%", backgroundColor: "white" }}>
          <Grid style={{ backgroundColor: "lightgrey", padding: "10px" }}>
            <Typography variant="h6">
              <b>BROUCHURES AND BOOKMARKS</b>
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Grid item lg={6} md={6} sm={6} xs={12} align="center">
            <a target="_blank"
                style={{ color: "blue" }}
                href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_SPN.pdf"
              >
                <img src={Broucher} />
              </a>
              <Typography>
              <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_ENG.pdf"
                >
                  Brouchers-English
                </a>
                (Adobe Acrobat)
              </Typography>
              <Typography>
              <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_SPN.pdf"
                >
                  Broucher- Spanish
                </a>
                (Adobe Acrobat)
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} align="center">
            <a target="_blank"
                style={{ color: "blue" }}
                href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_SPN.pdf"
              >
                <img src={Broucher} />
              </a>
              <Typography>
              <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Bookmark_ENG.pdf"
                >
                  Bookmarks-English
                </a>
                (Adobe Acrobat)
              </Typography>
              <Typography>
              <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Bookmark_SPN.pdf"
                >
                  Bookmarks- Spanish
                </a>
                (Adobe Acrobat)
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ backgroundColor: "lightgrey", padding: "10px" }}>
            <Typography variant="h6">
              <b>FLYERS</b>
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Grid item lg={6} md={6} sm={6} xs={12} align="center">
            <a target="_blank"
                style={{ color: "blue" }}
                href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_SPN.pdf"
              >
                <img src={Bookmarks} />
              </a>
              <Typography>
              <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_ENG.pdf"
                >
                  Flier-English
                </a>
                (Adobe Acrobat)
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} align="center">
            <a target="_blank"
                style={{ color: "blue" }}
                href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_SPN.pdf"
              >
                <img src={Bookmarks} />
              </a>
              <Typography>
              <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Bookmark_ENG.pdf"
                >
                  Flier-Spanish
                </a>
                (Adobe Acrobat)
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ backgroundColor: "lightgrey", padding: "10px" }}>
            <Typography variant="h6">
              <b>POSTERS</b>
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Grid item lg={6} md={6} sm={6} xs={12} align="center">
            <a target="_blank"
                style={{ color: "blue" }}
                href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_SPN.pdf"
              >
                <img src={Bookmarks} />
              </a>
              <Typography>
              <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_ENG.pdf"
                >
                  Flier-English
                </a>
                (Adobe Acrobat)
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} align="center">
            <a target="_blank"
                style={{ color: "blue" }}
                href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Brochure_SPN.pdf"
              >
                <img src={Bookmarks} />
              </a>
              <Typography>
              <a target="_blank"
                  style={{ color: "blue" }}
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/2019_Bookmark_ENG.pdf"
                >
                  Flier-Spanish
                </a>
                (Adobe Acrobat)
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ backgroundColor: "lightgrey", padding: "10px" }}>
            <Typography variant="h6">
              <b> ART WORK</b>
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              paddingTop: "10px",
              paddingBottom: "10px",
              justifyContent: "center",
            }}
          >
            <Grid item lg={5} md={5} sm={12} xs={12} align="left">
              <ul style={{ lineHeight: "1.6" }}>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/why_not_me.pdf"
                  >
                    {" "}
                    Why Not Me? banner
                  </a>
                  (pdf)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_ME.jpg"
                  >
                    {" "}
                    Why Not Me? banner
                  </a>
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_ME2.jpg"
                  >
                    {" "}
                    Why Not Me banner, version 2 - Colorf
                  </a>
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_ME2%20_SPAN.jpg"
                  >
                    {" "}
                    Why Not Me banner, version 2 - Color, Spanish{" "}
                  </a>{" "}
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_ME2_B&W.jpg"
                  >
                    Why Not Me banner, version 2 - B&W{" "}
                  </a>{" "}
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_ME2_B&W_SPAN.jpg"
                  >
                    {" "}
                    Why Not Me banner, version 2 - B&W, Spanish
                  </a>{" "}
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_Me_pen.jpg"
                  >
                    {" "}
                    Why Not Me? pen artwork
                  </a>{" "}
                  (jpg)
                </li>
              </ul>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12} align="left">
              <ul style={{ lineHeight: "1.6" }}>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/why_not_you.pdf"
                  >
                    Why Not You? banner{" "}
                  </a>
                  (pdf)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_YOU.jpg"
                  >
                    {" "}
                    Why Not You? banner
                  </a>
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_YOU2.jpg"
                  >
                    Why Not You banner, version 2 - Color{" "}
                  </a>
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_YOU2_SPAN.jpg"
                  >
                    {" "}
                    Why Not You banner, version 2 - Color, Spanish
                  </a>
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_Me_pen.jpg"
                  >
                    {" "}
                    Why Not You? pen artwork
                  </a>
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/Why_not_Me_pen.jpg"
                  >
                    Texas Adoption Resource Exchange - Blue{" "}
                  </a>
                  (jpg)
                </li>
                <li>
                <a target="_blank"
                    style={{ color: "blue" }}
                    href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Why_Not_Me/documents/TARE_B&W.jpg"
                  >
                    Texas Adoption Resource Exchange - B&W{" "}
                  </a>
                  (jpg)
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid style={{ backgroundColor: "lightgrey", padding: "10px" }}>
            <Typography variant="h6">
              <b> TV SPOTS</b>
            </Typography>
          </Grid>
          <Grid
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <iframe
              id="youtubevideo1"
              title="Many children need a safe haven"
              src="//www.youtube.com/embed/t8DzVLlQWtQ?rel=0"
               frameborder="0"
              allowfullscreen=""
            >
              <p>PSA video urging adoption and foster care</p>
            </iframe>
            <p>Christmas Wishes. Adoption Promotion Video</p>
            <br />
            Television, English (60 seconds)
          </Grid>
          <Divider />
          <Grid style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <iframe
              id="youtubevideo1"
              src="//www.youtube.com/embed/10YXNM8RRvw"
              title="Why Not Me? Adoption Promotional Video"
               frameborder="0"
              allowfullscreen=""
            >
              <p>Why Not Me? Adoption Promotion Video</p>
            </iframe>
            <br />
            Television, English (30 seconds)
          </Grid>
          <Divider />
          <Grid style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <iframe
              id="youtubevideo1"
              src="//www.youtube.com/embed/dRZzynXGELY"
              title="¿Por qué yo no? - Television, Spanish 30 seconds"
               frameborder="0"
              allowfullscreen=""
            >
              <p>
                Ningún niño que está esperando ser adoptado debería tener que
                hacerse la pregunta, "¿Por qué yo no?"
              </p>
            </iframe>{" "}
            <br />
            Television, Spanish (30 seconds)
          </Grid>
          <Divider />
          <Grid style={{ backgroundColor: "lightgrey", padding: "10px" }}>
            <Typography variant="h6">RADIO SPOTS</Typography>
          </Grid>
          <Grid container style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Grid item lg={6} md={6} sm={12} xs={12} align="center">
            <audio controls>
                <source src={English_60} type="audio/mp3" />
              </audio>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} align="center">
            <audio controls>
                <source src={Spanish_60} type="audio/mp3" />
              </audio>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Videotable;
