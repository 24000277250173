import {
  Grid,
  Typography,
  Card,
  // CardHeader,
  CardContent,
  Grow,
  Divider,
} from "@mui/material";
import ImageSlider from "../../layout/imageslider";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import image from "../../../assets/newimages/adoption-18728807.jpg";
import { Link } from "react-router-dom";
import "./stepsview.css";

const StepsView = () => {
  // const items = {
  //   textAlign: "left",
  //   lineHeight: "2",
  //   textDecoration: 'none',
  //   alignItems: 'center',
  //   display: 'flex',
  //   color: 'blue'
  // };

  // const Typographyheading = {
  //   color: "#1A355D",
  // };

  const cardstyle = {
    borderRadius: "50px",
    // height: "15rem",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 1)",
  };

  const cardheding = {
    color: "red",
    fontSize: "23px",
  };

  const para = {
    textAlign: "justify",
    padding: "10px",
  };

  const listitem = {
    textAlign: "left",
    marginBottom: "10px",
  };

  return (
    <div className="root" align="center" style={{ fontSize: "15px" }}>
      {/* <ImageSlider /> */}

      {/* page information section */}
      {/* <Grid id="imagegrid" container  style={{ padding: "50px", display: "flex" }}>

        <Grid  item lg={9} xs={6}>
          <img src={image} alt="description_of_image" style={{ width: '100%', height: '350px', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }} />
        </Grid>
        <Grid item lg={3} xs={6}>
          <Card
            style={{ height: "350px", float: "left", borderBottomRightRadius: '20px', borderTopRightRadius: '20px' }}
          >
            <CardHeader
              style={{ backgroundColor: "rgb(53, 103, 178)" }}
            ></CardHeader>
            <CardContent>
              <Typography
                style={{ fontSize: 20, textAlign: "left" }}
                color="text.secondary"
                gutterBottom
              >
                <b>In This Section</b>
              </Typography>
              <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                <li><Link style={{color : 'blue'}} to="/requirements">Requirements for Foster/Adopt Families</Link></li>
                <li><Link style={{color : 'blue'}} to="/placement#informationmeeting">Information Meetings</Link></li>
              </ul>
              <Typography
                style={{ fontSize: 20, textAlign: "left" }}
                color="text.secondary"
                gutterBottom
              >
                <b>More Information</b>
              </Typography>
              <ul style={{ textAlign: "left", lineHeight: "30px" }}>
                <li><Link style={{color : 'blue'}} to="/state">State Adoption Websites</Link></li>
                <li><Link style={{color : 'blue'}} to="/partners">Private Adoption Agencies</Link></li>
                <li><Link style={{color : 'blue'}} to="/spanish2">En Español</Link></li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}

      {/* cards section */}
      <Grid
        id="stepsgrid"
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // width: "80%",
          gap: "20px",
        }}
      >
        {/* <Grid spacing={3} style={{display: 'flex'}}> */}
        <Grid
          item
          lg={10}
          md={10}
          sm={11}
          xs={11}
          style={{ boxShadow: "5px red" }}
          id="infomeet"
        >
          <Grow in={true} timeout={1500}>
            <Card id="infomeetcard" style={{ ...cardstyle }}>
              {" "}
              <CardContent>
                <Typography style={cardheding}>Information Meeting</Typography>
                <Divider style={{ padding: "2px" }} />
                <Typography variant="body1" gutterBottom style={para}>
                  You will need to attend an information meeting in your area
                  where you can discuss the scope and{" "}
                  <Link style={{ color: "blue" }} to="/requirements">
                    requirements of being a foster or adoptive parent
                  </Link>
                  .
                </Typography>
                <Typography variant="body1" gutterBottom style={para}>
                  You will get basic information and questions are welcome. Your
                  local DFPS office will furnish you with this information if
                  there are no informational meetings in your area.You do not
                  need an appointment. Find free foster care and adoption
                  information meetings in your{" "}
                  <Link
                    style={{ color: "blue" }}
                    to="/placement#informationmeeting"
                  >
                    {" "}
                    Texas area
                  </Link>
                  .
                </Typography>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
        <Grid item lg={10} md={10} sm={11} xs={11} id="preparation">
          <Grow in={true} timeout={1500}>
            <Card id="preparationcard" style={cardstyle}>
              <CardContent>
                <Typography style={cardheding}>
                  Preparation and Selection
                </Typography>
                <Divider style={{ padding: "2px" }} />
                <Typography variant="body1" gutterBottom style={para}>
                  If you can meet the{" "}
                  <Link style={{ color: "blue" }} to="/requirements">
                    basic requirements
                  </Link>
                  , you are invited to meet with DFPS staff to decide if
                  fostering or adopting is right for your family. You will also
                  be assessed by DFPS staff. This process furnishes you with
                  information about DFPS and the children who come into the
                  foster care system.{" "}
                </Typography>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
        {/* </Grid> */}
        {/* <Grid> */}

        {/* </li>
                  <li style={listitem}>Medical Consent</li>
                  <li style={listitem}>
                    Preventing & Recognizing Sexual Abuse & Victimization of
                    Youth in Foster Care */}
        <Grid item lg={10} md={10} sm={11} xs={11} id="ntdc">
          <Grow in={true} timeout={1500}>
            <Card id="ntdccard" style={{ ...cardstyle, height: "auto" }}>
              <CardContent>
                <Typography style={cardheding}>What is NTDC?</Typography>
                <Divider style={{ padding: "2px" }} />
                <Typography variant="body1" gutterBottom style={para}>
                  CPS requires potential foster parents to attend National
                  Training and Development Curriculum (NTDC) as part of the
                  family’s required pre-service training.
                </Typography>
                <Typography variant="body1" gutterBottom style={para}>
                  Texas NTDC is a 19-hour training program that provides
                  prospective foster families with base knowledge of information
                  on caring for children in the child welfare system. NTDC
                  covers topics such as child attachment, loss and grief,
                  discipline and behavior intervention, effects of abuse and
                  neglect, working with families who have children in the
                  welfare system, and the effects of trauma on children and
                  families.{" "}
                </Typography>
                <Typography style={para}>
                  NTDC was funded through a five-year agreement with the
                  Department of Health and Human Services, Administration for
                  Children and Families, Children Bureau. The cooperative
                  agreement was led by Spaulding for Children in close
                  partnership with other agencies.
                </Typography>
                <Typography style={{ ...para, textAlign: "left" }}>
                  <b>Additional Training Requirements</b>
                </Typography>
                <Typography style={para}>
                  The state minimum standards require that prospective foster
                  families also complete the following trainings or
                  certifications, which are not part of the NTDC curriculum:
                </Typography>
                <ul>
                  <li style={listitem}>Psychotropic Medication</li>
                  <li style={listitem}>
                    Certification in both First Aid and infant/child/adult CPR
                  </li>
                  <li style={listitem}>
                    AS+K? online training (suicide intervention & prevention)
                  </li>
                </ul>
                <Typography style={para}>
                  State minimum standards also require that verified foster
                  homes receive annual in-service training. Depending on the
                  number of foster parents and the needs of the children in a
                  foster home, the annual training requirements range from 10
                  hours per family to 30 hours per foster parent.
                </Typography>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
        <Grid item lg={10} md={10} sm={11} xs={11} id="training">
          <Grow in={true} timeout={1500}>
            <Card id="trainingcard" style={cardstyle}>
              <CardContent>
                <Typography style={cardheding}>Training</Typography>
                <Divider style={{ padding: "2px" }} />
                <Typography variant="body1" gutterBottom style={para}>
                  You will attend training (NTDC) to learn more about the
                  children available through DFPS and to assess your strengths
                  in parenting children. The classes also boost your knowledge
                  and confidence to meet the challenge of taking children into
                  your home and to be sure you are ready to follow through on
                  the commitment.
                </Typography>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
        <Grid item lg={10} md={10} sm={11} xs={11} id="family">
          <Grow in={true} timeout={1500}>
            <Card id="familycard" style={cardstyle}>
              <CardContent>
                <Typography style={cardheding}>Family Home Study</Typography>
                <Divider style={{ padding: "2px" }} />
                <Typography variant="body1" gutterBottom style={para}>
                  A caseworker will visit you in your home. The purpose is to
                  discuss your personal history, family interests and lifestyle,
                  childcare experiences, the types of children you feel would
                  best fit in your home, and your strengths and skills in
                  meeting the children's needs.
                </Typography>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
      </Grid>

      {/* </Grid> */}
    </div>
  );
};

export default StepsView;
