import { Typography, Grid, Link } from "@mui/material";
import ImageSlider from "../../layout/imageslider";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const ChildDisabilityLevel = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        container
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid align="center" style={{ backgroundColor: "white", width: "80%" }}>
          <br />
          <Typography variant="h5" style={{textAlign:'center',fontWeight:"bold",color:"navy"}}>Children's Disability Levels</Typography>
          <br />
          <Grid>
            <Typography style={{textAlign:"left",fontWeight:"bold",marginLeft:"20px"}}>Also see:</Typography>
            <Typography>
              <ul style={{ textAlign: "left" }}>
                <li>
                  <a href="/mostcommondisability"> Most Common Disabilities</a>
                </li>
                <li>
                  <a href="/childrendisabilities">
                    A Closer Look at Children's Disabilities
                  </a>
                </li>
              </ul>
            </Typography>
          </Grid>
          {/* Table1 */}
          <Grid
            item
            lg={11}
            md={11}
            sm={11}
            xs={12}
            align="center"
            id="table1"
            style={{
              paddingTop: "30px",
              width: "100%",
              backgroundColor: "",
              padding: "20px",
            }}
          >
            <TableContainer
              component={Paper}
              style={{ border: "1px solid black", width: "100%" }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "lightgray" }}>
                    <TableCell
                      align="left"
                      style={{
                        width: "50%",
                        color: "blue",
                        border: "1px solid black",
                      }}
                    >
                      <b>None</b>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        width: "",
                        color: "red",
                        border: "1px solid black",
                      }}
                    >
                      Selecting this option for the disability level will
                      significantly decrease your adoption options.
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top" colSpan={2}>
                      <p>
                        {" "}
                        No known disabilities, or disabilities so mild that they
                        do not affect the child's functioning in any significant
                        way, such as wearing glasses for near sightedness,
                        visual or hearing imparments that do not affect the
                        child's ability to learn or communicate, or mild
                        allergies.
                      </p>
                      <p>
                        <b>Notice: </b>Children come into the care of the Texas
                        Department of Family and Protective Services (DFPS) due
                        to abuse or neglect. Therefore, families who adopt
                        children through the agency should have an understanding
                        or be willing to learn the dynamics of neglect and
                        physical or sexual abuse.
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* table2 */}
          <Grid
            item
            lg={11}
            md={11}
            sm={11}
            xs={12}
            id="table1"
            style={{ paddingTop: "30px", width: "100%", padding: "20px" }}
          >
            <TableContainer
              component={Paper}
              style={{ border: "1px solid black", width: "100%" }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "lightgray" }}>
                    <TableCell
                      align="left"
                      style={{
                        width: "50%",
                        color: "blue",
                        border: "1px solid black",
                        wordBreak: "break-word",
                      }}
                    >
                      <b>MILD</b>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        width: "",
                        color: "red",
                        border: "1px solid black",
                        wordBreak: "break-word",
                      }}
                    >
                      Caregivers can expect the child to respond to
                      limit-setting or other interventions.
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="top"
                      style={{
                        borderRight: "1px solid black",
                        wordBreak: "break-word",
                        verticalAlign: "top",
                      }}
                    >
                      <p>
                        The child can perform basic life management functions
                        appropriate for child's age and development and can use
                        mainstream methods of transportation and communication.
                      </p>
                      <ul style={{ lineHeight: "1.6", paddingLeft: "5px" }}>
                        <li>
                          Child requires no equipment for daily functioning and
                          may require average or slightly above average medical
                          care and appointments.
                        </li>
                        <li>
                          Child may have a condition that is totally managed by
                          medication, or a condition that is correctable or
                          improves on its own with time.
                        </li>
                        <li>
                          The child may be developmentally delayed in physical
                          development but has a prognosis of catching up.
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ wordBreak: "break-word" }}>
                      <Typography>
                        <b>
                          The child with a Mild disability could have one or
                          more of the following:
                        </b>
                      </Typography>
                      <ul style={{ lineHeight: "1.6", paddingLeft: "5px" }}>
                        <li>
                          Developmental/Learning impairment that are not severe
                          enough to require special education.
                        </li>
                        <li>
                          Emotional/Behavioral impairment such as adjustment
                          reactions, situational depression or acting out
                          behaviors.
                        </li>
                        <li>
                          Cognitive impairment with a mild intellectual
                          disability in children who usually will be able to
                          live independently as an adult, hold a job, and manage
                          their lives with some guidance in crises. A child with
                          a mild intellectual disability can often be in a
                          mainstream class with resource room help or tutoring.
                        </li>
                        <li>
                          Physical/Medical conditions, such as mild cerebral
                          palsy and treatable medical conditions such as
                          controlled seizures, hearing or vision impairment.
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table3 */}
          <Grid
            item
            lg={11}
            md={11}
            sm={11}
            xs={12}
            id="table1"
            style={{ paddingTop: "30px", width: "100%", padding: "20px" }}
          >
            <TableContainer
              component={Paper}
              style={{ border: "1px solid black", width: "100%" }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "lightgray" }}>
                    <TableCell
                      align="left"
                      style={{
                        width: "50%",
                        color: "blue",
                        border: "1px solid black",
                        wordBreak: "break-word",
                      }}
                    >
                      <b>MODERATE</b>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        width: "",
                        color: "red",
                        border: "1px solid black",
                        wordBreak: "break-word",
                      }}
                    >
                      Caregivers need to provide a structured supportive setting
                      in which most activities are designed to improve the
                      child's functioning.
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="top"
                      style={{
                        borderRight: "1px solid black",
                        wordBreak: "break-word",
                        verticalAlign: "top",
                      }}
                    >
                      <p>
                        Child has a relatively stable non-correctable condition
                        that is neither progressive nor degenerative. Child can
                        perform basic life management functions appropriate for
                        age and development (feeding, dressing, toileting) with
                        some assistance.
                      </p>
                      <ul style={{ lineHeight: "1.6", paddingLeft: "5px" }}>
                        <li>
                          Child may require moderate home modifications,
                          corrective surgery, and/or one or more weekly medical
                          appointments.
                        </li>
                        <li>
                          Child may require some assistance with transportation
                          and communication functions.
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ wordBreak: "break-word" }}>
                      <p>
                        <b>
                          The child with a Mild disability could have one or
                          more of the following:
                        </b>
                      </p>
                      <ul style={{ lineHeight: "1.6", paddingLeft: "5px" }}>
                        <li>
                          Developmental/Learning impairment that are not severe
                          enough to require special education.
                        </li>
                        <li>
                          Emotional/Behavioral impairment such as adjustment
                          reactions, situational depression or acting out
                          behaviors.
                        </li>
                        <li>
                          Cognitive impairment with a mild intellectual
                          disability in children who usually will be able to
                          live independently as an adult, hold a job, and manage
                          their lives with some guidance in crises. A child with
                          a mild intellectual disability can often be in a
                          mainstream class with resource room help or tutoring.
                        </li>
                        <li>
                          Physical/Medical conditions, such as mild cerebral
                          palsy and treatable medical conditions such as
                          controlled seizures, hearing or vision impairment.
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table4 */}
          <Grid
            item
            lg={11}
            md={11}
            sm={11}
            xs={12}
            id="table1"
            style={{ paddingTop: "30px", width: "100%", padding: "20px" }}
          >
            <TableContainer
              component={Paper}
              style={{ border: "1px solid black", width: "100%" }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "lightgray" }}>
                    <TableCell
                      align="left"
                      style={{
                        width: "50%",
                        color: "blue",
                        border: "1px solid black",
                        wordBreak: "break-word",
                      }}
                    >
                      <b>SEVERE</b>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        width: "",
                        color: "red",
                        border: "1px solid black",
                        wordBreak: "break-word",
                      }}
                    >
                      {" "}
                      Caregivers may need specialized training or experience to
                      provide therapeutic, habilitative, and medical support and
                      interventions.
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="top"
                      style={{
                        borderRight: "1px solid black",
                        wordBreak: "break-word",
                        verticalAlign: "top",
                      }}
                    >
                      <p>
                        Child may require life support equipment, or has a
                        progressive, degenerative or terminal illness.
                      </p>
                      <ul style={{ lineHeight: "1.6", paddingLeft: "5px" }}>
                        <li>
                          Child may require significant home modifications.
                        </li>
                        <li>
                          Child may require repeated doctor or frequent
                          hospitalizations or surgeries.
                        </li>
                        <li>
                          Child may require repeated doctor or frequent
                          hospitalizations or surgeries.
                        </li>
                        <li>
                          Child requires a parent or aide to perform basic life
                          management functions (feeding, dressing, toileting,
                          etc.).
                        </li>
                        <li>
                          Child may require special adaptations for
                          transportation and/or communication.
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ wordBreak: "break-word" }}>
                      <p>
                        <b>
                          The child with a Severe disability could have one or
                          more of the following:
                        </b>
                      </p>
                      <ul style={{ lineHeight: "1.6", paddingLeft: "5px" }}>
                        <li>
                          Developmental/Learning impairments that may cause a
                          permanent difficulty in academic or social/emotional
                          functioning, or occupation.
                        </li>
                        <li>
                          Emotional/Behavioral impairments such as attachment
                          disorder that may require hospitalization or
                          residential treatment.
                        </li>
                        <li>
                          Cognitive impairment such as severe to profound
                          intellectual disability where conceptual and cognitive
                          skills are very limited. Individuals with severe
                          intellectual disability require assistance with all
                          aspects of daily care. Verbal communication may be
                          limited. Long-term supportive housing will be
                          necessary with round the clock supervision.
                        </li>
                        <li>
                          Medical/Physical conditions, such as fetal alcohol
                          syndrome (FAS), multiple moderate conditions or a
                          condition that requires ongoing and constant medical
                          attention, such as quadriplegia or cystic fibrosis.
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChildDisabilityLevel;
