import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import ImageSlider from "../../layout/imageslider";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";

function NonProfitTaxServices() {
  return (
    <div align="center" style={{ width: "100%" }}>
      <Grid>
        {/* <ImageSlider /> */}

        <Container component="section" sx={{ py: 4, backgroundColor: "white" }}>
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <Typography variant="h4" fontWeight="bold" color="navy">
              Non-Profit Tax Services
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography align="justify" sx={{ lineHeight: 2 }}>
              Non-profit organizations play a crucial role in driving social
              change and addressing pressing societal issues. However,
              navigating the complex landscape of tax regulations can be a
              challenge for these organizations. Our firm specializes in
              providing comprehensive non-profit tax services to help
              organizations meet their compliance requirements while maximizing
              their impact. With our in-depth knowledge of non-profit tax laws
              and regulations, we offer tailored solutions that ensure tax
              efficiency, financial transparency, and regulatory compliance for
              non-profit entities.
            </Typography>
            <Typography align="justify">
              Managing tax obligations and maintaining financial transparency
              are crucial for non-profit organizations to fulfill their missions
              effectively. Our firm specializes in providing comprehensive
              non-profit tax services designed to navigate the complex tax
              landscape and ensure compliance. With our expertise in non-profit
              tax regulations, we help organizations optimize their tax
              strategies, maximize exemptions and deductions, and maintain the
              necessary records for financial transparency and accountability.
            </Typography>
            <Typography align="justify" variant="h5" fontWeight="bold">
              Our Comprehensive Non-Profit Tax Services...
            </Typography>
            <List sx={{ lineHeight: 1 }}>
              <ListItem>
                <Typography style={{ display: "flex" }}>
                  <DoubleArrowIcon style={{ marginRight: "10px" }} />
                  Preparation and filing of Form 990: We assist non-profit
                  organizations in accurately preparing and filing their annual
                  Form 990, ensuring compliance with IRS regulations and
                  providing transparency to stakeholders.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{ display: "flex" }}>
                  <DoubleArrowIcon style={{ marginRight: "10px" }} /> State and
                  local tax filings: Our team handles the complexities of state
                  and local tax filings, ensuring that non-profit organizations
                  meet their tax obligations in various jurisdictions.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{ display: "flex" }}>
                  <DoubleArrowIcon style={{ marginRight: "10px" }} />
                  Compliance with IRS guidelines: We provide guidance and
                  support to ensure non-profit organizations adhere to IRS
                  guidelines, including maintaining appropriate records and
                  documentation for tax-exempt status.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{ display: "flex" }}>
                  <DoubleArrowIcon style={{ marginRight: "10px" }} />
                  Tax planning strategies: Our experts develop tax planning
                  strategies tailored to the unique needs of non-profit
                  organizations, helping to minimize tax liabilities and
                  maximize financial resources for mission-driven initiatives.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{ display: "flex" }}>
                  <DoubleArrowIcon style={{ marginRight: "10px" }} />
                  Tax-saving opportunities: We identify potential tax-saving
                  opportunities specific to non-profit organizations, such as
                  exemptions and deductions, to optimize tax outcomes and
                  increase available funds for impactful projects.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{ display: "flex" }}>
                  <DoubleArrowIcon style={{ marginRight: "10px" }} /> Financial
                  transparency and accountability: Our services focus on
                  maintaining financial transparency and accountability,
                  assisting non-profits in maintaining accurate records and
                  meeting reporting requirements to instill trust among
                  stakeholders.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography style={{ display: "flex" }}>
                  <DoubleArrowIcon style={{ marginRight: "10px" }} />
                  Compliance with regulatory changes: We stay informed about the
                  latest tax laws, regulations, and reporting requirements
                  specific to the non-profit sector, ensuring organizations
                  remain compliant and updated with evolving tax regulations.
                </Typography>
              </ListItem>
              <ListItem>
                For more information{" "}
                <Link
                  to="https://reneaucpa.com/services-nptax"
                  target="_blank"
                  style={{ color: "blue", marginLeft: "5px" }}
                >
                  {" "}
                  Click Here
                </Link>
                .
              </ListItem>
            </List>
          </Box>
          <br />
          <Grid>
            <Typography variant="h5" fontWeight="bold" textAlign="left ">
              COST AND ACCOUNTABILITY REPORT AND INFORMATION
            </Typography>
            <br />
            <Typography variant="h6" fontWeight="bold" textAlign="left">
              OVERVIEW :
            </Typography>
            <br />
            <Typography style={{ display: "flex", textAlign: "justify" }}>
              <DoubleArrowIcon />
              The purpose of a Medicaid Cost/Accountability Report is to gather
              financial and statistical information for HHSC to use in
              developing reimbursement rates. Some cost reports are also used in
              the determination of accountability under the Attendant
              Compensation Rate Enhancement program. The Cost and Accountability
              Reports for the following programs will be submitted thorugh the
              State of Texas Automated Informtaion Reporting System(STAIRS).
            </Typography>
            <ul style={{ lineHeight: "1.5", textAlign: "justify" }}>
              <li>24-Hour Residential Child Care (24RCC)</li>
              <li>
                Community Living Assistance and Support Services - Case
                Management Agency (CLASS - CMA)
              </li>
              <li>
                Community Living Assistance and Support Services - Direct
                Service Agency (CLASS-DSA)
              </li>
              <li>Day Activity and Health Services(DAHS)</li>
              <li>Deaf Blind Multiple Disabilities Waiver(DBMD)</li>
              <li>Early Chilhood Intervation(ECI)-Case Management</li>
              <li>
                Early Childhood Intervention(ECI)-Specialized Rehabilitative
                Services
              </li>
              <li>
                Home and Community based Services/Texas Home Living(HCS/TxHmL)
              </li>
            </ul>
            <Typography textAlign="left" style={{marginLeft:"20px"}}>
              For Accounting needs {" "}
              <a
                href="/costAccountability"
                style={{ color: "blue",  }}
              >
                {" "}
                Click Here
              </a>
            </Typography>
          </Grid>
        </Container>
      </Grid>
    </div>
  );
}

export default NonProfitTaxServices;
