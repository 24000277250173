import React from "react";
import {
  Grid,
  Grow,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";
import ImageSlider from "../../../layout/imageslider";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import BreadcrumbMenu from "../../../layout/BreadcrumbMenu";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Learnmore2 = () => {
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <ImageSlider /> */}
      <Grow in={true} timeout={1500}>
        <Grid container style={{}}>
          <Grid item xs={12} style={{ paddingLeft: "50px" }}>
            <BreadcrumbMenu
              currComp="Articles And Information"
              currCompRoute="/ArticlesInfoView"
              parentComp="Home"
              parentCompRoute="/"
              additionalComp="More Details"
            />
          </Grid>
          <Grid item xs={12} style={{}}>
            <Typography variant="h5" fontWeight="bold">
              How to become a Foster care Parent
            </Typography>
          </Grid>
        </Grid>
      </Grow>
      <Grow in={true} timeout={1500}>
        <Grid
          container
          xs={11}
          md={10}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            id="homestudy"
            style={{
              marginBottom: "30px",
              marginTop: "30px",
              width: "90%",
              textAlign: "justify",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardHeader
              style={{ backgroundColor: "rgb(53, 103, 178)", width: "100%" }}
            ></CardHeader>
            <CardContent style={{ width: "90%" }}>
              <Typography variant="h6" fontWeight="bold">
                A Homestudy – What is It?
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                All 50 states require a homestudy document to be completed
                before a couple or individual can adopt a child or sibling
                group. A homestudy has specific guidelines that must be met to
                be completed properly. Though the format can vary from state to
                state and across different agencies, most generally have two
                different parts – training process and information gathering.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                The training process is the first part and deals mainly with
                preparation and education about adoption. This can include such
                topics as preparing families for adoption, helping children
                adjust to the new type of family and it’s make-up, attaching and
                bonding with your adopted children, strategies and challenges
                with parenting, and how to explain adoption to your current
                children.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                This training can take between 10 and 20 hours and is usually
                done during a series of meetings with other parents interesting
                in adopting. This training gives you a good idea of the adoption
                process and helps you be more prepared to adjust to having newly
                adopted child in your home. The added bonus of these meetings is
                getting to meet other adopting parents, which allows you to make
                friendship connections with other parents going through the same
                process as you. This creates a friendship support system that
                can help you get through some of the challenges and stress of
                parenting and adoption.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                The next part of the homestudy is based on information
                gathering. This also is usually done through a series of
                meetings (both alone and with both parents) with a Case Worker.
                One of these sessions will be in your home, which allows the
                Case Worker to see the environment the adopted child will soon
                become a part of. If you have other people currently in the home
                – like children or aged relatives – they will be interviewed as
                well. Part of this process will include providing documents like
                birth certificates, marriage licenses, health records, criminal
                background check reports, personal references, and a child abuse
                clearance, which will be done through the agency.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Many agencies will ask you to write-up information about
                yourself, your family, and even life experiences. Another part
                of this process is talking to you Case Worker about the types of
                children you feel you can best raise as a parent and will become
                a part of your family and home.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                This information is combined into the homestudy document. Once
                this process is completed, you will be notified by your Case
                Worker and the adoption agency that you have been approved to
                adopt a child. The next step in the process will be to find the
                right match between your family and the adoptive child.
              </Typography>
              <br />
            </CardContent>
          </Card>

          <Card
            id="chidrenadoption"
            style={{
              marginBottom: "30px",
              marginTop: "30px",
              width: "90%",
              textAlign: "justify",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardHeader
              style={{ backgroundColor: "rgb(53, 103, 178)", width: "100%" }}
            ></CardHeader>
            <CardContent style={{ width: "90%" }}>
              <Typography variant="h6" fontWeight="bold">
                What Children are Available for Adoption?
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                You have probably given a lot of though to what children are
                available for adoption and which type of child you feel may
                thrive in your home. Once your homestudy is complete, your Case
                Worker may have some good ideas about what kind of child may be
                a perfect match. If you are working through an adoption agency,
                it probably already has children in its care. There are
                different child placement agencies in your state and surrounding
                areas.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Most children available for adoption directly from foster care
                are usually older children, with the average age being between
                8-10 years old. Some are pre-teens or teenagers, with almost 50%
                coming from minority cultures. Over 40% of the children are from
                sibling groups, which sometimes may need to be adopted together.
                Some children have emotional problems, resulting from neglect,
                abuse, abandonment, and the lack of stability that comes from
                being a part of the foster care system. Some may have physical
                or mental challenges, as well.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon /> Many of the children available for adoption
                are living in foster homes, with trained foster parents. These
                kids are involved in school, play in their neighborhoods and
                take part in the community activities of their area. Some
                children are in group homes, where they live with other foster
                children. If a child has severe behavior or emotional problems,
                they could also be temporarily living in a treatment facility,
                while their condition is being treated.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                There are over half a million children in the foster care system
                in the US, usually managed at the state and county level. These
                children have become a part of the foster care system because
                they have been removed from their biological parents due to
                circumstances beyond the child’s control. This can include being
                removed due to severe neglect or abuse, due to death or severe
                disability of their biological parents, or even because their
                biological parents can no longer take care of a child and have
                given up their legal parental rights.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Many states have websites that feature pictures and details of
                available, adoptable children. There are thousands of children
                in need in your community and throughout the country. Searching
                on your own is great, but also enlist the help of your Case
                Worker, a trusted adoption agency, or even someone you know who
                has adopted to learn more about how they were able to find their
                adopted child.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon /> All of these children want and need a home.
                They are loving children who need the love and security that
                comes from a permanent family and home.
              </Typography>
              <br />
            </CardContent>
          </Card>
        </Grid>
      </Grow>
    </div>
  );
};

export default Learnmore2;
