import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css"

const MostCommonDisability = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Typography variant="h5" style={{ color: "#002F86" }}>
            <b>Most Common Disabilities</b>
          </Typography>
          <br />
          <Grid>
            <Typography>
              <b>Also see:</b>
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li>
                <a href="/childdisabilitylevel">Disability </a>
              </li>
              <li>
                <a href="/childrendisabilities">
                  A Closer Look at Children's Disabilities
                </a>
              </li>
            </ul>
            <Typography style={{ textAlign: "justify" }}>
              These may be problems specifically related to the child's issues
              of neglect and/or abuse, loss, abandonment, or adoption issues.
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li>
                <a href="#emotionalpro">Emotional Problems</a>
              </li>
              <li>
                <a href="#attdeficitdisorder">Attention Deficit Disorder</a>
              </li>
              <li>
                <a href="#hyperactivity">Hyperactivity</a>
              </li>
            </ul>
            <Grid id="emotionalpro">
              <Typography variant="h6" style={{ color: "#002F86" }}>
                <b>Emotional Problems</b>
              </Typography>
              <p style={{ textAlign: "justify", lineHeight: "1.6" }}>
                &emsp; Emotional problems in children and teenagers can range
                from mild to severe. When a parent suspects an emotional
                problem, the first step is an open, honest talk with the child
                about feelings, if this is appropriate to the child's age.
                Parents may also want to consult with the child's doctor,
                teachers, clergy, or other adults the child knows. At least one
                in 20 children and adolescents may have a serious emotional
                disturbance that severely disrupts life at home, school, and in
                the community.
              </p>
              <p>
                <b>Signs in a young child may be:</b>
              </p>
              <ul style={{ lineHeight: "1.6" }}>
                <li>A marked drop in school performance</li>
                <li>Persistent nightmares</li>
                <li>Frequent unexplainable temper tantrums</li>
                <li>Persistent disobedience or aggression</li>
                <li>Refusal to participate in usual activities</li>
              </ul>
              <p>
                <b>
                  In older children and adolescents, warning signs may include:
                </b>
              </p>
              <ul style={{ lineHeight: "1.6" }}>
                <li>Depression</li>
                <li>Abuse of alcohol and/or drugs</li>
                <li>Sexual acting out self-injury,</li>
                <li>Self destructive behavior, or threats of harm to others</li>
                <li>Changes in eating and sleeping patterns</li>
                <li>Problems in relationships</li>
              </ul>
              <p style={{ textAlign: "justify", lineHeight: "1.6" }}>
                &emsp; Your child's pediatrician can rule out possible physical
                causes for problems and help you locate a mental health
                professional. Since adopted children often have emotional
                problems specifically related to adoption issues, parents should
                seek a counselor or therapist who is knowledgeable about
                adoption.
              </p>
              <p style={{ lineHeight: "1.6" }}>
                Once an appropriate diagnosis is made, treatment may include
                psychotherapy, behavior therapy, or medication.
              </p>
              <p>
                <b>For more information:</b>
              </p>
              <ul style={{ lineHeight: "1.6" }}>
                <li>
                  <p>
                    American Academy of Child and Adolescent Psychiatry (202)
                    966-7300
                  </p>
                  <p>
                    <a target="_blank" href="https://www.aacap.org/">
                      http://www.aacap.org
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    National Institute of Mental Health (301) 443-4513 TTY:
                    301-443-8431
                  </p>
                  <p>
                    <a target="_blank" href="https://www.nimh.nih.gov/">
                      http://www.nimh.nih.gov
                    </a>
                  </p>
                </li>
              </ul>
            </Grid>
            <Grid id="attdeficitdisorder">
              <Typography variant="h6" style={{ color: "#002F86" }}>
                {" "}
                <b>Attention Deficit Disorder</b>
              </Typography>
              <p style={{ textAlign: "justify", lineHeight: "1.6" }}>
                &emsp; Up to 3.5 million children have an Attention Deficit
                Disorder (ADD). It is a leading cause of school failure and
                under-achievement. ADD is a neurobiologically based disability,
                characterized by developmentally inappropriate attention skills,
                impulsivity, and, in some cases, hyperactivity. ADD is often
                accompanied by poor self-esteem and behavioral difficulties.
                Characteristics of children with ADD can include:
              </p>
              <ul style={{ lineHeight: "1.6" }}>
                <li>Fidgeting with hands or feet</li>
                <li>Difficult remaining seated</li>
                <li>Difficulty awaiting turns in games</li>
                <li>Difficulty following through on instructions</li>
                <li>Shifting from one uncompleted task to another</li>
                <li>Difficulty playing quietly</li>
                <li>Interrupting conversations</li>
                <li>Appearing not to listen</li>
                <li>
                  Doing things that are dangerous without thinking about the
                  consequences
                </li>
              </ul>
              <br />
              <p style={{ textAlign: "justify", lineHeight: "1.6" }}>
                &emsp; ADD students have a greater likelihood of repeating a
                grade, dropping out of school, under-achieving academically, and
                having social and emotional difficulties. Making and keeping
                friends is a difficult task for children with ADD. There is no
                "cure" for ADD, but parents can help their child by learning as
                much as possible about ADD; seeking professional evaluation and
                treatment; advociating for their child; seeking parent training.
              </p>
              <p style={{ textAlign: "justify", lineHeight: "1.6" }}>
                &emsp; Parent training will help a parent to provide clear,
                consistent expectations and directions; set up an effective
                discipline system; create a behavior modification plan; assist a
                child with social issues; and identify the child's strengths.
              </p>
            </Grid>
            <Grid id="hyperactivity">
              <Typography variant="h6" style={{ color: "#002F86" }}>
                <b> Hyperactivity</b>
              </Typography>
              <p style={{ textAlign: "justify", lineHeight: "1.6" }}>
                &emsp; The term hyperactivity commonly refers to manifestations
                of disturbed behavior in children or adolescents characterized
                by constant overactivity, distractibility, impulsiveness,
                inability to concentrate, and aggressiveness. Hyperactivity is
                often associated with attention deficit disorder (which is then
                referred to as attention deficit hyperactivity disorder) but can
                also be associated with other disorders (such fetal alcohol
                syndrome).
              </p>
              <p style={{ textAlign: "justify", lineHeight: "1.6" }}>
                Among the treatment options are behavior therapy, social skills
                training, medication, parent education, and modification to the
                child's educational program.
              </p>
              <p>
                <b>For more information:</b>
              </p>
              <ul style={{ lineHeight: "1.6" }}>
                <li>
                  <p>
                    American Academy of Child and Adolescent Psychiatry (202)
                    966-7300
                  </p>
                  <p>
                    <a target="_blank" href="https://www.aacap.org/">
                      http://www.aacap.org
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    CHADD. Children and Adults with Attention Deficit Disorder
                    (800) 233-4050
                  </p>
                  <p>
                    <a target="_blank" href="https://chadd.org/">
                      http://www.chadd.org
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    National Attention Deficit Disorder Association (847)
                    432-ADDA
                  </p>
                  <p>
                    <a target="_blank" href="https://add.org/">
                      {" "}
                      http://www.add.org
                    </a>
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MostCommonDisability;
