import React, { useEffect } from "react";
// import { makeStyles } from '@mui/material/styles';
import {
  Typography,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { linksContent } from "./HelpfulLinksContent";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import ImageSlider from "../../layout/imageslider";
import BreadcrumbMenu from "../../layout/BreadcrumbMenu";
import "./helpfullinks.css"
// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         padding: '30px',
//       },
//     title:{
//         padding: '0 16px 10px 16px'
//     },
//     body: {
//         color: '#1A355D',
//         padding: '10px 0',
//     },
//     linksTitle:{
//         padding: '0 16px',
//         color: '#d04e53'
//       },
//     linksContainer: {
//         background: 'linear-gradient(153deg, #95add1b0 30%, white 90%)',
//         color: '#1A355D',
//         padding: '10px 0',
//         boxShadow: '0 3px 5px 2px rgb(90 78 80 / 42%)',
//         marginBottom: '20px'
//     },
//     listLinks: {
//         padding: '0 30px',
//         alignItems: 'center'
//     },
//     link: {
//         minWidth: '20px',
//         color: '#1A355D'
//     }
//   }));

const HelpfulLinksView = () => {
  // const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div align="center" style={{ flexGrow: 1 }}>
      {/* <ImageSlider /> */}
      <Grid style={{ padding: "20px" }}>
        <Grow in={true} timeout={1500}>
          <Grid container style={{ color: "#1A355D", padding: "10px 0" }}>
            <Grid item xs={12} style={{ padding: "0 16px 10px 16px" }}>
              <BreadcrumbMenu
                currComp="Helpful Links"
                parentComp="Home"
                parentCompRoute="/"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                <b>Helpful Links</b>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: "0 16px 10px 16px" }}>
              <Typography> {linksContent.desc} </Typography>
            </Grid>
          </Grid>
        </Grow>
        <Grid id="maingrid" style={{padding:'0px'}}>
          {" "}
          <Grow in={true} timeout={1500}>
            <Grid
              container
              
              style={{
                background: "linear-gradient(153deg, #95add1b0 30%, white 90%)",
                color: "#1A355D",
                padding: "10px 0",
                boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
                // marginBottom: "20px",
                display:"flex",
                
              }}
            >
              {linksContent.links.map((item) => (
                <Grid item xs={12} key={item.key} >
                  <List component="div" aria-label="helpfulLinks content">
                    <ListItem
                      component="div"
                      style={{
                        padding: "0 20px",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <ListItemIcon
                        style={{ minWidth: "20px", color: "#1A355D" }}
                      >
                        <FontAwesomeIcon icon={faLink} />
                      </ListItemIcon>
                      <Link target="_blank" href={item.value}>
                        <ListItemText primary={item.label} />
                      </Link>
                    </ListItem>
                  </List>
                </Grid>
              ))}
            </Grid>
          </Grow>
        </Grid>
      </Grid>
    </div>
  );
};

export default HelpfulLinksView;
