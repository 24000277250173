import { Grid, Typography } from "@mui/material";
import React from "react";
import ImageSlider from "../../../layout/imageslider";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./region8.css";
import MapCard from "../mappages/mapcard";
import ResponsiveMap from "../../../fostercareOverview/texasmap";

const Region11 = () => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Grid style={{ textAlign: "left", width: "100%" }}>
            <Typography
              variant="h5"
              fontWeight="bold"
              style={{ color: "navy", textAlign: "bold" }}
            >
              Region 11: Laredo, Edinburg, Corpus Christi, and surrounding areas{" "}
            </Typography>
            <br />
            <Typography>
              Foster Care and Adoption Information Meetings
            </Typography>
            <br />
            <Typography style={{ color: "red", textAlign: "justify" }}>
              * If accommodations such as a sign language interpreter are needed
              in order to attend an information meeting, please call the
              Recruitment Staff listed below 3 days prior to the meeting, if
              possible. *
            </Typography>
          </Grid>
          <br />
          <Grid container style={{ display: "flex", padding: "10px" }}>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>Recruitment Events and Foster/Adopt Information Meetings:</b>
              </Typography>
              <br />
              <ul style={{ lineHeight: "1.6" }}>
                <li>
                  <Link to="#table1"> January 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table2">February 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#table3">March 2024</Link>
                </li>
                <li>
                  {" "}
                  <Link
                    target="_blank"
                    to="https://www.dfps.texas.gov/Adoption_and_Foster_Care/Texas_Heart_Galleries/SouthTexas/default.asp"
                  >
                    Heart Gallery of South Texas
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="#recruitment"> Recruitment Staff</Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.dfps.texas.gov/Community/volunteer/coordinators.asp"
                  >
                    {" "}
                    Faith-Based Staff
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "justify",
              }}
            >
              <MapCard />
            </Grid>
          </Grid>
          <Typography style={{ textAlign: "justify" }}>
            The Texas Department of Family and Protective Services is looking
            for caring foster and adoptive families in your area. To learn more
            about becoming a foster or adoptive parent through our agency, we
            will be having the following information meetings in your area:
          </Typography>
          <br />
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
              width:"100%"
            }}
          >
            <ResponsiveMap/>
          </Grid>
          <br />
          {/* Table1 */}
          <Grid style={{ paddingTop: "30px", width: "100%" }}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>January 2024</b>
            </Typography>
            <br />
            <TableContainer
              id="table1"
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Teams (Online)</strong>
                      Spanish speaking upon request
                    </TableCell>
                    <TableCell align="top">
                      <strong>
                        Tuesday, January 09, 2024 at 11:00am and 6:00pm
                      </strong>
                      <br />
                      Contact:
                      <strong> Marlen Alaniz at: 1 (956) 500-6027</strong> to
                      sign up by Tuesday, January 02, 2024.
                      <br />
                      <a
                        style={{ wordBreak: "break-word" }}
                        // target="_blank"
                        href=""
                      >
                        Marlen.Alaniz@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Teams (Online)</strong>
                      Spanish speaking upon request
                    </TableCell>
                    <TableCell>
                      <strong>
                        Tuesday, January 30, 2024 at 11:00am and 6:00pm
                      </strong>
                      <br />
                      Contact:
                      <strong>
                        {" "}
                        Mildred Benavides at: 1 (956) 244-4615{" "}
                      </strong>{" "}
                      to sign up by Tuesday, January 23, 2024.
                      <br />
                      Or Email:
                      <a
                        href=""
                        target="_blank"
                        style={{ wordBreak: "break-word" }}
                      >
                        {" "}
                        Mildred.Benavides@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table2 */}
          <Grid style={{ paddingTop: "30px", width: "100%" }}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>February 2024</b>
            </Typography>
            <br />
            <TableContainer
              id="table2"
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Teams (Online)</strong>
                      <p>Spanish speaking upon request</p>
                    </TableCell>
                    <TableCell align="top">
                      <strong>
                        Tuesday, February 13, 2024 at 11:00am and 6:00pm
                      </strong>
                      <br />
                      Contact:{" "}
                      <strong>Xochitl Campos at: 1 (956) 371-1064</strong> to
                      sign up by Tuesday, February 6, 2024.
                      <br /> Or Email:
                      <a style={{ wordBreak: "break-word" }} href="">
                        {" "}
                        Xochitl.Campos@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Teams (Online)</strong>
                      Spanish speaking upon request
                    </TableCell>
                    <TableCell>
                      <strong>
                        Tuesday, February 27, 2024 at 11:00am and 6:00pm
                      </strong>
                      <br />
                      Contact:<b> Anna Castillo at :1 (956) 371-3880</b> to sign
                      up by Tuesday, February 20, 2024.
                      <br /> Or Email:{" "}
                      <a style={{ wordBreak: "break-word" }} href="">
                        Anna.Castillo@texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Table 3 */}
          <Grid style={{ paddingTop: "30px", width: "100%" }}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              <b>March 2024</b>
            </Typography>
            <br />
            <TableContainer
              id="table3"
              component={Paper}
              style={{
                border: "1px solid black",
                // width: "80%",
                margin: "auto",
              }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "lightgray" }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: "" }}>
                      Location
                    </TableCell>
                    <TableCell align="left" style={{ width: "" }}>
                      Foster/Adopt Information Meetings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="top">
                      {" "}
                      <strong>Teams (Online)</strong>
                      Spanish speaking upon request
                    </TableCell>
                    <TableCell align="top">
                      <strong>
                        Tuesday, March 12, 2024 at 11:00am and 6:00pm{" "}
                      </strong>
                      <br />
                      Contact:
                      <strong> Hector Gonzalez at: +1 (956) 202-1656</strong> to
                      sign up by Tuesday, March 05, 2024. <br /> Or Email:{" "}
                      <a style={{ wordBreak: "break-word" }} href="">
                        Hector.Gonzalez@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <strong>Teams (Online)</strong>
                      Spanish speaking upon request
                    </TableCell>
                    <TableCell>
                      <strong>
                        Tuesday, March 26, 2024 at 11:00am and 6:00pm
                      </strong>
                      <br />
                      Contact:{" "}
                      <strong>Nely Guadarrama at: 1 (361) 660-2221</strong> to
                      sign up by Tuesday, March 19, 2024.
                      <br /> Or Email:
                      <a style={{ wordBreak: "break-word" }} href="">
                        {" "}
                        Nely.Guadarrama@dfps.texas.gov
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Recruitment Grid */}
          <Grid
            id="recruitment"
            style={{ paddingTop: "20px", width: "100%", textAlign: "left" }}
          >
            <Typography variant="h5" fontWeight="bold">
              Recruitment Staff
            </Typography>
            <br />
            <Typography>
              <b>
                **For questions and answers about becoming a foster and/or
                adoptive parent, see contacts below.**
              </b>
            </Typography>
            <ul style={{ lineHeight: "1.6" }}>
              <li>
                <a href="">Rosa Lopez</a>, Human Services Technician,{" "}
                <strong>(956) 316-8693 or (956) 371-4621</strong>
              </li>
              <li>
                <a href="">Debra Garcia</a> , Administrative Assistant,
                <strong> (361) 878-7525</strong>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Region11;
