import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";
const IntellectualDisability = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>A closer look at Intellectual Disability</b>
            </Typography>
            <br />
            <Typography>
              <em>
                For More Info Visit:{" "}
                <a
                  target="_blank"
                  href="http://www.aacap.org/cs/root/facts_for_families/children_who_are_mentally_retarded"
                >
                  American Academy of Child and Adolescent Psychiatry
                </a>
              </em>
            </Typography>
            <br />
            <Typography>
               Intellectual disability is a disability characterized by
              significant limitations in both intellectual functioning and in
              adaptive behavior, which covers many everyday social and practical
              skills. This disability originates before the age of 18. An
              intellectual disability is not a disease and it is not contagious.
              It should not be confused with mental illness. Intellectual
              disabilities affect up to 7.5 million individuals in the United
              States, according to one survey. Intellectual disabilities cross
              racial, ethnic, educational, social, and economic backgrounds. One
              in 10 American families has a family member with an intellectual
              disability.
            </Typography>
            <br />
            <Typography>
              The American Association on Intellectual and Developmental
              Disabilities defines intellectual disability on the basis of three
              criteria:
            </Typography>
            <br />
            <ul style={{ lineHeight: "1.9" }}>
              <li>
                {" "}
                Intellectual functioning level (IQ) around 70 to as high as 75,
              </li>
              <li>
                Significant limitations in two or more skills needed to adapt to
                daily living, and
              </li>
              <li>
                A condition that is present from childhood. This definition is
                accompanied by an assessment of a person's strengths and
                weaknesses in adaptive skills for daily living and the resulting
                support services that the person needs. Levels of support range
                from intermittent to limited to extensive to pervasive.
                Intermittent support might be "as needed" help (in finding a
                job, for example), whereas limited support may occur over a
                limited time, such as transition from school to work. Extensive
                support means assistance that a person needs on a daily and
                regular basis at home or work, while pervasive support can mean
                total dependence on others, including life-sustaining care.
              </li>
            </ul>
            <br />
            <Typography>
               An interdisciplinary team looks at the following adaptive
              skills in assessing a persons functioning level: communication,
              self-care, home living, social skills, leisure, health and safety,
              self-direction, functional academics, community use, and work.
            </Typography>
            <br />
            <Typography>
               Intellectual disability can be caused by any condition that
              impairs development of the brain before birth, during birth, or
              during childhood. Although hundreds of causes have been connected
              to intellectual disability, the three major causes are Down
              syndrome, fetal alcohol syndrome, and fragile X syndrome.
            </Typography>
            <br />
            <Typography>
               Generally, the causes can be put in categories such as
              genetic conditions, problems during pregnancy, problems at birth
              (especially premature and low birth weight), childhood diseases
              and trauma, and poverty and environmental deprivation.
            </Typography>
            <br />
            <Typography>
               The key to understanding intellectual disability is
              recognizing that adaptive skills are learned behavior. This means
              that each individual may learn daily living skills to the limits
              of that individual's physical and intellectual capacity. A person
              with intellectual limits may not be diagnosed as intellectually
              disabled if the person masters enough adaptive skills to cope with
              daily life in his or her typical environment. Thus, the challenge
              in considering adoption of a child with an intellectual disability
              is to assess the family's own ability to meet the child's needs to
              learn and grow as far as possible.
            </Typography>
            <br />
            <Typography>
              This, of course, is what parents want for all children to see them
              reach their potential, whatever it might be.
            </Typography>
            <br />
            <Typography>
               If you are a Texas resident and are not approved as a
              foster or adoptive family, please fill out our Adoption and Foster
              Care Interest form in the
              <a href="/generalInformation"> Get Started section.</a>
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href=""> Texas Adoption Resource Exchange (TARE)</a> or call
              1-800-233-3405.
            </Typography>
            <br />
            <Grid style={{ float: "right" }}>
              <Typography>
                This content taken from:{" "}
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                >
                  Adoption and Foster Care
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default IntellectualDisability;
