import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";

const DownSyndrome = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>A closer look at Down Syndrome</b>
            </Typography>
            <br />
            <Typography>
              <p>
                <em>
                  More...{" "}
                  <a
                    target="_blank"
                    href="http://www.ndss.org/index.php?option=com_content&amp;view=article&amp;id=54&amp;Itemid=74"
                  >
                    National Down Syndrome Society
                  </a>
                </em>
              </p>
            </Typography>
            <br />
            <Typography>
              Children with Down syndrome have a complicated condition, yet they
              can be loving participants in a family.
            </Typography>
            <br />
            <Typography>
              Children with Down syndrome usually can be recognized by sight:
              The eyes slope up at the outside corners, facial features are
              small, ears are lower than expected, and the tongue tends to be
              thick and sometimes sticks out. As many as 250,000 Americans have
              the condition, and it affects at least one out of every 800
              births.
            </Typography>
            <br />
            <Typography>
              Down syndrome is named after the English doctor John Langdon Down
              who described it in 1866. Nearly 100 years later, researchers
              discovered that the condition is due to a genetic error that puts
              an extra chromosome in the nucleus of cells. Each individual
              inherits 23 chromosomes from each parent, for a total of 46. For
              some reason still not completely understood, some individuals wind
              up with 47. The excess genes on this extra chromosome cause a
              number of possible irregularities in the formation and functioning
              of the body.
            </Typography>
            <br />
            <Typography>
              Among the irregularities are a tendency to have heart disorders,
              thyroid imbalance, and neurological disabilities such as
              intellectual disability, weak muscles, spinal problems, and
              seizures. Also noted as possible problems are a slightly higher
              likelihood of an incomplete intestine and acute leukemia, as well
              as being more susceptible to respiratory and ear infections. More
              recently, researchers have associated Down syndrome with the
              possibility of greater risk for Alzheimer's disease during the
              middle years. With this complicated array of possible problems, it
              is easy to see how people with Down syndrome often do not live
              past middle age.
            </Typography>
            <br />
            <Typography>
              While the child with Down syndrome faces many challenges, parents
              can gain knowledge and support to raise a Down syndrome child. The
              National Down Syndrome Society is a good place to start. The
              Society is at 666 Broadway, New York, NY 10012. Phone
              1-800-221-4602 or (212) 460-9330.
            </Typography>
            <br />
            <Typography>
              Parents must ensure that their Down syndrome child is under the
              care of a physician. A number of the problems associated with the
              condition may be controlled or alleviated through medication.
              Seizures and thyroid problems, for example, are often treated
              successfully with drugs.
            </Typography>
            <br />
            <Typography>
              In some cases, occupational or physical therapy can help children
              with poor muscle tone, especially if the therapy is started when
              the baby is only weeks old. These exercises of gross motor skills
              can improve walking, sitting, standing, turning, and climbing
              stairs. Where the ligaments at the lower spine are too relaxed, a
              child could be at risk of injury due to spinal cord compression.
              Parents of Down syndrome children might notice if their child has
              neck pain, limited movement of the neck, change in walking
              pattern, and sudden weakness of the arms and legs. Children may
              not always have these symptoms, in which case parents might want
              to limit activities such as gymnastics, trampoline, diving, and
              high-impact sports.
            </Typography>
            <br />
            <Typography>
              Children with Down syndrome require a positive and total
              commitment to make the best of their abilities. A stimulating and
              challenging atmosphere works best. Education and support are keys
              to success, along with a protective environment, including
              protection against exploitation and sexual abuse. Parents of a
              Down syndrome child must have reasonable expectations and be
              willing to celebrate the smallest achievement.
            </Typography>
            <br />
            <Typography>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the <a href="/generalInformation">Get Started</a>{" "}
              section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href="#">Texas Adoption Resource Exchange (TARE)</a> or call
              1-800-233-3405.
            </Typography>
            <br />
            <Grid style={{ float: "right" }}>
              <Typography>
                This content taken from:{" "}
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                >
                  Adoption and Foster Care
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DownSyndrome;
