import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";

const CysticFibrosis = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Typography
            variant="h5"
            style={{ color: "#002F86", width: "100%", textAlign: "left" }}
          >
            <b>A closer look at Cystic Fibrosis</b>
          </Typography>
          <br />
          <Typography style={{ width: "100%", textAlign: "left" }}>
            <em>
              More...{" "}
              <a target="_blank" href="http://www.cff.org/AboutCF/">
                Cystic Fibrosis Foundation
              </a>
            </em>
          </Typography>
          <Typography style={{ width: "100%", textAlign: "left" }}>
            Cystic Fibrosis is an inherited condition that affects breathing and
            digestion.
          </Typography>
          <br />
          <Typography>
            Cystic Fibrosis, known as CF, is not contagious. It is passed along
            to children by their parents. CF affects the glands, causing them to
            generate thick and sticky mucus rather than thin, watery fluid. As a
            result, dust and germs accumulate in the lungs and cause respiratory
            problems, infections, and lung damage.
          </Typography>
          <br />
          <Typography>
            The thick secretions also block the transfer of enzymes needed for
            digestion of food in the intestine. Without proper digestion,
            children with CF have a difficult time gaining weight and growing
            like they should.
          </Typography>
          <br />
          <Typography>
            Depending on how severe the condition is, some children with Cystic
            Fibrosis can look forward to growing up and living satisfying adult
            lives, although their life expectancy is shorter than average. Not
            too many years ago, CF was known as a children’s disease because few
            lived beyond childhood. Medical advances, however, have prolonged
            life expectancy to 30 years or more for some who have the condition.
          </Typography>
          <br />
          <Typography>
            As many as 40,000 Americans have Cystic Fibrosis, and it continues
            to occur in about one in every 2,500 newborn babies. The likelihood
            of a children being born with the condition depends on the genes
            they inherit from their parents. Some ethnic groups are more prone
            to carry the defective gene that causes CF. Caucasians have a one in
            25 chance of carrying the gene. In descending order, the gene is
            also in the heritage of Ashkenazi Jews, Hispanics,
            African-Americans, and Asians.
          </Typography>
          <br />
          <Typography style={{ width: "100%", textAlign: "left" }}>
            Genetic testing is the most direct method of detecting the mutant
            gene that causes Cystic Fibrosis, and some states require testing of
            all newborns.
          </Typography>
          <br />
          <Typography>
            The symptoms and severity of Cystic Fibrosis vary from one child to
            another, and this determines the medical care to be rendered under a
            physician’s guidance. Typical symptoms include breathing problems
            such as clogged airways in the lungs, sinus infections, coughing,
            wheezing, respiratory infections, pneumonia, polyps in the nose, as
            well digestive problems that result in poor weight gain and loose,
            fatty stools.
          </Typography>
          <br />
          <Typography>
            Though there is no cure, children with Cystic Fibrosis obtain relief
            by controlling the symptoms. Breathing medicated mists and using
            various techniques for breaking up the thick mucus in the lungs is
            one approach. Taking enzymes to help digest food is another. Eating
            high calorie meals and snacks help with weight gain and physical
            development.
          </Typography>
          <br />
          <Typography>
            Parents of a child with Cystic Fibrosis should ensure regular care
            and checkups by a physician and a strict routine of following the
            doctor’s directions for treating the symptoms. Children should not
            be over protected because of their condition, however, and to the
            extent possible, they should engage in regular exercise and physical
            activity.
          </Typography>
          <br />
          <Typography>
            CF has no effect on intellect or mental ability, so parents should
            support their child in achieving an education. For some children,
            illnesses that cause absence from school may make it difficult to
            maintain regular class attendance. Teachers should know that a child
            has CF, and arrangements may need to be made for make-up classes and
            exams. A child’s friends at school also should learn about CF so
            they are not as likely to ask awkward questions.
          </Typography>
          <br />
          <Typography>
            Children with CF can go on field trips and stay overnight with
            playmates so long as provision is made for taking their medications,
            getting their breathing treatments, and eating high energy foods. A
            child with Cystic Fibrosis should carry identification and medical
            information and contacts while away from home. A medical alert
            bracelet is handy.
          </Typography>
          <br />
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography>
              Because of increased longevity, some youth with Cystic Fibrosis
              face the same concerns as others regarding going to college,
              getting a loan, buying a car, and living independently.
            </Typography>
            <br />
            <Typography>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the
              <a href="/generalInformation"> Get Started </a>
              section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href="#">Texas Adoption Resource Exchange (TARE)</a> or call
              1-800-233-3405.
            </Typography>
            <br />
            <Grid style={{ float: "right" }}>
              <Typography>
                This content taken from:{" "}
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                >
                  Adoption and Foster Care
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CysticFibrosis;
