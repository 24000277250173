import React from "react";
import { Grid, Typography } from "@mui/material";
import txregions from "../../images/txregions.gif";

const Mapcardnoarea = () => {
  return (
    <div>
      <Grid
        id="MapCard"
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          height: "300px",
          width: "250px",
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: "bold" }}>
          SELECT YOUR TEXAS AREA
        </Typography>
        <div style={{ textAlign: "center" }}>
          <img
            src={txregions}
            width="160"
            height="151"
            alt="Map of Texas"
            useMap="#MapMap"
            style={{ verticalAlign: "middle" }}
          />
          <map name="MapMap" id="Map2">
            <area
              shape="poly"
              coords="114,63,92,74,94,84,100,88,108,96,117,99,130,88,130,72"
              href="/region7"
              alt="Region 7"
            />
            <area
              shape="poly"
              coords="61,97,60,92,86,91,91,89,97,93,101,96,106,100,116,101,120,107,121,113,112,115,99,112,95,113,94,120,79,118"
              href="/region8"
              alt="Region 8"
            />
            <area
              shape="poly"
              coords="132,81,136,86,141,87,144,91,147,97,142,103,137,107,131,109,124,107,122,102,124,96"
              href="/region4,5,6#region6"
              alt="Region 6"
            />
            <area
              shape="poly"
              coords="50,3,79,4,80,29,73,31,73,38,77,42,69,44,68,51,44,52,46,18"
              href="/region1"
              alt="Region 1"
            />
            <area
              shape="poly"
              coords="85,32,78,37,78,44,73,48,71,55,70,62,79,64,79,69,88,72,97,66,95,57,95,49,102,48,108,40,97,36"
              href="/region2"
              alt="Region 2"
            />
            <area
              shape="poly"
              coords="112,40,112,46,106,48,108,53,100,52,102,59,102,63,107,60,114,60,119,65,123,63,124,56,126,50,125,43,118,39"
              href="/region3"
              alt="Region 3"
            />
            <area
              shape="poly"
              coords="32,64,31,78,43,88,52,95,59,96,58,90,88,89,89,85,92,84,89,80,89,73,78,71,76,65,65,64,65,53,44,53,44,63"
              href="/region9"
              alt="region 9"
            />
            <area
              shape="poly"
              coords="150,62,148,41,137,36,130,37,126,59,129,67,136,69,141,70,142,64,150,62"
              href="/region4,5,6#region4"
              alt="region 4"
            />
            <area
              shape="poly"
              coords="150,94,156,87,157,74,151,65,142,66,141,72,133,70,133,77,139,84,145,88,149,93"
              href="/region4,5,6#region5"
              alt="region 5"
            />
            <area
              shape="poly"
              coords="117,149,112,134,115,122,119,117,107,113,96,113,95,120,88,121,80,119,87,131,91,141,100,146,106,149,115,148"
              href="/region11"
              alt="region 11"
            />
            <area
              shape="poly"
              coords="30,66,3,66,18,82,25,92,32,103,41,107,44,99,47,94,31,80"
              href="/region10"
              alt="10 - Northwest District"
            />
          </map>
        </div>
      </Grid>
    </div>
  );
};

export default Mapcardnoarea;
