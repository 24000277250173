const requirementsContent = [
  {
    key: `1`,
    id: `basicreq`,
    title: `Basic Requirements`,
    subheading: `The prospective foster/adoptive parents may be single or married and must:`,
    desc: [
      {
        key: `basicreq1`,
        value: `Be at least 21 years of age, financially stable, and responsible mature adults,`,
      },
      {
        key: `basicreq2`,
        value: `Complete an application (staff will assist you, if you prefer),`,
      },
      {
        key: `basicreq3`,
        value: `Share information regarding their background and lifestyle,`,
      },
      {
        key: `basicreq4`,
        value: `Provide relative and non-relative references,`,
      },
      {
        key: `basicreq5`,
        value: `Show proof of marriage and/or divorce (if applicable),`,
      },
      {
        key: `basicreq6`,
        value: `Agree to a home study which includes visits with all household members,`,
      },
      {
        key: `basicreq7`,
        value: `Allow staff to complete a criminal history background check and an abuse/neglect check on all adults in the household, and`,
      },
      {
        key: `basicreq8`,
        value: `Attend free training to learn about issues of abused and neglected children.`,
      },
    ],
    content: `The training provides an opportunity for the family and DFPS to assess whether foster care or adoption is best for the family. The family may withdraw from the meetings at any time. There is no charge for the meetings. Foster/adoptive parents generally train together.`,
  },
  {
    key: `2`,
    id: `addfostercarereq`,
    title: `Additional Foster Care Requirements`,
    subheading: `In addition to the basic requirements, foster parents must:`,
    desc: [
      {
        key: `key1`,
        value: `Have adequate sleeping space.`,
      },
      {
        key: `key2`,
        value: `Allow no more than 6 children in the home including your own children or children for whom you provide day care.`,
      },
      {
        key: `key3`,
        value: `Agree to a nonphysical discipline policy.`,
      },
      {
        key: `key4`,
        value: `Permit fire, health and safety inspections of the home.`,
      },
      {
        key: `key5`,
        value: `Vaccinate all pets.`,
      },
      {
        key: `key6`,
        value: `Obtain and maintain CPR/First Aid Certification.`,
      },
      {
        key: `key7`,
        value: `Obtain TB testing as required by the local Health Department for household members.`,
      },
      {
        key: `key8`,
        value: `Attend 20 hours or more of training each year.`,
      },
    ],
  },
  {
    key: `3`,
    id: `resoffosterandadadoptivefamilies`,
    title: `Responsibilities of Foster and Adoptive Families`,
    subheading: [
      {
        key: 1,
        head: `Foster Parents:`,
        desc: [
          {
            key: `key1`,
            value: `Provide daily care and nurturing of children in foster care;`,
          },
          {
            key: `key2`,
            value: `Advocate for children in their schools and communities;`,
          },
          {
            key: `key3`,
            value: `Inform the children's caseworkers about adjustments to the home, school, and community, as well as any problems that may arise, including any serious illnesses, accidents, or serious occurrences involving the foster children or their own families;`,
          },
          {
            key: `key4`,
            value: `Make efforts as team members with children's caseworkers towards reunifying children with their birth families;`,
          },
          {
            key: `key5`,
            value: `Provide a positive role model to birth families and`,
          },
          {
            key: `key6`,
            value: `Help children learn life skills.`,
          },
        ],
      },
      {
        key: 2,
        head: `Adoptive Parents:`,
        desc: [
          {
            key: `key1`,
            value: `Provide permanent homes and a lifelong commitment to children into adulthood;`,
          },
          {
            key: `key2`,
            value: `Provide for the short-term and long-term needs of children;`,
          },
          {
            key: `key3`,
            value: `Provide for children's emotional, mental, physical, social, educational, and cultural needs, according to each child's developmental age and growth;`,
          },
          {
            key: `key4`,
            value: `May become certified as a foster family and accept children who are not legally free for adoption, but whose permanency plan is adoption.`,
          },
        ],
      },
    ],
  },
  {
    key: 4,
    id: `fosterfamiliesadopt`,
    title: `Can foster families adopt?`,
    content: `Yes! Many families are interested in both fostering and adopting. They agree with the agency that the children's needs come first. In most cases, this means helping prepare children for reunification with their birth family, mentoring the birth parents, or working toward a relative or kinship placement.

    When termination of parental rights is in the children's best interest and adoption is their plan, then foster parents who have cared for the children will be given the opportunity to adopt. Dual certification of parents to both foster and adopt speeds up the placement process, reduces the number of moves a child makes, and allows relationships to evolve with the initial placement process. Nearly half the adoptions of children in DFPS foster care are by their foster families.`,
  },
  {
    key: 5,
    id: `provide`,
    title: `Can adoptive families provide foster care?`,
    content: `Yes! Adoptive families who are willing to accept placement of children who are not yet legally free for adoption but have a plan for adoption can also become certified as foster families. This dual certification increases the opportunities for successful adoptions. In some areas of the state, a "buddy system" has been developed in which experienced foster families, who understand the challenges and rewards of foster parenting, are available to share experiences with new families and give support.`,
  },
];

export default requirementsContent;
