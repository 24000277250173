import React from 'react';
// import { makeStyles } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// const useStyles = makeStyles(() => ({
//     body: {
//         color: '#1A355D',
//         padding: '10px 0',
//     },
//   }));

const BreadcrumbMenu = (props) => {
    const { currComp, parentComp, parentCompRoute, secParentComp, secParentRoute } = props;
    // const classes = useStyles();

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{color: '#1A355D',padding: '10px 0'}}>
            {secParentComp &&  <Link to={secParentRoute}> {secParentComp} </Link>}
            <Link to={parentCompRoute}> {parentComp} </Link>   
            <Typography color="textPrimary">{currComp}</Typography>
        </Breadcrumbs>
    )
}

export default BreadcrumbMenu;