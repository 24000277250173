import ImageSlider from "../../layout/imageslider";
import { Grid, Typography } from "@mui/material";
import "./common_diseases.css";
const MyastheniaGravis = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" style={{ color: "#002F86" }}>
              <b>A closer look at Myasthenia Gravis</b>
            </Typography>
            <br />
            <Typography>
              <em>
                For More Info Visit:{" "}
                <a
                  target="_blank"
                  href="http://www.myasthenia.org/amg_whatismg.cfm"
                >
                  Myasthenia Gravis Foundation of America
                </a>
              </em>
            </Typography>
            <br />
            <Typography>
              Myasthenia gravis is an uncommon condition that weakens the
              voluntary muscles. It is not contagious and doesn't seem to be
              inherited.
            </Typography>
            <br />
            <Typography>
              Roughly 36,000 people have been diagnosed with this chronic
              condition in the United States, although scientists believe more
              people have it and are never diagnosed.
            </Typography>
            <br />
            <Typography>
              The condition usually is noticed when people complain of fatigue
              and have trouble seeing due to double vision or drooping eyelids.
              The condition may extend to other muscles, causing weakness in the
              arms, hands, and legs. People with the condition often have
              trouble chewing. At its worst, the condition can affect breathing
              and swallowing.
            </Typography>
            <br />
            <Typography>
              Parents of children with MG have to be patient and understanding.
              They need to help the child achieve a balance of work, home, and
              social obligations. Balanced nutrition is important. Medical care
              is a must. Resting the eyes or taking naps may be helpful, as can
              avoidance of infections, such as a cold. With appropriate medical
              therapy, most people with myasthenia gravis can lead satisfying
              lives.
            </Typography>
            <br />
            <Typography>
              Scientists have discovered how the condition works, but they don't
              know exactly what causes it. The process relates to the body's
              immune system creating antibodies in the thymus gland that block
              muscle receptors and keep the muscles from getting nerve signals
              from the brain. Without signals, the muscles don't contract.
            </Typography>
            <br />
            <Typography>
              Physicians treat the condition in several ways, including immune
              system suppressant drugs, surgery to remove the thymus gland
              (especially if it has a tumor in it), and in worst cases,
              filtering the undesired antibodies from the blood. The various
              treatments are effective enough for people to enjoy significant
              improvement and to lead normal lives.
            </Typography>
            <br />
            <Typography>
              Sometimes, myasthenia gravis goes into remission by itself, but
              even when it does, a person's physician and dentist should be
              reminded that the condition exists. This is important because
              medications for other ailments can adversely affect people with
              myasthenia gravis. People with myasthenia gravis usually wear a
              medical alert bracelet to caution emergency personnel to use drug
              precautions in an accident or other crisis.
            </Typography>
            <br />
            <Typography>
              For some people, there are side effects of the drug therapy,
              including cramping, diarrhea, weight gain, and fluid retention.
              People with these effects should follow common sense steps to
              nutrition, including the possibility of reducing the dosage,
              drinking plenty of liquids, avoiding caffeinated beverages, eating
              fruits to replace minerals, eating low fiber foods, avoiding salt,
              and staying away from junk foods and prepared foods that are high
              in salt.
            </Typography>
            <br />
            <Typography>
              The term myasthenia gravis comes from the Greek and Latin words
              that mean "serious muscle weakness."
            </Typography>
            <br />
            <Typography>
              The Myasthenia Gravis Foundation is a support group for families
              who have a member with the condition. The organization's address
              is 222 South Riverside Plaza, Chicago, Illinois 60606, or by phone
              at 1-800-541-5454 or 312-256-0522.
            </Typography>
            <br />
            <Typography>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the{" "}
              <a href="/generalInformation">Get Started </a>
              section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group, contact the{" "}
              <a href="">Texas Adoption Resource Exchange (TARE) </a>or call
              1-800-233-3405.
            </Typography>
            <br />
            <Grid style={{ float: "right" }}>
              <Typography>
                This content taken from:{" "}
                <a
                  target="_blank"
                  href="https://www.dfps.texas.gov/Adoption_and_Foster_Care"
                >
                  Adoption and Foster Care
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyastheniaGravis;
