import ImageSlider from "../../layout/imageslider";
import { Grid, Typography, Link } from "@mui/material";
import "./common_diseases.css";
const ADHD = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{ textAlign: "left", display: "flex", justifyContent: "center" }}
      >
        <Grid
          id="maingrid"
          style={{
            backgroundColor: "white",
            // width: "90%",
            padding: "20px",
            textAlign: "justify",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" style={{ color: "#002F86",textAlign:"center",fontWeight:"bold" }}>
              <b>A closer look at ADHD</b>
            </Typography>
            <p>
              <em>
                {" "}
                More...
                <a
                  target="_blank"
                  href="http://www.nimh.nih.gov/health/topics/attention-deficit-hyperactivity-disorder-adhd/index.shtml"
                >
                  National Institute on Mental Health
                </a>
              </em>
            </p>

            <br />
            <Typography>
              Attention deficit hyperactivity disorder describes a condition
              that causes children to have a persistent pattern of being
              impulsive or have serious trouble paying attention or both. These
              behaviors are more frequent and severe than typical among
              individuals at the same level of development.
            </Typography>
            <br />
            <Typography>
              Known as ADHD, the condition causes children to fidget, fail to
              finish projects, and become easily distracted. They seem not to
              listen and have difficulty concentrating. Children with ADHD act
              before thinking. They switch from one activity to another and have
              a hard time sitting still for very long. These behaviors appear at
              home, school, and in social situations.
            </Typography>
            <br />
            <Typography>
              As many as one in every 20 school-age children has attention
              deficit hyperactivity disorder. The cause is unknown, but
              researchers suspect the possibility of a genetic or other prenatal
              cause, such as fetal alcohol syndrome, lead toxicity or other
              environmental cause, and food allergies.
            </Typography>
            <br />
            <Typography>
              ADHD can be diagnosed only after a complete evaluation of the
              child's medical, psychological, educational, and behavioral
              strengths and difficulties. Among the criteria for confirming a
              diagnosis is determining if the behavior is chronic and pervasive
              and whether it is due to anxiety or depression.
            </Typography>
            <br />
            <Typography>
              Children do not have to be hyperactive to be diagnosed with the
              condition, and some children actually are lethargic, shy, and
              withdrawn, as if they were living in another world, lazy, or
              uncaring. These children used to be diagnosed with attention
              deficit disorder. But since 1994, the ADD term has been replaced
              the more inclusive phrase attention deficit hyperactivity disorder
              (ADHD), with a subsidiary diagnosis to indicate whether a child
              exhibits one (inattention) or both of the characteristic behaviors
              (inattention and hyperactivity-impulsivity).
            </Typography>
            <br />
            <Typography style={{ textAlign: "left", width: "100%" }}>
              Attention deficit hyperactivity disorder usually shows up by age 7
              and it is found most often in boys. It affects children of average
              ability as well as gifted children.
            </Typography>
            <br />
            <Typography>
              ADHD is not a learning disability. A learning disability refers to
              difficulty in receiving and processing information and feeding it
              back to teachers and parents, whereas ADHD refers to trouble
              paying attention to information at the outset.
            </Typography>
            <br />
            <Typography>
              As many as half the children with ADHD grow out of it somewhat or
              completely at puberty. While the condition is present, medication
              has been found effective in treating or controlling the symptoms.
              Other avenues include special classrooms or seating in a classroom
              to eliminate distractions, tutoring to work past the point of
              receiving information, simplified instructions, a concrete
              calendar for assignments, rewards and consequences delivered
              immediately, and the use of a variety of rewards.
            </Typography>
            <br />
            <Typography>
              Children with ADHD endure frustration, disappointment, and
              feelings of failure over their inability to receive and process
              information, as well as due to the taunts of their classmates.
              Sometimes these feelings are vented through aggression or other
              lashing out behaviors. Families can go a long way toward easing
              these feelings by being patient and helping a child get along with
              playmates, family, and school personnel. Families can accomplish
              this by maintaining a sense of humor, perspective, and emotional
              distance from the child's problems and by choosing battles
              carefully.
            </Typography>
            <br />
            <Typography>
              Families can shield children with ADHD from distracting lights and
              sounds when the children are studying or going to sleep. This
              seems to help because children with the condition seem to respond
              indiscriminately to stimuli; that is, they pay attention to all
              signals, such as ringing phones, blinking lights, and voices down
              the hall. They can't seem to screen out the needed signals from
              the background noise.
            </Typography>
            <br />
            <Typography>
              Children with ADHD may be accident prone, and parents need to be
              careful about their health and safety. Further, children with ADHD
              may not learn from previous experience and are forgetful; parents
              may have to repeat experiences until the proper behavior is
              instilled.
            </Typography>
            <br />
            <Typography style={{ textAlign: "left", width: "100%" }}>
              Previous efforts to treat the condition through diets free of
              sugar and additives have not met expectations, although food
              without sugar or additives is certainly not harmful.
            </Typography>
            <br />
            <Typography>
              Children who are diagnosed with attention deficit hyperactivity
              disorder are found throughout this photo listing of children
              available for adoption. Almost all of them have found some benefit
              from medication to help focus their attention and in some cases to
              calm them down. While not an easy task, loving families can
              nurture children with ADHD. We know this because families are
              doing it every day.
            </Typography>
            <br />
            <Typography style={{ textAlign: "left", width: "100%" }}>
              If you are a Texas resident and are not approved as a foster or
              adoptive family, please fill out our Adoption and Foster Care
              Interest form in the <a href="/generalInformation">Get Started</a>{" "}
              section.
            </Typography>
            <br />
            <Typography>
              If you have questions or want to inquire about a specific child or
              sibling group,
              <br />
              contact the <a href="">
                Texas Adoption Resource Exchange (TARE)
              </a>{" "}
              or call 1-800-233-3405.
            </Typography>
            <Grid style={{ float: "right" }}>
              <Typography>
                This content taken from:{" "}
                <a href="https://www.dfps.texas.gov/Adoption_and_Foster_Care">
                  Adoption and Foster Care
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ADHD;
