import { Grid, Typography } from "@mui/material";
import ImageSlider from "../layout/imageslider";
import { Link } from "react-router-dom";
import "./incentives.css";

const AdoptionIncentivesview = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* <ImageSlider /> */}
      <Grid
        container
        id="maingrid0"
        // spacing={3}
        style={{
          // width: "80%",
          paddingTop: "20px",
          paddingBottom: "20px",
          lineHeight: "1.6",
          fontSize: "15px",
        }}
      >
        
        <Grid
          container
          style={{
            backgroundColor: "white",
            padding: "20px",
            textAlign: "left",
          }}
        >
          <Grid style={{ padding: "10px", width: "100%" }}>
          <Typography
            
            style={{ fontSize:"30px",fontWeight: "bold", color: "#255aad",textAlign:"center" }}
          >
            Financial Incentives for Adoption
          </Typography>
        </Grid>
          <Grid item xs={12} style={{ textAlign: "justify" }}>
            <Typography style={{ color: "#255aad", fontSize: "20px" }}>
              <b>Internal Revenue Service</b>
            </Typography>
            <Typography style={{}}>
              The Internal Revenue Service offers incentives for families
              adopting children.
            </Typography>
            <ul>
              <li>
                <Link
                  target="_blank"
                  to="https://www.irs.gov/taxtopics/tc607"
                  style={{ color: "blue" }}
                >
                  Learn more at the IRS website.
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.irs.gov/uac/Six-Things-to-Know-About-the-Expanded-Adoption-Tax-Credit"
                  style={{ color: "blue" }}
                >
                  Six Things to Know About the Expanded Adoption Tax Credit.
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} lg={12} style={{ textAlign: "justify" }}>
            <Grid>
              <Typography style={{ color: "#255aad", fontSize: "20px" }}>
                <b>Federal Adoption Tax Credit</b>
              </Typography>
              <br />
              <Typography>
                Information courtesy of North American Council on Adoptable
                Children
              </Typography>
              <br />
              <Typography>
                &emsp;Since 2003, families who adopted a child with special
                needs from foster care could claim a federal adoption tax credit
                even if they had no adoption expenses.
                <b>
                  Other adoptive families are also eligible for the credit, but
                  must document qualified adoption expenses.
                </b>
              </Typography>
              <br />
              <Typography>
                &emsp;The tax credit became refundable for 2010 and 2011. A
                refundable tax credit is one you get back regardless of what you
                owe or paid in taxes for the year.
              </Typography>
              <br />
              <Typography>
                &emsp; Families who adopted from 2005 to 2009 may be able to
                benefit from the refundable credit because credits from those
                years can be carried forward until 2010. (Families who adopted
                in 2003 and 2004 may be able to take some limited advantage of
                the credit but will not benefit from refundability. Families who
                adopted earlier will not benefit from the credit if they did not
                take it already.)
              </Typography>
              <br />
              <Typography>
                &emsp; The amount of the credit is based on the year the
                adoption finalized (see table on right):
              </Typography>
              <br />
              <Typography>
                &emsp; The credit is claimed one time for each adopted child
                with special needs. Below, we explain the basics of the adoption
                tax credit.
              </Typography>
              <br />
              <Typography>To be eligible for the credit, you must:</Typography>
            </Grid>
            <br />
            <Grid
              item
              xs={12}
              lg={12}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <Grid item xs={12} lg={9}>
                <ul style={{}}>
                  <li>
                    Have adopted a child other than a stepchild — Children who
                    receive a monthly adoption subsidy payment have been
                    determined by the state to have special needs, so these
                    children are eligible for the full tax credit without
                    documenting expenses. Families who adopted children without
                    special needs are also eligible, but need to document
                    expenses.
                  </li>
                  <br />
                  <li>
                    And be within the income limits — How much of the credit you
                    can claim is based on income. In 2010, families with a
                    federal modified adjusted gross income above $222,520 cannot
                    claim the credit; families with incomes above $182,520 can
                    claim part credit. Adoptions from previous years had
                    different income limits.
                  </li>
                </ul>
                <Typography>
                  &emsp;You will need to prove the adoption by providing the IRS
                  with a copy of the adoption decree. Families who adopted a
                  child with special needs must also provide a copy of the
                  adoption assistance agreement.
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3} style={{ paddingTop: "20px" }}>
                <table
                  width="150"
                  border="1"
                  align="center"
                  cellPadding="0"
                  cellSpacing="4"
                  className="datatable"
                >
                  <tbody>
                    <tr align="center">
                      <td className="text">2011</td>
                      <td className="text">$13,360</td>
                    </tr>
                    <tr align="center">
                      <td className="text">2010</td>
                      <td className="text">$13,170</td>
                    </tr>
                    <tr align="center">
                      <td width="75" className="text">
                        2009
                      </td>
                      <td width="107" className="text">
                        $12,150
                      </td>
                    </tr>
                    <tr align="center">
                      <td className="text">2008</td>
                      <td className="text">$11,650</td>
                    </tr>
                    <tr align="center">
                      <td className="text">2007</td>
                      <td className="text">$11,390</td>
                    </tr>
                    <tr align="center">
                      <td className="text">2006</td>
                      <td className="text">$10,960</td>
                    </tr>
                    <tr align="center">
                      <td className="text">2005</td>
                      <td className="text">$10,630</td>
                    </tr>
                    <tr align="center">
                      <td className="text">2004</td>
                      <td className="text">$10,390</td>
                    </tr>
                    <tr align="center">
                      <td className="text">2003</td>
                      <td className="text">$10,160</td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </Grid>
            </Grid>
          </Grid>

          <Grid style={{ textAlign: "justify" }}>
            <Typography style={{ color: "#255aad", fontSize: "20px" }}>
              <b>Penalties Assessed as a Result of the Adoption Tax Credit</b>
            </Typography>
            <br />
            <Typography>
              &emsp; Some families have received letters from the IRS stating
              that they owe thousands of dollars or that their refunds have been
              significantly reduced, primarily as a result of a penalty assessed
              for claiming a refund that the IRS has not approved. As we
              understand it, the IRS can assess taxpayers a penalty of 20
              percent of any refund they claim that they are not due.
            </Typography>
            <br />
            <Typography>
              &emsp;The letters from the IRS typically include a Form 886-A,
              Explanation of Items, which details why the IRS has not approved
              the adoption tax credit. The most common explanations we have
              heard are: (1) missing documentation; (2) the examiner's rejection
              of the adoption assistance agreement as proof of special needs;
              and (3) calculation or carry forward errors.
            </Typography>
            <br />
            <Typography>
              &emsp; If you receive such a letter and you claimed the credit
              correctly, you should write to the IRS explaining why you
              disagree, and either re-send any documentation requested or
              clarify that the adoption assistance agreement IS proof of special
              needs (highlight the{" "}
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.irs.gov/individuals/article/0,,id=231663,00.html"
                  style={{ color: "blue" }}
                >
                  IRS's own FAQs—question 13.
                </a>
              </span>
              )
            </Typography>
            <br />
            <Typography>
              Once your refund has been approved, the penalty will disappear.
            </Typography>
            <br />
            <Typography>
              &emsp;However, we also have spoken to several parents who made
              mistakes in claiming the credit, such as:
            </Typography>
            <ul>
              <li>
                claiming the credit more than one year; for example, requesting
                $12,150 for an adoption in 2009 and then seeking an additional
                $13,170 for that same adoption in 2010
              </li>
              <br />
              <li>
                claiming the full credit for an adoption that does not meet the
                IRS's definition of special needs (such as an international or
                private adoption of a medically fragile child), when expenses
                were less than the maximum credit
              </li>
            </ul>
            <Typography>
              &emsp; If you are aware that you made an error but haven't yet
              received a penalty letter, we recommend writing to the IRS to
              explain and correct your mistake. Assure them that the error was
              honest and that you are only seeking the amount that you are due.
            </Typography>

            <br />
            <Typography>
              &emsp; If you received a penalty letter based on your error, you
              can apply for abatement of the penalty using Form 843
              (http://www.irs.gov/pub/irs-pdf/f843.pdf). Explain why you made
              the error, and note that you are seeking abatement under the
              "reasonable cause" option under 5a. Be as detailed as possible
              about why the error happened (for example, if you used software
              that caused the problem, name the software, the date you completed
              the return, and what happened). We do not know if these penalties
              will be abated, but truly hope so.
            </Typography>
            <br />
            <Typography>
              &emsp; If you have any questions, please contact us at
              taxcredit@nacac.org.
            </Typography>
          </Grid>
          <br />
          <Grid style={{ textAlign: "justify" }}>
            <Typography style={{ color: "#255aad", fontSize: "20px" }}>
              <b>If You Adopted in 2010</b>
            </Typography>
            <Typography>
              &emsp;To claim the credit, you complete IRS Form 8839 with your
              IRS Form 1040. On line 5 of 8839, which asks for qualified
              adoption expenses, enter $13,170 as long as your child receives
              adoption assistance. Others should enter their qualified adoption
              expenses.
            </Typography>
          </Grid>
          <br />
          <Grid style={{ textAlign: "justify" }}>
            <Typography style={{ color: "#255aad", fontSize: "20px" }}>
              <b>
                &emsp; If You Adopted between 2005 and 2009 and Claimed the
                Credit the Year You Finalized
              </b>
            </Typography>
            <br />
            <Typography>
              &emsp; If you finalized in 2005 or later, claimed the credit that
              year, and carried forward the adoption tax credit on each year's
              returns, just carry forward the remaining amount of the credit
              from your 2009 return to 2010. You can claim the full amount
              remaining as a credit on your 2010 return.
            </Typography>
            <br />
            <Typography>
              &emsp;If you claimed the credit the year you finalized, but didn't
              carry it forward to subsequent years' tax forms, you'll need to
              amend returns for those years.
            </Typography>
          </Grid>
          <br />
          <Grid style={{ textAlign: "justify" }}>
            <Typography style={{ color: "#255aad", fontSize: "20px" }}>
              <b>
                If You Adopted between 2007 and 2009 and Didn't Claim the Credit
              </b>
            </Typography>
            <br />
            <Typography>
              &emsp; You must file for the adoption tax credit in the year you
              finalized the adoption, so you need to amend your tax return for
              the year the adoption was finalized and any subsequent years,
              carrying forward the amount of the adoption tax credit that you
              are not able to use each year. Any part of the credit not paid
              before 2010 can be used fully with the 2010 return.
            </Typography>
          </Grid>
          <br />
          <Grid style={{ textAlign: "justify" }}>
            <Typography style={{ color: "#255aad", fontSize: "20px" }}>
              <b>If You Adopted in 2005 and 2006 and Didn't Claim the Credit</b>
            </Typography>
            <br />
            <Typography>
              &emsp;Adoptions finalized in 2005 or 2006 are more complicated
              because the IRS allows people to amend returns to claim a credit
              only for the past three years.
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://nacac.org/taxcredit/irsletter.pdf"
                  style={{ color: "blue" }}
                >
                  The IRS has written,
                </a>
              </span>
              though, that these older credits can be accessed.
            </Typography>
            <br />
            <Typography>
              &emsp;If you adopted in 2005 or 2006, you should amend your
              returns starting the year you finalized. Because it's longer than
              three years ago, you cannot be paid a refund for the credit in
              2005 or 2006. You must still complete the 2005 and/or 2006 return
              as if you are getting a refund for the credit used, but any credit
              that could have been refunded in these years will be lost. When
              you amend your 2007 taxes and forward, you can use any credit due
              that year. Whatever amount remains after you do your 2009 taxes
              can be carried over and refunded in 2010. Please note that the IRS
              may reject your amendment but we encourage you to appeal. A
              taxpayer advocate may be helpful in the appeal.
            </Typography>
            <br />
            <Typography>
              &emsp; If you didn't file in 2005 or 2006 and had sufficient tax
              liability in 2005 and 2006 to use up the whole credit, you will
              not benefit. You need to have some credit carried forward to 2007
              to get a payment.
            </Typography>
          </Grid>
          <br />
          <Grid style={{ textAlign: "justify" }}>
            <Typography style={{ color: "#255aad", fontSize: "20px" }}>
              <b>If You Adopted in 2003 and 2004 and Didn't Claim the Credit</b>
            </Typography>
            <br />
            <Typography>
              &emsp; If you finalized an adoption in 2003 or 2004, the issue
              about amending returns more than three years old applies so you
              are less likely to benefit. You cannot carry the credit forward to
              2010 when the credit becomes refundable.
            </Typography>
            <br />
            <Typography>
              &emsp; A family with significant tax liability may use up all of
              the adoption tax credit in the years before 2007, but they cannot
              get a refund for these years. Those families who have a moderate
              tax liability might be able to carry forward some of the credit to
              2007, 2008, or 2009, when they could get a refund for whatever tax
              liability they had that year.
            </Typography>
          </Grid>
          <br />
          <Grid style={{ textAlign: "justify" }}>
            <Typography style={{ color: "#255aad", fontSize: "20px" }}>
              <b>Tuition Waiver</b>
            </Typography>
            <br />
            <Typography>
              &emsp;The Texas state tuition and fee waiver provides exemptions
              at state supported institutions of higher education to certain
              youth who were formerly in foster care, adopted youth, and youth
              in permanent managing conservatorship.
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.dfps.texas.gov/Child_Protection/Youth_and_Young_Adults/Education/state_college_tuition_waiver.asp"
                  style={{ color: "blue" }}
                >
                  Learn more.
                </a>
              </span>
            </Typography>
          </Grid>
          <br />
          <Grid style={{ textAlign: "justify" }}>
            <Typography style={{ color: "#255aad", fontSize: "20px" }}>
              <b>Texas and National Adoption Assistance Resources</b>
            </Typography>
            <br />
            <Typography>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.dfps.texas.gov/Child_Protection/Adoption/adoption_support.asp"
                style={{ color: "blue" }}
              >
                Post Adoption Services
              </a>
            </Typography>
            <Typography>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.dfps.texas.gov/Child_Protection/Adoption/adoption_assistance.asp"
                style={{ color: "blue" }}
              >
                Information on other programs
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdoptionIncentivesview;
