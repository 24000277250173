import RepeatIcon from '@mui/icons-material/Repeat';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
// import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';

export const donateContent = {
    title: `Why Give to Texas Foster Care Agency?`,
    mainContent: [
        {
            key: `donatemc1`,
            value: `Giving to Texas Foster Care is a way to give back to the children of our community.
            Be it a gift of $10 or $10,000, both provide the donor with a tax deduction and, more importantly, 
            an opportunity to really make a difference. Because we are a small, local charity every dollar given to 
            Texas Foster Care really counts.`,
            hasLink: false
        },
        {
            key: `donatemc2`,
            value: `Your decision to support children in foster care and adoption by way of donations is a 
            decision that will change their lives for the better - and yours, too. Your investment in Texas 
            Foster Care helps ensure that every child in Texas has a permanent, stable family and the chance for 
            a fulfilling future.`,
            hasLink: false
        },
        {
            key: `donatemc3`,
            value: `You can make a positive and lasting difference in the lives of children by making a 
            tax-deductible gift in one or more of the following ways:`,
            hasLink: true
        }
    ],
    gifts: [
        {
            key: `annualGift`,
            label: `Annual Gifts`,
            value: `Annual gifts include direct gifts, honor or memorial tributes, and pledges in the form of cash, 
            check or credit card. Click below to make a secure online donation. We also accept gifts of stock, mutual 
            funds or other appreciated property. Please call the Texas Foster Care Office at (210) 332-9171 for more 
            information or email arenr.org.`,
            icon: <CardGiftcardIcon />,
            hasLink: true
        },
        {
            key: `recurGift`,
            label: `Recurring Gift`,
            value: `Your recurring gift helps establish a stable source of funding to protect Texas Foster Care programs 
            serving children and families. You can designate an automatic credit card debit on a monthly or quarterly 
            basis through our secure online server.`,
            icon: <RepeatIcon />,
            hasLink: true
        },
        {
            key: `matchGift`,
            label: `Matching Gifts`,
            value: `Increase the value of your gift by taking advantage of your employer’s matching gift program for 
            active and retired employees.`,
            icon: <WorkIcon />,
            hasLink: false
        },
        {
            key: `plannedGift`,
            label: `Planned Gifts`,
            value: `Leaving a legacy through planned giving helps ensure that Texas Foster Care will be able to give new 
            generations of children a future. You may designate Texas Foster Care as a beneficiary in your will or trust,
             or you can use other vehicles. For information about how to get started with making Texas Foster Care part 
             of your estate plan.`,
             icon: <BusinessIcon />,
             hasLink: false
        },
        {
            key: `giftInKind`,
            label: `Gifts-in-Kind`,
            value: `Texas Foster Care appreciates donated goods and professional services.`,
            icon: <ThumbUpIcon />,
            hasLink: false
        }
    ]
}

export const donateSection = {
    title: `How to Give`,
    icon: <PriceCheckIcon />,
    text1: `Click on the Donate icon to make a secure online donation.`,
    text2:`Or`,
    text3: `mailyour check payable to Texas Foster Care to: Texas Foster Care Agency at`,
    text4: `or, for the Austin area,`,
    text5: `All gifts to Texas Foster Care are tax deductible and are acknowledged in writing.`,
    // addressSA1: `5825 Callaghan Road, Suite 206,`,
    // addressSA2: `San Antonio, TX 78228`,
    // addressAus1: `706-A West Ben White Blvd, Suite 250,`,
    // addressAus2: `Austin, TX 78704`
    
}