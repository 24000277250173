import { Grid, Typography, Card, CardHeader, CardContent } from "@mui/material";
import ImageSlider from "../layout/imageslider";
import { Link, useLocation } from "react-router-dom";
import download from "../../assets/downloads/down.pdf";
import "./generalinformation.css";
import React, { useEffect } from "react";

const GeneralInformation = () => {
  return (
    <div align="center">
      {/* <ImageSlider /> */}
      <Grid xs={11} container id="maingrid">
        <Grid
          container
          item
          // lg={12}
          // xs={12}
          style={{ display: "flex", gap: "20px" }}
        >
          <Grid
            item
            // lg={7}
            // xs={12}
            style={{ textAlign: "justify", lineHeight: "1.6" }}
          >
            <Grid>
              <Typography style={{ fontSize: "30px", color: "#1A355D" }}>
                <b> Get Started</b>
              </Typography>
              <Typography>
                Thank you for your interest in becoming a foster or adoptive
                family in Texas. You have three ways to begin the process to
                become a foster or adoptive parent. The first two include
                working with the Texas Department of Family and Protective
                Services (DFPS). Your third option is working with a private
                adoption agency who contracts with DFPS.
              </Typography>
            </Grid>
            <br />
            <Grid>
              <Typography style={{ fontSize: "25px", color: "#255aad" }}>
                <b>Three Options</b>
              </Typography>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href="https://www.dfps.texas.gov/Application/Forms/showFile.aspx?NAME=2170.pdf"
                    style={{ color: "blue" }}
                  >
                    Read information packet.
                  </a>
                </li>
                <li>
                  Attend a free foster/adopt{" "}
                  <span>
                    <Link
                      style={{ color: "blue" }}
                      to="/placement#informationmeeting"
                    >
                      Information Meeting
                    </Link>
                  </span>{" "}
                  View the schedule of information meetings in your area, local
                  contacts, events and statistics.
                </li>
                <li>
                  Work with DFPS or a{" "}
                  <span>
                    <Link style={{ color: "blue" }} to="/partners">
                      Private Adoption Agency
                    </Link>
                  </span>{" "}
                  who works in partnership with DFPS.
                </li>
              </ul>
            </Grid>
            <Grid>
              <Typography
                variant="h5"
                style={{
                  color: "#255aad",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Not a Texas resident?
              </Typography>
            </Grid>
            <br />
            <Typography>
              <b>Your first step:</b> Visit your{" "}
              <span style={{ color: "#255aad" }}>
                <Link style={{ color: "blue" }} to="/state">
                  State Adoption Website
                </Link>
              </span>{" "}
              for information on how to start the process to become a foster or
              adoptive family. Texas can only place children with families from
              other states if they have a current{" "}
              <span style={{ color: "#255aad" }}>
                <Link style={{ color: "blue" }} to="/homestudy">
                  home study
                </Link>
              </span>
              . You may also visit the national website at{" "}
              <span style={{ color: "#255aad" }}>
                <Link
                  style={{ color: "blue" }}
                  target="_blank"
                  to="https://www.adoptuskids.org/"
                >
                  www.AdoptUSKids.org.
                </Link>
              </span>
            </Typography>
            <br />
            <Typography style={{}}>
              <b>Note:</b> U.S. families approved to adopt within their state
              and who have a current{" "}
              <span style={{ color: "#255aad" }}>
                <Link style={{ color: "blue" }} to="/homestudy">
                  home study
                </Link>
              </span>{" "}
              May submit an inquiry directly on the child's photo/profile page
              once an account is created and the family profile completed.
            </Typography>
            <br />
            <Typography
              style={{ fontSize: 20, textAlign: "left" }}
              color="text.secondary"
              gutterBottom
            >
              <b>In This Section</b>
            </Typography>
            <ul style={{ textAlign: "left", lineHeight: "30px" }}>
              <li>
                <Link style={{ color: "blue" }} to="/requirements">
                  Requirements for Foster/Adopt Families
                </Link>
              </li>
              <li>
                <Link style={{ color: "blue" }} to="/stepsview">
                  Steps to Become a Foster/Adopt Parent
                </Link>
              </li>
              <li>
                <Link
                  style={{ color: "blue" }}
                  to="/placement#informationmeeting"
                >
                  Information Meetings
                </Link>
              </li>
            </ul>
            <br />
            <Typography
              style={{ fontSize: 20, textAlign: "left" }}
              color="text.secondary"
              gutterBottom
            >
              <b>More Information</b>
            </Typography>
            <ul style={{ textAlign: "left", lineHeight: "30px" }}>
              <li>
                <Link style={{ color: "blue" }} to="/state#maingrid">
                  State Adoption Websites
                </Link>
              </li>
              <li>
                <Link style={{ color: "blue" }} to="/state#miltarygrid">
                  Information for Military Families
                </Link>
              </li>
              <li>
                <Link style={{ color: "blue" }} to="/partners">
                  Private Adoption Agencies
                </Link>
              </li>
              <li>
                <Link style={{ color: "blue" }} to="/spanish2">
                  En Español
                </Link>
              </li>
            </ul>
          </Grid>

          {/* <Grid
            align="top"
            id="cardgrid"
            container
            item
            lg={4}
            xs={12}
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              // backgroundColor: 'green',
              verticalAlign: "top",
              paddingTop: "0px",
            }}
          >
            <Card style={{ height: "25rem" }}>
              <CardHeader
                style={{ backgroundColor: "rgb(53, 103, 178)" }}
              ></CardHeader>
              <CardContent>
                <Typography
                  style={{ fontSize: 20, textAlign: "left" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>In This Section</b>
                </Typography>
                <ul style={{ textAlign: "left", lineHeight: "30px" }}>
                  <li>
                    <Link style={{ color: "blue" }} to="/requirements">
                      Requirements for Foster/Adopt Families
                    </Link>
                  </li>
                  <li>
                    <Link style={{ color: "blue" }} to="/stepsview">
                      Steps to Become a Foster/Adopt Parent
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "blue" }}
                      to="/placement#informationmeeting"
                    >
                      Information Meetings
                    </Link>
                  </li>
                </ul>
                <Typography
                  style={{ fontSize: 20, textAlign: "left" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>More Information</b>
                </Typography>
                <ul style={{ textAlign: "left", lineHeight: "30px" }}>
                  <li>
                    <Link style={{ color: "blue" }} to="/state#maingrid">
                      State Adoption Websites
                    </Link>
                  </li>
                  <li>
                    <Link style={{ color: "blue" }} to="/state#miltarygrid">
                      Information for Military Families
                    </Link>
                  </li>
                  <li>
                    <Link style={{ color: "blue" }} to="/partners">
                      Private Adoption Agencies
                    </Link>
                  </li>
                  <li>
                    <Link style={{ color: "blue" }} to="/spanish2">
                      En Español
                    </Link>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default GeneralInformation;
