import React from "react";
import { Grid, Typography } from "@mui/material";
import ImageSlider from "../../layout/imageslider";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import Waitingform from "./childrenwaitingfrm";
import "./why_not_me.css";
const Why_not_me = () => {
  return (
    <div>
      {/* <ImageSlider /> */}
      <Grid
        style={{
          textAlign: "justify",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          id="maingrid"
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Grid style={{textAlign: "left",
                width: "100%",}}>
            <Typography
              variant="h5"
              fontWeight="bold"
              style={{
                color: "rgb(63, 81, 181)",
                
              }}
            >
              Why Not You ? Why Not Me ?
            </Typography>
            <br />
            <Typography fontWeight="bold">
              November is{" "}
              <a
                target="_blank"
                alt=""
                href="https://adoptionmonth.childwelfare.gov/topics/adoption/nam/"
              >
                National Adoption Month
              </a>
              , and Saturday, November 18, 2023 is{" "}
              <a
                target="_blank"
                alt=""
                href="https://www.nationaladoptionday.org/"
              >
                National Adoption Day!
              </a>
            </Typography>
          </Grid>
          <br />
          <Grid container style={{ display: "flex", gap: "50px" }}>
            <Grid item lg={7}>
              <Typography>
                <span style={{ fontWeight: "bold", lineHeight: "1.6" }}>
                  Children never outgrow the need for parents.
                </span>
                <br />
                Children are never too old to want and need a family of theit
                own. Even grown chikdren seek the comfort and wisdom of their
                parents. Becoming a uoung adult is difficult for maost youth,
                and it's even harder for youth without a permanent family.
                Teenagers need parents as role models for becoming productive
                citizens.
                <br />
                To shorten the wait, the Texas Department of Family and
                Protective Services (DFPS) launched a{" "}
                <span style={{ fontWeight: "bold" }}>
                  public awareness campaign called "Why Not Me ?" to recruit
                  adoptive parents.
                </span>{" "}
                <br />
                After all, that is what these children ask themselves almost
                every day.
              </Typography>
              <br />
              <Typography variant="h5" textAlign="center">
                "Why not me ? Why can't I have a family ?"
              </Typography>
              <br />
              <Typography style={{ textAlign: "justify", lineHeight: "1.6" }}>
                More than 6,000 of abuse and neglected children in Texas are
                awaiting adoption and about 60 percent of them are age 6 or
                older. The older the child, the longer they tend to wait for
                adoption.
              </Typography>
            </Grid>
            <Grid
              item
              align="center"
              xs={12}
              sm={12}
              lg={3}
              id="videogrid"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <em>
                <iframe
                  id="youtubevideo"
                  // width="320px"
                  // height="247vh"
                  src="//www.youtube.com/embed/10YXNM8RRvw?autoplay=1&rel=0"
                  title="Why Not Me? Video Promoting the value of adopting older children"
                  frameBorder="0"
                  allowFullScreen
                >
                  <p style={{ textAlign: "justify" }}>
                    Why Not Me? Video Promoting the value of adopting older
                    children
                  </p>
                </iframe>
                <br />
                Spread the word! Visit our{" "}
                <a href="/videotable" rel="noopener noreferrer">
                  campaign materials
                </a>
                page to play "Why Not Me?" TV and radio spots and download
                posters, bookmarks, brochures, and more.
              </em>
            </Grid>
          </Grid>
          <br />
          <Typography style={{ textAlign: "justify", lineHeight: "1.6" }}>
            <span style={{ fontWeight: "bold" }}>
              We are asking mature, tesponsible Texans this question: why not
              you ?
            </span>
            <br />
            Why not consider adopting an abused or neglected child ? Whether
            they are 7 or 17, children never outgrow the need for parents. Make
            a difference in a child's life.
            <a href="./videotable">
              View and download our campign materials and help spread the world!
            </a>
          </Typography>
          <br />
          <Typography
            variant="h5"
            fontWeight="bold"
            style={{ color: "rgb(63, 81, 181)",width:'100%',textAlign:'left' }}
          >
            Meet the Children Waiting for Adoption.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify", lineHeight: "1.6" }}>
            DFPS created the Texas Adoption Resource Exchange (TARE) website to
            help match adoptive parents with Texas children awaiting adoption.
            YOu may{" "}
            <a href="/childrenwaitingfrm">
              search and view children waiting for adoption
            </a>{" "}
            on the website at any time. To view full profiles.save yor
            preferences, and make inquiries, you will need to{" "}
            <a
              target="_blank"
              href="https://www.dfps.texas.gov/Application/TARE/Account.aspx/Register"
            >
              create an account.
            </a>
            <br />
            Once you create an account profile, you can inquire about children
            or sibling groups available for adoption and get status updates.
            DFPS staf can view your profile and your adoption preferences,
            including child characteristics, family skills, and Whether you ara
            willing to parent a child with a disability. Using the TARE website
            could result in a child being successfully placed with adopted by
            your family!.
          </Typography>
          <br />
          <Typography style={{width:"100%",textAlign:"left"}}>
            <a
              style={{
                display: "flex",
                justifyContenta: "center",
                alignItems: "center",
                gap: "10px",
              }}
              
              href="/childrenwaitingfrm"
            >
              <SavedSearchIcon />
              Meet the children waiting for adoption.
            </a>
          </Typography>
          <br />
          <Typography
            variant="h5"
            fontWeight="bold"
            style={{ color: "rgb(63, 81, 181)",width:"100%",textAlign:"left" }}
          >
            How Can We Help you on Your Path to Fostering or Adopting ?
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify", lineHeight: "1.6" }}>
            Foster parenting and adoption are not quick decisions and require a
            lot of thoughtful insideration and preparation.In addition to
            helping you find children awaiting adoption, the TARE website
            provides the following resources to help you along the way:
          </Typography>
          <br />
          <ul style={{ lineHeight: "1.6" ,width:"100%",textAlign:'left'}}>
            <li>
              <a href="/placement#informationmeeting">
                Information Meetings in Your Area
              </a>
              -Attent a meeting to learn about adoption and foster parenting and
              get your questions answered.
            </li>
            <li>
              <a href="/ourchildren">Learn About Our Children</a>- Understand
              the backgrounds our childrencome from and the impacts of fostering
              or adopting.
            </li>
            <li>
              <a href="/stepsview">
                Steps to Become a Foster or Adoptive Parent
              </a>
              - Adoption is a process. Learn what that looks like!
            </li>
            <li>
              <a href="/placement#adoptionpartners">
                Texas Adoption Partners and Agencies
              </a>
              - Get list of private adoption agencies and learn about community
              partner programs.
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default Why_not_me;
