import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";
import "../../../components/adoptionOverview/Partners/partnerstabs.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Partners_accordings from "./partner_accordings";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Partnerview() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{}}>
      {/* <Typography>TITLE</Typography> */}
      <Grid style={{ padding: "10px" }}>
        <Typography
          style={{
            textAlign: "center",
            width: "100%",
            fontWeight: "bold",
            backgroundColor: "rgb(53, 103, 178)",
            color: "white",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
          variant="h5"
        >
          STEPS
        </Typography>
      </Grid>
      <br />
      <Grid
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Grid
          id="regions"
          style={{ border: "1px solid black", borderRight: "none" }}
        >
          {" "}
          {/* <Tabs
              orientation="vertical"
              // variant="scrollable"
              value={value}
              // onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider", width: "35px" }}
            >
              <Tab
                style={{
                  backgroundColor: "#3f51b5",
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  color: "white",
                  height: "62px",
                }}
                label="I"
                {...a11yProps(0)}
              />
              <Tab
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  height: "61px",
                }}
                label="II"
                {...a11yProps(1)}
              />
              <Tab
                style={{
                  backgroundColor: "#3f51b5",
                  fontSize: "15px",
                  fontWeight: "bold",
                  paddingRight: "70px",
                  color: "white",
                  height: "62px",
                }}
                label="III"
                {...a11yProps(2)}
              />
              <Tab
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  height: "61px",
                }}
                label="IV"
                {...a11yProps(3)}
              />
              <Tab
                style={{
                  backgroundColor: "#3f51b5",
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  color: "white",
                  height: "62px",
                }}
                label="V"
                {...a11yProps(4)}
              />
              <Tab
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  height: "61px",
                }}
                label="VI"
                {...a11yProps(5)}
              />
              <Tab
                style={{
                  backgroundColor: "#3f51b5",
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  color: "white",
                  height: "62px",
                }}
                label="VII"
                {...a11yProps(5)}
              />
              <Tab
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  height: "80px",
                }}
                label="VIII"
                {...a11yProps(5)}
              />
              <Tab
                style={{
                  backgroundColor: "#3f51b5",
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  color: "white",
                  height: "118px",
                }}
                label="IX"
                {...a11yProps(5)}
              />
              <Tab
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  height: "61px",
                }}
                label="X"
                {...a11yProps(5)}
              />
              <Tab
                style={{
                  backgroundColor: "#3f51b5",
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingRight: "70px",
                  color: "white",
                  height: "80px",
                }}
                label="XI"
                {...a11yProps(5)}
              />
            </Tabs> */}
          <Tabs
            // id="tabs"
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              style={{
                backgroundColor: "lightgrey",
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: "left",
              }}
              label="REGION 1 "
              {...a11yProps(0)}
            />
            <Tab
              style={{
                fontWeight: "bold",
                fontSize: "15px",
              }}
              label="REGION 2 "
              {...a11yProps(1)}
            />
            <Tab
              style={{
                backgroundColor: "lightgrey",
                fontSize: "15px",
                fontWeight: "bold",
              }}
              label="REGION 3 "
              {...a11yProps(2)}
            />
            <Tab
              style={{
                fontWeight: "bold",

                fontSize: "15px",
              }}
              label="REGION 4 "
              {...a11yProps(3)}
            />
            <Tab
              style={{
                backgroundColor: "lightgrey",
                fontWeight: "bold",
                fontSize: "15px",
              }}
              label="REGION 5 "
              {...a11yProps(4)}
            />
            <Tab
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                paddingRight: "",
              }}
              label="REGION 6 "
              {...a11yProps(5)}
            />
            <Tab
              style={{
                backgroundColor: "lightgrey",
                fontWeight: "bold",
                fontSize: "15px",
                paddingRight: "",
              }}
              label="REGION 7 "
              {...a11yProps(6)}
            />
            <Tab
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                paddingRight: "",
              }}
              label="REGION 8 "
              {...a11yProps(7)}
            />
            <Tab
              style={{
                backgroundColor: "lightgrey",
                fontWeight: "bold",
                fontSize: "15px",
                paddingRight: "",
              }}
              label="REGION 9"
              {...a11yProps(8)}
            />
            <Tab
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                paddingRight: "",
              }}
              label="REGION 10 "
              {...a11yProps(9)}
            />
            <Tab
              style={{
                backgroundColor: "lightgrey",
                fontWeight: "bold",
                fontSize: "15px",
                paddingRight: "",
              }}
              label="REGION 11"
              {...a11yProps(10)}
            />
          </Tabs>
        </Grid>

        <Grid id="links" style={{ border: "1px solid black", width: "80%" }}>
          <TabPanel value={value} index={0}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 1 (Lubbock)
              </Typography>
              <ul>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.arrow.org/"
                    id="listslink"
                  >
                    Arrow Child and Family Ministries
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.awfc.org/"
                    id="listslink"
                  >
                    A World For Children
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.bair.org/"
                    id="listslink"
                  >
                    Bair Foundation
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.buckner.org/"
                    id="listslink"
                  >
                    Buckner Children and Family Services
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://childshome.org/"
                    id="listslink"
                  >
                    Children's Home of Lubboock,Inc.
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://childrenhp.org/"
                    id="listslink"
                  >
                    Children's Hope Residential Services,Inc.
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.depelchin.org/"
                    id="listslink"
                  >
                    Depelchin Children's Center
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.gracemanor.org/"
                    id="listslink"
                  >
                    Grace Manor
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://fosteradopttexas.com/"
                    id="listslink"
                  >
                    Panhandle Child Placement Services,Inc.
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.texasboysranch.org/"
                    id="listslink"
                  >
                    Texas Boys Ranch,Inc.
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://tfifamily.org/"
                    id="listslink"
                  >
                    Texas Family Initiative
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://upbring.org/"
                    id="listslink"
                  >
                    Upbring
                  </Link>
                </li>
              </ul>
              <br />
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 2 (Abilene)
              </Typography>
              <ul>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.arrow.org/"
                    id="listslink"
                  >
                    Arrow Child and Family Ministries
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.awfc.org/"
                    id="listslink"
                  >
                    A World For Children
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://caringheartsforchildren.org/"
                    id="listslink"
                  >
                    Caring Hearts for children
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://christianhomes.com/"
                    id="listslink"
                  >
                    Christian Homes & Family Services
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                    id="listslink"
                  >
                    Gladney Center for Adoption
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.harmonyfamilyservices.org/"
                    id="listslink"
                  >
                    Harmony Family Services - child Placing
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.mch.org/"
                    id="listslink"
                  >
                    Methodist Children's Home
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.newhorizonsinc.com/"
                    id="listslink"
                  >
                    New Horizons Ranch and Center Inc.
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://pathway.org/"
                    id="listslink"
                  >
                    Pathways Youth & Family Services
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.pchas.org/"
                    id="listslink"
                  >
                    Prestbyterian Children's Homes & Services.
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://tfifamily.org/"
                    id="listslink"
                  >
                    Texas Family Initiative
                  </Link>
                </li>
              </ul>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 3 (Arlington)
              </Typography>
              <ul>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.awfc.org/"
                    id="listslink"
                  >
                    A World For Children{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.aheartwithhope.org/"
                    id="listslink"
                  >
                    A Heart with Hope Family Services, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://achservices.org/"
                    id="listslink"
                  >
                    ACH Child and Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.addyshope.org/ "
                    id="listslink"
                  >
                    Addy's Hope Adoption Agency{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.ococtexas.org/ "
                    id="listslink"
                  >
                    Advantage Adoptions{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.agapemanorhome.org/ "
                    id="listslink"
                  >
                    Agape Manor Homes{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.amazinggracecfs.org/ "
                    id="listslink"
                  >
                    {" "}
                    Amazing Grace Child and Family
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="#" id="listslink">
                    Anchor Family Services, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.angelheartkids.org/"
                    id="listslink"
                  >
                    {" "}
                    Angelheart
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://avcyfs.org/" id="listslink">
                    Antelope Valley Child, Youth & Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.arrow.org/ "
                    id="listslink"
                  >
                    Arrow Child and Family Ministries{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.assuringlove.org/page/page/8789769.htm "
                    id="listslink"
                  >
                    Assuring Love Child Placement Agency{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.azleway.org/ "
                    id="listslink"
                  >
                    Azleway Children's Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://benevolenthouse.org/"
                    id="listslink"
                  >
                    {" "}
                    Benevolent House Child Placing Agency
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://benchmarkfamilyservices.org/"
                    id="listslink"
                  >
                    Benchmark Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.buckner.org/foster-care-adoption "
                    id="listslink"
                  >
                    Buckner Children and Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.caregiversytls.org/"
                    id="listslink"
                  >
                    Caregivers Youth and Transitional Living Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://caringheartsforchildren.org/"
                    id="listslink"
                  >
                    {" "}
                    Caring Hearts for Children
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.ccdallas.org/ "
                    id="listslink"
                  >
                    {" "}
                    Catholic Charities of Dallas
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.cherishedimpressionscpa.com/ "
                    id="listslink"
                  >
                    {" "}
                    Cherished Impressions Child Placing Agency
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.cfiservices.org/ "
                    id="listslink"
                  >
                    Children and Family Institute{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://childrenhp.org/ "
                    id="listslink"
                  >
                    {" "}
                    Children's Hope Residential Services
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://coc.tempurl.host/ "
                    id="listslink"
                  >
                    Circles of Care{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://ckfamilyservices.org/ "
                    id="listslink"
                  >
                    CK Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.divinityfamilyservices.com/ "
                    id="listslink"
                  >
                    {" "}
                    Divinity Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://eckerd.org/" id="listslink">
                    Eckerd Youth Alternatives{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption "
                    id="listslink"
                  >
                    Gladney Center for Adoption{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Harbor of Hope, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="http://homeboundcpa.com/"
                    id="listslink"
                  >
                    {" "}
                    Homebound Child-Placing Agency
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.hoshiloh.com/"
                    id="listslink"
                  >
                    Hope Cottage, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.hoshiloh.com/ "
                    id="listslink"
                  >
                    {" "}
                    House of Shiloh Family Services
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://serenityrtc.org/"
                    id="listslink"
                  >
                    {" "}
                    Houston Serenity Place, Inc.
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.jpkids.org/"
                    id="listslink"
                  >
                    {" "}
                    Jonathan's Place
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Kids Grace Child Placement Agency
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="http://ww25.kingdomkidscpa.org/?subid1=20240123-1740-4272-9815-d87df97c10b1 "
                    id="listslink"
                  >
                    {" "}
                    Kingdom Kids
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://lifelinecfs.org/ "
                    id="listslink"
                  >
                    Lifeline Children and Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.lonestarsocialservices.com/"
                    id="listslink"
                  >
                    Lonestar Social Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://makeaway.info/"
                    id="listslink"
                  >
                    Make A Way, Inc. Child Placing Agency{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.mch.org/"
                    id="listslink"
                  >
                    {" "}
                    Methodist Children's Home
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://ocskids.care/ "
                    id="listslink"
                  >
                    Optimum Children's Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.passageofyouth.org/"
                    id="listslink"
                  >
                    Passage of Youth Family Center{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://pathway.org/"
                    id="listslink"
                  >
                    {" "}
                    Pathways Youth & Family Services
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://ourcommunity-ourkids.org/agencies/ "
                    id="listslink"
                  >
                    {" "}
                    Perfection Children Services
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.pchas.org/ "
                    id="listslink"
                  >
                    {" "}
                    Presbyterian Children's Homes & Services
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.ratcliffservices.org/ "
                    id="listslink"
                  >
                    {" "}
                    Ratcliff Youth and Family Services
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.reachcpatx.org/"
                    id="listslink"
                  >
                    REACH Child Placing Agency{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://refugehouse.org/ "
                    id="listslink"
                  >
                    Refuge House{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://safelifefoster.com/ "
                    id="listslink"
                  >
                    Safe Life Journey Child Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://tbhc.org/ " id="listslink">
                    {" "}
                    Texas Baptist Home For Children
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.bair.org/"
                    id="listslink"
                  >
                    {" "}
                    The Bair Foundation of Texas
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://adoptionsbygladney.com/ "
                    id="listslink"
                  >
                    {" "}
                    The Gladney Center for Adoption
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.tgifoundation.org/"
                    id="listslink"
                  >
                    {" "}
                    The Grandberry Intervention Foundation
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://tflife.org/ "
                    id="listslink"
                  >
                    Therapeutic Family Life{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Tomorrow's Children, Inc.
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://upbring.org/ "
                    id="listslink"
                  >
                    Upbring{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://unifycpa.org/ "
                    id="listslink"
                  >
                    Urban Neighborhood Initiative for Families and Youth (UNIFY){" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Vessels With Purpose{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://youthinview.org/ "
                    id="listslink"
                  >
                    Youth in View{" "}
                  </Link>
                </li>
              </ul>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 4 (Tyler)
              </Typography>
              {/* <ul>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    A World For Children
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Arrow Child and Family Ministries
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Azleway Children's Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    BCFS Health and Human Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Buckner Children and Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Christian Homes and Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Gladney Center for Adoption
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Grace Manor{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Living Alternatives{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Lonestar Social Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Methodist Children's Home
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Noble Children's Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    The Bair Foundation
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Upbring{" "}
                  </Link>
                </li>
              </ul> */}
              <ul>
                <li>
                  <a target="_blank" href="http://www.awfc.org">
                    A World For Children
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.arrow.org/">
                    Arrow Child and Family Ministries
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://azleway.org">
                    Azleway Children's Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://discoverbcfs.net/">
                    BCFS Health and Human Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.buckner.org">
                    Buckner Children and Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://christianhomes.com/">
                    Christian Homes and Family Services
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                  >
                    Gladney Center for Adoption
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.gracemanor.org/">
                    Grace Manor
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://livingalternatives.org/">
                    Living Alternatives{" "}
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://lonestarsocialservices.com/">
                    Lonestar Social Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.mch.org/">
                    Methodist Children's Home
                  </a>
                </li>
                <li>
                  {" "}
                  <a target="_blank" href="https://noblecs.org/">
                    Noble Children's Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.bair.org/">
                    The Bair Foundation
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.upbring.org/">
                    Upbring
                  </a>
                </li>
              </ul>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={4}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 5 (Beaumont)
              </Typography>
              {/* <ul>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Arrow Child and Family Ministries
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Azleway Children's Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Buckner Children and Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Faith 2 Faith Foster Care and Adoption{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Gladney Center for Adoption
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Grace Manor
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Methodist Children's Home
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Noble Children's Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Therapeutic Family Life{" "}
                  </Link>
                </li>
              </ul> */}
              <ul>
                <li>
                  <a target="_blank" href="http://www.arrow.org">
                    Arrow Child and Family Ministries
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://azleway.org">
                    Azleway Children's Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.beafamily.org">
                    Buckner Children and Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="">
                    Faith 2 Faith Foster Care and Adoption
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                  >
                    Gladney Center for Adoption
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.gracemanor.org/">
                    Grace Manor
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.mch.org/">
                    Methodist Children's Home
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://noblecs.org/">
                    Noble Children's Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.tflife.org">
                    Therapeutic Family Life
                  </a>
                </li>
              </ul>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={5}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 6 (Houston)
              </Typography>
              {/* <ul>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    1 Archangel Foster and Adoption Agency
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    1 Care Premier Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    A World For Children
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Accompanied by God's Love{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Agape Manor Home{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Alternatives in Motion (AIM){" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    America's Angels, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Arms Wide Adoption Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Arrow Child and Family Ministries
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Ascension Child and Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Azleway Children's Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Caring Adoptions{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Casa De Esperanza De Los Ninos{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Children of Diversity, Inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Circles of Care
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Circle of Living Hope{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Credence Village, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    DePelchin Children’s Center
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Family to Family Adoption Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Forever Families, Inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Gladney Center for Adoption{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Good Hearts Youth and Family Services, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Guardians Promise, LLC
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Hands of Healing Residential Treatment Center, Inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Have Haven{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Heart Of The Kids{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Homes with Hope{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Houston Achievement Place
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Houston Serenity Place, Inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Kidz 2 Kidz Child Placing Agency
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Kidz Thrive Child Placing Agency{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Light Accommodation Foundation{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Lonestar Social Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Loving Houston Adoption Agency{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Matching Hearts
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Methodist Children's Home{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Monarch Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Passion For Families{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Pathways Youth & Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Presbyterian Children's Homes & Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Rainbow of Love Adoption Agency, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Safe Haven Community Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Transitions for Tomorrow Child Placing Agency{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Trel's Home for Children
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Unity Children's Home
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    We R Champions Child Placing Agency
                  </Link>
                </li>
              </ul> */}
              <ul>
                <li>
                  <a target="_blank" href="https://www.1archangel.org/">
                    1 Archangel Foster and Adoption Agency
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.1carepremierservices.com/"
                  >
                    1 Care Premier Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.awfc.org">
                    {" "}
                    A World For Children
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.accompaniedbygodslove.com/"
                  >
                    Accompanied by God's Love
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.agapemanorhome.org/">
                    Agape Manor Home
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.aimadoptions.org/">
                    Alternatives in Motion (AIM)
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.americas-angels.com/">
                    America's Angels, Inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.armswideadoption.org/">
                    Arms Wide Adoption Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.arrow.org/">
                    Arrow Child and Family Ministries
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://ascensionchildandfamilyservices.com/"
                  >
                    Ascension Child and Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://azleway.org">
                    Azleway Children's Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.caringadoptions.org">
                    Caring Adoptions
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.casahope.org/">
                    Casa De Esperanza De Los Ninos
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://www.children-of-diversity.org/"
                  >
                    Children of Diversity, Inc.{" "}
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.circlesofcareinc.org">
                    Circles of Care
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.colh.org/">
                    Circle of Living Hope
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://credencevillage.org/">
                    Credence Village, Inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.depelchin.org">
                    DePelchin Children’s Center
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.fam2fam.org">
                    Family to Family Adoption Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.forfam.org/%20">
                    Forever Families, Inc.
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                  >
                    Gladney Center for Adoption
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://goodheartsyfs.org/">
                    Good Hearts Youth and Family Services, Inc.
                  </a>
                  &nbsp;&nbsp;{" "}
                </li>
                <li>
                  <a target="_blank" href="https://www.guardianspromise.com/">
                    Guardians Promise, LLC
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://handsofhealing.org/child-placement-agency/"
                  >
                    Hands of Healing Residential Treatment Center, Inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.havehaven.org">
                    Have Haven
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.heartofthekids.org/">
                    Heart Of The Kids
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.homeswithhope.org/">
                    Homes with Hope
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://hapkids.org/">
                    Houston Achievement Place
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://serenityrtc.org/">
                    Houston Serenity Place, Inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="">
                    Kidz 2 Kidz Child Placing Agency
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://kidzthrive.com/">
                    Kidz Thrive Child Placing Agency
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://lightaccommodation.org/">
                    Light Accommodation Foundation
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://www.lonestarsocialservices.com/"
                  >
                    Lonestar Social Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://lovinghoustonadoption.org/">
                    Loving Houston Adoption Agency
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://matchingheartstx.com/">
                    Matching Hearts
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.mch.org/">
                    Methodist Children's Home
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://monarchfamilyservices.com/">
                    Monarch Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.passionforfamilies.org/">
                    Passion For Families
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.pathway.org">
                    Pathways Youth &amp; Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.pchas.org">
                    Presbyterian Children's Homes &amp; Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.rainbowoflove.org/">
                    Rainbow of Love Adoption Agency, Inc.
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://safehavencommunityservices.org/"
                  >
                    Safe Haven Community Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="">
                    Transitions for Tomorrow Child Placing Agency
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.th4c.org/">
                    Trel's Home for Children
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.unitychildrenshome.com/">
                    Unity Children's Home
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.werchampionscpa.com/">
                    We R Champions Child Placing Agency
                  </a>
                </li>
              </ul>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={6}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 7 (Austin)
              </Typography>
              {/* <ul>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    4 Points Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    A World For Children
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Access Hope{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    America's Heart{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Angelheart{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Arrow Child and Family Ministries
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Benchmark Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Caring Hearts for Children
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Circles of Care{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Circle of Living Hope{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    CK Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Depelchin Children’s Center
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Divinity Family Services, Inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Foster Texas{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Gladney Center for Adoption{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Helping Hand Home Foster Care and Adoption Program
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Lighthouse Family Network{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Lonestar Social Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Pathways Youth & Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Renaissance Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    STARRY{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    The Bair Foundation
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    The Giocosa Foundation
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    The Payton Foundation
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    The Safe Alliance{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    The Settlement Home for Children{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Texas Foster Care and Adoption Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Therapeutic Family Life{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Upbring
                  </Link>
                </li>
              </ul> */}
              <ul>
                <li>
                  <a target="_blank" href="https://www.4points.life/">
                    4 Points Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.awfc.org">
                    A World For Children
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.fbfutures.org/">
                    Access Hope
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.americas-heart.org/">
                    America's Heart
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://angelheartkids.org/">
                    Angelheart
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.arrow.org">
                    Arrow Child and Family Ministries
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://www.benchmarkfamilyservices.org/"
                  >
                    Benchmark Family Services
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://www.caringheartsforchildren.org/"
                  >
                    Caring Hearts for Children
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.circlesofcareinc.org/">
                    Circles of Care
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.colh.org/">
                    Circle of Living Hope
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.ckfamilyservices.org">
                    CK Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.depelchin.org">
                    Depelchin Children’s Center
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.divinityfamilyservices.com/"
                  >
                    Divinity Family Services, Inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.fostertexas.net/">
                    Foster Texas
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                  >
                    Gladney Center for Adoption
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.helpinghandhome.org">
                    Helping Hand Home Foster Care and Adoption Program
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.lfnetwork.org/">
                    Lighthouse Family Network
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://www.lonestarsocialservices.com"
                  >
                    Lonestar Social Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.pathway.org">
                    Pathways Youth &amp; Family Services
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://www.rfsoftexas.com/index.html"
                  >
                    Renaissance Family Services
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://www.starry.org/Site/Services/Foster-Care-Adoption-Program/"
                  >
                    STARRY
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.bair.org">
                    The Bair Foundation
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.giocosa.org/">
                    The Giocosa Foundation
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.tpfcpa.org/about">
                    The Payton Foundation
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.safeaustin.org/">
                    The Safe Alliance
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.settlementhome.org/">
                    The Settlement Home for Children
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.texasfostercare.org/">
                    Texas Foster Care and Adoption Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.tflife.org">
                    Therapeutic Family Life
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.upbring.org/">
                    Upbring
                  </a>
                </li>
              </ul>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={7}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 8 (San Antonio)
              </Typography>
              {/* <ul>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    1 HOPE for Kids, Inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    4kids of South Texas{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    A World For Children
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Adoption Promises{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Angels' Crossing{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Baptist Child & Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Best Care 4 Kidz
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Boysville
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Benchmark Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Caring For Kids, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Compass Connection{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Circle of Living Hope{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Depelchin Children’s Center{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Divinity Family Services, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    El Paso Center for Children, Inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Emberhope, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Families Especial{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Familylink Treatment Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Hill Country Youth Ranch{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Kids First, Inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Lighthouse Foster Care & Adoption Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Methodist Children's Home
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Pathways Youth & Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Providence Place
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    San Antonio Foster Care and Adoption Services, Inc.{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    St. Jude’s Ranch for Children - SJRC Texas
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    St. Peter – St. Joseph Children’s Home
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Texas Foster Care and Adoption Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    The Children's Shelter
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    The Sanctuary Foster Care Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Therapeutic Family Life
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    TruLight127 Ministries, Inc{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Upbring
                  </Link>
                </li>
              </ul> */}
              <ul>
                <li>
                  <a target="_blank" href="https://1hopeforkids.org/">
                    1 HOPE for Kids, Inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.4kidsofstx.org/">
                    4kids of South Texas
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.awfc.org">
                    A World For Children
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.adoptionpromises.com/">
                    Adoption Promises
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="http://angelscrossingtx.org/index.html"
                  >
                    Angels' Crossing
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://bcfs.net/services">
                    Baptist Child &amp; Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://bestcare4kidz.com/">
                    Best Care 4 Kidz
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://boysvilletexas.org/foster-care-and-adoption/"
                  >
                    Boysville
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://benchmarkfamilyservices.org/"
                  >
                    Benchmark Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://cfkadopt.org/">
                    Caring For Kids, Inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://compassconnections.org/">
                    Compass Connection
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.colh.org/">
                    Circle of Living Hope
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.depelchin.org">
                    Depelchin Children’s Center
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.divinityfamilyservices.com/"
                  >
                    Divinity Family Services, Inc.{" "}
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://epccinc.org/">
                    El Paso Center for Children, Inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.emberhope.org/">
                    Emberhope, Inc.{" "}
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://familiesespecial.org/">
                    Families Especial{" "}
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://familylinkkids.com/">
                    Familylink Treatment Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://youth-ranch.org/">
                    Hill Country Youth Ranch
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://kidsfirstinc.net/">
                    Kids First, Inc.{" "}
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.lighthousefcas.org/">
                    Lighthouse Foster Care &amp; Adoption Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.mch.org/">
                    Methodist Children's Home
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.pathway.org">
                    Pathways Youth &amp; Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.provplace.org/">
                    Providence Place
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.sanantoniofostercare.org/"
                  >
                    San Antonio Foster Care and Adoption Services, Inc.{" "}
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.sjrctexas.org">
                    St. Jude’s Ranch for Children - SJRC Texas
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.stpjhome.org/">
                    St. Peter – St. Joseph Children’s Home
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.texasfostercare.org/">
                    Texas Foster Care and Adoption Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.childrensshelter.org">
                    The Children's Shelter
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.sanctuaryfostercare.org/"
                  >
                    The Sanctuary Foster Care Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.tflife.org">
                    Therapeutic Family Life
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.trulight127.org/">
                    TruLight127 Ministries, Inc
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.upbring.org/">
                    Upbring
                  </a>
                </li>
              </ul>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={8}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 9 (Big Spring, Midland, Odessa, San Angelo)
              </Typography>
              {/* <ul>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Arrow Child and Family Ministries
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    A World For Children
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Addy's Hope Adoption Agency{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Buckner Children and Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Children's Hope Residential Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    High Sky Children's Ranch
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Methodist Children's Home{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    New Horizons Ranch and Center
                  </Link>
                </li>
              </ul> */}
              <ul>
                <li>
                  <a target="_blank" href="https://www.arrow.org/">
                    Arrow Child and Family Ministries
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.awfc.org">
                    A World For Children
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.addyshope.org/">
                    Addy's Hope Adoption Agency
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.buckner.org">
                    Buckner Children and Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://childrenhp.org">
                    Children's Hope Residential Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://highsky.org/">
                    High Sky Children's Ranch
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.mch.org/">
                    Methodist Children's Home
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.newhorizonsinc.com">
                    New Horizons Ranch and Center
                  </a>
                </li>
              </ul>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={9}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 10 (El Paso)
              </Typography>
              {/* <ul>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    A World For Children
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Children's Hope Residential Services, Inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Circle of Living Hope
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Upbring
                  </Link>
                </li>
              </ul> */}
              <ul>
                <li>
                  <a target="_blank" href="http://www.awfc.org">
                    A World For Children
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.childrenhp.org">
                    Children's Hope Residential Services, Inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.colh.org/">
                    Circle of Living Hope
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.upbring.org/">
                    Upbring
                  </a>
                </li>
              </ul>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={10}>
            <Grid id="Lists" style={{ textAlign: "justify" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", paddingLeft: "25px" }}
              >
                REGION 11 (Corpus Christi)
              </Typography>
              {/* <ul>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    A World For Children{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Agape Harbor
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Arms Wide Adoption Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Arrow Child and Family Ministries{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Buckner Children and Family Services
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Children's Hope Residential Services, inc.
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Circles of Care
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    Circle of Living Hope
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Gladney Center for Adoption{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Homes in Harmony{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Pathways Youth & Family Services{" "}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    {" "}
                    The Burke Foundation
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="" id="listslink">
                    Upbring{" "}
                  </Link>
                </li>
              </ul> */}
              <ul>
                <li>
                  <a target="_blank" href="http://www.awfc.org">
                    A World For Children
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.agapeharbor.org/">
                    Agape Harbor
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.armswideadoption.org/">
                    Arms Wide Adoption Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.arrow.org">
                    Arrow Child and Family Ministries
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.beafamily.org">
                    Buckner Children and Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.childrenhp.org">
                    Children's Hope Residential Services, inc.
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.circlesofcareinc.org/">
                    Circles of Care
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.colh.org/">
                    Circle of Living Hope
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://adoptionsbygladney.com/i-want-to-adopt/foster-adoption"
                  >
                    Gladney Center for Adoption
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.harmonyfcaa.com/">
                    Homes in Harmony
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.pathway.org">
                    Pathways Youth &amp; Family Services
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://burkecenterforyouth.org/">
                    The Burke Foundation
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.upbring.org/">
                    Upbring
                  </a>
                </li>
              </ul>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>

      <Grid style={{ padding: "15px" }}>
        <Partners_accordings />
      </Grid>
    </div>
  );
}
