import ImageSlider from "../layout/imageslider";
import { Grid, Typography, Card, CardContent, CardHeader } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import FeedIcon from "@mui/icons-material/Feed";
import texasfamilyassociationimage from "../../assets/newimages/TFFA_logo.jpg";
import nefaimage from "../../assets/newimages/NFPA_logo.jpg";
import FostercareOverviewTab from "./fostercareOverviewTab";
import { Link } from "react-router-dom";
import "./fostercareoverview.css";
import ResponsiveMap from "./texasmap";
import Vendor from "./vendor";

const FosterCareOverview = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {/* <ImageSlider /> */}
      {/* Box cards view grid */}
      <Grid id="cards" style={{ backgroundColor: "white", padding: "10px" }}>
        <br/>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", color: "navy", textAlign: "center",textDecoration:"underline" }}
        >
          FOSTER CARE OVERVIEW
        </Typography>
        <br/>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <Grid item sm={12} xs={12} md={5} lg={5} style={{}}>
            <Card id="card0">
              <CardHeader
                style={{ backgroundColor: "rgb(53, 103, 178)" }}
              ></CardHeader>
              <CardContent>
                <Typography
                  style={{ fontSize: 20, textAlign: "left" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>More Information on Foster Care</b>
                </Typography>
                <ul style={{ textAlign: "left", lineHeight: "20px" }}>
                  <li style={{ lineHeight: "30px" }}>
                    <Link
                      to="/Fostervideo"
                      style={{ color: "blue", display: "flex" }}
                    >
                      <VideocamIcon style={{ color: "rgb(53, 103, 178)" }} />
                      View foster/adopt videos and Public Service Announcements
                    </Link>
                  </li>
                  <li style={{ lineHeight: "30px" }}>
                    <Link
                      target="_blank"
                      to="https://www.dfps.texas.gov/site_map/forms.asp"
                      style={{ color: "blue", display: "flex" }}
                    >
                      <FeedIcon style={{ color: "rgb(53, 103, 178)" }} /> Need a
                      Form? View List
                    </Link>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={12} md={5} lg={5}>
            <Card style={{}}>
              <CardHeader
                style={{ backgroundColor: "rgb(53, 103, 178)" }}
              ></CardHeader>
              <CardContent>
                <Typography
                  style={{ fontSize: 20, textAlign: "left" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>Foster Family Associations</b>
                </Typography>
                <ul style={{ textAlign: "left", lineHeight: "20px" }}>
                  <li style={{ lineHeight: "30px" }}>
                    <Link
                      target="_blank"
                      to="https://www.tffa.org/"
                      style={{ color: "blue", display: "flex" }}
                    >
                      <img
                        src={texasfamilyassociationimage}
                        alt="familyimage"
                        style={{ width: "30px", height: "30px" }}
                      />
                      Texas Foster Family Association
                    </Link>
                  </li>
                  <br />
                  <li style={{ lineHeight: "30px" }}>
                    <Link
                      target="_blank"
                      to="https://www.nfpaonline.org/"
                      style={{ color: "blue", display: "flex" }}
                    >
                      <img
                        src={nefaimage}
                        alt="NEFA image"
                        style={{ width: "30px", height: "30px" }}
                      />{" "}
                      National Foster Parent Association
                    </Link>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br />
        <div align="center">
          <div
            style={{ width: "90%", display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                textAlign: "left",
                textDecoration: "underline",
              }}
            >
              Regions -- Vendors
            </Typography>
            <br />
            <Vendor />
          </div>
        </div>
        <br />
        <ResponsiveMap />

        <Grid id="tabs1">
          <FostercareOverviewTab />
        </Grid>
      </Grid>
      {/* TabPanel Grid */}
    </div>
  );
};

export default FosterCareOverview;
