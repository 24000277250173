import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import "./overview_verticaltab";
import Content1 from "./overview_verticaltab_content_sm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const VerticalTabs1 = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            // width: "95%",
            // backgroundColor: "green",
          }}
        >
          {/* <Typography>TITLE</Typography> */}
          <Typography
            id="steps"
            style={{
              textAlign: "center",
              color: "white",
              fontWeight: "bold",
              backgroundColor: "rgb(63, 81, 181)",
              width: "100%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            variant="h5"
          >
            STEPS
          </Typography>

          <Grid
            className="grid1"
            style={{
              // flexGrow: 1,
              display: "flex",
              // backgroundColor:'red'
            }}
          >
            <Grid id="tabs" style={{ display: "flex" }}>
              {/* <Tabs
                    orientation="vertical"
                    // variant="scrollable"
                    value={value}
                    // onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider", }}
                >
                    <Tab
                    style={{
                        backgroundColor: "#3f51b5",
                        fontWeight: "bold",
                        fontSize: "18px",
                    
                        color: "white",
                    }}
                    label="I"
                    {...a11yProps(0)}
                    />
                    <Tab
                    style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        height: "61px",
                    
                        paddingBottom: "35px",
                    }}
                    label="II"
                    {...a11yProps(1)}
                    />
                    <Tab
                    style={{
                        backgroundColor: "#3f51b5",
                        fontSize: "18px",
                        fontWeight: "bold",
                    
                        color: "white",
                    }}
                    label="III"
                    {...a11yProps(2)}
                    />
                    <Tab
                    style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                    
                        height: "62px",
                        paddingBottom: "35px",
                    }}
                    label="IV"
                    {...a11yProps(3)}
                    />
                    <Tab
                    style={{
                        backgroundColor: "#3f51b5",
                        fontWeight: "bold",
                        fontSize: "18px",
                        paddingBottom: "35px",
                    
                        height: "61px",
                        color: "white",
                    }}
                    label="V"
                    {...a11yProps(4)}
                    />
                    <Tab
                    style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                    
                        height: "137px",
                        paddingBottom: "55px",
                    }}
                    label="VI"
                    {...a11yProps(5)}
                    />
                </Tabs> */}

              <Tabs
                id="tabs"
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  // borderColor: "divider",
                  border: "1px solid black",
                  borderRightColor: "divider",
                }}
                // style={{}}
              >
                <Tab
                  id="vertical_tabs"
                  style={{
                    backgroundColor: "skyblue",
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "left",
                    paddingRight: "50px",
                    color: "black",
                  }}
                  label="Recruitment"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "left",
                  }}
                  label="Certification Process"
                  {...a11yProps(1)}
                />
                <Tab
                  style={{
                    backgroundColor: "skyblue",
                    fontSize: "15px",
                    fontWeight: "bold",
                    textAlign: "left",
                    paddingRight: "30px",
                    color: "black",
                  }}
                  label="Certifications"
                  {...a11yProps(2)}
                />
                <Tab
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "15px",
                  }}
                  label="Foster Care Reimbursements"
                  {...a11yProps(3)}
                />
                <Tab
                  style={{
                    backgroundColor: "skyblue",
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "left",
                    color: "black",
                  }}
                  label="Training and Support"
                  {...a11yProps(4)}
                />
                <Tab
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",

                    textAlign: "left",
                  }}
                  label="Adoption worker's support responsibilities include:"
                  {...a11yProps(5)}
                />
              </Tabs>
            </Grid>

            <Grid id="tabs2" style={{ border: "1px solid black",marginTop:"20px" }}>
              <TabPanel value={value} index={0}>
                <Grid style={{ textAlign: "left" }}>
                  <Typography style={{ display: "flex", textAlign: "justify" }}>
                    <DoubleArrowIcon />
                    TDFPS conducts recruitment on a statewide basis through the
                    support of public service announcements, civic and community
                    group meetings, and distribution of printed materials. All
                    recruitment efforts involve the use of TDFPS staff, foster
                    and adoptive parents and other volunteers who have
                    recruitment experience. Each region supports recruitment of
                    potential foster and adoptive parents through local
                    activities, too.
                  </Typography>
                  <br />
                  <Typography style={{ display: "flex", textAlign: "justify" }}>
                    <DoubleArrowIcon />
                    TDFPS has established processes to identify and track those
                    families and individuals interested in providing foster care
                    and adoption services The public through inquiries for
                    additional information exhibits interest in fostering and
                    adopting. Most inquiries come from two sources. The first is
                    through telephone contact to the statewide 800-inquiry
                    number (1-800-233-3405) or to local TDFPS offices. The
                    second form of inquiries is through public information
                    meetings or orientation sessions. Additional information is
                    provided to prospective families and individuals who inquire
                    about the foster care and adoption programs.
                  </Typography>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid style={{ textAlign: "left" }}>
                  <Typography style={{ display: "flex", textAlign: "justify" }}>
                    <DoubleArrowIcon />
                    The general requirements to be a foster or adoptive parent
                    are:
                  </Typography>

                  <Typography style={{}} s>
                    <ul>
                      <li>Must be at least 21 years of age,</li>
                      <li>Can be married or single,</li>
                      <li>
                        Be willing to attend pre-service training and
                        participate in a home study, and
                      </li>
                      <li>
                        Be willing to foster or adopt children with backgrounds
                        of abuse and neglect.
                      </li>
                    </ul>
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      display: "flex",
                      textAlign: "justify",
                    }}
                  >
                    <DoubleArrowIcon />
                    Prospective parents must formally apply in order to foster
                    or adopt children in TDFPS custody. This application is
                    normally made after attending an orientation (public
                    information meeting) and during the pre-service training
                    process. All prospective foster and adoptive parents attend
                    approximately ten weeks of pre-service training known as
                    PRIDE (Parents Resource for Information Development
                    Education). This training is normally done in a group
                    setting using experienced foster and/or adoptive parents as
                    co-trainers with TDFPS staff. The PRIDE curriculum covers
                    topics, such as, attachment, loss, behavior problems and
                    management, sexual abuse, and birth family connections. The
                    training serves two purposes: to educate potential parents
                    about foster care and adoption, and to mutually assess the
                    applicant’s appropriateness to care for children in TDFPS
                    custody.
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      display: "flex",
                      textAlign: "justify",
                    }}
                  >
                    <DoubleArrowIcon />
                    TDFPS staff begins the process of checking backgrounds on
                    applicants and all adult members of the home after the
                    signing of appropriate releases. Background checks include
                    criminal history reports from the Texas Department of Public
                    Safety (DPS) and child abuse checks through TDFPS computer
                    database known as CAPS. Background checks can also include
                    criminal history and child abuse reports from local law
                    enforcement, the FBI, and local CPS offices in Texas and
                    throughout the nation.
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      display: "flex",
                      textAlign: "justify",
                    }}
                  >
                    <DoubleArrowIcon />
                    Successful completion of pre-service training begins the
                    home study process. The home study is an in-depth assessment
                    of the family, which includes interviewing all adults and
                    children in the home. The home study is also used in
                    assessing the home for safety and available space. All homes
                    must meet standards enumerated in the Minimum Standards and
                    Guidelines for Child-Placing Agencies.
                  </Typography>
                  <br />
                  <Typography style={{ display: "flex", textAlign: "justify" }}>
                    <DoubleArrowIcon />
                    The home study is designed to elicit information on a
                    variety of issues including: motivation for wanting to
                    foster or adopt; health status; marital and family
                    relationships; applicants feelings about their own childhood
                    and parents including any history of abuse and/or neglect;
                    opinions about discipline; sensitivity about abused and
                    neglected children; sensitivity towards birth families;
                    sensitivity about different socioeconomic, ethnic, and
                    cultural groups in relation to their ability to maintain the
                    ethnic identity of a child from a different background;
                    feelings about maintaining sibling relationships;
                    expectations of children in foster care; family’s ability to
                    work with specific kinds of behavior and backgrounds; and
                    documentation on the number, age and sex for whom the home
                    is approved. Applicants are informed by TDFPS staff whether
                    or not their home was approved and the reasons for the
                    decision.
                  </Typography>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid style={{ textAlign: "left" }}>
                  <Typography style={{ display: "flex" }}>
                    <DoubleArrowIcon />
                    Those families, which have successfully completed the TDFPS
                    screening process and are able to meet the needs of the
                    children in TDFPS custody, will be approved. Families
                    wishing to provide adoption services will be approved to
                    provide adoption services only. Families can also be
                    approved to provide foster care only and/or foster care and
                    adoption services. Foster care certifications are:
                  </Typography>
                  <br />
                  <Typography>
                    <ul>
                      <li style={{ textAlign: "justify" }}>
                        Basic Foster Family- A private home that provides foster
                        care to children in TDFPS conservatorship and is
                        verified to care for no more than six children,
                        including the children of the foster family and children
                        for whom the family provides regular part-time day care.
                        These homes can provide care for children up to and
                        including a level of care II.
                      </li>
                      <li style={{ textAlign: "justify" }}>
                        Habilitative- A private home verified to provide
                        specialized services to children who have mental
                        retardation, developmental disabilities, or severe
                        developmental delay. These homes can provide care for
                        children up to and including a level of care IV.
                      </li>
                      <li style={{ textAlign: "justify" }}>
                        Therapeutic- A private home verified to provide
                        specialized services to children with serious emotional
                        disturbance and/or behavioral problems in a family
                        setting. These homes can provide care for children up to
                        and including a level of care IV.
                      </li>
                      <li style={{ textAlign: "justify" }}>
                        Primary Medical Needs - A private home verified to
                        provide specialized services to children who are
                        medically fragile. These homes can provide care for
                        children up to and including a level of care IV.
                      </li>
                      <li style={{ textAlign: "justify" }}>
                        Group Foster Family - A private home that provides
                        foster care to children in TDFPS conservatorship and is
                        verified to care for no more than twelve children,
                        including the children of the foster family and children
                        for whom the family provides regular part-time day care.
                        These homes can provide care for children up to and
                        including a level of care IV.
                      </li>
                    </ul>
                  </Typography>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Grid style={{ textAlign: "left" }}>
                  <Typography
                    style={{
                      display: "flex",
                      textAlign: "justify",
                    }}
                  >
                    <DoubleArrowIcon />
                    The monthly reimbursement provided to foster families is a
                    combination of federal, state, or county funds. It is for
                    child care-related costs such as food, clothing, recreation,
                    transportation, and housing cost. In extraordinary
                    circumstances, special rates may be reimbursed to foster
                    families who care for children with exceptional needs, as in
                    the case of medical needs that require providing a child
                    with specialized care, food, clothing, or equipment.
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      display: "flex",
                      textAlign: "justify",
                    }}
                  >
                    <DoubleArrowIcon />
                    The Health and Human Services Commission (HHSC) developed
                    rates for the 24-hour residential child-care reimbursements
                    (foster care) program operated by DFPS. HHSC authorized DFPS
                    to implement these recommended payment rates effective
                    September 1, 2009.
                  </Typography>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Grid style={{ textAlign: "left" }}>
                  <Typography
                    style={{
                      display: "flex",
                      textAlign: "justify",
                    }}
                  >
                    <DoubleArrowIcon />
                    Minimum Standards and Guidelines for Child-Placing Agencies
                    and CPS policy require verified basic foster family units to
                    complete 20 hours of additional in-service training on a
                    yearly basis. Non-group foster family units providing
                    therapeutic care must complete at least 50 hours of annual
                    in-service training. TDFPS also requires at least the
                    primary caretaker in the foster family to be certified in
                    infant/child CPR and first aid training prior to home
                    certification and must be updated as required to maintain
                    certification. Training requirements are similar for group
                    foster homes with the following exception: Training
                    requirements extend to all foster parents in-group homes.
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      display: "flex",
                      textAlign: "justify",
                    }}
                  >
                    <DoubleArrowIcon />
                    Minimum Standards and Guidelines for Child-Placing Agencies
                    or TDFPS policy do not require in-service training for
                    approved adoptive parents. Approved adoptive parents can
                    access additional training through post adoption services,
                    the Council on Adoptable Children (COAC), and TDFPS.
                  </Typography>
                  <br />
                  <Typography style={{ display: "flex", textAlign: "justify" }}>
                    <DoubleArrowIcon />
                    Approved foster and adoptive families are assigned workers
                    who help support the family. The foster home development
                    worker’s support responsibilities include:
                  </Typography>

                  <Typography>
                    <ul>
                      <li>Presentation of children for possible placement;</li>
                      <li>
                        Helping the family maintain minimum standard and policy
                        requirements;
                      </li>
                      <li>
                        Provide support and training based on the families
                        strengths and needs;
                      </li>
                      <li>
                        Maintain regular telephone contact and visiting families
                        in their homes at least quarterly;
                      </li>
                      <li>
                        Provide support to foster parents in the event of an
                        investigation;
                      </li>
                      <li>
                        Ensure foster families are members of the foster care
                        team;
                      </li>
                      <li>Help foster parents obtain needed services;</li>
                      <li>
                        Be involved in supportive services such as leading
                        support groups, working on statewide committees,
                        attending foster parent association meetings, and
                        implementing foster parent training; and
                      </li>
                      <li>
                        provide support and training to foster families in the
                        area of developing and maintaining a mentoring
                        relationship with birth parents.
                      </li>
                    </ul>
                  </Typography>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <Grid style={{ textAlign: "left" }}>
                  <Typography>
                    <ul>
                      <li>
                        Presenting all de-identified information regarding the
                        child to the adoptive family if they are selected for a
                        child and assisting the family in determining if the
                        child should be placed with their family for purpose of
                        adoption;
                      </li>
                      <li>
                        Developing an adoption case plan with the family and
                        child, if older than three years of age, to address the
                        needs of the child and family in the placement;
                      </li>
                      <li>
                        Meeting with the child and adoptive family on a monthly
                        basis, at a minimum, for the first six months of
                        placement to assess the attachment to each other,
                        address issues which may arise, assist the family in
                        obtaining and utilizing services, and assist the family
                        in finalizing the adoption;
                      </li>
                      <li>
                        Informing the adoptive family of their expectations,
                        roles, and responsibilities;
                      </li>
                      <li>
                        Informing adoptive families of training available
                        through TDFPS or through other means;
                      </li>
                      <li>
                        Providing a copy of the child's de-identified case
                        record to the adoptive family at the time of
                        consummation or within 30 days after the hearing;
                      </li>
                      <li>
                        Informing the adoptive family of adoption assistance and
                        post adoption services and assisting the adoptive
                        parents in completing the application process prior to
                        consummation of the adoption;
                      </li>
                      <li>
                        Assisting the adoptive family in finalizing the adoption
                        within 6 to 12 months after placement if there are no
                        significant issues which would be contrary to this
                        process;
                      </li>
                      <li>
                        Providing information and clarification to the adoptive
                        family of all documents and forms that are signed by the
                        family.
                      </li>
                    </ul>
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      display: "flex",
                      textAlign: "justify",
                    }}
                  >
                    <DoubleArrowIcon />
                    TDFPS encourages and supports the formation of foster parent
                    associations and local chapters of COAC. These independent
                    organizations help provide additional support to foster and
                    adoptive families by increasing the community’s awareness
                    and responsibility toward children in care; improving
                    services to children and foster/adoptive families; enhancing
                    the public image of foster and adoptive parents; and
                    providing encouragement, resources, and support for each
                    other. TDFPS can provide financial support through contracts
                    to these groups if they are incorporated as private,
                    nonprofit organizations.
                  </Typography>
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
          <Grid id="grid2">
            <Content1 />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default VerticalTabs1;
