import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  // ListItemIcon,
  ListItemText,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Slide,
  Button,
  Modal,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ImageSlider from "../layout/imageslider";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import HomeContent from "./homecontent";
import "./home.css";
import { Link, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ChildCareimage from "../../assets/img/child_image.png";
import React, { useEffect, useState } from "react";
import SimpleFade from "./toggle";
import Ticker from "react-ticker";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import Diversity3Icon from "@mui/icons-material/Diversity3";
// import Marquee from "react-simple-marquee";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Marquee from "react-fast-marquee";
import ImageSlider1 from "../layout/imageslider1";
import FoundationIcon from "@mui/icons-material/Foundation";
const Home = () => {
  const Styles = {
    youtubegrid: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      backgroundColor: "#3567b20f",
    },
    youtubegrid1: {
      padding: "20px",
      display: "flex",
      justifyContent: "flex-start",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "#3567b20f",
    },
    contentgrid0: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    contentgrid1: {
      padding: "25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "justify",
    },
    contentimage: {
      opacity: "1",
      transition: "opacity 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      transform: "999ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      width: "100%",
      boxShadow: "0px 0px 20px black",
    },
    commoncss0: {
      padding: "25px ",
    },
    listicon: {
      color: "rgba(0, 0, 0, 0.54)",
      minWidth: "25px",
      paddingRight: "10px",
    },
    listtext: {
      fontSize: "2rem",
      fontWeight: "400",
      color: "rgb(117, 117, 117)",
      textAlign: "justify",
      wordSpacing: "normal",
      letterSpacing: "normal",
      WebkitHyphens: "auto",
      MozHyphens: "auto",
      msHyphens: "auto",
      hyphens: "auto",
    },
    cardsgrid: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      marginTop: "50px",
    },
    card: {
      lineHeight: "1.5",
    },

    secondlisttext: {
      textAlign: "justify",
      wordSpacing: "normal",
      letterSpacing: "normal",
      WebkitHyphens: "auto",
      MozHyphens: "auto",
      msHyphens: "auto",
      hyphens: "auto",
    },
    imagediv: {
      display: "flex",
      gap: "10px",
      fontSize: "1.1rem",
      fontWeight: "400",
      color: "rgb(117, 117, 117)",
      textAlign: "justify",
      wordSpacing: "normal",
      letterSpacing: "normal",
      WebkitHyphens: "auto",
      MozHyphens: "auto",
      msHyphens: "auto",
      hyphens: "auto",
    },
    style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 2,
    },
  };

  function Ticker1() {
    return (
      <div style={{ width: "100%" }}>
        <Marquee
          style={{
            height: 40,
            color: "white",
            textDecoration: "none",
            backgroundColor: "#3567B2 ",
            cursor: "pointer",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
          pauseOnHover={true}
        >
          <Typography style={{ fontWeight: "bold" }}>
            In 2021 in Bexar County, there were 17,000 investigations of
            reported child abuse, 9000 of these were for neglect. Last year half
            of the fatalities of children were linked to neglect.{" "}
          </Typography>
        </Marquee>
      </div>
    );
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleCloseButton = () => {
    setOpen(false); // Use this function for the close button inside the modal
  };
  const theme = useTheme();
  const xsView = useMediaQuery(theme.breakpoints.down("xs"));
  const navigate = useNavigate();
  return (
    <div className="root" align="center">
     
      <Grid>
        <Grid>
          <Grid>
            
            <Grid>
              <Typography
                variant="h5"
                style={{
                  fontStyle: "italic",
                  color: "#255aad",
                  padding: "5px",
                }}
              >
                "Adopting one child won’t change the world, but for that one
                child, the world will change."
              </Typography>
            </Grid>
            <br />
            <Grid style={{ width: "100%" }}>
              {/* <ImageSlider /> */}
              <ImageSlider1 />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            lg={10}
            md={10}
            sm={9}
            xs={11}
            style={{ backgroundColor: "" }}
          >
            {/* <Grid container sx={Styles.youtubegrid}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                id="grid0"
                style={Styles.commoncss0}
              >

              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                id="grid0"
                style={Styles.commoncss0}
              >

              </Grid>
            </Grid> */}
           
            {/* <Grid className="ticker">
              <Grid style={{ width: "90%", display: "flex" }}>
                <Grid
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "red",
                    color: "white",
                    padding: "10px",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  NEWS
                </Grid>
                <Ticker1 />
              </Grid>
            </Grid>
            <br /> */}

            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                className="countgrid"
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    color: "#255aad",
                    textAlign: "center",
                  }}
                >
                  Impact Of Foster Care Services in 2023
                </Typography>
                <br />

                <Grid className="youthgrid" style={{}}>
                  <Grid id="ourservices">
                    <Grid>
                      <Typography
                        fontWeight="bold"
                        style={{ fontSize: "1.7rem" }}
                      >
                        {(819338).toLocaleString()}
                      </Typography>
                      <Divider
                        style={{
                          height: "1px",
                          width: "35%",
                          backgroundColor: "#255aad",
                        }}
                      />
                      <Typography
                        style={{
                          padding: "5px",
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                        }}
                      >
                        Intake to CPS about Allegations or Abuse and Neglect of
                        kids
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <br /> */}
                  <Grid id="ourservices1">
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      style={{ fontSize: "1.7rem" }}
                    >
                      {(17457).toLocaleString()}
                    </Typography>
                    <Divider
                      style={{
                        height: "1px",
                        width: "35%",
                        backgroundColor: "#255aad",
                      }}
                    />
                    <Typography
                      style={{
                        padding: "5px",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                      }}
                    >
                      Current Youth in Texas Foster Care System
                    </Typography>
                  </Grid>
                  {/* <br /> */}
                  <Grid id="ourservices">
                    <Grid>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        style={{ fontSize: "1.7rem" }}
                      >
                        {(4182).toLocaleString()}
                      </Typography>
                      <Divider
                        style={{
                          height: "1px",
                          width: "35%",
                          backgroundColor: "#255aad",
                        }}
                      />
                      <Typography
                        style={{
                          padding: "5px",
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                        }}
                      >
                        Youth adopted from Texas Foster Care System
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* <br /> */}
                  <Grid id="ourservices">
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      style={{ fontSize: "1.7rem" }}
                    >
                      {(874).toLocaleString()}
                    </Typography>
                    <Divider
                      style={{
                        height: "1px",
                        width: "35%",
                        backgroundColor: "#255aad",
                      }}
                    />
                    <Typography
                      style={{
                        padding: "5px",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                      }}
                    >
                      Aged Out Foster Care Youth
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              className="contentgrid"
              style={{
                boxShadow: "2px 2px 11px 1px rgb(90 78 80 / 55%)",
                // padding: "30px",
                width: "90%",
              }}
            >
              {/* Content Grid*/}
              <Grid item lg={7} md={7} sx={Styles.contentgrid0}>
               
                <Grid item style={{}}>
                  <Grid style={Styles.commoncss0}>
                    <Typography
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        color: "#255aad",
                        textAlign: "center",
                      }}
                    >
                      Why Do We Need Foster Care in Texas ?
                    </Typography>
                    <List
                      component="div"
                      aria-label="home content"
                      style={{ px: "8px" }}
                    >
                      {HomeContent.mainContent.map((item) => (
                        <ListItem
                          component="div"
                          key={item.key}
                          style={{ padding: 0, display: "flex" }}
                        >
                          <ListItemText>
                            <Grid style={Styles.imagediv}>
                              <PlaylistAddCheckCircleIcon />
                              {item.value}
                            </Grid>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>

              {/*table */}

              <Grid item lg={5} md={5} id="table">
                <Typography
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                    color: "#255aad",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Youth in Texas Foster Care System per Region
                </Typography>
                <br />
                <Grid style={{ padding: "10px", paddingTop: "0px" }}>
                  <Table size="small" className="table">
                    <TableHead style={{ backgroundColor: "cyan" }}>
                      <TableRow style={{ fontWeight: "bold" }}>
                        <TableCell style={{ fontWeight: "bold" }}>
                          S.no
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Region Name
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>1.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={() =>
                            window.open(
                              "https://www.dfps.texas.gov/CBC/community-areas/region-1.asp"
                            )
                          }
                          title="click here to know more about Region1"
                        >
                          Region 1
                        </TableCell>
                        <TableCell>{(1048).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>2.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={() =>
                            window.open(
                              "https://www.dfps.texas.gov/CBC/community-areas/region-2.asp"
                            )
                          }
                           title="click here to know more about Region2"
                        >
                          {" "}
                          Region 2
                        </TableCell>
                        <TableCell>{(1013).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>3.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={() =>
                            window.open(
                              "https://www.dfps.texas.gov/CBC/community-areas/region-3w.asp"
                            )
                          }
                           title="click here to know more about Region3"
                        >
                          Region 3
                        </TableCell>
                        <TableCell>{(3717).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>4.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={() =>
                            window.open(
                              "https://www.dfps.texas.gov/CBC/community-areas/region-4.asp"
                            )
                          }
                           title="click here to know more about Region4"
                        >
                          Region 4
                        </TableCell>
                        <TableCell>{(1137).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>5.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={() =>
                            window.open(
                              "https://www.dfps.texas.gov/CBC/community-areas/region-5.asp"
                            )
                          }
                           title="click here to know more about Region5"
                        >
                          Region 5
                        </TableCell>
                        <TableCell>{(818).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>6.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={(e) => navigate("/region4,5,6")}
                           title="click here to know more about Region6"
                        >
                          Region 6
                        </TableCell>
                        <TableCell>{(2377).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>7.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={(e) => navigate("/region7")}
                           title="click here to know more about Region7"
                        >
                          Region 7
                        </TableCell>
                        <TableCell>{(2362).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>8.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={(e) => navigate("/region8")}
                           title="click here to know more about Region8"
                        >
                          Region 8
                        </TableCell>
                        <TableCell>{(2724).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>9.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={(e) => navigate("/region9")}
                           title="click here to know more about Region9"
                        >
                          Region 9
                        </TableCell>
                        <TableCell>{(443).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>10.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={(e) => window.open("/region10")}
                           title="click here to know more about Region10"
                        >
                          Region 10
                        </TableCell>
                        <TableCell>{(262).toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>11.</TableCell>
                        <TableCell
                          className="tablecell"
                          onClick={(e) => navigate("/region11")}
                           title="click here to know more about Region11"
                        >
                          Region 11
                        </TableCell>
                        <TableCell>{(1556).toLocaleString()}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <br />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              className="contentgrid"
              style={{
                boxShadow: "2px 2px 11px 1px rgb(90 78 80 / 55%)",
                // padding: "30px",
                width: "90%",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  marginTop: "10px",
                }}
                variant="h5"
              >
                EVENTS CALENDAR
              </Typography>
              <iframe
                title="Unique Title for Accessibility"
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FChicago&bgcolor=%23ffffff&mode=AGENDA&title=Texas%20Foster%20Care&src=ZjFlYTU4ODZmNmY3ZWVkMmU1MWJkZjk5YjI5ZDBiYWU2MTkxNDliMWY1ODhlNGVmODU4NDQ0NGQ5NTg5YjBlN0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=NWkzcTEwZmpjanFlY2VqazJsbWhpdjJydHIydDBnM2hAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%237986CB&color=%23D50000"
                width="100%"
                height="350"
                frameBorder="0"
                style={{
                  padding: "20px",
                }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
              />
            </Grid>
            <br />
            <Grid
              className="resourcesgrid"
              style={{
                display: "flex",
                boxShadow: "2px 2px 11px 1px rgb(90 78 80 / 55%)",
                width: "90%",
              }}
            >
              {/* resources grid 1 */}

              <Card style={{ width: "100%" }}>
                <CardActionArea>
                  <CardContent>
                    <Grid className="youtubegrid" sx={Styles.youtubegrid}>
                      <Grid style={{ marginBottom: "40px" }}>
                        <Typography id="resources_title">
                          Resources for Potential Foster Parent
                        </Typography>
                      </Grid>

                      <Grid
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "50px",
                          flexWrap: "wrap",
                          // marginBottom: "2rem",
                        }}
                      >
                        <Link className="icons" to="/fostercareOverview">
                          <Grid id="iconsgrid">
                            <Typography
                              id="resourcesHead"
                              fontWeight="bold"
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "0px",
                              }}
                            >
                              Foster Care
                            </Typography>

                            <VolunteerActivismIcon
                              style={{ fontSize: "3rem" }}
                            />
                          </Grid>
                        </Link>{" "}
                        <Grid id="iconsgrid" style={{ padding: "10px" }}>
                          {" "}
                          <Link className="icons2" to="/AdoptionOverview">
                            <Grid
                              style={{
                                display: "flex",
                                // justifyContent:"flex-start",
                                width: "100%",
                                gap: "10px",
                              }}
                            >
                              <FamilyRestroomIcon
                                style={{ fontSize: "2rem" }}
                              />
                              <Typography
                                id="resourcesHead"
                                fontWeight="bold"
                                style={{
                                  paddingTop: "10px",
                                  paddingBottom: "0px",
                                  textAlign: "left",
                                  // fontSize: "1.1rem",
                                }}
                              >
                                Adoption
                              </Typography>
                            </Grid>
                          </Link>
                          <br />
                          <Link className="icons2" to="/adoptionIncentivesview">
                            <Grid
                              style={{
                                display: "flex",
                                gap: "5px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PriceCheckIcon style={{ fontSize: "2rem" }} />
                              <Typography
                                id="resourcesHead"
                                fontWeight="bold"
                                style={{
                                  paddingTop: "10px",
                                  paddingBottom: "0px",
                                  textAlign: "left",

                                  // fontSize: "1.1rem",
                                }}
                              >
                                Adoption Incentives
                              </Typography>
                            </Grid>
                          </Link>
                        </Grid>
                        {/* <Link className="icons" to="/adoptionIncentivesview">
                          <Grid
                            style={{
                              width: "150px",
                              height: "150px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              // backgroundColor: "white",
                              boxShadow: "0px 0px 5px 1px black",marginTop:"10px",
                              color: "black",
                            }}
                          >
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "0px",
                              }}
                            >
                              Adoption Incentives
                            </Typography>

                            <PriceCheckIcon style={{ fontSize: "3rem" }} />
                          </Grid>
                        </Link> */}
                        <Link className="icons" to="/agencylist">
                          <Grid id="iconsgrid">
                            <Typography
                              id="resourcesHead"
                              fontWeight="bold"
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "0px",
                              }}
                            >
                              Agency Directory
                            </Typography>

                            <ManageSearchIcon style={{ fontSize: "3rem" }} />
                          </Grid>
                        </Link>
                        <Link className="icons" to="/helpfulresources">
                          {" "}
                          <Grid id="iconsgrid">
                            <Typography
                              id="resourcesHead"
                              fontWeight="bold"
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "0px",
                              }}
                            >
                              Helpful Resources
                            </Typography>
                            <Diversity3Icon style={{ fontSize: "3rem" }} />
                          </Grid>
                        </Link>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
              {/* resources grid 2 */}

              <Card style={{ width: "100%" }}>
                <CardActionArea>
                  <CardContent>
                    <Grid className="youtubegrid" sx={Styles.youtubegrid1}>
                      <Grid style={{ marginBottom: "40px" }}>
                        <Typography id="resources_title">
                          Resources to Start a CPA and Accountability
                        </Typography>
                      </Grid>

                      <Grid
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "50px",
                          flexWrap: "wrap",
                          // marginBottom: "2rem",
                        }}
                      >
                        <Link className="icons" to="/generalInformation">
                          {" "}
                          <Grid id="iconsgrid">
                            <Typography
                              id="resourcesHead"
                              fontWeight="bold"
                              style={{ paddingBottom: "0px" }}
                            >
                              How to start a Child Placing Agency
                            </Typography>
                            <BusinessCenterOutlinedIcon
                              style={{ fontSize: "3rem" }}
                            />
                          </Grid>
                        </Link>

                        <Link className="icons" to="/taxService">
                          {" "}
                          <Grid id="iconsgrid">
                            <Typography
                              id="resourcesHead"
                              fontWeight="bold"
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "0px",
                              }}
                            >
                              Accounting Needs
                            </Typography>
                            <ChecklistOutlinedIcon
                              style={{ fontSize: "3rem" }}
                            />
                          </Grid>
                        </Link>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card style={{ width: "100%" }}>
                <CardActionArea>
                  <CardContent>
                    <Grid className="youtubegrid" sx={Styles.youtubegrid1}>
                      <Grid style={{ marginBottom: "40px" }}>
                        <Typography id="resources_title">
                          Resources for the Foster Youth
                        </Typography>
                      </Grid>

                      <Grid
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "50px",
                          flexWrap: "wrap",
                          // marginBottom: "2rem",
                        }}
                      >
                        <Link
                          className="icons"
                          to="https://www.dfps.texas.gov/Child_Protection/Youth_and_Young_Adults/Transitional_Living/transition_centers.asp"
                          target="_blank"
                        >
                          {" "}
                          <Grid id="iconsgrid">
                            <Typography
                              id="resourcesHead"
                              fontWeight="bold"
                              style={{ paddingBottom: "0px" }}
                            >
                              Transition Centers
                            </Typography>
                            <FoundationIcon style={{ fontSize: "3rem" }} />
                          </Grid>
                        </Link>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            {/* cards grid */}

            <Grid container className="cardsgrid" style={Styles.cardsgrid}>
              <Grid item style={{ width: "100%" }}>
                <Grid container>
                  {HomeContent.mainReadCards.map((card) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className="cardsItems"
                      key={card.key}
                      style={{}}
                    >
                      <Slide
                        direction="right"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        timeout={2000}
                      >
                        <Card
                          className="card"
                          key={card.key}
                          style={Styles.card}
                        >
                          <CardActionArea
                            style={{ backgroundColor: "#3567b20f" }}
                          >
                            <CardMedia className="media" title={card.title}>
                              {card.cardIcon}
                            </CardMedia>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                                className="cardTitle"
                                style={{
                                  color: "#d04e53",
                                  fontWeight: "bold",
                                }}
                              >
                                {card.title}
                              </Typography>
                              <List component="div" aria-label="card-content">
                                {card.cardContentList.map((listItem) => (
                                  <ListItem
                                    component="div"
                                    className="listLinks"
                                    key={listItem.key}
                                  >
                                    <ListItemText
                                      // primary={listItem.value}
                                      style={Styles.secondlisttext}
                                    >
                                      <Grid
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                        }}
                                      >
                                        {listItem.value}
                                      </Grid>
                                    </ListItemText>
                                  </ListItem>
                                ))}
                              </List>
                              <List
                                component="div"
                                aria-label="card-secondary-content"
                                className="secList"
                              >
                                {card.cardContentSecList?.map((secListItem) => (
                                  <ListItem
                                    component="div"
                                    className="listLinks"
                                    key={secListItem.key}
                                  >
                                    {secListItem.isRedirect ? (
                                      <Link
                                        to={`/${secListItem.redirectLink}`}
                                        className="secListRedirects"
                                        style={{
                                          textDecoration: "none",
                                          color: "#22477E",
                                        }}
                                      >
                                        <ListItemText
                                          primary={secListItem.value}
                                        />
                                      </Link>
                                    ) : (
                                      <Grid>
                                        <ListItemText>
                                          <Grid
                                            style={{
                                              display: "flex",
                                              gap: "10px",
                                            }}
                                          >
                                            <KeyboardDoubleArrowRightIcon
                                              style={{ color: "#22477E" }}
                                            />
                                            {secListItem.value}
                                          </Grid>
                                        </ListItemText>
                                      </Grid>
                                    )}
                                  </ListItem>
                                ))}
                              </List>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Slide>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid
            elevation={3}
            item
            lg={2}
            md={2}
            sm={3}
            xs={4}
            className="fixed-ads"
            id="advgrid"
            sx={{
              backgroundColor: "#BFD8AF",
              height: "full",
              padding: "10px",
              display: { xs: "none", sm: "block", md: "block", lg: "block" },
              width: "100%",
              border: "1px solid green",
            }}
          >
            <Grid style={{marginTop:"60px"}}>
              <Ads />
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};
export default Home;
