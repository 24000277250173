import React from "react";
import {
  Grid,
  Grow,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";
import ImageSlider from "../../../layout/imageslider";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import BreadcrumbMenu from "../../../layout/BreadcrumbMenu";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Learnmore1 = () => {
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <ImageSlider /> */}
      <Grow in={true} timeout={1500}>
        <Grid container style={{ color: "#1A355D", padding: "10px 0" }}>
          <Grid item xs={12} style={{ paddingLeft: "100px" }}>
            <BreadcrumbMenu
              currComp="Articles And Information"
              parentComp="Home"
              parentCompRoute="/"
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "0 16px 10px 16px" }}>
            <Typography variant="h6" fontWeight="bold">
              How to become a Foster care Parent
            </Typography>
          </Grid>
        </Grid>
      </Grow>
      <Grow in={true} timeout={1500}>
        <Grid
          container
          xs={11}
          sm={10}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            id="caseworker"
            style={{
              marginBottom: "30px",
              marginTop: "30px",
              width: "90%",
              textAlign: "justify",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardHeader
              style={{ backgroundColor: "rgb(53, 103, 178)", width: "100%" }}
            ></CardHeader>
            <CardContent style={{ width: "90%" }}>
              <Typography variant="h5" style={{ paddingBottom: "20px" }}>
                Expect to work with the child's caseworker
              </Typography>
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Since most children in foster care will have regular visits with
                their birth parents, or the family members from which they have
                been removed (including siblings), a caseworker will be involved
                in organizing these visits. Though the court will make the
                decisions on supervision, the caseworker will be coordinating
                the time and location of the visits.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                With the exception of cases where extreme abuse has occurred,
                most often the plan of the caseworker is to return the child to
                their original home. The biological parents are provided help to
                aid in this process, and the foster parent is involved in
                helping achieve this goal.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon /> After a set period of time, reunification
                may no longer be a viable option. In this instance, the State
                will file for legal termination of parental rights, or the birth
                parents may surrender rights, rather then going through a trial
                process. A child is not available for adoption until the
                parental rights of the biological parents have been removed.
              </Typography>
              <br />
            </CardContent>
          </Card>

          <Card
            style={{
              marginBottom: "30px",
              marginTop: "30px",
              width: "90%",
              textAlign: "justify",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardHeader
              style={{ backgroundColor: "rgb(53, 103, 178)", width: "100%" }}
            ></CardHeader>
            <CardContent style={{ width: "90%" }}>
              <Typography variant="h5" style={{ paddingBottom: "20px" }}>
                Provide for the child's well-being
              </Typography>
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                If you are caring for a foster child in your home, you must
                ensure that their well-being is met, including emotional,
                medical & dental, and educational needs. Beyond regular medical
                & dental care, often times foster children will require therapy
                and treatment, due to the circumstances of their particular
                situation. Because foster children have often been the subject
                of physical or sexual abuse, emotional abuse, or neglect, they
                will often have special needs above and beyond other children.{" "}
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Detailed information from a case worker is essential to ensure
                that all of the needs of the child are clearly understood and
                are met. Here is a brief list of the needs you may be required
                to meet for foster children:{" "}
              </Typography>
              <br />
              <Grid style={{ padding: "10px", display: "flex" }}>
                <ArrowRightIcon />
                <Typography>
                  Medical needs - children who receive foster care payments are
                  usually eligible for Medicaid from their specific state
                </Typography>
              </Grid>
              <Grid style={{ padding: "10px", display: "flex" }}>
                <ArrowRightIcon />
                <Typography>
                  Food, Clothing, School Supplies - the state will usually
                  reimburse foster parents for food, clothing and the normal
                  supplies a child would need for school.The rates of
                  reimbursement are based on any special needs the child may
                  have.
                </Typography>
              </Grid>
              <Grid style={{ padding: "10px", display: "flex" }}>
                <ArrowRightIcon />
                <Typography>
                  Sleeping - children may share bedrooms, but must have a
                  separate bed. Children of the opposite sex may not share a
                  room if above a certain age, which varies by state. Of course
                  behavior concerns must be taken into account as well when
                  considering arrangements.Children are required to have 80
                  square feet of space in a room, or 40 square feet of space
                  when sharing a room.
                </Typography>
              </Grid>
              <Grid style={{ padding: "10px", display: "flex" }}>
                <ArrowRightIcon />
                <Typography>
                  Other concerns - other circumstances may exists on a case by
                  case basis, so it is important to have the needed details from
                  the child's caseworker.
                </Typography>
              </Grid>
              <br />
            </CardContent>
          </Card>

          <Card
            style={{
              marginBottom: "30px",
              marginTop: "30px",
              width: "90%",
              textAlign: "justify",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardHeader
              style={{ backgroundColor: "rgb(53, 103, 178)", width: "100%" }}
            ></CardHeader>
            <CardContent style={{ width: "90%" }}>
              <Typography variant="h5" style={{ paddingBottom: "20px" }}>
                Make Time for Yourself to Prevent Burnout{" "}
              </Typography>
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Any parent can face burnout, as they balance the needs of their
                children with their own. This occurs with foster parents as
                well. If you take time for yourself and prevent burnout before
                it happens, you will be a better parent and foster parent.{" "}
              </Typography>{" "}
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Babysitters are appropriate for foster parents, but check with
                your state for specific rules and requirements for child care in
                a foster situation. Texas state law requires that babysitters be
                at least 16. Respite, or short-term care to give a regular
                caregiver or foster parent a break, may also be an option.
              </Typography>{" "}
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Check with your foster child's Texas Foster Care Service Case
                Manager for specific details.
              </Typography>
              <br />
            </CardContent>
          </Card>

          {/* second learn more button Card */}
          <Card
            id="childrenshome"
            style={{
              marginBottom: "30px",
              marginTop: "30px",
              width: "90%",
              textAlign: "justify",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardHeader
              style={{ backgroundColor: "rgb(53, 103, 178)", width: "100%" }}
            ></CardHeader>
            <CardContent style={{ width: "90%" }}>
              <Typography variant="h5" style={{ paddingBottom: "20px" }}>
                Volunteer at a children’s home
              </Typography>
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Children’s homes can be the first location for foster children,
                or even their home in between foster families. Children’s homes
                are always in need of volunteers to help with many different
                tasks. Sorting and organizing donations, reading or playing
                games with the children, or even getting involved in outdoor
                activities are all opportunities. Contact your local children’s
                home and find out how you can help.
              </Typography>
              <br />
              <Typography variant="h5">Help drive a kid around</Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon /> foster agencies need help taking children to
                their appointments and meetings, including medical and dental
                visits, visiting their birth families, or even to a foster home.
                Some trips can be over long distances, but many need help right
                in your area. You can call your local agency to see if they have
                this need.
              </Typography>
              <br />
              <Typography variant="h5">Be a Big Brother or Sister</Typography>
              <br />
              <Typography
                style={{
                  display: "flex",
                  // flexDirection: "column",
                  gap: "10px",
                }}
              >
                <DoubleArrowIcon />
                spending a few hours a week with a child can make a huge
                different in their life. It can be fun to work or play with a
                child at their hobby or introduce them to something new. Though
                the Big Brother/Big Sister program includes non-foster children
                as well, foster children are a big part of their program. Find
                out more at
                <br />
              </Typography>
              <br />
              <span
                style={{
                  // color: "darkblue",
                  display: "flex",
                  gap: "10px",
                  // fontWeight: "bold",
                  // alignItems: "center",
                }}
              >
                <DoubleArrowIcon />{" "}
                <Typography>Big Brothers Big Sisters of South Texas</Typography>
              </span>

              <br />
              <Typography variant="h5">Help with a Job</Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon /> Many young people in a foster care program
                have a hard time finding part time work. Having a job can give a
                foster kid a sense of worth and help them feel empowered.
                Consider helping a foster child with a first job to gain
                valuable work experience.
              </Typography>
              <br />
              <Typography variant="h5"> Donate</Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                There are always plenty of items needed by a foster care agency
                or children’s home. Shoes, clothes, toys, and school supplies
                all help to make the environment better for foster kids. Since
                many children don’t have luggage or suitcases to carry their
                belongings from home to home, these are always a good idea. Only
                donate items that are in good or like-new condition. Some
                agencies only allow new items, so check first before making your
                donations.
              </Typography>
              <br />
              <Typography variant="h5">Help with Respite Care</Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Respite care is a way to provide relief to other foster families
                or to help with emergency care of foster children. Often this
                involves having a foster child in your home for a limited period
                of time from a few days to a month.
              </Typography>
              <br />
              <Typography style={{ display: "flex", gap: "10px" }}>
                <DoubleArrowIcon />
                Children in foster care need your help! Call your local
                children’s home or foster care agency to see what you can do.
              </Typography>
              <br />
            </CardContent>
          </Card>
        </Grid>
      </Grow>
    </div>
  );
};

export default Learnmore1;
